export const BILLING_PAGE_HEADER_TITLES = [
  {
    title: 'Organization name',
    field: 'name',
  },
  {
    title: 'Organization address',
    field: 'address',
  },
  {
    title: 'NMG ID',
    field: 'nmgId',
  },
  {
    title: 'Active users',
    field: 'activeUsers',
  },
  {
    title: 'Total users',
    field: 'totalUsers',
  },
  {
    title: 'Registered',
    field: 'createdAt',
  },
]
