import { gql } from '@apollo/client'

export const CREATE_RETAILER_ADMIN = gql`
  mutation CreateRetailerAdmin(
    $organization: String!
    $name: String!
    $email: String!
    $password: String
    $nmgId: String!
  ) {
    CreateRetailerAdmin(
      organization: $organization
      name: $name
      email: $email
      password: $password
      nmgId: $nmgId
  ) {
      id
      nmgId
      name
      email
      phoneNumber
      accessList {
        organization
        store
        roles
      }
    }
  }
`

export const UPDATE_RETAILER_ADMIN = gql`
  mutation UpdateUser($id: ID!, $name: String, $email: String) {
    UpdateUser(id: $id, name: $name, email: $email) {
      id
      name
      email
    }
  }
`
