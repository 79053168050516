import React from 'react'
import PropTypes from 'prop-types'
import { ModalCheckBox } from './ModalCheckbox'

export const AddQuestionModalCheckbox = ({ isQuestionTypeText, register }) => {
  const name = isQuestionTypeText ? 'isLongReply' : 'isMultipleChoice'
  const title = isQuestionTypeText ? 'Allow long reply' : 'Allow multiple choice'

  return (
    <ModalCheckBox
      name={name}
      text={title}
    >
      <input
        {...register(name)}
        className="mr-4"
        type="checkbox"
        value={1}
      />
    </ModalCheckBox>
  )
}

AddQuestionModalCheckbox.propTypes = {
  isQuestionTypeText: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
}
