import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody } from 'Pages/Components/TableWithStyles'
import { isMobile } from 'react-device-detect'
import { TableHeader } from 'Components/TableHeader'
import WorkflowTableRow from './workflowTableRow'
import { workflowsHeaders } from './workflowTableData'

export const WorkflowTable = ({ workflows, actions, refetchWorkflows }) => {
  const handleCellClick = (field, direction) => {
    refetchWorkflows({
      sorting: {
        field,
        direction,
      },
    })
  }
  return (
    <Table>
      {!isMobile && (
        <TableHeader
          handleCellClick={handleCellClick}
          headerTitles={workflowsHeaders}
        />
      )}
      <TableBody>
        {workflows && workflows.map(workflow => (
          <WorkflowTableRow
            key={workflow.id}
            actions={actions}
            workflow={workflow}
          />
        ))}
      </TableBody>
    </Table>
  )
}
WorkflowTable.propTypes = {
  workflows: PropTypes.array,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onReactive: PropTypes.func,
  }),
  refetchWorkflows: PropTypes.func.isRequired,
}

WorkflowTable.defaultProps = {
  workflows: [],
  actions: {
    onEdit: () => {},
    onDelete: () => {},
    onReactive: () => {},
  },
}
