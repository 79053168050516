import React from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'
import ColorPicker from './ColorPicker'

import './styles.scss'

const ColorComponent = ({
  settings,
  onChange,
}) => (
  <div className="color-container">
    <div className="row">
      <div className="col-6">
        <ColorPicker
          icon={<GetAppIcon />}
          label="Brand Color"
          value={settings.messengerBrandColor}
          onChange={value => onChange('messengerBrandColor', value)}
        />
      </div>
      <div className="col-6">
        <ColorPicker
          icon={<GetAppIcon />}
          label="Header background"
          value={settings.messengerHeaderBackground}
          onChange={value => onChange('messengerHeaderBackground', value)}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <ColorPicker
          icon={<GetAppIcon />}
          label="Chat background"
          value={settings.messengerChatBackground}
          onChange={value => onChange('messengerChatBackground', value)}
        />
      </div>
      <div className="col-6">
        <ColorPicker
          icon={<GetAppIcon />}
          label="Active call background"
          value={settings.messengerActiveAllBackground}
          onChange={value => onChange('messengerActiveAllBackground', value)}
        />
      </div>
    </div>
  </div>
)

export default ColorComponent

ColorComponent.propTypes = {
  settings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
