import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { getPriceFormat, getContrastPercentage } from 'Helpers/helpers'

import { useStyles } from './styles'

export const StatisticsItem = ({ item }) => {
  const classes = useStyles()
  const { name, value, prevValue } = item
  const percentage = getContrastPercentage(value, prevValue, 2)

  return (
    <div className={classes.statisticsItem}>
      <div className={classes.top}>
        <Typography className={classes.name}>{name}</Typography>
      </div>
      <div className={classes.bottom}>
        <Typography className={classes.value}>{getPriceFormat(value)}</Typography>
        {percentage !== null && (
          <Typography className={percentage >= 0 ? classes.green_up : classes.red_down}>
            {percentage >= 0 ? `+${ percentage}` : percentage}
            %
          </Typography>
        )}
      </div>
      <div className={classes.footer}>
        <Typography className={classes.prevDesc}>vs previous period</Typography>
        <Typography className={classes.prevValue}>{getPriceFormat(prevValue)}</Typography>
      </div>
    </div>
  )
}

StatisticsItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    prevValue: PropTypes.number,
    key: PropTypes.string,
  }).isRequired,
}
