/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import {
  Save, Add, AutorenewOutlined, AccessTime,
  QuestionAnswerOutlined,
  SubtitlesOutlined,
  EditOutlined,
  CallSplit,
} from '@material-ui/icons'
import {
  Label,
  Input as BootstrapInput,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from 'Components/common/Input'
import { SelectWithStyles } from 'Components/common/Select'
import { MenuItem } from '@material-ui/core'
import { ModalChoices } from 'Components/AddQuestionModal/ModalChoices'
import { useApolloMutation, useApolloLazyQuery } from 'GraphQL/apollo'

import './styles.scss'
import { CREATE_WORKFLOW, UPDATE_WORKFLOW } from 'GraphQL/mutations/workflow'
import { toast } from 'react-toastify'
import { useAuthContext } from 'Providers/AuthProvider'
import { LONG_REPLY_LIMIT, SHORT_REPLY_LIMIT } from 'Constants/preChatQuestions'
import { GET_WORKFLOW_BY_ID } from 'GraphQL/queries/workflow'
import { validationSchema } from './validationSchema'

const conditionTypes = [
  {
    key: 'visit_url',
    value: 'Visit to a specific page URL',
  },
  {
    key: 'time_on_site',
    value: 'Time on Site',
  },
  {
    key: 'visit_count_for_session',
    value: 'Pages/Visit',
  },
  {
    key: 'return_visitor',
    value: 'Return Visitor',
  },
]
const conditionOperators = [
  {
    key: 'greater_than',
    value: 'Greater Than (>)',
  },
  {
    key: 'equal',
    value: 'Equal (=)',
  },
  {
    key: 'less_than',
    value: 'Less Than (<)',
  },
]
const conditionValueField = {
  visit_url: 'Page URL',
  time_on_site: 'Duration in seconds users stay on the site',
  visit_count_for_session: 'Visit count per session',
  return_visitor: 'Duration in hours since users visited last time',
}
const CreateUpdateWorkflowModal = ({
  workflowId,
  edit,
  open,
  onClose,
  typesOfQuestion,
  refetchWokflows,
  ...modalProps
}) => {
  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const [conditionValueLabel, setConditionValueLabel] = useState('Condition Value')
  const [loadWorkflowQuery, { data: dataWorkflowQuery }] = useApolloLazyQuery(GET_WORKFLOW_BY_ID)
  const { getOrganizationId } = useAuthContext()
  const organizationId = getOrganizationId()

  const [questionType, setQuestionType] = useState(typesOfQuestion[0])
  const [choices, setChoices] = useState([])

  const handleCancelCreateUpdateWorkflowModal = () => {
    reset()
  }

  const onError = (submitErros, e) => {
    console.error('Agent submitErros', { submitErros }, e)
  }
  const onQuestionTypeChange = (event) => {
    setQuestionType(event.target.value)
  }
  const [createWorkflowMutation] = useApolloMutation(CREATE_WORKFLOW, {
    onCompleted: (data) => {
      const { CreateWorkflow: { name } } = data
      refetchWokflows()
      toast.success(`${name} Workflow created`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updateWorkflowMutation] = useApolloMutation(UPDATE_WORKFLOW, {
    onCompleted: (data) => {
      const { UpdateWorkflow: { name } } = data
      refetchWokflows()
      toast.success(`${name} Workflow updated`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (edit) {
      loadWorkflowQuery({
        variables: {
          id: workflowId,
        },
      })
    }
  }, [edit, workflowId])

  useEffect(() => {
    if (edit && dataWorkflowQuery) {
      const workflow = dataWorkflowQuery.Workflow
      const questionInfo = workflow.triggers?.[0].actions?.[0].questionInfo
      const condition = workflow.triggers?.[0].conditions?.[0]
      setValue('name', workflow.name)
      setValue('type', condition.type)
      setValue('value', condition.value)
      setValue('operator', condition.operator)
      setConditionValueLabel(conditionValueField[condition.type])
      switch (questionInfo?.type) {
        case 'input':
          setQuestionType('text')
          if (questionInfo.max === LONG_REPLY_LIMIT) {
            setValue('isLongReply', true)
          }
          break
        case 'radio':
          setQuestionType('choice')
          break
        case 'checkbox':
          setQuestionType('choice')
          setValue('isMultipleChoice', true)
          break
        default:
          break
      }
      if (questionInfo.min === 1) {
        setValue('isRequired', true)
      }
      setValue('title', questionInfo?.title)
      setValue('question', questionInfo?.description)
      setChoices(Object.entries(questionInfo.resultOptions).map(([id, value]) => ({ id, value })))
    }
  }, [dataWorkflowQuery])
  const createWorkflowTigger = (data) => {
    let workflowQuestionType = 'input'
    if (questionType !== 'text') {
      if (data.isMultipleChoice) {
        workflowQuestionType = 'checkbox'
      } else {
        workflowQuestionType = 'radio'
      }
    }
    const getMaxAnswers = () => {
      if (workflowQuestionType === 'input') {
        return data.isLongReply ? LONG_REPLY_LIMIT : SHORT_REPLY_LIMIT
      }
      if (workflowQuestionType === 'checkbox') {
        return choices.length
      }
      return 1
    }
    const finalChoices = choices.reduce((prev, choice) => ({ ...prev, [choice.id]: choice.value }), {})
    return {
      triggers: [
        {
          conditions: [
            {
              type: data.type,
              value: data.value,
              operator: data.operator,
            }],
          actions: [
            {
              type: 'ask_question',
              questionFeedback: edit ? dataWorkflowQuery.Workflow.triggers?.[0].actions?.[0].questionFeedback : '',
              questionInfo: {
                type: workflowQuestionType,
                title: data.title,
                description: data.question,
                min: data.isRequired ? 1 : 0,
                max: getMaxAnswers(),
                resultOptions: finalChoices,
              },
            },
          ],
        },
      ],
    }
  }
  const createWorkflow = (data) => {
    createWorkflowMutation({
      variables: {
        ...createWorkflowTigger(data),
        organization: organizationId,
        name: data.name,
      },
    })
  }
  const updateWorkflow = (data) => {
    updateWorkflowMutation({
      variables: {
        ...createWorkflowTigger(data),
        id: workflowId,
        name: data.name,
      },
    })
  }

  const onSelectConditionType = (e) => {
    setValue('type', e.target.value)
    setConditionValueLabel(conditionValueField[e.target.value])
  }
  const onSubmit = (data) => {
    if (questionType === 'choice' && choices.length < 2) {
      toast.error('Question should contain at least 2 choices')
      return
    }
    const choicesCheck = choices.every(choice => !!choice.value)
    if (!choicesCheck) return

    const isValidURL = (url) => {
      const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url)
      return valid
    }
    const isNumeric = val => /^-?\d+$/.test(val)
    switch (data.type) {
      case 'visit_url':
        if (!isValidURL(data.value)) {
          toast.error(`${data.value} is not a valid URL.`)
          return
        }
        break
      default:
        if (!isNumeric(data.value)) {
          toast.error(`${data.value} is not a valid number.`)
          return
        }
        break
    }
    if (edit) {
      updateWorkflow(data)
    } else {
      createWorkflow(data)
    }
    onClose()
  }
  return (
    <ModalBase
      {...modalProps}
      acceptIcon={edit ? <Save /> : <Add />}
      acceptTitle={edit ? 'Save Workflow' : 'Create Workflow'}
      open={open}
      title={edit ? 'Edit Workflow' : 'Create Workflow'}
      titleCancel="Cancel"
      onAccept={handleSubmit(onSubmit, onError)}
      onCancel={handleCancelCreateUpdateWorkflowModal}
      onClose={onClose}
    >
      <form className="workflow-modal-container">
        <Input
          control={control}
          error={errors.name}
          errorMessage={errors.name?.message}
          label="Workflow name"
          labelIcon={<AutorenewOutlined />}
          name="name"
          placeholder="Workflow 1"
          fullWidth
        />
        <SelectWithStyles
          control={control}
          error={!!errors.type}
          errorMessage={errors.type?.message}
          label="Condition Type"
          labelIcon={<AccessTime />}
          name="type"
          displayEmpty
          fullWidth
          onChange={onSelectConditionType}
        >
          {conditionTypes.map(conditionType => (
            <MenuItem
              key={conditionType.key}
              value={conditionType.key}
            >
              {conditionType.value}
            </MenuItem>
          ))}
        </SelectWithStyles>
        <SelectWithStyles
          control={control}
          error={!!errors.operator}
          errorMessage={errors.operator?.message}
          label="Condition Operator"
          labelIcon={<CallSplit />}
          name="operator"
          displayEmpty
          fullWidth
        >
          {conditionOperators.map(conditionOperator => (
            <MenuItem
              key={conditionOperator.key}
              value={conditionOperator.key}
            >
              {conditionOperator.value}
            </MenuItem>
          ))}
        </SelectWithStyles>
        <Input
          control={control}
          error={errors.value}
          errorMessage={errors.value?.message}
          label={conditionValueLabel}
          labelIcon={<EditOutlined />}
          name="value"
          displayEmpty
          fullWidth
        />
        <label>Select type of question</label>
        <div className="type-container">
          {
            typesOfQuestion.map(item => (
              <div className={item === questionType && 'selected'}>
                <Label>
                  <BootstrapInput
                    {...register('questionType')}
                    checked={questionType === item}
                    className="position-relative"
                    name="questionType"
                    type="radio"
                    value={item}
                    onChange={onQuestionTypeChange}
                  />
                  {item}
                </Label>
              </div>
            ))
          }
        </div>
        <Input
          className="mb-2"
          control={control}
          error={errors?.title}
          errorMessage={errors?.title?.message}
          label="Question Header"
          labelIcon={<SubtitlesOutlined />}
          name="title"
          fullWidth
        />

        <Input
          control={control}
          error={errors?.question}
          errorMessage={errors?.question?.message}
          label="Question Description"
          labelIcon={<QuestionAnswerOutlined />}
          name="question"
          fullWidth
        />

        <label className="mr-2">
          Required
        </label>
        <input
          {...register('isRequired')}
          className="mr-4"
          type="checkbox"
          value={1}
        />
        <label className="mr-2">
          {questionType === 'text' ? 'Allow long reply' : 'Allow multiple choice'}
        </label>
        <input
          {...register(questionType === 'text' ? 'isLongReply' : 'isMultipleChoice')}
          className="mr-4"
          type="checkbox"
          value={1}
        />
        <div>
          {
            questionType === 'choice' && (
              <ModalChoices
                choices={choices}
                questionType={questionType}
                setChoices={setChoices}
              />
            )
          }
        </div>
      </form>
    </ModalBase>
  )
}
export default CreateUpdateWorkflowModal

CreateUpdateWorkflowModal.propTypes = {
  workflowId: PropTypes.string,
  edit: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  refetchWokflows: PropTypes.func,
  typesOfQuestion: PropTypes.array,
}

CreateUpdateWorkflowModal.defaultProps = {
  workflowId: null,
  edit: false,
  open: false,
  typesOfQuestion: [],
  onClose: () => {},
  refetchWokflows: PropTypes.func,
}
