import { useStoresContext } from 'Providers/StoresProvider'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { Checkbox } from '@material-ui/core'
import StorefrontIcon from '@material-ui/icons/Storefront'
import { useStyles } from 'Pages/messenger/chat/components/Inbox/Stores/styles'

export const Stores = () => {
  const classes = useStyles()
  const { stores, selectedStores, setSelectedStores } = useStoresContext()
  const [initialLoad, setInitialLoad] = useState(true)

  const handleCheckboxChange = (storeId) => {
    if (selectedStores.includes(storeId)) {
      const stores$ = [...selectedStores]
      const storeIndex = stores$.indexOf(storeId)
      stores$.splice(storeIndex, 1)
      setSelectedStores(stores$)
    } else {
      setSelectedStores([storeId, ...selectedStores])
    }
  }

  const handleSelectAllStores = () => {
    if (selectedStores?.length >= stores?.length) {
      setSelectedStores([0])
    } else {
      const storeIds = stores.map(store => store.id)
      setSelectedStores(storeIds)
    }
  }

  useEffect(() => {
    if (initialLoad && stores?.length) {
      const storeIds = stores?.map(store => store.id)
      setSelectedStores(storeIds)
      setInitialLoad(false)
    }
  }, [stores, initialLoad])
  return (
    <div className={classes.storesWrapper}>
      <div className={classes.title}>
        <StorefrontIcon className={classes.storeIcon} />
        <span>Stores:</span>
      </div>
      <div className={classes.allStores}>
        <Checkbox
          checked={selectedStores?.length === stores?.length}
          classes={{
            root: classes.storeCheckbox,
            checked: classes.checked,
          }}
          size="small"
          onChange={handleSelectAllStores}
        />
        <span>All</span>
      </div>
      {stores?.map(store => (
        <div
          key={store.name}
          className={classNames(
            classes.storeWrapper,
            selectedStores.includes(store.name) && classes.isActive,
          )}
        >
          <Checkbox
            checked={selectedStores.includes(store.id)}
            classes={{
              root: classes.storeCheckbox,
              checked: classes.checked,
            }}
            iconstyle={{ color: 'red' }}
            size="small"
            onChange={() => handleCheckboxChange(store.id)}
          />
          <div className={classes.storeInfo}>
            <span>{store.name}</span>
            <span className={classes.storeAddress}>{`${store.address}, ${store.state} ${store.postalCode}`}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
