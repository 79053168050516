import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  FormGroup,
  Label,
} from 'reactstrap'

import { AddQuestionModal } from 'Components/AddQuestionModal/AddQuestionModal'
import { QuestionsPageHeader } from 'Components/QuestionsPageHeader'
import { FeedbackMessage } from 'Components/FeedbackMessage'

import { useAuthContext } from 'Providers/AuthProvider'

import { useApolloQuery } from 'GraphQL/apollo'
import { GET_CHAT_QUESTIONS } from 'GraphQL/queries/questions'
import { GET_STORES } from 'GraphQL/queries/store'

import { Card } from '../components/Card'

function PreQualifiedQuestions() {
  const [activePreChatQuestions, setActivePreChatQuestions] = useState([])
  const [inactivePreChatQuestions, setInactivePreChatQuestions] = useState([])
  const [isAddQuestionModalOpened, setIsAddQuestionModalOpened] = useState(false)
  const [storeId, setStoreId] = useState(null)
  const { currentUser } = useAuthContext()

  const filterQuestions = (questions) => {
    const activeQuestions = questions.filter(question => question.active)
    setActivePreChatQuestions(activeQuestions)
    const inactiveQuestions = questions.filter(question => !question.active)
    setInactivePreChatQuestions(inactiveQuestions)
  }
  const setData = (data) => {
    filterQuestions(data)
  }
  const { data: questionItems, refetch: refetchQuestions } = useApolloQuery(GET_CHAT_QUESTIONS, {
    variables: { store: storeId, relation: 'preChat' },
  })

  const organizationId = currentUser.accessList[0].organization

  const { data: stores } = useApolloQuery(GET_STORES, {
    variables: {
      organization: organizationId,
    },
  })

  useEffect(() => {
    if (stores) {
      const { Stores: { items: stores$ } } = stores

      setStoreId(stores$[0]?.id)
    }
  }, [stores])

  const activeQuestionsLength = activePreChatQuestions.length
  const inactiveQuestionsLength = inactivePreChatQuestions.length

  useEffect(() => {
    const result = questionItems?.Feedbacks?.items
    if (result) {
      setData(result)
    }
  }, [questionItems?.Feedbacks?.items])

  const changeStore = event => setStoreId(event.target.value)

  return (
    <div className="auto-responder-page">
      <AddQuestionModal
        activeQuestionsLength={activeQuestionsLength}
        isOpen={isAddQuestionModalOpened}
        refetchQuestions={refetchQuestions}
        storeId={storeId}
        type="preChat"
        onCancel={() => setIsAddQuestionModalOpened(false)}
      />
      <QuestionsPageHeader
        changeStore={changeStore}
        openQuestionModal={setIsAddQuestionModalOpened}
        storeId={storeId}
        stores={stores?.Stores.items}
        title="Pre-chat questions"
      />
      <div className={isMobile ? 'settings-page-rows pt-10 w-100' : 'settings-page-rows pt-10 w-80'}>
        {/* label ACTIVE PRE-CHAT MESSAGE & QUESTIONS */}
        <section className="page-row d-flex">
          <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-40 d-flex flex-column'}>
            <span className="settings-label">
              Active pre-chat message & questions
            </span>
            <span className="settings-label-description">
              Add up to 3 active questions to ask before you start a conversation with a user.
            </span>
          </div>
          <div className={isMobile ? 'd-flex flex-column w-100 mt-20' : 'd-flex flex-column w-60 ml-20'}>
            <FeedbackMessage
              configureMessageTitle="Pre-chat message"
              relation="preChat"
              storeId={storeId}
            />
            <FormGroup className="mb-15">
              <Label>
                <span className="ti-help-alt" />
                <span className="profile-label pl-10">
                  Active pre-chat questions
                  (
                  {activePreChatQuestions.length}
                  /3)
                </span>
              </Label>

              <div>
                {activePreChatQuestions.map(card => (
                  <Card
                    key={card.id}
                    activeQuestionsLength={activeQuestionsLength}
                    id={card.id}
                    inactiveQuestionsLength={inactiveQuestionsLength}
                    isActive={card.active}
                    refetchQuestions={refetchQuestions}
                    relation="preChat"
                    storeId={storeId}
                    drag
                  />
                ))}
              </div>
            </FormGroup>
          </div>
        </section>

        {/* label INACTIVE PRE-CHAT MESSAGE & QUESTIONS */}
        <section className="page-row d-flex">
          <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-40 d-flex flex-column'}>
            <span className="settings-label">
              Inactive pre-chat message & questions
            </span>
            <span className="settings-label-description">
              Add up to 3 active questions to ask before you start a conversation with a user.
            </span>
          </div>
          <div className={isMobile ? 'd-flex flex-column w-100 mt-20' : 'd-flex flex-column w-60 ml-20'}>
            <FormGroup className="mb-15">
              <Label>
                <span className="ti-help-alt" />
                <span className="profile-label pl-10">
                  Inactive pre-chat questions
                  {` (${inactivePreChatQuestions.length})`}
                </span>
              </Label>
              <div className="inactive-questions-container">
                {inactivePreChatQuestions.map(card => (
                  <Card
                    key={card.id}
                    activeQuestionsLength={activeQuestionsLength}
                    id={card.id}
                    inactiveQuestionsLength={inactiveQuestionsLength}
                    isActive={card.active}
                    refetchQuestions={refetchQuestions}
                    relation="preChat"
                    storeId={storeId}
                    drag
                  />
                ))}
              </div>
            </FormGroup>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PreQualifiedQuestions
