import { gql } from '@apollo/client'

export const GET_PAYMENT_ANALYTICS = gql`
  query PaymentAnalytics($organization: String, $date: DateInput){
    PaymentAnalytics(organization: $organization, date: $date){
      name
      date {
          from
          to
      }
      totalPayments
      totalPaymentsForPrevPeriod
      totalAchPayments
      totalAchPaymentsForPrevPeriod
      totalCcPayments
      totalCcPaymentsForPrevPeriod
      avgPaymentsPerOrg
      avgPaymentsPerOrgForPrevPeriod
      paymentsPerDay {
          date
          value
      }
    }
  }
`

export const GET_PAYMENT_HISTORY = gql`
  query PaymentHistory(
    $organization: String
    $date: DateInput
    $pagination: PaginationInput
    $sorting: SortingInput
  ) {
    PaymentHistory(organization: $organization, date: $date, pagination: $pagination, sorting: $sorting) {
      items {
        id
        paymentRequest
        organization
        store

        amount
        success
        paymentMethod
        retailer {
          name
          address
          site
          logo
        }
        transactionDetails
        createdAt
        updatedAt
      }
      pagination {
        offset
        size
        totalItems
      }
    }
  }
`
