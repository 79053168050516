import React from 'react'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'

import 'Components/common/Input/BaseInput/styles.scss'
import BaseInput from '../BaseInput'

const ControlledInput = ({
  control,
  name,
  ...restProps
}) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  })

  return (
    <BaseInput
      {...inputProps}
      {...restProps}
      inputRef={ref}
      disableUnderline
    />
  )
}

ControlledInput.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
}

ControlledInput.defaultProps = {
  control: {},
}

export default ControlledInput
