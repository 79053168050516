import React from 'react'
import PropTypes from 'prop-types'
import 'Components/common/Input/BaseInput/styles.scss'
import BaseInput from './BaseInput'
import ControlledInput from './ControlledInput'

const Input = ({
  control,
  ...restProps
}) => {
  if (control) {
    return (
      <ControlledInput
        control={control}
        {...restProps}
      />
    )
  }

  return <BaseInput {...restProps} />
}

Input.propTypes = {
  control: PropTypes.shape({}),
}

Input.defaultProps = {
  control: null,
}

export default Input
