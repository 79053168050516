import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import AsyncSelect from 'react-select/async'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

export const StoresSelect = ({
  setSelectedStores,
  handleOnBlur,
  stores,
  refetchStores,
}) => {
  const [searchQuery, setSearchQuery] = useState('')

  const makeOptions = useCallback(() => stores.map(organization => ({
    value: organization.id, label: organization.name,
  })), [stores])

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      refetchStores({
        variables: {
          search: searchQuery,
        },
      }).then((res) => {
        const options = makeOptions(res?.data?.Stores.items)
        callback(options)
      })
    }
  }
  const handleInputChange = value => setSearchQuery(value)
  const DropdownIndicator = () => (<ArrowDropDownIcon />)
  return (
    <AsyncSelect
      components={{ DropdownIndicator }}
      defaultOptions={makeOptions()}
      loadOptions={loadOptions}
      passEmptyOprions={false}
      TextFieldProps={{
        placeholder: 'store',
        label: 'Store',
        InputLabelProps: {
          shrink: true,
        },
      }}
      onBlur={handleOnBlur}
      onChange={value => setSelectedStores([value])}
      onInputChange={handleInputChange}
    />
  )
}

StoresSelect.propTypes = {
  setSelectedStores: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape({})),
  refetchStores: PropTypes.func.isRequired,
}

StoresSelect.defaultProps = {
  stores: [],
}
