import React, { useState, useEffect } from 'react'
import { TableHeader } from 'Components/TableHeader'
import { BillingPageTableRow } from 'Pages/BillingPage/BillingPageTableRow'
import {
  Table,
  TableBody,
} from 'Pages/Components/TableWithStyles'
import { TableContainer } from '@material-ui/core'
import TablePagination from 'Pages/settings/components/TablePagination'
import { getDefaultDateFrom, convertDateToTimeStamp } from 'Helpers/helpers'
import { useOrganizationsBilling } from 'Pages/BillingPage/useOrganizationsBilling'
import { useOrganizationCSV } from 'Pages/BillingPage/useOrganizationCSV'
import { downloadCSV } from 'Helpers/json2CSV'
import { toast } from 'react-toastify'
import { isMobile } from 'react-device-detect'
import { BILLING_PAGE_HEADER_TITLES } from './config'
import { useStyles } from './styles'
import { AnalyticsPageHeader } from '../Components/AnalyticsPageHeader/AnalyticsPageHeader'
import './styles.scss'

const allOrganizationsOption = { value: '', label: 'All' }
const initSorting = { direction: 'ASC', field: 'name' }
const pagination = 25
const maxSize = 1000

export const BillingPage = () => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const [sorting, setSorting] = useState(initSorting)
  const [selectedDateFrom, setSelectedDateFrom] = useState(getDefaultDateFrom())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const [selectedOrganization, setSelectedOrganization] = useState(allOrganizationsOption)

  const {
    organizationBillingPagination,
    organizationsBillingData,
    getOrganizationBilling,
  } = useOrganizationsBilling()

  const requestDownloadCSV = (data) => {
    const timeText = `${convertDateToTimeStamp(selectedDateFrom)} to ${convertDateToTimeStamp(selectedDateTo)}`
    const fileName = `Billing report from ${timeText}`
    const result = []
    data.forEach((element) => {
      const obj = {
        'Organization name': element.name.replace(',', ''),
        'Organization address': element.address.replaceAll(',', ''),
        'NMG ID': element.nmgId.replace(',', ''),
        'Active users': element.activeUsers,
        'Total users': element.totalUsers,
        Registered: convertDateToTimeStamp(element.createdAt).replace(',', ''),
      }
      result.push(obj)
    })
    downloadCSV(result, fileName)
    toast.success('successfully exported')
  }

  const getOrganizationBillingCSV = useOrganizationCSV({ onCompleted: requestDownloadCSV })

  const total = organizationBillingPagination?.totalItems
  const totalforCSV = organizationBillingPagination?.totalItems || maxSize
  const queryParams = {
    pagination: {
      size: pagination,
      offset: (page - 1) * pagination,
    },
    date: {
      from: selectedDateFrom.toISOString(),
      to: selectedDateTo.toISOString(),
    },
    sorting: {
      ...sorting,
    },
    organization: selectedOrganization.value,
  }
  const queryParamsCSV = {
    pagination: {
      size: maxSize,
      offset: 1,
    },
    date: {
      from: selectedDateFrom.toISOString(),
      to: selectedDateTo.toISOString(),
    },
    sorting: {
      ...sorting,
    },
    organization: selectedOrganization.value,
  }
  useEffect(() => {
    getOrganizationBilling({
      variables: {
        ...queryParams,
      },
    })
  }, [selectedDateFrom, selectedDateTo, selectedOrganization])

  const countPage = Math.ceil(total / queryParams.pagination.size)

  const changePagination = (_, currentPage) => {
    getOrganizationBilling({
      variables: {
        ...queryParams,
        pagination: {
          offset: (currentPage - 1) * queryParams.pagination.size,
          size: queryParams.pagination.size,
        },
        date: {
          from: selectedDateFrom.toISOString(),
          to: selectedDateTo.toISOString(),
        },
      },
    })
    setPage(currentPage)
  }

  const handleCellClick = (field, direction) => {
    getOrganizationBilling({
      variables: {
        ...queryParams,
        sorting: {
          field,
          direction,
        },
      },
    })
    setSorting({
      field,
      direction,
    })
  }

  const handleExportCSV = () => {
    toast.warn('Please wait while exporting')
    getOrganizationBillingCSV({
      variables: {
        ...queryParamsCSV,
        pagination: {
          size: totalforCSV,
        },
      },
    })
  }

  const handleChangeOrganization = (org) => {
    setSelectedOrganization(org)
    setPage(1)
  }

  return (
    <div className="billing-page">
      <AnalyticsPageHeader
        handleExport={handleExportCSV}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        selectedOrganization={selectedOrganization}
        setSelectedDateFrom={setSelectedDateFrom}
        setSelectedDateTo={setSelectedDateTo}
        setSelectedOrganization={handleChangeOrganization}
        title="Billing report"
        withExport
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.billingTable}>
          {!isMobile && (
            <TableHeader
              handleCellClick={handleCellClick}
              headerTitles={BILLING_PAGE_HEADER_TITLES}
            />
          )}
          <TableBody>
            {organizationsBillingData.map(organization => (
              <BillingPageTableRow organization={organization} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.pagination}>
        <TablePagination
          count={countPage}
          page={page}
          onChange={changePagination}
        />
      </div>
    </div>
  )
}
