// sidebar nav links
const sidebarMenu = {
  retailerLinks: [
    {
      menu_title: 'Messenger',
      menu_icon: 'zmdi zmdi-comments',
      path: '/app/messenger',
      new_item: false,
      child_routes: null,
    },

    {
      menu_title: 'Settings',
      menu_icon: 'zmdi zmdi-settings',
      path: '/app/settings',
      new_item: false,
      child_routes: null,
    },
  ],
  retailerAdminLinks: [
    {
      menu_title: 'Messenger',
      menu_icon: 'zmdi zmdi-comments',
      path: '/app/messenger',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Customers',
      menu_icon: 'zmdi zmdi-accounts-outline',
      path: '/app/customers',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Analytics',
      menu_icon: 'zmdi zmdi-chart',
      path: '/app/analytics',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Payment Reports',
      menu_icon: 'zmdi zmdi-money-box',
      path: '/app/payments',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Settings',
      menu_icon: 'zmdi zmdi-settings',
      path: '/app/settings',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Workflows',
      menu_icon: 'zmdi zmdi-swap-alt',
      path: '/app/workflows',
      new_item: false,
      child_routes: null,
    },
  ],
  platformAdminLinks: [
    {
      menu_title: 'Organizations',
      menu_icon: 'zmdi zmdi-calendar-note',
      path: '/app/organizations',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Analytics',
      menu_icon: 'zmdi zmdi-chart',
      path: '/app/analytics',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Payment Reports',
      menu_icon: 'zmdi zmdi-money-box',
      path: '/app/payments',
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: 'Billing',
      menu_icon: 'zmdi zmdi-money-box',
      path: '/app/billing',
      new_item: false,
      child_routes: null,
    },
  ],
}

export default sidebarMenu
