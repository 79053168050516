import { AGENT_STATUSES } from 'Constants/common'
import { useEffect, useState } from 'react'
import { useFirebaseContext } from 'Providers/FirebaseProvider'

export const useAgentAvailability = (userId) => {
  const { db } = useFirebaseContext()
  const rootRef = db.ref()
  const [availability, setAvailability] = useState('')
  const rootPath = rootRef.child(`users/${userId}`)

  useEffect(() => {
    if (userId) {
      rootPath.on('value', (snapshot) => {
        const value = snapshot.val()
        if (value?.availability) {
          setAvailability(value.availability)
        }
        if (!value) {
          setAvailability(AGENT_STATUSES.OFFLINE)
        }
      })
    }
  }, [userId])

  return availability
}
