import { useRef, useEffect } from 'react'

export const useClickOutside = ({
  onClick,
}) => {
  const ref = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      const isEventTargetButton = Boolean(event.target.closest('button'))

      if (ref.current && !ref.current.contains(event.target) && !isEventTargetButton) {
        onClick(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClick])

  return ref
}
