import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useFirebaseContext } from 'Providers/FirebaseProvider'
import usePrevious from './usePrevious'

export const useConversation = () => {
  const { selectedUser } = useSelector(state => state?.chatAppReducer)
  const [conversation, setConversation] = useState(null)
  const [conversationListener, setConversationListener] = useState(null)
  const preSelectedUser = usePrevious(selectedUser)
  const { db } = useFirebaseContext()

  const listen = (endpoint, updaterFn) => {
    db.ref(endpoint).on('value', updaterFn)
    return () => db.ref(endpoint).off()
  }

  useEffect(() => {
    if (selectedUser?.conversationId !== preSelectedUser?.conversationId) {
      const conversationPath = selectedUser.conversationPath
      if (conversationListener) conversationListener()

      const listener = listen(
        `${conversationPath}/${selectedUser.storeId}/conversations/${selectedUser.conversationId}`,
        (snapshot) => { setConversation(snapshot.val()) },
      )
      setConversationListener(listener)
    }
  }, [selectedUser])

  return conversation ?? {}
}
