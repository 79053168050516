import PropTypes from 'prop-types'

export const userPropType = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  last_chat: PropTypes.string,
  isActive: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
})
