import React from 'react'
import { useDispatch } from 'react-redux'
import { List } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { onToggleMenu } from 'Store/Actions'
import PropTypes from 'prop-types'
import { useAuthContext } from 'Providers/AuthProvider'
import NavMenuItem from './NavMenuItem'
import LauncherVisibilitySwitch from './LauncherVisibilitySwitch'
import { useMenuItems } from '../../Hooks/useMenuItems'

function SidebarContent({ toggleSlider }) {
  const dispatch = useDispatch()
  const menuItems = useMenuItems()
  const { isPlatformAdmin, isPlatformAnalyst } = useAuthContext()

  const toggleMenu = (menu, stateCategory) => {
    const data = { menu, stateCategory }
    dispatch(onToggleMenu(data))
  }

  return (
    <div className="rct-sidebar-nav">
      <nav className="navigation">
        {menuItems && (
          <List className="rct-mainMenu p-0 m-0 list-unstyled">
            {menuItems.map(menu => (
              <NavMenuItem
                key={menu.menu_title}
                menu={menu}
                toggleSlider={toggleSlider}
                onToggleMenu={() => toggleMenu(menu, 'category1')}
              />
            ))}
          </List>
        )}
        {!isPlatformAdmin && !isPlatformAnalyst && <LauncherVisibilitySwitch />}
      </nav>
    </div>
  )
}
SidebarContent.propTypes = {
  toggleSlider: PropTypes.func.isRequired,

}

export default withRouter(SidebarContent)
