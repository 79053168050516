import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useStyles } from './styles'
import { AnalyticsTooltip as Tooltip } from '../../AnalyticsTooltip/AnalyticsTooltip'

const MAX_SHOW_DAYS = 31

export const VerticalChart = ({ daysRange, sessionsPerDay }) => {
  const classes = useStyles()
  const sessionsDays = useMemo(() => Object.keys(sessionsPerDay), [sessionsPerDay])
  const sessionsCount = useMemo(() => Object.keys(sessionsPerDay).map(key => sessionsPerDay[key]), [sessionsPerDay])
  const colors = useMemo(() => Array.from({ length: Object.keys(sessionsPerDay).length }, () => '#FF7A59'), [sessionsPerDay])

  const daysToShow = daysRange <= MAX_SHOW_DAYS ? daysRange : MAX_SHOW_DAYS

  const labels = sessionsDays.slice(0, daysToShow).map(row => moment(row, 'YYYY/MM/DD').format('MM/DD'))

  const chartData = {
    labels,
    datasets: [
      {
        data: sessionsCount,
        backgroundColor: colors,
      },
    ],
  }

  const options = {
    scale: {
      ticks: {
        precision: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <Tooltip title="Number of sessions per selected date range">
      <div className={classes.verticalChartWrapper}>
        <Typography className={classes.chartTitle}>Sessions/Day</Typography>
        <Bar
          data={chartData}
          options={options}
        />
      </div>
    </Tooltip>
  )
}

VerticalChart.propTypes = {
  daysRange: PropTypes.number.isRequired,
  sessionsPerDay: PropTypes.shape({}),
}

VerticalChart.defaultProps = {
  sessionsPerDay: {},
}
