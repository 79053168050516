import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { TableHeader } from 'Components/TableHeader'
import { TableHeaderCell } from 'Components/common/Table/TableHeaderCell'
import PropTypes from 'prop-types'
import { convertDateToTimeStamp } from 'Helpers/helpers'
import { GET_PAYMENT_HISTORY } from 'GraphQL/queries/payment'
import { useApolloLazyQuery } from 'GraphQL/useApolloLazyQuery'
import TablePagination from 'Pages/settings/components/TablePagination'
import { Table, TableBody } from 'Pages/Components/TableWithStyles'
import { toast } from 'react-toastify'
import { downloadCSV } from 'Helpers/json2CSV'
import { PaymentReportCSV } from './paymentReportCSV'
import { paymentsTableData } from './paymentsTableData'
import { PaymentsTableRow } from './PaymentsTableRow'
import { Header } from './Header'
import './styles.scss'

const initPagination = {
  pagination: {
    offset: 0,
    size: 5,
  },
}

const sorting = {
  field: 'active',
  direction: 'DESC',
}

export const PaymentTable = ({ dateRange, orgId }) => {
  const [page, setPage] = useState(1)

  const [loadPaymentHistory,
    { data: dataPaymentHistory, refetch: refetchPaymentHistory }] = useApolloLazyQuery(GET_PAYMENT_HISTORY, {
    variables: {
      organization: orgId,
      sorting,
      date: dateRange,
      ...initPagination,
    },
  })

  const historyPagination = dataPaymentHistory?.PaymentHistory.pagination
  const historyData = dataPaymentHistory?.PaymentHistory.items

  const pagination = {
    offset: 0,
    limit: initPagination.pagination.size,
    total: historyPagination?.totalItems,
  }
  const countPage = Math.ceil(pagination.total / pagination.limit)

  const changePagination = (_, currentPage) => {
    refetchPaymentHistory({
      date: dateRange,
      pagination: {
        offset: (currentPage - 1) * pagination.limit,
        size: pagination.limit,
      },
    })
    setPage(currentPage)
  }

  const handleCellClick = (field, direction) => {
    refetchPaymentHistory({
      dateRange,
      sorting: {
        field,
        direction,
      },
    })
  }

  useEffect(() => {
    loadPaymentHistory()
  }, [])

  const requestDownloadCSV = (data) => {
    const timeText = `${convertDateToTimeStamp(dateRange.from)} to ${convertDateToTimeStamp(dateRange.to)}`
    const fileName = `Payment history report from ${timeText}`
    const result = []
    data.forEach((element) => {
      const obj = {
        Date: convertDateToTimeStamp(element.createdAt).replace(',', ''),
        Amount: `$${ element.amount}`,
        'Payment Method': element.paymentMethod === 'credit_card' ? 'Credit Card' : 'ACH',
        'Retailer Name': element.retailer.name,
        Location: element.retailer.address,
      }
      result.push(obj)
    })
    downloadCSV(result, fileName)
    toast.success('successfully exported')
  }

  const getPaymentReportCSV = PaymentReportCSV({ onCompleted: requestDownloadCSV })

  const handleExportCSV = () => {
    toast.warn('Please wait while exporting')
    getPaymentReportCSV({
      variables: {
        organization: orgId,
        date: dateRange,
        pagination: {
          offset: 0,
          size: 100000,
        },
      },
    })
  }

  return (
    <div className="paymentsTable">
      <Header
        disableExport={!historyData?.length}
        handleExport={() => handleExportCSV()}
        title="Payment History"
      />
      <div className="table-responsive">
        <Table>
          {!isMobile && (
            <TableHeader
              handleCellClick={handleCellClick}
              headerTitles={paymentsTableData}
            />
          )}
          <TableBody>
            <>
              {historyData && historyData.map(item => (
                <PaymentsTableRow
                  historyData={item}
                />
              ))}
            </>
          </TableBody>
        </Table>
      </div>
      <div className="table-pagination-container">
        <TablePagination
          count={countPage}
          page={page}
          onChange={changePagination}
        />
      </div>
    </div>
  )
}

TableHeaderCell.propTypes = {
  children: PropTypes.node,
  isSorting: PropTypes.bool,
}

TableHeaderCell.defaultProps = {
  isSorting: true,
  children: null,
}

PaymentTable.propTypes = {
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  orgId: PropTypes.string,
}

PaymentTable.defaultProps = {
  dateRange: null,
  orgId: null,
}
