import React, { Fragment, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  Chip,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

function NavMenuItem({ menu, onToggleMenu, toggleSlider }) {
  const [subMenuOpen, setSubMenuOpen] = useState('')

  const onToggleCollapseMenu = (index) => {
    if (subMenuOpen === '') {
      setSubMenuOpen(index)
    } else if (subMenuOpen !== index) {
      setSubMenuOpen(index)
    } else {
      setSubMenuOpen('')
    }
  }

  if (menu.child_routes != null) {
    return (
      <>
        <ListItem
          className={`list-item ${classNames({ 'item-active': menu.open })}`}
          component="li"
          button
          onClick={onToggleMenu}
        >
          <ListItemIcon className="menu-icon">
            <i className={menu.menu_icon} />
          </ListItemIcon>
          <span className="menu text-capitalize">
            {menu.menu_title}
          </span>
          {menu.new_item && menu.new_item === true
            ? (
              <Chip
                className="new-item"
                color="secondary"
                label="new"
              />
            )
            : ''}
        </ListItem>
        <Collapse
          className="sub-menu"
          in={menu.open}
          timeout="auto"
        >
          <>
            {menu.type_multi === null
              ? (
                <List className="list-unstyled py-0">
                  {menu.child_routes.map(subMenu => (
                    <ListItem
                      key={subMenu.path}
                      component="li"
                      button
                    >
                      <NavLink
                        activeClassName="item-active"
                        to={subMenu.path}
                      >
                        <span className="menu">
                          {menu.menu_title}
                        </span>
                        {subMenu.new_item && subMenu.new_item === true
                          ? (
                            <Chip
                              className="new-item"
                              color="secondary"
                              label="new"
                            />
                          )
                          : ''}
                      </NavLink>
                    </ListItem>
                  ))}
                </List>
              )
              : (
                <List className="list-unstyled py-0">
                  {menu.child_routes.map((subMenu, index) => (
                    <Fragment key={subMenu.path}>
                      <ListItem
                        className={`list-item ${classNames({ 'item-active': subMenuOpen === index })}`}
                        component="li"
                        button
                        onClick={() => onToggleCollapseMenu(index)}
                      >
                        <span className="menu">
                          {menu.menu_title}
                          {menu.new_item && menu.new_item === true
                            ? (
                              <Chip
                                className="new-item"
                                color="secondary"
                                label="new"
                              />
                            )
                            : null}
                        </span>
                      </ListItem>
                      <Collapse
                        in={subMenuOpen === index}
                        timeout="auto"
                      >
                        <List className="list-unstyled py-0">
                          {subMenu.child_routes.map(nestedMenu => (
                            <ListItem
                              component="li"
                              button
                              // key={nestedKey}
                            >
                              <NavLink
                                activeClassName="item-active"
                                to={nestedMenu.path}
                              >
                                <span className="menu pl-10 d-inline-block">
                                  {menu.menu_title}
                                  {menu.new_item && menu.new_item === true
                                    ? (
                                      <Chip
                                        className="new-item"
                                        color="secondary"
                                        label="new"
                                      />
                                    )
                                    : null}
                                </span>
                              </NavLink>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </Fragment>
                  ))}
                </List>
              )}
          </>
        </Collapse>
      </>
    )
  }
  return (
    <ListItem
      component="li"
      button
      onClick={toggleSlider}
    >
      <NavLink
        activeClassName="item-active some-cls"
        to={menu.path}
      >
        <ListItemIcon className="menu-icon">
          <i className={`${menu.menu_icon} zmdi-icon-active-item`} />
        </ListItemIcon>
        <span className="menu">
          {menu.menu_title}
        </span>
      </NavLink>
    </ListItem>
  )
}

NavMenuItem.propTypes = {
  menu: PropTypes.shape({
    menu_title: PropTypes.string,
    menu_icon: PropTypes.string,
    path: PropTypes.string,
    new_item: PropTypes.bool.isRequired,
    child_routes: PropTypes.arrayOf(PropTypes.shape({})),
    open: PropTypes.bool,
    type_multi: PropTypes.bool,
  }).isRequired,
  toggleSlider: PropTypes.func.isRequired,
  onToggleMenu: PropTypes.func.isRequired,
}

export default NavMenuItem
