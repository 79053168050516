import React from 'react'
import PropTypes from 'prop-types'
import { useApolloQuery } from 'GraphQL/useApolloQuery'
import classNames from 'classnames'
import { GET_FEEDBACK_ANSWERS } from 'GraphQL/queries/questions'

const customEmojis = [
  { name: 'bad', value: '😠' },
  { name: 'poor', value: '🙁' },
  { name: 'average', value: '😐' },
  { name: 'great', value: '😊' },
  { name: 'excellent', value: '😃' },
]

const SurveyBlock = ({
  selectedUser, relation, refetchSurvey, stopRefetching,
}) => {
  const { data, refetch } = useApolloQuery(GET_FEEDBACK_ANSWERS, {
    variables: {
      store: selectedUser.storeId,
      client: selectedUser.id,
      relation,
    },
  })

  if (refetchSurvey) {
    refetch().then(stopRefetching)
  }

  const SurveyTitle = relation === 'preChat' ? 'Pre-chat' : 'Post-chat'

  return (data?.FeedbacksAnswered?.items?.length ? (
    <div className="survey-container">
      <div className="avatar-block md">
        <div className="avatar survey-icon-block md">
          <span className="user-abbreviation survey-icon md">&</span>
        </div>
      </div>
      <div className="survey-wrapper">
        <div className="chat-bubble even survey-header">
          <div>
            {SurveyTitle}
            {' '}
            survey:
          </div>
        </div>
        {
          data.FeedbacksAnswered.items.map(item => (
            <div
              key={item.id}
              className="chat-bubble even bg-aqua survey-item"
            >
              <div className="survey-title">{item.title}</div>
              {item?.type !== 'review' && item.answer.join(', ')}
              {item?.type === 'review' && (
                <div className="emoji-wrapper">
                  {customEmojis.map(emoji => (
                    <button
                      key={emoji.name}
                      className={classNames(
                        'emoji-button',
                      )}
                      disabled={item.answer[0] !== emoji.name}
                      type="button"
                    >
                      <span className="emoji-item">{emoji.value}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))
        }
      </div>
    </div>
  ) : null)
}

SurveyBlock.propTypes = {
  selectedUser: PropTypes.shape({
    storeId: PropTypes.string.isRequired,
    conversationId: PropTypes.string.isRequired,
    conversationPath: PropTypes.string,
    id: PropTypes.string.isRequired,
  }).isRequired,
  relation: PropTypes.string.isRequired,
  refetchSurvey: PropTypes.bool,
  stopRefetching: PropTypes.func,
}

SurveyBlock.defaultProps = {
  refetchSurvey: false,
  stopRefetching: () => {},
}
export default SurveyBlock
