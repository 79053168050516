import React, { useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { StoreSelect } from 'Components/StoreSelect/StoreSelect'
import { useAuthContext } from 'Providers/AuthProvider'
import { useApolloQuery, useApolloLazyQuery } from 'GraphQL/apollo'
import { GET_AUTO_RESPONSES } from 'GraphQL/queries/autoResponder'
import { GET_STORES } from 'GraphQL/queries/store'
import { AutoResponderItem } from './components/AutoResponderItem'
import {
  AUTO_RESPONSE_TYPES,
  emptyResponses,
  RESPONSE_DESCRIPTIONS,
  RESPONSE_TITLES,
} from './config'

function AutoResponder() {
  const [autoResponses, setAutoResponses] = useState(Object.values(emptyResponses))
  const [storeId, setStoreId] = useState(null)
  const { currentUser } = useAuthContext()

  const organizationId = currentUser.accessList[currentUser.accessList.length - 1].organization

  const { data: stores } = useApolloQuery(GET_STORES, {
    variables: {
      organization: organizationId,
    },
  })

  const [loadAutoResponsesQuery, { data: responsesData }] = useApolloLazyQuery(GET_AUTO_RESPONSES)

  useEffect(() => {
    if (stores) {
      const { Stores: { items: stores$ } } = stores

      setStoreId(stores$[0]?.id)
    }
  }, [stores])

  useEffect(() => {
    if (storeId) {
      loadAutoResponsesQuery({ variables: { storeId } })
    }
  }, [storeId])

  useEffect(() => {
    const result = responsesData?.AutoResponses

    if (result?.length) {
      setAutoResponses(result)
    }
  }, [responsesData])

  const defaultResponse = useMemo(
    () => autoResponses.find(r => r.type === AUTO_RESPONSE_TYPES.DEFAULT)
      || emptyResponses[AUTO_RESPONSE_TYPES.DEFAULT],
    [autoResponses],
  )

  const workingHoursResponse = useMemo(
    () => autoResponses.find(r => r.type === AUTO_RESPONSE_TYPES.WORKING_HOURS)
      || emptyResponses[AUTO_RESPONSE_TYPES.WORKING_HOURS],
    [autoResponses],
  )

  const breaksResponse = useMemo(
    () => autoResponses.find(r => r.type === AUTO_RESPONSE_TYPES.BREAKS)
      || emptyResponses[AUTO_RESPONSE_TYPES.BREAKS],
    [autoResponses],
  )

  const weekendResponse = useMemo(
    () => autoResponses.find(r => r.type === AUTO_RESPONSE_TYPES.WEEKEND_RESPONSE)
      || emptyResponses[AUTO_RESPONSE_TYPES.WEEKEND_RESPONSE],
    [autoResponses],
  )

  const changeStore = event => setStoreId(event.target.value)

  return (
    <div className="auto-responder-page">
      <section className="settings-page-title d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span><small>Chat settings</small></span>
          <h3 className="pt-5">Auto responder</h3>
        </div>
        <StoreSelect
          changeStore={changeStore}
          storeId={storeId}
          stores={stores?.Stores.items || []}
        />
      </section>
      <div className={isMobile ? 'settings-page-rows pt-10 w-100' : 'settings-page-rows pt-10 w-80'}>
        <AutoResponderItem
          description={RESPONSE_DESCRIPTIONS.WORKING_HOURS}
          response={workingHoursResponse}
          title={RESPONSE_TITLES.WORKING_HOURS}
        />
        <AutoResponderItem
          description={RESPONSE_DESCRIPTIONS.BREAKS}
          response={breaksResponse}
          title={RESPONSE_TITLES.BREAKS}
        />
        <AutoResponderItem
          description={RESPONSE_DESCRIPTIONS.DEFAULT}
          response={defaultResponse}
          title={RESPONSE_TITLES.DEFAULT}
        />
        <AutoResponderItem
          description={RESPONSE_DESCRIPTIONS.WEEKEND_RESPONSE}
          response={weekendResponse}
          title={RESPONSE_TITLES.WEEKEND_RESPONSE}
        />
      </div>
    </div>
  )
}

export default AutoResponder
