import { gql } from '@apollo/client'

export const CREATE_PAYMENT_REQUEST = gql`
mutation CreatePaymentRequest(
  $conversation: String!
  $customer: String!
  $store: String!
  $receivingMerchant: String!
  $expiresIn: Int
  $invoiceNumber: String
  $receiptEmail: String
  $acceptedPaymentMethods: [PaymentMethod!]
  $lineItems: [LineItemInput!]
  $totalAmount: Float
) {
  CreatePaymentRequest(
    conversation: $conversation,
    customer: $customer,
    store: $store,
    receivingMerchant: $receivingMerchant,
    expiresIn: $expiresIn,
    invoiceNumber: $invoiceNumber,
    receiptEmail: $receiptEmail,
    acceptedPaymentMethods: $acceptedPaymentMethods,
    lineItems: $lineItems,
    totalAmount: $totalAmount
  ) 
    {
    id
    conversation
    customer
    store
    receivingMerchant
    expiresIn
    invoiceNumber
    receiptEmail
    acceptedPaymentMethods
    lineItems {
      name
      description
      url
      price
      quantity
    }
    totalAmount
    status
  }
}
`
