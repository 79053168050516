const DISPLAY_USERS = 4

export const useRestCountRetailers = (users, displayUsers = DISPLAY_USERS) => {
  let restCountUsers
  const usersCount = users.length
  const displayCountUsers = usersCount < displayUsers ? usersCount : displayUsers

  if (usersCount <= displayCountUsers) {
    restCountUsers = 0
  } else {
    restCountUsers = usersCount - displayCountUsers
  }

  return { restCountUsers, displayCountUsers }
}
