import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Table = withStyles(() => ({
  root: {
    borderCollapse: 'separate',
  },
}))(MuiTable)
const TableBody = withStyles(() => ({}))(MuiTableBody)
const TableCell = withStyles(() => ({
  root: {
    padding: '12px 18px',
    borderBottom: '1px solid #D8DADD',
    '&:first-child': {
      borderLeft: '1px solid #D8DADD',
    },
    '&:last-child': {
      borderRight: '1px solid #D8DADD',
    },
  },
  head: {
    color: '#0D172A',
    fontSize: 12,
    borderTop: '1px solid #D8DADD',
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderLeft: '1px solid #D8DADD',
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderRight: '1px solid #D8DADD',
    },

  },
}))(MuiTableCell)
const TableHead = withStyles(() => ({}))(MuiTableHead)
const TableRow = withStyles(() => ({}))(MuiTableRow)

export {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
}
