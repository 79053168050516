import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Timepicker } from 'Components/common/Timepicker'
import { SelectWithStyles } from 'Components/common/Select'

import {
  TodayOutlined,
  Add,
  EmojiFoodBeverage,
} from '@material-ui/icons'

import { useController, useFieldArray, useWatch } from 'react-hook-form'
import { Break } from './Break'

import './styles.scss'

const DEFAULT_STATUS = ['Open', 'Closed']

export const WorkingHourRow = ({
  control,
  day,
  onApplyAll,
  permission,
  name,
  setValue,
  errors,
}) => {
  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: `${name}.breaks`,
    },
  )

  const {
    field: fieldFrom,
  } = useController({
    name: `${name}.from`,
    control,
  })

  const {
    field: fieldTo,
  } = useController({
    name: `${name}.to`,
    control,
  })

  const openStatusValue = useWatch({
    name: `${name}.open`,
    control,
  })

  const handleAddBreak = () => append({ from: '', to: '' })

  const handleRemoveBreak = index => remove(index)

  useEffect(() => {
    if (openStatusValue === 'Closed') {
      setValue(`${name}.from`, '')
      setValue(`${name}.to`, '')
      setValue(`${name}.breaks`, [])
    }
  }, [openStatusValue, name])

  return (
    <div className="working-hour-row-container mt-2">
      <div className="px-1">
        <div className="working-hour-row-header-container mb-1">
          <span className="working-hours-label m-0">
            <TodayOutlined />
            {' '}
            {day}
          </span>
          {day === 'Monday' && (
            <Button
              className="ml-2"
              color="primary"
              disabled={!permission}
              onClick={onApplyAll}
            >
              Apply to all days
            </Button>
          )}
        </div>
        <div className="picker-wrapper">
          <SelectWithStyles
            control={control}
            name={`${name}.open`}
            readOnly={!permission}
            displayEmpty
            fullWidth
          >
            {DEFAULT_STATUS.map(status => (
              <MenuItem
                key={status}
                value={status}
              >
                {status}
              </MenuItem>
            ))}
          </SelectWithStyles>
          {
            openStatusValue !== 'Closed' && (
              <>
                <Timepicker
                  {...fieldFrom}
                  error={!!errors?.from}
                  errorMessage={errors?.from?.message}
                  name={`${name}.from`}
                  readOnly={!permission}
                />
                <span className="mt-2 pt-1">-</span>
                <Timepicker
                  {...fieldTo}
                  error={!!errors?.to}
                  errorMessage={errors?.to?.message}
                  name={`${name}.to`}
                  readOnly={!permission}
                />
              </>
            )
          }
        </div>
      </div>
      {openStatusValue !== 'Closed' && (
        <Box
          className="px-1"
          flex={1}
        >
          <div className="working-hour-row-header-container mb-1">
            <span className="working-hours-label m-0">
              <EmojiFoodBeverage />
              {' '}
              Breaks
            </span>
            <Button
              color="primary"
              disabled={!permission}
              onClick={handleAddBreak}
            >
              <Add />
              Add break
            </Button>
          </div>
          {fields.map((field, breakIndex) => (
            <Break
              key={field.id}
              control={control}
              day={day}
              errors={errors?.breaks?.[breakIndex]}
              handleRemove={() => handleRemoveBreak(breakIndex)}
              name={`${name}.breaks[${breakIndex}]`}
              permission={permission}
            />
          ))}
        </Box>
      )}
    </div>
  )
}

WorkingHourRow.propTypes = {
  control: PropTypes.shape({}).isRequired,
  day: PropTypes.string,
  name: PropTypes.string.isRequired,
  onApplyAll: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  errors: PropTypes.shape(),
}

WorkingHourRow.defaultProps = {
  day: 'Monday',
  errors: {},
}
