import { gql } from '@apollo/client'

export const CREATE_STORE = gql`
  mutation CreateStore(
    $organization: String!
    $name: String!
    $country: String
    $state: String!
    $city: String
    $address: String!
    $secondaryAddress: String
    $postalCode: String!
    $timezone: String
    $workingHours: [DailyWorkingHoursInput!]
    $retailers: [String!]
    $conversationAssignmentMode: ConversationAssignmentMode
    $phoneNumber: String!
    $allowReassignChatByAgent: Boolean
  ) {
    CreateStore(
      organization: $organization
      name: $name
      country: $country
      state: $state
      city: $city
      address: $address
      secondaryAddress: $secondaryAddress
      postalCode: $postalCode
      timezone: $timezone
      workingHours: $workingHours
      retailers: $retailers
      conversationAssignmentMode: $conversationAssignmentMode
      phoneNumber: $phoneNumber
      allowReassignChatByAgent: $allowReassignChatByAgent
    ) {
      id
      organization {
        id
        name
      }
      name
      country
      state
      city
      address
      secondaryAddress
      postalCode
      timezone
      conversationAssignmentMode
      phoneNumber
      allowReassignChatByAgent
      retailers {
        id
        name
        email
      }
    }
  }
`

export const UPDATE_STORE = gql`
  mutation UpdateStore(
    $id: ID!
    $name: String!
    $country: String
    $state: String
    $city: String
    $address: String!
    $secondaryAddress: String
    $postalCode: String!
    $timezone: String
    $site: String
    $workingHours: [DailyWorkingHoursInput!]
    $retailers: [String!]
    $conversationAssignmentMode: ConversationAssignmentMode
    $phoneNumber: String!
    $allowReassignChatByAgent: Boolean
  ) {
    UpdateStore(
      id: $id
      name: $name
      country: $country
      state: $state
      city: $city
      address: $address
      secondaryAddress: $secondaryAddress
      postalCode: $postalCode
      timezone: $timezone
      site: $site
      workingHours: $workingHours
      retailers: $retailers
      conversationAssignmentMode: $conversationAssignmentMode
      phoneNumber: $phoneNumber
      allowReassignChatByAgent: $allowReassignChatByAgent
    ) {
      id
      name
      address
      secondaryAddress
      country
      postalCode
      city
      conversationAssignmentMode
      phoneNumber
      timezone
      allowReassignChatByAgent
      retailers {
        id
        name
        email
      }
    }
  }

`

export const DELETE_STORE = gql`
  mutation DeleteStore($id: ID!) {
    DeleteStore(id: $id) {
      id
      name
    }
  }
`

export const DELETE_STORE_PHONE_NUMBER = gql`
    mutation DeleteStorePhoneNumber($id: ID!) {
        DeleteStoreNumber(id: $id) {
            id
        }
    }
`

export const TOGGLE_STORE_FLAGS = gql`
  mutation ToggleStoreFlag($id: ID!, $flags: FlagsInput) {
    ToggleStoreFlag(id: $id, flags: $flags) {
      result
    }
  }
`
