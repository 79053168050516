import React from 'react'
import PropTypes from 'prop-types'
import { StatisticsItem } from './StatisticsItem'
import { useStyles } from './styles'

const STATISTICS = [
  {
    name: 'Total Payments',
    key: 'totalPayments',
    sec_key: 'totalPaymentsForPrevPeriod',
  },
  {
    name: 'Total ACH Payments',
    key: 'totalAchPayments',
    sec_key: 'totalAchPaymentsForPrevPeriod',
  },
  {
    name: 'Total CC Payments',
    key: 'totalCcPayments',
    sec_key: 'totalCcPaymentsForPrevPeriod',
  },
  {
    name: 'Avg. Payments per Organization',
    key: 'avgPaymentsPerOrg',
    sec_key: 'avgPaymentsPerOrgForPrevPeriod',
  },
]

export const GeneralStatistics = ({ analyticsData }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {STATISTICS.map(statisticsItem => (
        <StatisticsItem
          key={statisticsItem.key}
          item={{
            ...statisticsItem,
            value: analyticsData[statisticsItem.key],
            prevValue: analyticsData[statisticsItem.sec_key],
          }}
        />
      ))}
    </div>
  )
}

GeneralStatistics.propTypes = {
  analyticsData: PropTypes.shape({
    totalPayments: PropTypes.number.isRequired,
    totalPaymentsForPrevPeriod: PropTypes.number.isRequired,
    totalAchPayments: PropTypes.number.isRequired,
    totalAchPaymentsForPrevPeriod: 0,
    totalCcPayments: PropTypes.number.isRequired,
    totalCcPaymentsForPrevPeriod: PropTypes.number.isRequired,
    avgPaymentsPerOrg: PropTypes.number.isRequired,
    avgPaymentsPerOrgForPrevPeriod: PropTypes.number.isRequired,
  }),
}

GeneralStatistics.defaultProps = {
  analyticsData: {
    totalPayments: 0,
    totalPaymentsForPrevPeriod: 0,
    totalAchPayments: 0,
    totalAchPaymentsForPrevPeriod: 0,
    totalCcPayments: 0,
    totalCcPaymentsForPrevPeriod: 0,
    avgPaymentsPerOrg: 0,
    avgPaymentsPerOrgForPrevPeriod: 0,
  },
}
