import React from 'react'
import PropTypes from 'prop-types'

import { errorPropType } from 'customPropTypes/errorPropType'
import {
  EmailOutlined,
  PersonOutlineOutlined,
  PhoneEnabledOutlined,
} from '@material-ui/icons'

import Input from 'Components/common/Input'

export const ProfileForm = ({
  errors,
  control,
}) => (
  <>
    <div className="profile-name pt-20">
      <Input
        control={control}
        error={!!errors.name}
        errorMessage={errors.name?.message}
        inputProps={{
          autocomplete: 'name',
          form: {
            autocomplete: 'off',
          },
        }}
        label="Name"
        labelIcon={<PersonOutlineOutlined />}
        name="name"
        placeholder="Jane Cooper"
        fullWidth
      />
    </div>
    <div className="profile-email">
      <Input
        control={control}
        error={!!errors.email}
        errorMessage={errors.email?.message}
        inputProps={{
          autocomplete: 'email',
          form: {
            autocomplete: 'off',
          },
        }}
        label="Email"
        labelIcon={<EmailOutlined />}
        name="email"
        placeholder="j.cooper@logo.com"
        fullWidth
      />
    </div>
    <div className="profile-phone">
      <Input
        control={control}
        error={!!errors.phoneNumber}
        errorMessage={errors.phoneNumber?.message}
        inputProps={{
          autocomplete: 'phoneNumber',
          form: {
            autocomplete: 'off',
          },
        }}
        label="Phone number"
        labelIcon={<PhoneEnabledOutlined />}
        name="phoneNumber"
        placeholder="+1 (xxx) xxx-xx-xx"
        fullWidth
      />
    </div>
  </>
)

ProfileForm.propTypes = {
  control: PropTypes.shape({}),
  errors: PropTypes.shape({
    phoneNumber: errorPropType,
    email: errorPropType,
    name: errorPropType,
  }),
}

ProfileForm.defaultProps = {
  control: null,
  errors: {},
}
