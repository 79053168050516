import React from 'react'

import { Tabs } from 'Components/common/Tabs'
import { Tab } from 'Components/common/Tab'
import { TabPanel } from 'Components/common/TabPanel'

import PropTypes from 'prop-types'

import { UsersInStore } from './UsersInStore'
import { StoreDetails } from './StoreDetails'

export const StoreTabs = ({
  activeTab,
  control,
  errors,
  storeTab,
  permission,
  usersTab,
  onChange,
  handleAssignModeChange,
  conversationAssignmentMode,
  storeData,
  storePhoneNumber,
  setStorePhoneNumber,
  refetchStore,
  getValues,
  resetValues,
  setValue,
  ...props
}) => (
  <>
    <Tabs
      aria-label="simple tabs example"
      value={activeTab}
      onChange={onChange}
    >
      <Tab
        label="Store details"
        value={storeTab}
      />
      <Tab
        label="Agents in the store"
        value={usersTab}
      />
    </Tabs>
    <TabPanel
      tab={storeTab}
      value={activeTab}
    >
      <StoreDetails
        control={control}
        conversationAssignmentMode={conversationAssignmentMode}
        errors={errors}
        getValues={getValues}
        handleAssignModeChange={handleAssignModeChange}
        permission={permission}
        refetchStore={refetchStore}
        resetValues={resetValues}
        setStorePhoneNumber={setStorePhoneNumber}
        setValue={setValue}
        storeData={storeData}
        storePhoneNumber={storePhoneNumber}
      />
    </TabPanel>
    <TabPanel
      tab={usersTab}
      value={activeTab}
    >
      <UsersInStore
        control={control}
        errors={errors}
        permission={permission}
        {...props}
      />
    </TabPanel>
  </>
)

StoreTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  handleAssignModeChange: PropTypes.func.isRequired,
  conversationAssignmentMode: PropTypes.string,
  errors: PropTypes.shape({
    users: PropTypes.shape({}),
    name: PropTypes.shape({
      message: PropTypes.string,
    }),
    address: PropTypes.shape({
      message: PropTypes.string,
    }),
    secondaryAddress: PropTypes.shape({
      message: PropTypes.string,
    }),
    city: PropTypes.shape({
      message: PropTypes.string,
    }),
    state: PropTypes.shape({
      message: PropTypes.string,
    }),
    postalCode: PropTypes.shape({
      message: PropTypes.string,
    }),
    workingHours: PropTypes.array,
  }).isRequired,
  storeTab: PropTypes.string.isRequired,
  storePhoneNumber: PropTypes.string.isRequired,
  setStorePhoneNumber: PropTypes.func.isRequired,
  permission: PropTypes.bool,
  usersTab: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  storeData: PropTypes.shape({}),
  refetchStore: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  resetValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}

StoreTabs.defaultProps = {
  permission: false,
  onChange: () => {},
  conversationAssignmentMode: '',
  storeData: {},
}
