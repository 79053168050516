export const ROLE_TYPES = Object.freeze({
  PLATFORM_ADMIN: 'PLATFORM_ADMIN',
  RETAILER_ADMIN: 'RETAILER_ADMIN',
  PLATFORM_ANALYST: 'PLATFORM_ANALYST',
  RETAILER: 'RETAILER',
})

export const POSITION_TITLES = {
  PLATFORM_ADMIN: 'Platform administrator',
  PLATFORM_ANALYST: 'Platform Analyst',
  RETAILER_ADMIN: 'Organization Admin',
  RETAILER: 'Retailer',
  AGENT: 'Agent',
}
