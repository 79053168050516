import { LONG_REPLY_LIMIT, SHORT_REPLY_LIMIT } from 'Constants/preChatQuestions'

export const createQuestion = ({
  data,
  question,
  title,
  store,
  choices,
  questionType,
  isActive,
}) => {
  const getQuestionType = () => {
    if (questionType === 'rating') return 'review'
    if (questionType !== 'choice') return 'input'
    if (!data.isMultipleChoice) return 'radio'
    return 'checkbox'
  }

  const type = getQuestionType(data.questionType)

  const getMaxAnswers = () => {
    if (type === 'input') return data.isLongReply ? LONG_REPLY_LIMIT : SHORT_REPLY_LIMIT
    if (type === 'checkbox') return choices.length
    return 1
  }

  const finalChoices = choices.map(choice => ({
    id: choice.id,
    value: choice.value,
  }))

  return {
    store,
    title,
    description: question,
    active: isActive,
    type,
    min: data.isRequired ? 1 : 0,
    max: getMaxAnswers(),
    options: finalChoices,
  }
}
