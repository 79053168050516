const AppConfig = {
  appLogo: `${process.env.PUBLIC_URL}/assets/images/img/site-logo.png`,
  brandName: 'PW Platform',
  navCollapsed: false,
  darkMode: false,
  boxLayout: false,
  rtlLayout: false,
  miniSidebar: false,
  enableSidebarBackgroundImage: true,
  sidebarImage: `${process.env.PUBLIC_URL}/assets/images/img/sidebar-4.jpg`,
  isDarkSidenav: true,
  enableThemeOptions: true,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'en',
  },
  enableUserTour: process.env.NODE_ENV === 'production',
  // light theme colors
  themeColors: {
    primary: '#5D92F4',
    secondary: '#677080',
    success: '#00D014',
    danger: '#FF3739',
    warning: '#FFB70F',
    info: '#00D0BD',
    dark: '#464D69',
    default: '#FAFAFA',
    greyLighten: '#A5A7B2',
    grey: '#677080',
    white: '#FFFFFF',
    purple: '#896BD6',
    yellow: '#D46B08',
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: '#424242',
  },
}

export default AppConfig
