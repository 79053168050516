import * as yup from 'yup'
import { passwordRegExp } from 'Constants/common'

export const validationSchema = yup.object().shape({
  newPassword: yup.string()
    .required()
    .matches(passwordRegExp, 'Password should contain numbers, symbols, upper- and lower-case letters'),
  confirmNewPassword: yup.string()
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})
