import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  datePicker: {
    marginRight: 10,
    border: 0,
    width: 160,
    paddingTop: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      border: '1px solid #9e9e9e',
      borderRadius: 3,
      padding: 5,
      marginTop: 5,
      height: 45,
    },
  },
}))
