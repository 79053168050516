import { makeStyles } from '@material-ui/core'

const BREAK_POINT_1700 = 1700

export const useStyles = makeStyles(theme => ({
  ratingWrapper: {
    width: '100%',
    border: '1px solid #c9c9c9',
    borderRadius: 5,
    height: 310,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginLeft: 0,
    },
    [theme.breakpoints.up(BREAK_POINT_1700)]: {
      height: 350,
    },
    [theme.breakpoints.up('xl')]: {
      height: 520,
    },
  },
  progress: {
    width: 150,
    height: 5,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 25,
    color: 'red',
  },
  ratingItem: {
    display: 'flex',
    alignItems: 'center',
  },
  ratingItemsWrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up('xl')]: {
      marginTop: 20,
      height: 390,
    },
  },
  percent: {
    fontWeight: 'bold',
    marginLeft: 'auto',
    width: 75,
    [theme.breakpoints.up('xl')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  count: {
    fontSize: 15,
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.up('xl')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  chartTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    padding: 10,
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #D8DADD',
    marginTop: 5,
    padding: '5px 10px',

  },
  text: {
    fontSize: 15,
    fontWeight: 'bold',
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
}))
