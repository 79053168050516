import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { Button, FormGroup } from 'reactstrap'
import { useApolloMutation, useApolloQuery } from 'GraphQL/apollo'
import { GET_AUTO_RESPOND } from 'GraphQL/queries/autoResponder'
import { UPDATE_AUTO_RESPOND } from 'GraphQL/mutations/autoResponder'
import { toast } from 'react-toastify'
import SwitcherBox from './SwitcherBox'
import DelayBox from './DelayBox'
import MessageBox from './MessageBox'

export const AutoResponderItem = ({ response: { id }, title, description }) => {
  const [isActive, setIsActive] = useState(false)
  const [body, setBody] = useState('')
  const [delay, setDelay] = useState({ value: 0, option: '' })
  const [initialData, setInitialData] = useState({})

  const { data: autoRespondData } = useApolloQuery(GET_AUTO_RESPOND, {
    variables: {
      id,
    },
  })

  const [updateAutoRespond] = useApolloMutation(UPDATE_AUTO_RESPOND, {
    onCompleted: () => {
      toast.success('Changes have been successfully saved.')
    },
    refetchQueries: [{
      query: GET_AUTO_RESPOND,
      variables: {
        id,
      },
    }],
  })

  useEffect(() => {
    const result = autoRespondData?.AutoRespond
    if (result) {
      setBody(result.body)
      setDelay(result.delay)
      setIsActive(result.active)
      setInitialData(result)
    }
  }, [autoRespondData?.AutoRespond])

  const saveChanges = () => {
    updateAutoRespond({
      variables: {
        id,
        active: isActive,
        body,
        delay: {
          value: delay.value,
          option: delay.option,
        },
      },
    })
  }

  const resetChanges = () => {
    setIsActive(initialData.active)
    setBody(initialData.body)
    setDelay(initialData.delay)
  }

  const handleDelayInputChange = event => setDelay(d => (
    {
      ...d,
      value: Number(event.target.value) < 0 ? 0 : Number(event.target.value),
    }))

  const handleDelayOptionChange = event => setDelay(d => ({ ...d, option: event.target.value }))
  const handleIsActiveChange = () => setIsActive(prevState => !prevState)
  const handleBodyTextChange = event => setBody(event.target.value)

  return (
    <section className="page-row d-flex">
      <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-40 d-flex flex-column'}>
        <SwitcherBox
          checked={isActive}
          description={description}
          title={title}
          onChange={handleIsActiveChange}
        />
      </div>
      <div className={isMobile ? 'd-flex flex-column w-100 mt-15' : 'd-flex flex-column w-60 ml-20'}>
        <DelayBox
          inputChangeHandler={handleDelayInputChange}
          label="Delay"
          option={delay.option}
          optionChangeHandler={handleDelayOptionChange}
          value={delay.value}
        />
        <FormGroup className="mb-15">
          <MessageBox
            delayOptions={delay}
            id={id}
            label="Auto-responder message"
            text={body}
            onChange={handleBodyTextChange}
          />
        </FormGroup>
        <div className="d-flex mb-10 justify-content-end">
          <Button
            className="form-button-cancel mr-10"
            onClick={resetChanges}
          >
            <span className="ti-reload mr-10" />
            {isMobile ? 'Reset' : 'Reset Change'}
          </Button>
          <Button
            className="form-button-accept"
            color="primary"
            onClick={saveChanges}
          >
            <span className="ti-save mr-10" />
            {isMobile ? 'Save' : 'Save Change'}
          </Button>
        </div>
      </div>
    </section>
  )
}

AutoResponderItem.propTypes = {
  response: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
