export const MessageType = Object.freeze({
  FILE: 'file',
  DOC: 'doc',
  TEXT: 'text',
})

export const MessageContentType = Object.freeze({
  PLAIN: 'plain',
  PAYMENT_REQUEST: 'payment_request',
  PAYMENT_RESPONSE: 'payment_response',
  INFORMATION_REQUEST: 'information_request',
  INFORMATION_RESPONSE: 'information_response',
  REVIEW_REQUEST: 'review_request',
  REVIEW_RESPONSE: 'review_response',
})
