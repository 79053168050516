import React from 'react'
import PropTypes from 'prop-types'
import AntSwitch from '../../components/AntSwitch'

const SwitcherBox = ({
  checked,
  onChange,
  title,
  description,
}) => (
  <>
    <div className="settings-label d-flex align-items-center">
      <AntSwitch
        checked={checked}
        name="checked"
        onChange={onChange}
      />
      <span className="pl-10">{title}</span>
    </div>

    <span className="settings-label-description pl-40">
      {description}
    </span>
  </>
)

SwitcherBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

SwitcherBox.defaultProps = {
  checked: false,
  title: '',
  description: '',
}

export default SwitcherBox
