export const CONVERSATION_CHANNELS = [
  {
    title: 'Web chat',
    subTitle: 'Reply via messenger',
    value: 'widget',
    dataMissingTooltip: '',
  },
  {
    title: 'Email',
    subTitle: 'Reply via email address',
    value: 'email',
    dataMissingTooltip: 'Request user\'s e-mail address',
  },
  {
    title: 'SMS',
    subTitle: 'Reply via Mobile phone',
    value: 'sms',
    dataMissingTooltip: 'Request user\'s phone number',
  },
]
