import React from 'react'

import { Avatar } from '@material-ui/core'
import MuiAvatarGroup from '@material-ui/lab/AvatarGroup'

import { PersonOutlined } from '@material-ui/icons'
import { useRestCountRetailers } from 'Hooks/useRestCountRetailers'
import PropTypes from 'prop-types'

import { useStyles } from './styles'

export const AvatarGroup = ({ users, maxDisplay }) => {
  const classes = useStyles()
  const { restCountUsers, displayCountUsers } = useRestCountRetailers(users, maxDisplay)
  const moreCountUsers = !!restCountUsers && `+${restCountUsers} more`
  const avatars = Array(displayCountUsers).fill(1)

  return (
    <>
      <MuiAvatarGroup spacing={15}>
        {avatars.map((el, index) => (
          <Avatar
            key={index}
            className={classes.root}
            src={users[index].avatar}
          >
            <PersonOutlined className={classes.agent} />
          </Avatar>
        ))}
      </MuiAvatarGroup>
      <span className="page-table-cell fw-normal pl-5">
        {moreCountUsers}
      </span>
    </>
  )
}

AvatarGroup.propTypes = {
  users: PropTypes.array.isRequired,
  maxDisplay: PropTypes.number,
}

AvatarGroup.defaultProps = {
  maxDisplay: undefined,
}
