import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  tooltip: {
    height: 50,
    fontSize: 12,
    backgroundColor: '#acacac',
    display: 'flex',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
}))
