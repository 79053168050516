import { useMutation } from '@apollo/client'

export const useApolloMutation = (query, options = {}) => {
  const { onError, ...restOptions } = options

  const [name] = useMutation(query, {
    onError: (errors) => {
      if (errors.message !== 'Access token is expired' && onError) onError(errors)
    },
    ...restOptions,
  })

  return [name]
}
