import { useState, useEffect } from 'react'
import './style.css'
import axios from 'axios'

import useQuery from 'Hooks/useQuery'
import ChooseThumbs from './ChooseThumbs'
import PostReview from './PostReview'
import AccountSelection from './AccountSelection'
import ReviewFailure from './ReviewFailure'
import ReviewSuccess from './ReviewSuccess'
import { getAPIURLBasedENV } from '../payment/utils'

const REVIEW_STEP_ERROR = -1
const REVIEW_STEP_INIT = 0
const REVIEW_STEP_SOCIAL = 1
const REVIEW_STEP_ANSWER = 2
const REIVEW_STEP_SUCCESS = 3
const REIVEW_STEP_FAILURE = 4

export const ReviewsPage = () => {
  const [reviewStep, setReviewStep] = useState(REVIEW_STEP_ERROR)
  const [requestData, setRequestData] = useState(null)
  const query = useQuery()
  const requestId = query.get('requestId')
  const conversationId = query.get('conversationId')
  const [requestErrorString, setRequestErrorString] = useState(null)

  const RESPONSE_200_SUCCESS = 200
  const RESPONSE_201_SUCCESS = 201

  const loadRequestData = async () => {
    const request = `${getAPIURLBasedENV()}/feedbacks/review/${requestId}?conversation=${conversationId}`
    try {
      const { data, status } = await axios.get(request)
      if (status === RESPONSE_200_SUCCESS) {
        if (data != null) {
          setReviewStep(REVIEW_STEP_INIT)
          setRequestData(data)
        }
      }
    } catch (err) {
      setRequestErrorString(err?.response?.data?.message || 'Oops! There was a problem. Please try again later.')
    }
  }

  const postAnswer = async (answer, comment) => {
    const answerToSend = {
      id: requestData.reviewRequest.id,
      conversation: requestData.reviewRequest.conversation,
      answer,
      comment,
    }
    const { status } = await axios.post(
      `${getAPIURLBasedENV()}/feedbacks/review/answer`,
      answerToSend,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (status === RESPONSE_200_SUCCESS || status === RESPONSE_201_SUCCESS) {
      setReviewStep(REIVEW_STEP_SUCCESS)
    } else {
      setReviewStep(REIVEW_STEP_FAILURE)
    }
  }

  useEffect(() => {
    loadRequestData()
  }, [])

  const tryAgain = () => {
    setReviewStep(REVIEW_STEP_INIT)
    window.location.reload(true)
  }

  return (
    <div className="payment_container container">
      {reviewStep === REVIEW_STEP_INIT && (
        <ChooseThumbs
          gotoAccountSelection={() => setReviewStep(REVIEW_STEP_SOCIAL)}
          gotoPostReview={() => setReviewStep(REVIEW_STEP_ANSWER)}
          storeInfo={requestData && requestData.store}
        />
      )}
      {reviewStep === REVIEW_STEP_SOCIAL && (
        <AccountSelection
          reviewDestinations={requestData && requestData.reviewRequest.reviewDestinations}
          storeInfo={requestData && requestData.store}
        />
      )}
      {reviewStep === REVIEW_STEP_ANSWER && (
        <PostReview
          clientlogo=""
          clientName={requestData && requestData.customer.name}
          postAnswer={postAnswer}
          storeName={requestData && requestData.store.name}
        />
      )}
      {reviewStep === REIVEW_STEP_SUCCESS && (
        <ReviewSuccess
          storeInfo={requestData && requestData.store}
        />
      )}
      {reviewStep === REIVEW_STEP_FAILURE && (
        <ReviewFailure
          storeInfo={requestData && requestData.store}
          tryAgain={tryAgain}
        />
      )}
      {
        !!requestErrorString && (
          <div>
            <div className="text-center">
              <svg
                className="bi bi-x-circle"
                fill="currentColor"
                height="80"
                style={{ color: '#FF4545' }}
                viewBox="0 0 16 16"
                width="80"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
              <p className="text-content-highlight mx-5 my-3">{requestErrorString}</p>
            </div>
          </div>
        )
      }
    </div>
  )
}
