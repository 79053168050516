import { FormGroup, Input, Label } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useApolloMutation, useApolloLazyQuery } from 'GraphQL/apollo'
import PropTypes from 'prop-types'
import { mockPreQualifiedQuestions } from 'Pages/settings/mockData/mockPreQualifiedQuestions'
import { CREATE_CHAT_MESSAGE, UPDATE_CHAT_MESSAGE } from 'GraphQL/mutations/questions'
import { GET_CHAT_MESSAGES } from 'GraphQL/queries/questions'
import { MESSAGE_TEXT_LIMIT } from 'Constants/preChatQuestions'

export const FeedbackMessage = ({ storeId, relation, configureMessageTitle }) => {
  const [messageText, setMessageText] = useState('Please configure your message')
  const [messageId, setMessageId] = useState('')
  const [getChatMessage, { data: chatMessage }] = useApolloLazyQuery(GET_CHAT_MESSAGES, {
    variables: {
      store: storeId,
      relation,
    },
  })
  const [createChatMessage] = useApolloMutation(CREATE_CHAT_MESSAGE, {
    variables: {
      store: storeId,
      relation,
      body: messageText,
    },
  })
  const [updateChatMessage] = useApolloMutation(UPDATE_CHAT_MESSAGE, {
    variables: {
      id: messageId,
      body: messageText,
    },
  })

  useEffect(() => {
    if (storeId) {
      getChatMessage()
    }
  }, [storeId])

  useEffect(() => {
    const message = chatMessage?.FeedbackMessages.items[0]
    setMessageText(message?.body || '')
    setMessageId(message?.id)
  }, [chatMessage])

  const messageTextLength = `${messageText?.length || 0}/${MESSAGE_TEXT_LIMIT}`

  const changeMessageText = event => setMessageText(event.target.value)

  const saveMessage = () => {
    if (messageId) return updateChatMessage()
    return createChatMessage()
  }

  return (
    <FormGroup className="mb-15">
      <Label className="d-flex align-items-center">
        <span className="ti-comment" />
        <span className="profile-label pl-10">
          {configureMessageTitle}
        </span>
      </Label>
      <Input
        className="auto-responder-textarea"
        maxLength="240"
        placeholder={mockPreQualifiedQuestions}
        type="textarea"
        value={messageText}
        onBlur={saveMessage}
        onChange={changeMessageText}
      />
      <div className="d-flex justify-content-between mt-10">
        <div>
          <span className="teaxt-area-limit">
            Characters limit:
            {' '}
            {messageTextLength}
          </span>
        </div>
      </div>
    </FormGroup>
  )
}

FeedbackMessage.propTypes = {
  configureMessageTitle: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  relation: PropTypes.string.isRequired,
}
