import * as yup from 'yup'
import { phoneRegExpWithoutCode } from 'Constants/common'

export const emailValidationSchema = yup.object().shape({
  email: yup.string().required('It is a required field').email('Field must be a valid email'),
})

export const phoneValidationSchema = yup.object().shape({
  phone: yup.string().required('It is a required field').matches(phoneRegExpWithoutCode, 'Field must be a valid phone number'),
})
