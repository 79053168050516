import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from 'Components/common/Button'
import Input from 'Components/common/Input'
import { LinkButton } from 'Components/common/LinkButton'

import { updateSelectedUser, sendMessageToUser } from 'Store/Actions'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { useAuthContext } from 'Providers/AuthProvider'
import { useConversation } from 'Hooks/useConversation'
import { useSendMessage } from 'Pages/messenger/chat/components/useSendMessage'
import { ADD_CLIENT_INFO } from 'GraphQL/mutations/userInfo'
import { CLIENT_INFORMATION } from 'GraphQL/queries/userInfo'
import { ASSIGN_AGENT_TO_CONVERSATION } from 'GraphQL/mutations/conversations'
import { MessageType, MessageContentType } from 'Constants/chatConstants'
import { STORE_CONV_ASSIGN_TYPES } from 'Constants/common'

import { emailValidationSchema, phoneValidationSchema } from './validationSchema'
import { useMessengerContext } from '../../useMessenger'

import './styles.scss'

export const AddDataModal = ({ type, onClose }) => {
  const dispatch = useDispatch()
  const { currentUser } = useAuthContext()
  const { currentStore } = useMessengerContext()
  const { sendMessage } = useSendMessage()
  const [phoneCode, setPhoneCode] = useState('+1')
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)
  const {
    selectedUser: {
      conversationId,
      id,
      email,
      phone,
    },
  } = chatAppReducer

  const conversation = useConversation()
  const assignmentMode = currentStore.conversationAssignmentMode ?? STORE_CONV_ASSIGN_TYPES.LESS_BUSY

  const {
    control: emailControl,
    handleSubmit: handleEmailSubmit,
    formState: { errors: emailErrors },
    getValues: getEmailFormValue,
    setValue: setEmailValue,
  } = useForm({
    resolver: yupResolver(emailValidationSchema),
    mode: 'onChange',
  })

  const {
    control: phoneControl,
    handleSubmit: handlePhoneSubmit,
    formState: { errors: phoneErrors },
    getValues: getPhoneFromValue,
    setValue: setPhoneValue,
  } = useForm({
    resolver: yupResolver(phoneValidationSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (type === 'email' && email) {
      setEmailValue('email', email)
    }
    if (type === 'phone' && phone) {
      setPhoneValue('phone', phone)
    }
  }, [phone, email, type])

  const handlePhoneCodeChange = event => setPhoneCode(event.target.value)

  const [addUsersData] = useApolloMutation(ADD_CLIENT_INFO, {
    onCompleted: () => {
      onClose()
      const data = {
        ...getEmailFormValue(),
        ...getPhoneFromValue(),
      }

      dispatch(updateSelectedUser(data))
    },
    refetchQueries: [{
      query: CLIENT_INFORMATION,
      variables: { client: id },
    }],
  })

  const [assignAgent] = useApolloMutation(ASSIGN_AGENT_TO_CONVERSATION, {
    variables: {
      user: currentUser.id,
      conversation: conversationId,
    },
  })

  const saveEmail = (data) => {
    if (!emailErrors.email) {
      addUsersData({
        variables: {
          client: id,
          email: data.email,
        },
      }).then(() => {
        onClose()
      })
    }
  }

  const savePhone = (data) => {
    if (!phoneErrors.phone) {
      addUsersData({
        variables: {
          client: id,
          phone: data.phone,
        },
      }).then(() => {
        onClose()
      })
    }
  }

  const requestInfo = async () => {
    const data = {
      type: MessageType.TEXT,
      content: type,
      contentType: MessageContentType.INFORMATION_REQUEST,
      sender: 'retailer',
      sentAt: new Date().getTime(),
    }

    if (!personalCall) {
      if (assignmentMode === STORE_CONV_ASSIGN_TYPES.FIRST_TO_RESPOND) {
        await assignAgent()
      }

      dispatch(sendMessageToUser(data))
    }

    await sendMessage(data, conversation.customerChannel)

    onClose()
  }

  return (type === 'email'
    ? (
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">
            <i className="zmdi zmdi-email icon" />
            <span>Set a new email address</span>
          </div>
          <LinkButton
            color="secondary"
            onClick={requestInfo}
          >
            {'Request user\'s email'}
          </LinkButton>
        </div>
        <form
          className="modal-main"
          onSubmit={handleEmailSubmit(saveEmail)}
        >
          <Input
            className="modal-input"
            control={emailControl}
            error={emailErrors.email}
            errorMessage={emailErrors.email?.message}
            name="email"
            fullWidth
          />
        </form>
        <div className="modal-footer">
          <Button
            className="form-button-cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="form-button-accept"
            type="submit"
            onClick={handleEmailSubmit(saveEmail)}
          >
            <ControlPointIcon />
            Set Email
          </Button>
        </div>
      </div>
    ) : (
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">
            <i className="zmdi zmdi-phone icon" />
            <span>Set a new phone number</span>
          </div>
          <LinkButton
            color="secondary"
            onClick={requestInfo}
          >
            Request phone number
          </LinkButton>
        </div>
        <form
          className="modal-main"
          onSubmit={handlePhoneSubmit(savePhone)}
        >
          <div className="phone-picker">
            <Select
              className="phone-code-select"
              value={phoneCode}
              fullWidth
              onChange={handlePhoneCodeChange}
            >
              <MenuItem value="+1">+1</MenuItem>
            </Select>
            <Input
              className="modal-input"
              control={phoneControl}
              error={phoneErrors.phone}
              errorMessage={phoneErrors.phone?.message}
              name="phone"
              fullWidth
            />
          </div>
        </form>
        <div className="modal-footer">
          <Button
            className="form-button-cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="form-button-accept"
            type="submit"
            onClick={handlePhoneSubmit(savePhone)}
          >
            <ControlPointIcon />
            Set Phone Number
          </Button>
        </div>
      </div>
    ))
}

AddDataModal.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
