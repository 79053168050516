export const useConversationPath = () => {
  const conversationPath = window.localStorage.getItem('conversationPath')
  const conversationId = window.localStorage.getItem('conversationId')
  const storeId = window.localStorage.getItem('storeId')

  const setConversationPath = path => window.localStorage.setItem('conversationPath', path)
  const setConversationId = id => window.localStorage.setItem('conversationId', id)
  const setStoreId = id => window.localStorage.setItem('storeId', id)

  return {
    conversationPath,
    conversationId,
    storeId,
    setConversationId,
    setConversationPath,
    setStoreId,
  }
}
