import { useAuthContext } from 'Providers/AuthProvider'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import DeleteIcon from '@material-ui/icons/Delete'
import { DropBox } from 'Components/Drop-box'
import ControlHeader from '../ControlHeader'

import './styles.scss'

const MAX_IMAGE_DIMENSION = 500

const CompanyLogo = ({
  settings,
  onChange,
  label,
}) => {
  const [isOpenDropBox, setIsOpenDropBox] = useState(false)
  const handleOpenDropBox = () => {
    setIsOpenDropBox(true)
  }
  const { getOrganizationId } = useAuthContext()

  const handleCloseDropBox = () => {
    setIsOpenDropBox(false)
  }

  const handleChangeDropBox = (url) => {
    onChange('logo', url)
    handleCloseDropBox()
  }
  const removePhoto = () => {
    onChange('logo', '')
  }

  return (
    <div className="company-logo-container">
      <ControlHeader
        icon={<GetAppIcon />}
        label="Company logo"
      />
      <div className="logo-container">
        {settings.logo ? (
          <div
            className="logo"
            style={{
              backgroundImage: `url("${settings.logo}")`,
            }}
          />
        ) : (
          <span className="logo">&</span>
        )}
        <div className="controls">
          <span>
            Max image size:
            {label}
          </span>
          <div className="control-btn">
            <Button
              classes={{
                label: 'upload-btn',
              }}
              size="small"
              startIcon={<GetAppIcon />}
              onClick={handleOpenDropBox}
            >
              Upload new logo
            </Button>
            <Button
              classes={{
                label: 'remove-btn',
              }}
              size="small"
              startIcon={<DeleteIcon />}
              onClick={removePhoto}
            >
              Remove logo
            </Button>
          </div>
        </div>
      </div>
      <DropBox
        maxFileDimension={MAX_IMAGE_DIMENSION}
        open={isOpenDropBox}
        uploadPath={`/organizations/${getOrganizationId()}`}
        onChange={handleChangeDropBox}
        onClose={handleCloseDropBox}
        onOpen={handleOpenDropBox}
      />
    </div>
  )
}

export default CompanyLogo

CompanyLogo.propTypes = {
  settings: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

CompanyLogo.defaultProps = {
  label: '64x64px',
}
