/* eslint max-lines: ["error", 2000] */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalBase } from 'Components/common/ModalBase/ModalBase'

import { useApolloMutation } from 'GraphQL/apollo'
import { useAuthContext } from 'Providers/AuthProvider'
import { useConversation } from 'Hooks/useConversation'
import { CREATE_PAYMENT_REQUEST } from 'GraphQL/mutations/payment'
import { MessageContentType } from 'Constants/chatConstants'
import { PAYMENT_METHODS } from 'Constants/payment'

import ProductInputModal from './ProductInputModal'
import PaymentTable from './PaymentTable'
import BankingDetails, { TIME_UNITS } from './BankingDetails'
import EmailReceipt from './EmailReceipt'
import { validationSchema } from './validationSchema'

import './styles.scss'

const DEFAULT_VALUES = {
  items: [{
    name: '', url: '', quantity: 1, price: 0.00,
  }],
  paymentMethods: [PAYMENT_METHODS.CREDIT_CARD],
  expiresIn: 0,
  timeUnit: 'Days',
}

const RequestPaymentModal = ({
  open, onClose, onSubmitMessage,
}) => {
  const {
    control, reset, setValue, getValues, trigger, handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  })

  const [selectedProduct, setSelectedProduct] = useState(null)

  const { getOrganization } = useAuthContext()
  const merchantData = getOrganization().merchantAccounts
  const conversation = useConversation()

  const showProductInputModal = (index, type) => setSelectedProduct({ index, type })

  const [createPaymentRequestMutation] = useApolloMutation(CREATE_PAYMENT_REQUEST, {
    onCompleted: async (res) => {
      const reqData = res.CreatePaymentRequest
      const paymentMessage = {
        content: `requestId:${reqData.id},amount:${reqData.totalAmount},methods:${reqData.acceptedPaymentMethods.join('&')}`,
        contentType: MessageContentType.PAYMENT_REQUEST,
      }

      await onSubmitMessage(null, paymentMessage)
      onClose()
      toast.success('Payment request created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const createPaymentRequest = () => {
    const {
      items, receivingMerchant, expiresIn, timeUnit, invoiceNumber, paymentMethods, receiptEmail,
    } = getValues()
    const lineItems = items.map(item => ({
      ...item,
      price: parseFloat(item.price),
      quantity: parseInt(item.quantity, 10),
    }))

    const totalAmount = items?.reduce(
      (r, item) => (r + parseFloat(item.price) * parseInt(item.quantity, 10)),
      0,
    ) || 0

    const variables = {
      conversation: conversation.id,
      customer: conversation.customer.id,
      store: conversation.store,
      receivingMerchant,
      expiresIn: parseInt(expiresIn, 10) * TIME_UNITS[timeUnit],
      invoiceNumber,
      acceptedPaymentMethods: paymentMethods,
      lineItems,
      totalAmount,
      receiptEmail,
    }

    createPaymentRequestMutation({ variables })
  }

  const onError = (submitErrors, e) => console.error('Store submitErrors', { submitErrors }, e)

  const handleAccept = () => {
    if (isValid) {
      handleSubmit(createPaymentRequest, onError)()
    } else {
      handleSubmit(onError)()
    }
  }

  useEffect(() => {
    if (open) {
      reset(DEFAULT_VALUES)
    }
  }, [open])

  return (
    <ModalBase
      acceptTitle="Create a payment link"
      open={open}
      title="Request a payment"
      titleCancel="Cancel"
      width={600}
      onAccept={handleAccept}
      onCancel={onClose}
      onClose={onClose}
    >
      <form>
        <div className="request-payment-modal-container">
          <PaymentTable
            control={control}
            errors={errors}
            revalidate={trigger}
            onProductInputClick={showProductInputModal}
          />
          <BankingDetails
            control={control}
            errors={errors}
            isSubmitted={isSubmitted}
            merchantData={merchantData}
            revalidate={trigger}
            setValue={setValue}
          />
          <EmailReceipt
            control={control}
            errors={errors}
            revalidate={trigger}
          />
        </div>
        {!!selectedProduct && (
          <ProductInputModal
            control={control}
            data={selectedProduct}
            errors={errors}
            open={!!selectedProduct}
            onClose={() => setSelectedProduct(null)}
          />
        )}
      </form>
    </ModalBase>
  )
}

RequestPaymentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmitMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RequestPaymentModal
