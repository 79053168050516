import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Avatar } from 'Components/Avatar/Avatar'

export const UserInfoBlock = memo(() => {
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser } = chatAppReducer

  return (
    <div className="name-wrapper">
      <div className="user-name">
        <Avatar userName={selectedUser.name} />
        <div className="user-info">
          <span>{selectedUser.name}</span>
          <span className="email">{selectedUser.email}</span>
        </div>
      </div>
      <div className="store">
        Store
        <span>{ selectedUser.storeAddress }</span>
      </div>
    </div>
  )
})
