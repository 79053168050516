import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

function DashboardOverlay({ onClose }) {
  useEffect(() => {
    const open = document.getElementsByClassName('dashboard-overlay')[0]
    open.classList.remove('show')
    open.classList.add('d-none')
  })

  return (
    <div className="dashboard-overlay p-4">
      <div className="overlay-head d-flex justify-content-between mb-40">
        <div className="dash-user-info">
          <h2 className="fw-bold mb-0">Summary</h2>
        </div>
        <button
          className="closed rct-link-btn"
          type="button"
          onClick={onClose}
        >
          <i className="ti-close" />
        </button>
      </div>
      <div className="dashboard-overlay-content mb-30">
        <div className="row row-eq-height">
          <div className="col-sm-6 col-md-4" />
          <div className="col-sm-6 col-md-4" />
          <div className="col-sm-12 col-md-4" />
        </div>
      </div>
    </div>
  )
}
DashboardOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default DashboardOverlay
