import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, styled } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Close } from '@material-ui/icons'
import { Modal as MuiModal } from '@material-ui/core'
import Button from '../Button'
import './styles.scss'

const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: props => ({
    width: props.width,
  }),
  svgIcon: {
    root: {
      fontSize: '16px',
    },
  },
}))

const Icon = styled(Close)({
  fontSize: 16,
})

export const ModalBase = ({
  children,
  title,
  width,
  onClose,
  onCancel,
  onAccept,
  titleCancel,
  acceptIcon,
  acceptTitle,
  footer,
  modalBodyOutlined,
  withControls,
  isAcceptButtonDisabled,
  ...props
}) => {
  const classes = useStyles({ width })

  const handleCancel = () => {
    onCancel()
    onClose()
  }
  const handleAccept = () => {
    onAccept()
  }

  return (
    <MuiModal
      {...props}
      className={classes.modal}
      onClose={onClose}
    >
      <div className={classNames('ant-modal',
        classes.modalContainer,
        { 'with-title': Boolean(title) })}
      >
        <span
          className="ant-modal-close"
          onClick={onClose}
        >
          <Icon />
        </span>
        {title && (
          <div className="ant-modal-header">
            <span className="ant-modal-title">{title}</span>
          </div>
        )}
        <div className={classNames({ 'ant-modal-body': modalBodyOutlined })}>
          {children}
        </div>
        {onAccept && withControls && (
          <div className="ant-modal-footer">
            {footer || (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleCancel}
                >
                  {titleCancel}
                </Button>
                <Button
                  color="primary"
                  disabled={isAcceptButtonDisabled}
                  startIcon={acceptIcon || null}
                  variant="contained"
                  onClick={handleAccept}
                >
                  {acceptTitle}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </MuiModal>
  )
}

ModalBase.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  acceptIcon: PropTypes.element,
  titleCancel: PropTypes.string,
  acceptTitle: PropTypes.string,
  footer: PropTypes.element,
  modalBodyOutlined: PropTypes.bool,
  withControls: PropTypes.bool,
  isAcceptButtonDisabled: PropTypes.bool,
}

ModalBase.defaultProps = {
  title: null,
  onClose: () => {},
  onCancel: null,
  onAccept: null,
  acceptIcon: null,
  titleCancel: '',
  acceptTitle: '',
  footer: null,
  modalBodyOutlined: true,
  width: null,
  withControls: true,
  isAcceptButtonDisabled: false,
}
