import React, { useState, useEffect } from 'react'

import { getDefaultDateFrom } from 'Helpers/helpers'
import { useApolloLazyQuery } from 'GraphQL/apollo'
import { GET_ORGANIZATION_ANALYTICS } from 'GraphQL/queries/analytics'
import { AnalyticsPageHeader } from '../Components/AnalyticsPageHeader/AnalyticsPageHeader'
import { GeneralStatistics } from './GeneralStatistics/GeneralStatistics'
import { StatisticsCharts } from './StatisticsCharts/StatisticsCharts'
import { useStyles } from './styles'

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000

const allOrganizationsOption = { value: '', label: 'All' }

export const AnalyticsPage = () => {
  const classes = useStyles()

  const [selectedDateFrom, setSelectedDateFrom] = useState(getDefaultDateFrom())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const [selectedOrganization, setSelectedOrganization] = useState(allOrganizationsOption)

  const [getAnalytics, { data: analyticsData }] = useApolloLazyQuery(GET_ORGANIZATION_ANALYTICS)

  useEffect(() => {
    getAnalytics({
      variables: {
        organization: selectedOrganization.value,
        date: {
          from: selectedDateFrom.toISOString(),
          to: selectedDateTo.toISOString(),
        },
      },
    })
  }, [selectedDateFrom, selectedDateTo, selectedOrganization])

  const daysRange = Math.round(Math.abs((selectedDateFrom - selectedDateTo) / (DAY_IN_MILLISECONDS)))

  return (
    <div className={classes.analyticsPageWrapper}>
      <AnalyticsPageHeader
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        selectedOrganization={selectedOrganization}
        setSelectedDateFrom={setSelectedDateFrom}
        setSelectedDateTo={setSelectedDateTo}
        setSelectedOrganization={setSelectedOrganization}
        title="Platform statistics"
      />
      {analyticsData ? (
        <>
          <GeneralStatistics
            analyticsData={analyticsData?.OrganizationAnalytics}
          />
          <StatisticsCharts
            analyticsData={analyticsData?.OrganizationAnalytics}
            daysRange={daysRange}
          />
        </>
      ) : (
        <div className={classes.analyticsEmptyContainer}>
          {`Analytics of "${selectedOrganization.label}" for given period not found!`}
        </div>
      )}
    </div>
  )
}
