import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { StarsRating } from 'Components/StarsRating/StarsRating'
import { withStyles } from '@material-ui/core/styles'
import { getPercentage } from 'Helpers/helpers'
import { useStyles } from './styles'
import { AnalyticsTooltip as Tooltip } from '../../AnalyticsTooltip/AnalyticsTooltip'

const STAR_RATING_ITEMS = [
  {
    id: 5,
    name: 'Excellent',
    key: 'excellent',
  },
  {
    id: 4,
    name: 'Great',
    key: 'great',
  },
  {
    id: 3,
    name: 'Average',
    key: 'average',
  },
  {
    id: 2,
    name: 'Poor',
    key: 'poor',
  },
  {
    id: 1,
    name: 'Bad',
    key: 'bad',
  },
]

export const RatingChart = ({ reviewScore }) => {
  const classes = useStyles()

  const BorderLinearProgress = withStyles(() => ({
    root: {
      width: '95%',
      height: 5,
      borderRadius: 5,
      marginRight: 10,
      marginLeft: 10,
    },
    colorPrimary: {
      backgroundColor: '#EEEFF0',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FF7A59',
    },
  }))(LinearProgress)

  const starRatingItems = useMemo(() => STAR_RATING_ITEMS.map(item => ({ ...item, count: reviewScore[item.key] })),
    [reviewScore])

  const totalVotesCount = useMemo(() => starRatingItems.reduce((acc, item) => acc + item.count, 0),
    [starRatingItems])

  return (
    <Tooltip title="Rating based on post-chat questions">
      <div className={classes.ratingWrapper}>
        <Typography className={classes.chartTitle}>Review score</Typography>
        <div className={classes.ratingItemsWrapper}>
          {starRatingItems.map(item => (
            <div
              key={item.id}
              className={classes.ratingItem}
            >
              <StarsRating
                title={item.name}
                value={item.id}
              />
              <BorderLinearProgress
                value={getPercentage(item.count, totalVotesCount, 1)}
                variant="determinate"
              />
              <Typography
                className={classes.count}
                variant="body2"
              >
                {`${item.count}`}
              </Typography>
              <Typography
                className={classes.percent}
                variant="body2"
              >
                {`${getPercentage(item.count, totalVotesCount, 1)}%`}
              </Typography>
            </div>
          ))}
        </div>
        <div className={classes.total}>
          <Typography className={classes.text}>Total</Typography>
          <Typography className={classes.text}>{totalVotesCount}</Typography>
        </div>
      </div>
    </Tooltip>
  )
}

RatingChart.propTypes = {
  reviewScore: PropTypes.shape({
    excellent: PropTypes.number.isRequired,
    great: PropTypes.number.isRequired,
    average: PropTypes.number.isRequired,
    poor: PropTypes.number.isRequired,
  }),
}

RatingChart.defaultProps = {
  reviewScore: {
    excellent: 0,
    great: 0,
    average: 0,
    poor: 0,
  },
}
