import { makeStyles } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

export const useStyles = makeStyles(() => ({
  wrapper: {
    width: 30,
    display: isMobile ? 'none' : 'block',
    borderTop: '1px solid #eaeaea',
    borderRight: '1px solid #eaeaea',
    position: 'relative',
    backgroundColor: '#F8F9F9',
    minHeight: '90vh',
  },
  titleWrapper: {
    writingMode: 'vertical-lr',
    height: 250,
    cursor: 'pointer',
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  titleWrapperOpen: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    position: isMobile ? 'fixed' : 'inherit',
    width: isMobile ? '100%' : 'auto',
    zIndex: 40000,
    opacity: 1,
    background: 'inherit',
  },
  open: {
    display: isMobile && 'block',
    width: '100%',
    paddingBottom: 40,
  },
  title: {
    transform: 'rotate(-180deg)',
    color: '#FF7A59',
    marginTop: 5,
    fontSize: 20,
    height: 90,
    fontWeight: 450,
  },
  folderIcon: {
    fontSize: 20,
    color: '#646B77',
  },
  titleOpen: {
    fontSize: 22,
    marginTop: 5,
    alignItems: 'center',
    display: 'flex',
    fontWeight: 450,
  },
  icon: {
    color: '#FF7A59',
    fontSize: 18,
    marginLeft: 10,
  },
  iconOpen: {
    fontSize: 18,
    marginLeft: 5,
  },
  button: {
    position: 'absolute',
    background: isMobile ? '#fff' : 'inherit',
    right: 0,
    width: 35,
    height: 35,
    '&isOpen': {
      marginRight: 5,
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  filtersWrapper: {
    marginTop: 15,
  },
  filterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5,
    cursor: 'pointer',
    padding: '8px 15px',
    fontSize: 14,
    height: 40,
    fontWeight: 450,
  },
  active: {
    borderLeft: '3px solid #FF7A59',
    backgroundColor: 'white',
  },
  scrollBar: {
    height: '95vh !important',
    width: isMobile ? '100% !important' : '300px !important',
  },
}))
