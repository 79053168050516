import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import classnames from 'classnames'
import { userPropType } from 'customPropTypes/userPropType'
import { textTruncate } from 'Helpers/helpers'
import { Avatar } from 'Components/Avatar/Avatar'
import { useFirebaseContext } from 'Providers/FirebaseProvider'
import { MessageContentType } from 'Constants/chatConstants'
import { getMessageFromCustomer } from 'Store/Actions'

const LAST_MSG_MAX_LEN = 50

const UserListItem = ({
  user,
  selectedUser,
  onClickListItem,
  isLastUser,
}) => {
  const conversationPath = user?.conversationPath || ''
  const { db, eventTypes } = useFirebaseContext()
  const dispatch = useDispatch()
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { searchUsers } = chatAppReducer

  const renderTextWithHighlight = (userName) => {
    const index = userName?.toLowerCase().indexOf(searchUsers?.toLowerCase())
    const beforeHighLightText = userName.substring(0, index)
    const highLightedText = userName.substring(index, index + searchUsers.length)
    const afterHighLightText = userName.substring(index + searchUsers.length)
    if (index !== -1) {
      return (
        <>
          {beforeHighLightText}
          <span style={{ color: '#FF7A59' }}>{highLightedText}</span>
          {afterHighLightText}
        </>
      )
    }
    return userName
  }

  useEffect(() => {
    if (conversationPath && selectedUser) {
      db.ref(`${conversationPath}/${selectedUser?.storeId}/conversations/${user.conversationId}/messages`).on(eventTypes.CHILD_ADDED, (snapMessage) => {
        const messageValue = snapMessage.val()

        const content = {
          customerId: selectedUser.id,
          messageId: snapMessage.key,
          messageValue,
        }

        if (messageValue?.content) return
        if (content.sender !== 'admin') {
          dispatch(getMessageFromCustomer(content))
        }
      })
    }
  }, [selectedUser])

  const isRead = selectedUser?.id === user?.id ? selectedUser.isRead : user.isRead

  const getLastChat = (type, content) => {
    switch (type) {
      case MessageContentType.PAYMENT_REQUEST:
        return 'Payment Requested'
      case MessageContentType.PAYMENT_RESPONSE:
        return 'Payment Complete'
      case MessageContentType.INFORMATION_REQUEST:
        if (content.includes('email,phone')) return 'Email address / Phone number Requested'
        if (content === 'email') return 'Email address Requested'
        return 'Phone number Requested'
      case MessageContentType.INFORMATION_RESPONSE:
        if (content.includes('email,phone')) return 'Email address / Phone number Provided'
        if (content === 'email') return 'Email address Provided'
        return 'Phone number Provided'
      case MessageContentType.REVIEW_REQUEST:
        return 'Review Requested'
      case MessageContentType.REVIEW_RESPONSE:
        return 'Received a Review'
      default:
        return (textTruncate(content ?? '', LAST_MSG_MAX_LEN))
    }
  }

  return (
    <ListItem
      className={classnames('user-list-item',
        {
          'item-active': selectedUser && selectedUser.id === user.id,
          'last-user': isLastUser,
        })}
      onClick={onClickListItem}
    >
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="media align-items-center w-90">
          <div className="media-left position-relative mr-10">
            <Avatar userName={user.name} />
          </div>
          <div className="media-body pt-5">
            <h5 className="mb-0">
              { renderTextWithHighlight(user.name ?? '') }
            </h5>
            <span className={`font-xs d-block ${user.lastMessage?.contentType !== 'plain' ? 'font-italic' : ''}`}>
              {getLastChat(user.lastMessage?.contentType, user.lastMessage?.content)}
            </span>
          </div>
        </div>
        <div className="text-right msg-count">
          {!isRead
            ? <span className="badge badge-danger rounded-circle"><span className="zmdi  zmdi-arrow-right" /></span>
            : null}
        </div>
      </div>
    </ListItem>
  )
}

UserListItem.propTypes = {
  user: userPropType.isRequired,
  selectedUser: userPropType,
  onClickListItem: PropTypes.func.isRequired,
  isLastUser: PropTypes.bool.isRequired,
}

UserListItem.defaultProps = {
  selectedUser: {},
}

export default UserListItem
