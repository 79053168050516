import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDebounce } from 'Hooks/useDebounce'
import { useCheckPermission } from 'Hooks/useCheckPermission'
import { PERMISSION_NAMES } from 'config/permissions'
import { DEBOUNCED_SEARCH_DELAY } from 'Constants/common'
import { TableHeaderCell } from 'Components/common/Table/TableHeaderCell'
import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { GET_USERS } from 'GraphQL/queries/users'
import { PowerSettingsNewOutlined } from '@material-ui/icons'
import { DEACTIVATE_RETAILER, RECOVER_AGENT } from 'GraphQL/mutations/retailer'
import { useApolloLazyQuery } from 'GraphQL/useApolloLazyQuery'
import { useAuthContext } from 'Providers/AuthProvider'
import TablePagination from '../components/TablePagination'
import { AgentsTable } from './agentsTable'
import { CreateUpdateAgentModal } from './CreateUpdateAgentModal'
import './styles.scss'
import { AgentsToolbar } from './agentsToolbar'
import { AgentsPageHeader } from './agentsPageHeader'
import { useAgentsMutations } from './AgentGraphqlHooks'

const initPagination = {
  pagination: {
    offset: 0,
    size: 5,
  },
}

const sorting = {
  field: 'active',
  direction: 'DESC',
}

const Agents = () => {
  const { getOrganizationId } = useAuthContext()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, DEBOUNCED_SEARCH_DELAY)
  const [isOpenInviteAgentModal, setIsOpenInviteAgentModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const checkPermission = useCheckPermission()
  const organizationId = getOrganizationId()
  const isUserHasEditAgentPermission = checkPermission(PERMISSION_NAMES.EDIT_AGENT)
  const isUserHasAddAgentPermission = checkPermission(PERMISSION_NAMES.ADD_AGENT)

  const [agentId, setAgentId] = useState('')

  const [loadUsersQuery,
    { data: dataUsersQuery, refetch: refetchUsersQuery }] = useApolloLazyQuery(GET_USERS, {
    variables: {
      organization: organizationId,
      sorting,
      includeInactive: true,
      ...initPagination,
    },
  })

  const [recoverAgent] = useAgentsMutations(RECOVER_AGENT, () => {
    refetchUsersQuery()
    setAgentId('')
  })
  const [deactivateAgent] = useAgentsMutations(DEACTIVATE_RETAILER, () => {
    refetchUsersQuery()
    setAgentId('')
  })

  const usersPagination = dataUsersQuery?.Users.pagination
  const usersData = dataUsersQuery?.Users.items
  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [positionedConfirmOpen, setPositionedConfirmOpen] = useState(false)
  const [positionedConfirmPlacement, setPositionedConfirmPlacement] = useState()

  const pagination = {
    offset: 0,
    limit: initPagination.pagination.size,
    total: usersPagination?.totalItems,
  }
  const countPage = Math.ceil(pagination.total / pagination.limit)

  const changePagination = (_, currentPage) => {
    refetchUsersQuery({
      pagination: {
        offset: (currentPage - 1) * pagination.limit,
        size: pagination.limit,
      },
    })
    setPage(currentPage)
  }

  useEffect(() => {
    loadUsersQuery()
  }, [])

  useEffect(() => {
    if (debouncedSearch.length >= 2 || !debouncedSearch.length) {
      loadUsersQuery({
        variables: {
          searchQuery: debouncedSearch,
          sorting,
          ...initPagination,
        },
      })
    }
  }, [debouncedSearch])

  const deleteAgent = () => {
    deactivateAgent({
      variables: {
        userId: agentId,
      },
    })
  }
  const reactivateAgent = (id) => {
    recoverAgent({
      variables: {
        id,
      },
    })
  }

  const handleOpenInviteAgentModal = () => {
    setIsEditMode(false)
    setIsOpenInviteAgentModal(true)
  }

  const handleCloseInviteAgentModal = () => {
    setIsOpenInviteAgentModal(false)
  }

  const handlePositionedConfirm = (id, name, newPlacement) => (event) => {
    setAgentId(id)
    setPositionedConfirmAnchorEl(event.currentTarget)
    setPositionedConfirmOpen(prev => positionedConfirmPlacement !== newPlacement || !prev)
    setPositionedConfirmPlacement(newPlacement)
  }

  const handleEdit = (id) => {
    setAgentId(id)
    setIsEditMode(true)
    setIsOpenInviteAgentModal(true)
  }
  const handlePositionedConfirmDelete = (id, name, newPlacement) => (event) => {
    handlePositionedConfirm(id, name, newPlacement)(event)
  }

  const closePositionedConfirm = () => setPositionedConfirmOpen(false)

  const handleAccept = () => {
    deleteAgent(agentId)
  }

  const handleSearch = e => setSearch(e.target.value)

  const actions = {
    onEdit: handleEdit,
    onDelete: handlePositionedConfirmDelete,
    onReactive: reactivateAgent,
  }

  return (
    <div className="agents-page">
      <PositionedConfirmModal
        acceptIcon={<PowerSettingsNewOutlined />}
        anchorEl={positionedConfirmAnchorEl}
        open={positionedConfirmOpen}
        placement={positionedConfirmPlacement}
        title="Are you sure you want to deactivate this account?"
        onAccept={handleAccept}
        onClose={closePositionedConfirm}
      />

      {isOpenInviteAgentModal && (
        <CreateUpdateAgentModal
          agentId={agentId}
          edit={isEditMode}
          hasPermission={isUserHasEditAgentPermission}
          open={isOpenInviteAgentModal}
          refetchUsers={refetchUsersQuery}
          width={450}
          onClose={handleCloseInviteAgentModal}
        />
      )}
      <AgentsPageHeader />
      <AgentsToolbar
        handleOpenInviteAgentModal={handleOpenInviteAgentModal}
        handleSearch={handleSearch}
        isUserHasAddAgentPermission={isUserHasAddAgentPermission}
      />
      <div className="table-responsive">
        <AgentsTable
          actions={actions}
          agents={usersData}
          refetchUsers={refetchUsersQuery}
        />
      </div>
      <div className="table-pagination-container">
        <TablePagination
          count={countPage}
          page={page}
          onChange={changePagination}
        />
      </div>
    </div>
  )
}

TableHeaderCell.propTypes = {
  children: PropTypes.node,
  isSorting: PropTypes.bool,
}

TableHeaderCell.defaultProps = {
  isSorting: true,
  children: null,
}

export default Agents
