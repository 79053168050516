import { gql } from '@apollo/client'

export const GET_WORKFLOW_BY_ID = gql`
query Workflow($id: ID!){
  Workflow(id: $id){
    id
    organization
    name
    triggers {
      conditions {
        type
        value
        operator
      }
      actions {
        type
        questionFeedback
        questionInfo {
          type
          title
          description
          min
          max
          resultOptions
        }
      }
    }
    active
    deleted
    createdAt
    updatedAt
  }
}
`

export const GET_WORKFLOWS = gql`
query Workflows($organization: String!, $searchQuery: String, $active: Boolean, $pagination: PaginationInput, $sorting: SortingInput) {
  Workflows(organization: $organization, searchQuery: $searchQuery, active: $active, pagination: $pagination, sorting: $sorting) {
    items {
      id
      organization
      name
      active
      deleted
      createdAt
      updatedAt
    }
    pagination {
      offset
      size
      totalItems
    }
  }
}
`
