import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const useStyles = makeStyles(theme => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  titleHeader: {
    marginLeft: 10,
  },
  desc: {
    fontSize: 10,
    color: grey,
    marginBottom: 5,
  },
  pickersWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  label: {
    color: '#757575',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  organizationsSelect: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #9e9e9e',
      borderRadius: 3,
      padding: 5,
      marginTop: 5,
      width: '95%',
    },
  },
  organizationWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 15,
      height: 55,
    },
  },
  exportButton: {
    marginTop: 15,
    height: 40,
    marginLeft: 10,
    position: 'inherit',
    right: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
}))
