import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import React, { useState, useRef, useEffect } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { MessengerPages } from 'Pages/messenger/chat/components/Inbox/config'
import classnames from 'classnames'
import { setInnerHeight } from 'Helpers/helpers'
import ChatArea from './components/ChatArea'
import ChatSidebar from './components/ChatSidebar'

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '100%',
      display: 'inline-block',
    },
    backgroundColor: '#fff',
  },
  content: {
    flexGrow: 1,
  },
})

function ChatList({ classes, theme }) {
  const chatHeaderReducer = useSelector(state => state.chatHeader)
  const { messengerStep } = chatHeaderReducer
  const [mobileOpen, setMobileOpen] = useState(false)
  const chatRef = useRef(null)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  useEffect(() => {
    const setContainerHeight = () => isMobile && setInnerHeight('chat-content')
    setContainerHeight()
    window.addEventListener('resize', setContainerHeight)
    return () => window.removeEventListener('resize', setContainerHeight)
  }, [])

  const drawer = <ChatSidebar />
  return (
    <div className="chat-wrapper">
      <div className={classes.root}>
        <Hidden
          className="user-list-wrap"
          mdUp
        >
          <Drawer
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            classes={{
              paper: classes.drawerPaper,
            }}
            className="user-list-drawer"
            ModalProps={{
              keepMounted: true,
            }}
            open={mobileOpen}
            variant="temporary"
            onClose={handleDrawerToggle}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden
          className={classnames('user-list-wrap', { 'd-none': isMobile && messengerStep === MessengerPages.CHAT })}
          implementation="css"
          smDown={!isMobile}
        >
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div
          className={classnames(`chat-content ${classes.content}`, { 'd-none': isMobile && messengerStep !== MessengerPages.CHAT })}
        >
          <ChatArea
            chatRef={chatRef}
            onMenuIconPress={handleDrawerToggle}
          />
        </div>
      </div>
    </div>
  )
}

ChatList.propTypes = {
  classes: PropTypes.shape({
    drawerPaper: PropTypes.string,
    content: PropTypes.string,
    root: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({
    direction: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles, { withTheme: true })(ChatList)
