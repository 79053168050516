import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Popper,
  IconButton,
  Button as MuiButton,
  withStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import './styles.scss'

const Button = withStyles({
  root: {
    fontSize: 14,
    color: '#ffffff',
    padding: '8px 16px',
    backgroundColor: '#33475B',
    '&:hover': {
      backgroundColor: '#33475B',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#33475B',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(MuiButton)

const AcceptButton = withStyles(() => ({
  root: {
    backgroundColor: '#FAA61A',
  },
}))(Button)

const CloseButtonStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
}))

export const PositionedConfirmModal = ({
  open,
  anchorEl,
  placement,
  onClose,
  onAccept,
  onCancel,
  children,
  title,
  acceptTitle,
  cancelTitle,
  acceptIcon,
  cancelIcon,
}) => {
  const CloseButtonClasses = CloseButtonStyles()
  const handleAccept = () => {
    onClose()
    onAccept()
  }
  const handleCancel = () => {
    onClose()
    onCancel()
  }

  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement={placement}
      style={{
        zIndex: 9999,
        paddingTop: 30,
        paddingBottom: 30,
        boxSizing: 'content-box',
      }}
      transition
    >
      <div className="positioned-confirm-modal">
        <div className="positioned-confirm-modal-header">
          {/* todo add a title from the props */}
          <div className="title">{title}</div>
          <IconButton
            aria-label="delete"
            classes={CloseButtonClasses}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {children && <div className="positioned-confirm-modal-body">{children}</div>}
        <div className="positioned-confirm-modal-footer">
          <Button
            startIcon={cancelIcon}
            onClick={handleCancel}
          >
            {cancelTitle}
          </Button>
          <AcceptButton
            startIcon={acceptIcon}
            onClick={handleAccept}
          >
            {acceptTitle}
          </AcceptButton>
        </div>
      </div>
    </Popper>
  )
}

PositionedConfirmModal.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.element,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.func,
  title: PropTypes.string,
  acceptTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  acceptIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  cancelIcon: PropTypes.element,
}

PositionedConfirmModal.defaultProps = {
  open: false,
  anchorEl: null,
  placement: 'bottom-end',
  onClose: () => {},
  onAccept: () => {},
  onCancel: () => {},
  children: () => {},
  acceptIcon: <DeleteOutlineIcon />,
  cancelIcon: null,
  title: 'Are you sure you want?',
  acceptTitle: 'Deactivate',
  cancelTitle: 'Cancel',
}
