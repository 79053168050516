import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import ButtonWithIcon from 'Pages/settings/components/ButtonWithIcon'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './styles.scss'

export const AddQuestionModalChoice = ({
  id,
  number,
  title,
  choices,
  setChoices,
}) => {
  const [value, setValue] = useState('')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setValue(title)
  }, [title])

  const saveChoice = () => {
    if (!value) setIsError(true)
    else setIsError(false)
    const newChoices = [...choices]
    const choiceIndex = newChoices.findIndex(choice => choice.id === id)
    const newChoice = newChoices[choiceIndex]
    newChoice.value = value
    newChoices.splice(choiceIndex, 1, newChoice)
    setChoices(newChoices)
  }

  const deleteChoice = () => {
    const newChoices = [...choices].filter(choice => choice.id !== id)
    setChoices(newChoices)
  }

  useEffect(() => {
    if (!value) setIsError(true)
    else setIsError(false)
  }, [value])

  const titleChangeHandler = event => setValue(event.target.value)

  return (
    <div className="ant-choices-item">
      <div className="items-container">
        <div className="item-left-wrapper">
          <div className="item-index-wrapper">
            <span className="ti-menu drag-mark" />
            <span className="index-mark">{number}</span>
          </div>
        </div>
        <Input
          className={classNames('title', { 'error-border': isError })}
          placeholder="Type a choice"
          type="text"
          value={value}
          fullWidth
          onBlur={saveChoice}
          onChange={event => titleChangeHandler(event)}
        />
        {' '}
        <div className="item-right-wrapper">
          <ButtonWithIcon
            aria-label="Remove"
            component="span"
            onClick={deleteChoice}
          >
            <span className="ti-trash" />
          </ButtonWithIcon>
        </div>
      </div>
      {isError && <div className="error-msg">Choice can not be empty</div>}
    </div>
  )
}

AddQuestionModalChoice.propTypes = {
  id: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  setChoices: PropTypes.func.isRequired,
}
