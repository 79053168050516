import {
  CHAT_WITH_SELECTED_USER,
  SEND_MESSAGE_TO_USER,
  SEND_FILE_MESSAGE_TO_USER,
  UPDATE_USERS_SEARCH,
  GET_RECENT_CHAT_USERS,
  ADD_NEW_CUSTOMERS,
  UPDATE_CUSTOMERS,
  REMOVE_CUSTOMERS,
  GET_MESSAGE_FROM_CUSTOMER,
  GENERATE_CALL_ID,
  SET_ACTIVE_FILTER,
  CHANGE_ASSIGNED_AGENT,
  SET_CONVERSATION_RESOLVED,
  MARK_CONVERSATION_AS_READ,
  UPDATE_SELECTED_USER,
  RESET_USER_SELECTION,
  SET_PERSONAL_CALL,
} from 'Store/Actions/types'

export const generateCallId = () => ({ type: GENERATE_CALL_ID })

export const addNewCustomers = customers => ({
  type: ADD_NEW_CUSTOMERS,
  payload: customers,
})

export const updateCustomers = (store, customers) => ({
  type: UPDATE_CUSTOMERS,
  store,
  payload: customers,
})

export const removeCustomers = customers => ({
  type: REMOVE_CUSTOMERS,
  payload: customers,
})

export const changeAssignedAgent = payload => ({
  type: CHANGE_ASSIGNED_AGENT,
  payload,
})

export const setConversationResolved = payload => ({
  type: SET_CONVERSATION_RESOLVED,
  payload,
})

export const getMessageFromCustomer = payload => ({
  type: GET_MESSAGE_FROM_CUSTOMER,
  payload,
})

export const chatWithSelectedUser = user => ({
  type: CHAT_WITH_SELECTED_USER,
  payload: user,
})

export const sendMessageToUser = data => ({
  type: SEND_MESSAGE_TO_USER,
  payload: data,
})

export const sendFileMessage = data => ({
  type: SEND_FILE_MESSAGE_TO_USER,
  payload: data,
})

export const updateUsersSearch = value => ({
  type: UPDATE_USERS_SEARCH,
  payload: value,
})

export const updateSelectedUser = data => ({
  type: UPDATE_SELECTED_USER,
  payload: data,
})

export const getRecentChatUsers = () => ({
  type: GET_RECENT_CHAT_USERS,
})

export const setActiveFilter = value => ({
  type: SET_ACTIVE_FILTER,
  payload: value,
})

export const markConversationAsRead = id => ({
  type: MARK_CONVERSATION_AS_READ,
  payload: id,
})

export const resetUserSelection = () => ({
  type: RESET_USER_SELECTION,
})

export const setPersonalCall = payload => ({
  type: SET_PERSONAL_CALL,
  payload,
})
