/*eslint-disable*/
import moment from 'moment-timezone'
import { v4 as uuid } from 'uuid'
import heic2any from "heic2any"
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect'
import {
  faGoogle, faYelp, faFacebook, faFoursquare, faStaylinked, faTwitter, faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { getEnv } from './getEnv'
import { ENVIRONMENTS } from 'Constants/common'
export const getUuid = options => uuid(options)

export function getLogoFromURL(url) {
  if (url.includes('google')) {
    return faGoogle
  }
  if (url.includes('facebook')) {
    return faFacebook
  }
  if (url.includes('foursquare')) {
    return faFoursquare
  }
  if (url.includes('yelp')) {
    return faYelp
  }
  if (url.includes('twitter')) {
    return faTwitter
  }
  if (url.includes('instagram')) {
    return faInstagram
  }
  return faStaylinked
}

export function getThemeColorFromURL(url) {
  if (url.includes('google')) {
    return `rgb(66, 133, 244)`
  }
  if (url.includes('facebook')) {
    return `rgb(24, 119, 242)`
  }
  if (url.includes('foursquare')) {
    return `rgb(249, 72, 119)`
  }
  if (url.includes('yelp')) {
    return `rgb(211, 35, 35)`
  }
  if (url.includes('twitter')) {
    return `rgb(29, 161, 242)`
  }
  if (url.includes('instagram')) {
    return `rgb(195, 42, 163)`
  }
  return `rgb(23, 26, 33)`
}

export function hexToRgbA(hex, alpha) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`
  }
  throw new Error('Bad Hex')
}

export function setInnerHeight(name) {
  const k = 0.01
  const elements = document.getElementsByClassName(name)
  if (elements.length) {
    const el = elements[0]
    const vh = window.innerHeight * k
    el.style.setProperty('height', `100vh`)
    el.style.setProperty('height', `calc(var(--vh, 1vh) * 100)`)
    el.style.setProperty('--vh', `${vh}px`)
  }
}


/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  }
  return str
}

export function getTheDate(timestamp, format) {
  const time = timestamp * 1000
  const formatDate = format || 'MM-DD-YYYY'
  return moment(time).format(formatDate)
}

export function getCurrentTime(format) {
  const formatDate = format || 'HH:mm:sss'
  return moment().format(formatDate)
}

export function getTime(time, format, timezone) {
  const formatDate = format || 'HH:mm:sss'
  let timeMoment = moment(time)

  if (timezone) {
    timeMoment = timeMoment.tz(timezone)
  }

  return timeMoment.format(formatDate)
}

export function convertDateToTimeStamp(date, format, timezone) {
  const formatDate = format || 'DD, MMM YYYY'
  return (timezone ? moment.tz(date, timezone) : moment(date)).format(formatDate)
}

export function getAppLayout(url) {
  const location = url.pathname
  const path = location.split('/')
  return path[1]
}

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getTimeAgo = date => moment(date).fromNow()

export const convertTimeToTimestamp = (time, format, timezone) => {
  const formatDate = format || 'HH:mm'

  return (timezone ? moment.tz(time, formatDate, timezone) : moment(time, formatDate)).format()
}

export const pluralize = (count, noun, suffix = 's') => (
  `${count} ${noun}${count !== 1 ? suffix : ''}`
)

export const getLocationHashValue = (key) => {
  const matches = location.hash.match(`${key}=([^&]*)`)
  return matches ? matches[1] : null
}

export const getParamsFromLocation = (key) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return key ? urlSearchParams.get(key) : Object.fromEntries(urlSearchParams.entries());
}

export const objectByString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const getDefaultDateFrom = () => {
  const date = new Date()

  date.setMonth(date.getMonth() - 1)

  return date
}

export const generateWidgetInstallCode = (userName, nmgId) => {
  return `
1) Copy the code below:
<div id=\"primeworks-customer-widget\" nmg-id=${nmgId}></div>
    <script defer src=\"widget.production.showroomvideo.chat/lib/build.widget.min.js?1b21612f13d4ac4d5a7c559e06eb8f0e517aaa59\"></script>
2) Open your application source code
3) In the source code, find and open the index.html file
4) Paste the copied code in <body></body> tag.Please mind that install code should be inside "body" tag.
Note: Widget will not affect your application styles.`
}

export const getNumberWithComma = (x) =>x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getPriceFormat = (x) =>  "$" + x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getPercentage = (currentValue, totalValue, noOfDecimal = 0) => currentValue > 0 ?Number(( currentValue / totalValue ) * 100).toFixed(noOfDecimal) : currentValue

export const getContrastPercentage = (currentValue, prevValue, noOfDecimal = 0) => {
  if (prevValue === currentValue)
    return 0
  else if (prevValue === 0) return null
  else if (currentValue === 0) return -100
  return Number(( (currentValue - prevValue) / prevValue ) * 100).toFixed(noOfDecimal)
}
export const convertHoursToTime = (currentHours) => {
  const hoursInSeconds = currentHours * 60 * 60
  const date = new Date(null)
  date.setSeconds(hoursInSeconds)
  const result = date.toISOString().substr(11, 8)
  const hours = result.split(":")[0]
  const mins = result.split(":")[1]
  const seconds = result.split(":")[2]

  return {
    hours,
    mins,
    seconds,
  };
};

export  const convertHoursToDaysAndHHMMSS = (hoursInput) => {
  let days = 0, aloneFormate = '', groupTime = '';
  if (hoursInput >= 24) {
    const hoursInDays = hoursInput / 24;
    if (+hoursInDays % 1 != 0) {
      days = +String(hoursInDays).split(".")[0];
    } else {
      days = hoursInDays;
    }
  }
  const remainingHours = hoursInput - (days * 24);

  const {hours, mins, seconds} = convertHoursToTime(remainingHours)

  if (days) {
    aloneFormate = `${days}d`
    groupTime = `${hours}h ${mins}m`
  } else if (+hours) {
    aloneFormate = `${hours}h`
    groupTime = `${mins}m ${seconds}s`
  } else if (+mins) {
    aloneFormate = `${mins}m`
    groupTime = `${seconds}s`
  } else if (+seconds) {
    aloneFormate = `${seconds}s`
  }

  return `${aloneFormate} ${groupTime}`;
};

export const getBlobURL = (url) => {
  return new Promise(async (resolve, _) => {
    const res = await fetch(url);
    const blob = await res.blob();

    const conversionResult = await heic2any({
      blob,
      toType: "image/jpeg",
    });
    var reader = new FileReader();
    reader.readAsDataURL(conversionResult);
    reader.onloadend = function () {
      resolve(reader.result);
    }
  });
}

export const getDeviceTypeClassName = () => {
  switch (true) {
    case isIOS && isTablet:
      return 'ios-tablet-device'
    case isIOS && isMobile:
      return 'ios-mobile-device'
    case isAndroid && isTablet:
      return 'android-tablet-device'
    case isAndroid && isMobile:
      return 'android-mobile-device'
    default:
      return null
  }
}

export const getEnvironment = () => {
  switch (getEnv()) {
    case ENVIRONMENTS.DEVELOPMENT:
      return 'dev01'
    case ENVIRONMENTS.STAGING:
      return 'staging01'
    case ENVIRONMENTS.STAGING_STABLE:
      return 'staging-stable'
    case ENVIRONMENTS.PRODUCTION:
      return ''
    default:
      return 'dev01'
  }
}

export const findDuplicates = (arr) => {
  let sorted_arr = arr.slice().sort();

  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] == sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
}

export const onBeforeUnload = (event) => {
  // eslint-disable-next-line no-param-reassign
  event.returnValue = ''
}

export const listenBeforeUnload = () => window.addEventListener('beforeunload', onBeforeUnload, true)
export const removeBeforeUnloadListener = () => window.removeEventListener('beforeunload', onBeforeUnload, true)

export const getTimeDifference = (date, format) => moment().diff(date, format)
