import React from 'react'
import {
  Redirect,
  Route,
  withRouter,
  useLocation,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import AppLayout from 'Components/AppLayout'
import { routes } from 'Pages'
import { useAuthContext } from 'Providers/AuthProvider'
import { LOGIN_PATH } from 'Constants/routerPaths'
import { ROLE_TYPES } from 'Constants/rolesConstants'
import { useNotifications } from 'Hooks/useNotifications'

// import { PersonalCallModal } from 'Container/PersonalCallModal/PersonalCallModal'

function DefaultLayout({ match }) {
  const {
    isAuth,
    currentUser,
  } = useAuthContext()
  const location = useLocation()
  useNotifications()

  const isPlatformAdminOrAnalyst = currentUser?.roles.some(
    role => role === ROLE_TYPES.PLATFORM_ADMIN || role === ROLE_TYPES.PLATFORM_ANALYST,
  )

  if (isAuth() && currentUser.id) {
    const allowedRoutes = routes.filter(route => route.permissions
      .some(el => currentUser.roles.includes(el)))

    const redirectPath = isPlatformAdminOrAnalyst
      ? allowedRoutes.find(route => route.path === 'organizations')?.path
      : allowedRoutes?.[0].path

    const isCurrentRouteAllowed = allowedRoutes.some(
      route => location.pathname.split('/').includes(route.path),
    )
    return (
      <AppLayout>
        {/* <PersonalCallModal /> */}
        {allowedRoutes && allowedRoutes.map(route => (
          <Route
            key={route.path}
            component={route.component}
            path={`${match.url}/${route.path}`}
          />
        ))}
        {!isCurrentRouteAllowed && (
          <Redirect
            to={`${match.url}/${redirectPath}`}
            exact
          />
        )}
      </AppLayout>
    )
  }

  return (<Redirect to={LOGIN_PATH} />)
}

DefaultLayout.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
}

export default withRouter(DefaultLayout)
