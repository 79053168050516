import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox as MuiCheckbox } from '@material-ui/core'
import PropTypes from 'prop-types'
import FormControlLabel from '../FormControlLabel'

const CheckboxIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="white"
      height="15"
      rx="1.5"
      stroke="#D8DADD"
      width="15"
      x="0.5"
      y="0.5"
    />
  </svg>
)
const CheckedIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="#FF7A59"
      height="16"
      rx="2"
      width="16"
    />
    <path
      d="M12.1953 4.46875L6.03125 10.6328L3.78125 8.35938C3.66406 8.26562 3.47656 8.26562 3.38281 8.35938L2.70312 9.03906C2.60938 9.13281 2.60938 9.32031 2.70312 9.4375L5.84375 12.5547C5.96094 12.6719 6.125 12.6719 6.24219 12.5547L13.2734 5.52344C13.3672 5.42969 13.3672 5.24219 13.2734 5.125L12.5938 4.46875C12.5 4.35156 12.3125 4.35156 12.1953 4.46875Z"
      fill="white"
    />
  </svg>
)

const CheckboxWithStyles = withStyles(() => ({
  root: {
    padding: 0,
    paddingRight: 8,
  },
}))(MuiCheckbox)

export const Checkbox = ({
  title,
  checked,
  onChange,
  ...restProps
}) => (
  <FormControlLabel
    control={(
      <CheckboxWithStyles
        checked={checked}
        checkedIcon={<CheckedIcon />}
        icon={<CheckboxIcon />}
        name={title}
        onChange={onChange}
        {...restProps}
      />
    )}
    label={title}
  />
)

Checkbox.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

Checkbox.defaultProps = {
  checked: false,
}

export default Checkbox
