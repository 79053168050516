import { gql } from '@apollo/client'

export const GET_STORES = gql`
  query Stores(
    $organization: String!
    $pagination: PaginationInput
    $search: String
    $sorting: SortingInput,  
  ) {
    Stores(
      organization: $organization
      pagination: $pagination
      searchQuery: $search
      sorting: $sorting
    ) {
      items {
        name
        id
        country
        state
        city
        address
        postalCode
        timezone
        workingHours {
          open,
          from,
          to,
          breaks {
            from,
            to
          }
        }
        retailers {
          id
          name
          email
          avatar
        }
        site
        active
        deleted
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      pagination {
        offset
        size
        totalItems
        hasNext
        hasPrevious
      }
    }
  }
`

export const GET_STORE = gql`
  query Store($id: ID!) {
    Store(id: $id) {
      id
      name
      state
      city
      address
      secondaryAddress
      postalCode
      conversationAssignmentMode
      phoneNumber
      timezone
      allowReassignChatByAgent
      workingHours {
        open,
        from,
        to,
        breaks {
          from,
          to
        }
      }
      retailers {
        id
        name
        email
      }
    }
  }

`
