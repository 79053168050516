import PropTypes from 'prop-types'
import { useStyles } from 'Pages/BillingPage/styles'
import { isMobile } from 'react-device-detect'
import {
  TableCell,
  TableRow,
} from 'Pages/Components/TableWithStyles'
import { convertDateToTimeStamp } from 'Helpers/helpers'

export const BillingPageTableRow = ({ organization }) => {
  const classes = useStyles()

  return (
    <TableRow
      className="main-cell"
      hover
    >
      <TableCell className={classes.organizationAddress}>
        <span className={classes.name}>{organization.name}</span>
        <span className={classes.website}>{organization.site[0]}</span>
        {isMobile && (
          <>
            <div className="cell-item">
              <div className="cell-label">Organization address</div>
              <div>{organization.address}</div>
            </div>
            <div className="cell-item">
              <div className="cell-label">NMG ID</div>
              <div>{organization.nmgId}</div>
            </div>
            <div className="cell-item">
              <div className="cell-label">Agent users (Ret.admin)</div>
              <div>{organization.activeUsers}</div>
            </div>
            <div className="cell-item">
              <div className="cell-label">Total users</div>
              <div>{organization.totalUsers}</div>
            </div>
            <div className="cell-item">
              <div className="cell-label">Registered</div>
              <div>{convertDateToTimeStamp(organization.createdAt)}</div>
            </div>
          </>
        )}
      </TableCell>
      {!isMobile && (
        <>
          <TableCell>{organization.address}</TableCell>
          <TableCell>{organization.nmgId}</TableCell>
          <TableCell>{organization.activeUsers}</TableCell>
          <TableCell>{organization.totalUsers}</TableCell>
          <TableCell>{convertDateToTimeStamp(organization.createdAt)}</TableCell>
        </>
      )}
    </TableRow>
  )
}

BillingPageTableRow.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    site: PropTypes.arrayOf(
      PropTypes.string,
    ),
    nmgId: PropTypes.string,
    address: PropTypes.string,
    activeUsers: PropTypes.number,
    totalUsers: PropTypes.number,
    createdAt: PropTypes.instanceOf(Date),
  }).isRequired,
}
