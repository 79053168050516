import { Select as MuiSelect } from '@material-ui/core'
import { useController } from 'react-hook-form'
import './styles.scss'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { styles } from './styles'

const Select = ({
  control,
  name,
  children,
  error,
  errorMessage,
  label,
  labelIcon,
  wrapperClassName,
  ...restProps
}) => {
  const {
    field: { value, ...selectProps },
  } = useController({
    name,
    control,
  })

  const selectValue = value || ''

  return (
    <div className={classNames('select-wrapper', wrapperClassName)}>
      {label && (
        <div className="label">
          {labelIcon && <span className="label-icon">{labelIcon}</span>}
          {label}
        </div>
      )}
      <MuiSelect
        {...selectProps}
        {...restProps}
        value={selectValue}
        variant="outlined"
      >
        {children}
      </MuiSelect>
      {error && <span className="error-message">{errorMessage}</span>}
    </div>
  )
}

Select.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  labelIcon: PropTypes.element,
  wrapperClassName: PropTypes.string,
}

Select.defaultProps = {
  control: {},
  error: false,
  errorMessage: 'Error',
  label: null,
  labelIcon: null,
  wrapperClassName: null,
}

export const SelectWithStyles = withStyles(styles)(Select)
