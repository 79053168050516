import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation Authenticate($email: String!, $password: String!) {
    Authenticate(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        id
        name
        email
        avatar
        timezone
        availability
        isOnboarded
        phoneNumber
        personalCallId
        accessList {
          organization
          store
          roles
          nmgId
        }
        active
      }
    }
  }
`

export const CREATE_MEMBER_NET_AUTHENTICATION = gql`
  mutation CreateMemberNetAuthentication($redirectUrl: String!) {
    CreateMemberNetAuthentication(redirectUrl: $redirectUrl) {
      authenticationUrl
    }
  }
`

export const CONFIRM_MEMBER_NET_AUTHENTICATION = gql`
  mutation ConfirmMemberNetAuthentication($accessToken: String!) {
    ConfirmMemberNetAuthentication(access_token: $accessToken) {
      accessToken
      refreshToken
      user {
        id
        name
        email
        avatar
        timezone
        isOnboarded
        phoneNumber
        accessList {
          organization
          store
          roles
          nmgId
        }
        active
      }
    }
  }
`

export const AUTHORIZE = gql`
  mutation Authorize($refreshToken: String!) {
    Authorize(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      user {
        id
        name
        email
        availability
        accessList {
          organization
          store
          roles
        }
      }
    }
  }
`

export const SET_AVAILABILITY_STATUS = gql`
  mutation SetMyAvailability($status: AvailabilityTypes!) {
    SetMyAvailability(availability: $status) {
      availability
    }
  }

`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    ForgotPassword(email: $email) {
      email
      result
    }
  }
`

export const UPDATE_FORGOTTEN_PASSWORD = gql`
  mutation UpdateForgottenPassword(
    $email: String!
    $password: String!
    $recoveryToken: String!
  ) {
    UpdateForgottenPassword(
      email: $email
      password: $password
      recoveryToken: $recoveryToken
    ) {
      email
      result
    }
  }
`

export const SET_USER_AVATAR = gql`
    mutation SetMyAvatar($avatar: String!){
        SetMyAvatar(avatar: $avatar,) {
           id
           name
           email
           avatar
        }
    }`

export const UPDATE_MY_PROFILE = gql`
  mutation UpdateMyProfile(
    $name: String
    $phoneNumber: String
    $timezone: String
    $isOnboarded: Boolean
    $email: String
    $browserNotificationStatus: Boolean
    $smsNotificationStatus: Boolean
    $emailNotificationStatus: Boolean
  ) {
    UpdateMyProfile(
      name: $name
      phoneNumber: $phoneNumber
      timezone: $timezone
      isOnboarded: $isOnboarded
      email: $email
      notifications: {
        browser: $browserNotificationStatus
        sms: $smsNotificationStatus,
        email: $emailNotificationStatus
      }
    ) {
      id
      name
      phoneNumber
      timezone
      isOnboarded
      email
      notifications {
        browser
        sms
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String
    $email: String
    $phoneNumber: String
  ) {
    UpdateUser(id: $id, name: $name, email: $email, phoneNumber: $phoneNumber) {
      id
      name
      email
      phoneNumber
    }
  }
`

export const UPDATE_MY_PASSWORD = gql`
  mutation UpdateMyPassword($password: String!) {
    UpdateMyPassword(password: $password) {
      result
    }
  }
`

export const REMOVE_USER_FROM_STORE = gql`
  mutation RemoveFromStore($userId: ID!, $storeId: String!) {
    RemoveFromStore(id: $userId, store: $storeId) {
      result
    }
  }
`
export const REINVITE_USER = gql`
mutation ReinviteUser($email: String!) {
    ReinviteUser(email: $email)
} 
`
