/* eslint-disable max-lines */
import { STORE_CONV_ASSIGN_TYPES, WEEK_DAYS } from 'Constants/common'
import { useAuthContext } from 'Providers/AuthProvider'
import React, { useCallback, useEffect, useState } from 'react'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import {
  Add,
  Save,
  ArrowForwardOutlined,
} from '@material-ui/icons'
import moment from 'moment-timezone'

import { useApolloMutation, useApolloLazyQuery } from 'GraphQL/apollo'
import { CREATE_STORE, UPDATE_STORE } from 'GraphQL/mutations/store'
import { GET_STORE } from 'GraphQL/queries/store'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { toast } from 'react-toastify'

import { StoreTabs } from './StoreTabs'
import { validationSchema } from '../validationSchema'
import '../styles.scss'
import {
  CreateUpdateStoreModalDefaultProps,
  CreateUpdateStoreModalPropTypes,
} from './propTypes'

const FIELDS = ['name', 'state', 'city', 'address', 'secondaryAddress', 'postalCode', 'from', 'to', 'conversationAssignMode', 'phoneNumber', 'timezone', 'allowReassignChatByAgent']
const STORE_TAB = 'STORE_TAB'
const USERS_TAB = 'USER_TAB'
const DEFAULT_WORKING_HOURS = WEEK_DAYS.map(day => ({
  from: '',
  open: 'Open',
  to: '',
  day,
  breaks: [],
}))

export const CreateUpdateStoreModal = ({
  storeId,
  edit,
  open,
  onClose,
  refetchStores,
  hasPermission,
  ...modalProps
}) => {
  const [initialRetailers, setInitialRetailers] = useState([])
  const [conversationAssignmentMode, setConversationAssignmentMode] = useState(STORE_CONV_ASSIGN_TYPES.LESS_BUSY)
  const [activeTab, setActiveTab] = useState(STORE_TAB)
  const [storePhoneNumber, setStorePhoneNumber] = useState('')

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
    getValues,
    setError,
  } = useForm({
    mode: 'all',
    defaultValues: {
      workingHours: DEFAULT_WORKING_HOURS,
      allowReassignChatByAgent: false,
    },
    resolver: yupResolver(validationSchema),
  })

  const { getOrganizationId } = useAuthContext()

  const [createStoreMutation] = useApolloMutation(CREATE_STORE, {
    onCompleted: (store) => {
      const { CreateStore: { name } } = store

      toast.success(`${name} Store created`)
      reset()
      onClose()
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })
  const [updateStoreMutation] = useApolloMutation(UPDATE_STORE, {
    onCompleted: (store) => {
      const { UpdateStore: { name } } = store

      toast.success(`${name} Store updated`)
      onClose()
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [loadStoreQuery, { data: dataStoreQuery, refetch: refetchStore }] = useApolloLazyQuery(GET_STORE, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (edit) {
      loadStoreQuery({
        variables: {
          id: storeId,
        },
      })
    }
  }, [])

  const formattedWorkingHours = (data = []) => WEEK_DAYS.map((day, index) => {
    const dayData = { ...data[index] }
    dayData.open = dayData.open ? 'Open' : 'Closed'
    dayData.breaks = dayData.breaks?.map(row => ({
      from: row.from || '',
      to: row.to || '',
    })) || []
    dayData.day = day

    return dayData
  })

  useEffect(() => {
    if (edit && dataStoreQuery) {
      const store = dataStoreQuery.Store
      const storeNormalizeData = {
        ...store,
        secondaryAddress: store.secondaryAddress ?? '',
        allowReassignChatByAgent: !!store.allowReassignChatByAgent,
      }
      setInitialRetailers(storeNormalizeData.retailers)
      reset({
        users: storeNormalizeData.retailers,
        workingHours: formattedWorkingHours(storeNormalizeData.workingHours),
      })

      FIELDS.forEach((field) => {
        setValue(field, storeNormalizeData[field] ?? '')
      })
    }
  }, [dataStoreQuery])

  useEffect(() => {
    const storeData = dataStoreQuery?.Store
    if (storeData) {
      setConversationAssignmentMode(storeData.conversationAssignmentMode)
    }
  }, [dataStoreQuery])

  const createStore = (data, retailers) => {
    const {
      from, to, phoneNumber, ...restData
    } = data
    const organizationId = getOrganizationId()

    createStoreMutation({
      variables: {
        organization: organizationId,
        conversationAssignmentMode,
        phoneNumber: phoneNumber ?? '',
        retailers: retailers ?? [],
        ...restData,
      },
      refetchQueries: refetchStores,
    })
  }

  const updateStore = (id, data, retailers) => {
    const {
      // eslint-disable-next-line no-shadow
      from, to, conversationAssignmentMode, phoneNumber, ...restData
    } = data

    updateStoreMutation({
      variables: {
        id,
        phoneNumber: phoneNumber ?? '',
        conversationAssignmentMode,
        retailers,
        ...restData,
      },
      refetchQueries: refetchStores,
    })
  }

  const onSubmit = (data) => {
    const { users, ...storeDetails } = data

    storeDetails.workingHours = storeDetails.workingHours.map(({
      open: _open, from, to, breaks,
    }) => ({
      from,
      to,
      breaks,
      open: _open === 'Open',
    }))
    const retailers = users?.map(user => user.email)

    if (activeTab === USERS_TAB) {
      if (retailers.length) {
        if (edit) {
          updateStore(storeId, storeDetails, retailers)
        } else {
          createStore(storeDetails, retailers)
        }
      } else {
        toast.error('At least one Agent should assigned')
      }
    }
  }

  const handleAssignModeChange = (event) => {
    const mode = event.target.value
    setValue('conversationAssignmentMode', mode)
    setConversationAssignmentMode(mode)
  }

  const onError = (submitErrors, e) => console.error('Store submitErrors', { submitErrors }, e)

  const handleCancelAddStoreModal = () => {
    reset()
  }

  const handleChangeTab = useCallback((event, tab) => {
    setActiveTab(tab)
  }, [])

  const permittedOperationsTitle = edit ? 'Edit store' : 'Add store'

  const acceptIcon = () => {
    if (activeTab === STORE_TAB) return <ArrowForwardOutlined />
    return edit ? <Save /> : <Add />
  }

  const acceptTitle = () => {
    if (activeTab === STORE_TAB) return 'next'
    return edit ? 'Save' : 'Add'
  }

  const handleAccept = () => {
    if (isValid && activeTab === STORE_TAB) {
      const workingHours = getValues('workingHours')

      let workingHoursValid = true

      // Custom validation logic for break hours
      workingHours.forEach((row, rowIndex) => {
        if (row.open === 'Open' && row.breaks?.length > 0) {
          const fromTime = moment(row.from, 'HH:mm')
          const toTime = moment(row.to, 'HH:mm')

          if (toTime.isBefore(fromTime)) {
            toTime.add(1, 'day')
          }

          row.breaks.forEach((breakRow, breakIndex) => {
            const breakFrom = moment(breakRow.from, 'HH:mm')
            const breakTo = moment(breakRow.to, 'HH:mm')

            if (breakTo.isBefore(breakFrom)) {
              breakTo.add(1, 'day')
            }

            if (breakFrom.isBefore(fromTime, 'minute')) {
              setError(`workingHours.${rowIndex}.breaks.${breakIndex}.from`, {
                type: 'manual',
                message: 'Break "From" time cannot be before working "From" time.',
              })
              workingHoursValid = false
            }

            if (breakFrom.isAfter(toTime, 'minute')) {
              setError(`workingHours.${rowIndex}.breaks.${breakIndex}.from`, {
                type: 'manual',
                message: 'Break "From" time cannot be after working "To" time.',
              })
              workingHoursValid = false
            }

            if (breakTo.isBefore(fromTime, 'minute')) {
              setError(`workingHours.${rowIndex}.breaks.${breakIndex}.to`, {
                type: 'manual',
                message: 'Break "To" time cannot be before working "From" time.',
              })
              workingHoursValid = false
            }

            if (breakTo.isAfter(toTime, 'minute')) {
              setError(`workingHours.${rowIndex}.breaks.${breakIndex}.to`, {
                type: 'manual',
                message: 'Break "To" time cannot be after working "To" time.',
              })
              workingHoursValid = false
            }
          })
        }
      })

      if (!workingHoursValid) {
        return
      }

      setActiveTab(USERS_TAB)
    }

    handleSubmit(onSubmit, onError)()
  }

  return (
    <ModalBase
      {...modalProps}
      acceptIcon={acceptIcon()}
      acceptTitle={acceptTitle()}
      open={open}
      title={hasPermission ? permittedOperationsTitle : 'View store'}
      titleCancel="Cancel"
      withControls={hasPermission}
      onAccept={handleAccept}
      onCancel={handleCancelAddStoreModal}
      onClose={onClose}
    >
      <StoreTabs
        activeTab={activeTab}
        control={control}
        conversationAssignmentMode={conversationAssignmentMode}
        editMode={edit}
        errors={errors}
        getValues={getValues}
        handleAssignModeChange={handleAssignModeChange}
        permission={hasPermission}
        refetchStore={refetchStore}
        resetValues={reset}
        retailers={{
          initialRetailers,
          setInitialRetailers,
        }}
        setStorePhoneNumber={setStorePhoneNumber}
        setValue={setValue}
        storeData={dataStoreQuery?.Store}
        storeId={storeId}
        storePhoneNumber={storePhoneNumber}
        storeTab={STORE_TAB}
        usersTab={USERS_TAB}
        onChange={handleChangeTab}
      />
    </ModalBase>
  )
}

CreateUpdateStoreModal.propTypes = CreateUpdateStoreModalPropTypes

// eslint-disable-next-line max-lines
CreateUpdateStoreModal.defaultProps = CreateUpdateStoreModalDefaultProps
