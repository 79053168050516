import React from 'react'
import Typography from '@material-ui/core/Typography'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { useStyles } from './styles'
import ExportLogo from '../../../Assets/img/export.svg'

export const Header = ({
  title,
  handleExport,
  disableExport,
}) => {
  const classes = useStyles()

  const svgIcon = (
    <Icon>
      <img
        alt="export"
        className="expoImg"
        src={ExportLogo}
      />
    </Icon>
  )

  return (
    <div className={classes.pageHeader}>
      <div className={classes.titleHeader}>
        <Typography variant={isMobile ? 'h6' : 'h2'}>{title}</Typography>
      </div>
      <div className={classes.pickersWrapper}>
        <Button
          aria-label="Download CSV"
          className={classes.exportButton}
          disabled={disableExport}
          startIcon={svgIcon}
          variant="outlined"
          disableRipple
          onClick={handleExport}
        >
          Export payment CSV
        </Button>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  handleExport: PropTypes.func,
  disableExport: PropTypes.bool.isRequired,
}

Header.defaultProps = {
  handleExport: () => {},
}
