import { gql } from '@apollo/client'

export const GET_USERS = gql`
    query Users(
        $organization: String!
        $pagination: PaginationInput
        $searchQuery: String
        $sorting: SortingInput
        $store: String
        $includeInactive: Boolean
    ) {
        Users(
            organization: $organization
            pagination: $pagination
            searchQuery: $searchQuery
            sorting: $sorting
            store: $store
            includeInactive: $includeInactive
        ) {
            items {
                id
                name
                email
                phoneNumber
                avatar
                availability
                isOnboarded
                active
                deleted
                isPasswordExpired
                accessList {
                    organization
                    store
                    roles
                }
            }
            pagination {
                offset
                size
                totalItems
            }
        }
    }
`

export const GET_USER_BY_ID = gql`
  query User($id: ID!) {
    User(id: $id) {
      id
      nmgId
      name
      email
      phoneNumber
      avatar
      personalCallId
      accessList {
        organization
        store
        roles
      }
      active
    }
  }
`

export const GET_CURRENT_USER = gql`
  query Me {
    Me {
      id
      name
      email
      avatar
      timezone
      isOnboarded
      phoneNumber
      availability
      personalCallId
      accessList {
        organization
        store
        roles
        nmgId
      }
      notifications {
        browser
        sms
        email
      }
      active
    }
  }
`
