import { GET_ORGANIZATIONS_BILLING_QUERY } from 'GraphQL/queries/billing'
import { useApolloLazyQuery } from 'GraphQL/apollo'

export const useOrganizationsBilling = () => {
  const [getOrganizationBilling,
    { data: dataOrganizationsBillingQuery }] = useApolloLazyQuery(GET_ORGANIZATIONS_BILLING_QUERY)
  const organizationBillingPagination = dataOrganizationsBillingQuery?.OrganizationsBilling.pagination
  const organizationsBillingData = dataOrganizationsBillingQuery?.OrganizationsBilling.items ?? []

  return {
    organizationBillingPagination,
    organizationsBillingData,
    getOrganizationBilling,
  }
}
