import { gql } from '@apollo/client'

export const GET_CHAT_QUESTIONS = gql`
query Feedbacks($store: String!, $relation: RelationTypes){
  Feedbacks(store: $store, relation: $relation){
    items{
      id,
      active,
    }
  }
}
`
export const GET_QUESTION = gql`
query Feedback($id: ID!){
  Feedback(id: $id){
    type,
    relation,
    title,
    description,
    min,
    max,
    resultOptions{
      id,
      value,
    }
  }
}
`

export const GET_CHAT_MESSAGES = gql`
query FeedbackMessages($store: String!, $relation: RelationTypes) {
  FeedbackMessages(store: $store, relation: $relation) {
    items {
      id
      body
    }
  }
}
`

export const GET_FEEDBACK_ANSWERS = gql`
query FeedbacksAnswered($store: String!, $client: String, $relation: RelationTypes, $searchQuery: String = "", $pagination: PaginationInput) {
  FeedbacksAnswered(store: $store, client: $client, relation: $relation, searchQuery: $searchQuery, pagination: $pagination) {
    items {
      id
      store
      title
      type
      relation
      description
      answer
    }
  }
}`
