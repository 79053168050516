import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  separator: {
    backgroundColor: '#F8F9F9',
    margin: '24px -22px',
    padding: '16px 22px',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    color: '#0D172A',
    opacity: 0.8,
    boxShadow: 'inset 0px -1px 0px rgba(13, 23, 42, 0.08)',
  },
  organizationSiteList: {
    listStyle: 'none',
    margin: 0,
  },
  organizationSiteItem: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  removeButton: {
    marginTop: 28,
  },
  merchantAccountSwitch: {
    fontSize: '0.8rem',
    marginLeft: 12,
  },
  merchantAccountList: {
    fontSize: '0.8rem',
    marginLeft: 12,
    marginTop: 3,
  },
  merchantAccountListWrapper: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D8DADD',
    marginBottom: 14,
  },
  merchantAccountListWrapperHeader: {
    marginBottom: 20,
  },
}))
