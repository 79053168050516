import React from 'react'
import { SearchInput } from 'Components/common/SearchInput'
import PropTypes from 'prop-types'
import './styles.scss'
import { Button } from 'reactstrap'

const WorkflowToolbar = ({ handleSearch, handleCreateWorkflow }) => (
  <div className="page-toolbar">
    <section className="search-and-filter">
      <SearchInput onChange={handleSearch} />
    </section>
    <Button
      className="btn-custom"
      onClick={handleCreateWorkflow}
    >
      <i className="zmdi zmdi-plus" />
      <span>Add New</span>
    </Button>
  </div>
)

WorkflowToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleCreateWorkflow: PropTypes.func.isRequired,
}
export default WorkflowToolbar
