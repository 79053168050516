import { useStyles } from 'Pages/AnalyticsPage/StatisticsCharts/styles'
import PropTypes from 'prop-types'
import { DoughnutChart } from './DoughnutChart/DoughnutChart'
import { RatingChart } from './RatingChart/RatingChart'
import { VerticalChart } from './VerticalChart/VerticalChart'

export const StatisticsCharts = ({ daysRange, analyticsData }) => {
  const classes = useStyles()

  return (

    <div className={classes.chartsWrapper}>
      <DoughnutChart typeOfChat={analyticsData.typeOfChat} />
      <RatingChart reviewScore={analyticsData.reviewScore} />
      <VerticalChart
        daysRange={daysRange}
        sessionsPerDay={analyticsData.sessionsPerDay}
      />
    </div>
  )
}

StatisticsCharts.propTypes = {
  daysRange: PropTypes.number.isRequired,
  analyticsData: PropTypes.shape({
    abandonedSessions: PropTypes.string,
    avgSessionLength: PropTypes.string,
    avgTimeToAnswer: PropTypes.string,
    numberOfSessions: PropTypes.string,
    timeOnChat: PropTypes.string,
    typeOfChat: PropTypes.shape({
      backoffice: PropTypes.number,
      email: PropTypes.number,
      video: PropTypes.number,
      widget: PropTypes.number,
    }),
    reviewScore: PropTypes.shape({
      excellent: PropTypes.number,
      great: PropTypes.number,
      average: PropTypes.number,
      poor: PropTypes.number,
    }),
    sessionsPerDay: PropTypes.shape({}),
  }),
}

StatisticsCharts.defaultProps = {
  analyticsData: {
    abandonedSessions: '0',
    avgSessionLength: '0',
    avgTimeToAnswer: '0',
    numberOfSessions: '0',
    timeOnChat: '0',
    typeOfChat: {
      backoffice: 0,
      email: 0,
      video: 0,
      widget: 0,
    },
    reviewScore: {
      excellent: 0,
      great: 0,
      average: 0,
      poor: 0,
    },
    sessionsPerDay: {},
  },
}
