export const resolutions = [
  {
    label: '360p',
    value: {
      width: 480,
      height: 360,
    },
  },
  {
    label: '480p',
    value: {
      width: 640,
      height: 480,
    },
  },
  {
    label: '720p',
    value: {
      width: 1280,
      height: 720,
    },
  },
  {
    label: '1080p',
    value: {
      width: 1920,
      height: 1080,
    },
  },
]
