
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import UserHeaderBlock from 'Components/UserHeaderBlock'
import thumbup from '../../Assets/img/thumbup.svg'
import thumbdown from '../../Assets/img/thumbdown.svg'

const ChooseThumbs = ({
  gotoPostReview,
  gotoAccountSelection,
  storeInfo,
}) => (
  <div className="review_card">
    <div className="card-header">
      <UserHeaderBlock
        address={storeInfo?.address}
        logo={storeInfo?.logo}
        name={storeInfo?.name}
      />
    </div>
    <div className="option-body">
      <p className="text-bold my-4">How was your experience?</p>
      <div className="row">
        <div className="col-6">
          <Icon
            className="thumb-icon"
            onClick={gotoAccountSelection}
          >
            <img
              alt="thumbup"
              className="thumb-img"
              src={thumbup}
            />
          </Icon>
        </div>
        <div className="col-6">
          <Icon
            className="thumb-icon"
            onClick={gotoPostReview}
          >
            <img
              alt="thumbdown"
              className="thumb-img"
              src={thumbdown}
            />
          </Icon>
        </div>
      </div>
    </div>
  </div>
)

ChooseThumbs.propTypes = {
  storeInfo: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    address: PropTypes.string,
  }),
  gotoPostReview: PropTypes.func,
  gotoAccountSelection: PropTypes.func,
}

ChooseThumbs.defaultProps = {
  storeInfo: {
    name: '',
    logo: '',
    address: '',
  },
  gotoPostReview: () => {},
  gotoAccountSelection: () => {},
}

export default ChooseThumbs
