import PropTypes from 'prop-types'
import UserHeaderBlock from 'Components/UserHeaderBlock'

const ReviewSuccess = ({
  storeInfo,
}) => (
  <div id="payment-success">
    <div className="review_card">
      <div className="card-header">
        <UserHeaderBlock
          address={storeInfo?.address}
          logo={storeInfo?.logo}
          name={storeInfo?.name}
        />
      </div>
      <div className="pcard-body">
        <div className="text-center">
          <svg
            className="bi bi-check-circle"
            fill="currentColor"
            height="80"
            style={{ color: '#017AFF' }}
            viewBox="0 0 16 16"
            width="80"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
          <p className="text-content-highlight mx-5 my-3">Your review was submitted successfully!</p>
          <p className="text-description mx-5 my-3">Close this window and return to chat.</p>
          <button
            className="btn payment_button"
            type="button"
            onClick={() => { window.close() }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)

ReviewSuccess.propTypes = {
  storeInfo: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    address: PropTypes.string,
  }),
}

ReviewSuccess.defaultProps = {
  storeInfo: {
    name: '',
    logo: '',
    address: '',
  },
}

export default ReviewSuccess
