import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TextField as MuiTextField } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { styles, muiStyles } from './styles'
import './styles.scss'

const useStyles = makeStyles(muiStyles)

export const TextField = withStyles(styles)(({
  label,
  labelIcon,
  error,
  errorMessage,
  wrapperClassName,
  InputProps,
  className,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <div className={classNames('input-wrapper', wrapperClassName)}>
      {label && (
        <div className="label">
          {labelIcon && <span className="label-icon">{labelIcon}</span>}
          {label}
        </div>
      )}
      <MuiTextField
        {...restProps}
        className={classNames({ error })}
        error={error}
        InputProps={{
          ...InputProps,
          classes,
        }}
      />
      {error && <span className="error-message">{errorMessage}</span>}
    </div>
  )
})

TextField.propTypes = {
  label: PropTypes.string,
  labelIcon: PropTypes.element,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  wrapperClassName: PropTypes.string,
  InputProps: PropTypes.shape({}),
  className: PropTypes.string,
}

TextField.defaultProps = {
  label: '',
  labelIcon: null,
  error: false,
  errorMessage: 'Error',
  wrapperClassName: '',
  InputProps: null,
  className: null,
}
