import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow } from 'Pages/settings/components/TableWithStyles'
import { TableActions } from 'Components/common/Table/TableActions'
import { isMobile } from 'react-device-detect'

const WorkflowTableRow = ({ workflow, actions }) => {
  const {
    id, name, deleted, createdAt,
  } = workflow
  return (
    <TableRow
      key={name}
      classes={{
        root: !deleted && 'deactive',
      }}
      hover
    >
      <TableCell>
        <h5 className="mb-5 fw-bold workflow-name">
          {name}
        </h5>
      </TableCell>
      <TableCell><span className="page-table-cell">{!deleted ? 'active' : 'inactive'}</span></TableCell>
      <TableCell><span className="page-table-cell">{createdAt}</span></TableCell>
      <TableCell>
        <TableActions
          {...actions}
          row={{ id, name, active: !deleted }}
          size={isMobile ? 'mb' : 'lg'}
          deactivate
        />
      </TableCell>
    </TableRow>
  )
}
WorkflowTableRow.propTypes = {
  workflow: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    deleted: PropTypes.bool,
    createdAt: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onReactive: PropTypes.func,
  }).isRequired,
}
export default WorkflowTableRow
