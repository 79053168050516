import PropTypes from 'prop-types'

export const CardPropTypes = {
  id: PropTypes.string.isRequired,
  activeQuestionsLength: PropTypes.number.isRequired,
  inactiveQuestionsLength: PropTypes.number.isRequired,
  drag: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  relation: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
}

export const CardDefaultProps = {
  drag: false,
}
