/* eslint-disable no-magic-numbers */
import React from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'
import PhoneEnabled from '@material-ui/icons/PhoneEnabled'
import ControlHeader from '../ControlHeader'
import { SHAPE_CLASSES } from '../../../config'

import './styles.scss'

const ShapeContainer = ({
  settings,
  onChange,
}) => {
  const handleOnChange = value => onChange('launcherShape', value)
  return (
    <div className="shape-container">
      <ControlHeader
        icon={<GetAppIcon />}
        label="Shape"
      />
      <div className="shapes">
        {SHAPE_CLASSES.map(
          item => (
            <div
              className={`launcher ${item} ${item === settings.launcherShape && 'selected'}`}
              onClick={() => handleOnChange(item)}
            >
              {item === settings.launcherShape && <PhoneEnabled />}
            </div>
          ),
        )}
      </div>
    </div>
  )
}

export default ShapeContainer

ShapeContainer.propTypes = {
  settings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
