import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'

import { useAuthContext } from 'Providers/AuthProvider'
import { GET_STORES } from 'GraphQL/queries/store'
import { useApolloLazyQuery } from 'GraphQL/apollo'

const StoresContext = createContext({
  stores: null,
  selectedStores: [],
  setSelectedStores: () => {},
})
export const useStoresContext = () => useContext(StoresContext)

export const StoresProvider = ({ children }) => {
  const { currentUser, getOrganizationId } = useAuthContext()

  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState([])
  const organizationId = getOrganizationId()

  const [loadStores, { data: storesData }] = useApolloLazyQuery(GET_STORES)

  useEffect(() => {
    if (storesData?.Stores.items) {
      setStores(storesData.Stores.items)
    }
  }, [storesData])

  useEffect(() => {
    if (currentUser && organizationId) {
      loadStores({
        variables: {
          organization: organizationId,
          skipCondition: !organizationId,
        },
      })
    }
  }, [currentUser, organizationId, loadStores])

  const contextValue = {
    stores,
    selectedStores,
    setSelectedStores,
  }

  return (
    <StoresContext.Provider value={contextValue}>
      {children}
    </StoresContext.Provider>
  )
}

StoresProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
