import React, { useState } from 'react'
import { PERMISSION_NAMES } from 'config/permissions'
import { isMobile } from 'react-device-detect'
import { useCheckPermission } from 'Hooks/useCheckPermission'
import PropTypes from 'prop-types'
import { TableHeader } from 'Components/TableHeader'
import { TableActions } from 'Components/common/Table/TableActions'
import { Badge } from 'reactstrap'
import { AvatarGroup } from './AvatarGroup'
import {
  Table, TableBody, TableCell, TableRow,
} from '../components/TableWithStyles'
import './styles.scss'
import { UsersDropdownList } from './UsersDropdownList'

export const StoresTable = ({
  headers, stores, actions, refetchStores,
}) => {
  const checkPermission = useCheckPermission()
  const isUserHasEditStorePermission = checkPermission(PERMISSION_NAMES.EDIT_STORE)
  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)

  const handlePositionedConfirm = (event) => {
    setPositionedConfirmAnchorEl(event.currentTarget)
  }
  const handleCellClick = (field, direction) => {
    refetchStores({
      sorting: {
        field,
        direction,
      },
    })
  }

  return (
    <Table>
      {!isMobile && (
        <TableHeader
          handleCellClick={handleCellClick}
          headerTitles={headers}
        />
      )}
      <TableBody>
        <>
          {stores?.map((store) => {
            const {
              name, address, id, isTopStore, state, retailers, active,
            } = store

            return (
              <TableRow
                key={id}
                hover
              >
                <TableCell className="first-cell">
                  <div className="d-flex align-items-center">
                    <span className="fw-bold">{name}</span>
                    {isTopStore && (
                      <Badge
                        className="badge badge-pill ml-5 pl-5"
                        color="warning"
                      >
                        <span className="zmdi zmdi-star pr-5" />
                        <span>Top Store</span>
                      </Badge>
                    )}
                  </div>
                  <span>{state}</span>
                </TableCell>
                <TableCell><span className="page-table-cell">{address}</span></TableCell>
                <TableCell
                  className="agents-col"
                  data-id={store.id}
                  onMouseEnter={e => handlePositionedConfirm(e, store.id)}
                  onMouseLeave={() => setPositionedConfirmAnchorEl(null)}
                >
                  <div
                    className="agents-avatars"
                  >
                    <AvatarGroup users={retailers} />
                  </div>
                  <UsersDropdownList
                    anchorEl={positionedConfirmAnchorEl}
                    id={store.id}
                    setAnchorEl={setPositionedConfirmAnchorEl}
                    users={retailers}
                  />
                </TableCell>
                <TableCell className="last-cell">
                  <span className="page-table-cell">
                    <TableActions
                      {...actions}
                      hasPermission={isUserHasEditStorePermission}
                      row={{ id, active, name }}
                    />
                  </span>
                </TableCell>
              </TableRow>
            )
          })}
        </>
      </TableBody>
    </Table>
  )
}

StoresTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  stores: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  }),
  refetchStores: PropTypes.func.isRequired,
}

StoresTable.defaultProps = {
  headers: [],
  stores: [],
  actions: null,
}
