import { gql } from '@apollo/client'

export const SEND_EMAIL_MESSAGE = gql`
    mutation SendEmailMessage($conversation: String!, $client: String!, $recipient: String!, $content: String!, $contentType: MessageContentTypes, $type: EmailTemplateTypes!, $attachments: [AttachmentInput]) {
        SendEmailMessage(client: $client, recipient: $recipient, content: $content, contentType: $contentType, conversation: $conversation, type: $type, attachments: $attachments) {
            result
        }
    }
`
export const SEND_SMS_MESSAGE = gql`
    mutation SendSmsMessage($conversation: String!, $client: String!, $recipient: [String!]!, $content: String!, $contentType: MessageContentTypes, ) {
        SendSmsMessage(client: $client, recipient: $recipient, content: $content, contentType: $contentType, conversation: $conversation) {
            result
        }
    }
`

export const APPEND_MESSAGE = gql`
    mutation AppendMessage($conversation: String!, $content: String!, $contentType: MessageContentTypes, $type: MessageType!, $attachments: [AttachmentInput], $channel: ChannelTypes) {
        AppendMessage(content: $content, contentType: $contentType, conversation: $conversation, type: $type, attachments: $attachments, channel: $channel) {
            result
        }
    }
`

export const SWITCH_CONVERSATION_CHANNEL = gql`
    mutation SwitchConversation($conversation: String!, $client: String!, $recipient: String!, $type: ConversationTypes!) {
        SwitchConversation(conversation: $conversation, client: $client, recipient: $recipient, type: $type) {
            result
        }
    }
`

export const MARK_MESSAGE_AS_READ = gql`
    mutation MarkMessageAsRead($conversation: String!, $messageId: String!) {
        MarkMessageAsRead(conversation: $conversation, messageId: $messageId) {
            result
        }
    }
`
