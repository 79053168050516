import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Avatar } from '../../../Components/Avatar/Avatar'

const PaymentSuccess = ({
  storeName,
  storeAddress,
  storeLogo,
}) => (
  <div id="payment-success">
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-2 justify-content-center align-self-center">
            <Avatar
              avatarSrc={storeLogo}
              className={classNames(
                'message-avatar',
                'response',
              )}
              userName={storeName}
            />
          </div>
          <div className="col justify-content-center align-self-center">
            <p
              className="text-heading"
              id="var-store-name-success"
            >
              {storeName}
            </p>
            <p
              className="text-description"
              id="var-store-address-success"
            >
              {storeAddress}
            </p>
          </div>
        </div>
      </div>
      <div className="pcard-body">
        <div className="text-center">
          <svg
            className="bi bi-check-circle"
            fill="currentColor"
            height="80"
            style={{ color: '#017AFF' }}
            viewBox="0 0 16 16"
            width="80"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
          <p className="text-content-highlight mx-5 my-3">Your payment was successful!</p>
          <p className="text-description mx-5 my-3">Close this window and return to chat.</p>
          <button
            className="btn payment_button"
            type="button"
            onClick={() => { window.close() }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)

PaymentSuccess.propTypes = {
  storeName: PropTypes.string,
  storeAddress: PropTypes.string,
  storeLogo: PropTypes.string,
}

PaymentSuccess.defaultProps = {
  storeName: '',
  storeAddress: '',
  storeLogo: '',
}

export default PaymentSuccess
