
export const parsePaymentInfo = (content) => {
  if (!content) {
    return null
  }

  const paymentInfo = content.split(',').map(p => p.split(':'))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

  if (!paymentInfo.requestId) {
    return null
  }

  return paymentInfo
}
