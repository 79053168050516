import { AGENT_STATUSES } from 'Constants/common'
import { useAgentStatusContext } from 'Providers/AgentStatusProvider'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { isTablet, isMobileOnly } from 'react-device-detect'
// import ListIcon from '@material-ui/icons/List'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Button from '@material-ui/core/Button'
import { useHistory, useLocation } from 'react-router-dom'
import { setInnerHeight } from 'Helpers/helpers'
import { useAuthContext } from 'Providers/AuthProvider'
import { usePeerContext } from 'Providers/PeerProvider/PeerProvider'
import { useCallSettingsContext } from 'Providers/CallSettingsProvider'
import Mic from '@material-ui/icons/Mic'
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined'
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined'
import { Controls } from './Controls/Controls'
import './style.scss'
import { VideoCallChat } from '../VideoCallChat/VideoCallChat'
import { APP_PATH, MESSENGER_PATH } from '../../../Constants/routerPaths'

const VideoSpaceRoom = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [time, setTime] = useState(0)
  const { changeAvailability } = useAgentStatusContext()
  const { currentUser } = useAuthContext()

  const {
    remoteVideoRef,
    localVideoRef,
    localScreenShareRef,
    isScreenSharing,
    isCallEnded,
    setForceUpdate,
    endCall,
    opponentCameraStatus,
  } = usePeerContext()
  const location = useLocation()
  const fromRealCall = location?.state ? location.state.fromRealCall : false
  const history = useHistory()
  const customerShortInfo = JSON.parse(window.localStorage.getItem('customerShortInfo'))

  const { isCameraEnabled, isMicrophoneEnabled } = useCallSettingsContext()
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-shadow
      setTime(time => time + 1)
    }, 1000)
    return () => { clearTimeout() }
  }, [time])

  useEffect(() => {
    if (fromRealCall) {
      changeAvailability(AGENT_STATUSES.BUSY)
    } else {
      endCall()
      history.push(`${APP_PATH}${MESSENGER_PATH}`)
    }
    history.replace({ state: { fromRealCall: false } })

    return () => {
      changeAvailability(AGENT_STATUSES.AVAILABLE)
    }
  }, [])

  useEffect(() => {
    const setContainerHeight = () => isMobileOnly && setInnerHeight('video-space-wrapper-mobile')
    setContainerHeight()
    window.addEventListener('resize', setContainerHeight)
    return () => window.removeEventListener('resize', setContainerHeight)
  }, [])

  const toggleChat = () => {
    setIsChatOpen((state) => {
      setForceUpdate(state)
      return !state
    })
  }

  const showVideo = !isTablet || (isTablet && !isChatOpen)
  return (
    <div className={classNames('video-space-wrapper', { 'video-space-wrapper-mobile': isMobileOnly })}>
      <div className="main-container d-flex justify-content-center align-items-center">
        {isMobileOnly && (
          <div className={classNames('room-call-header', { 'is-blurred': isCameraEnabled })} />
        )}
        {!isCameraEnabled && isMobileOnly && (
          <div className="camera-disabled">
            <div>
              <img
                alt="avatar"
                src={currentUser.avatar}
              />
              <b>{currentUser.name}</b>
              <span>{isMicrophoneEnabled ? <Mic /> : <MicOffOutlinedIcon />}</span>
              <span><VideocamOffOutlinedIcon /></span>
            </div>
          </div>
        )}
        <div className={classNames(
          'video-container',
          { 'with-chat': isChatOpen },
        )}
        >
          {(isCallEnded || !opponentCameraStatus) && (
            <div className="d-flex justify-content-center align-items-center avatar-container">
              {customerShortInfo.avatar && (
                <img
                  alt="user profile"
                  className="large-size avatar-image"
                  src={customerShortInfo.avatar}
                />
              )}
              {!customerShortInfo.avatar && (
                <span
                  className="user-abbreviation large-size avatar-abbreviation"
                  title={customerShortInfo?.name ?? 'Unknown'}
                >
                  {customerShortInfo && customerShortInfo.name ? customerShortInfo.name[0]?.toUpperCase() : '&'}
                </span>
              )}
            </div>
          )}
          {isScreenSharing && <div className="share-my-screen" />}
          {showVideo && (
            <div className="video-wrapper">
              <video
                ref={remoteVideoRef}
                className={classNames(
                  'remote-video',
                  { isDisabled: isCallEnded, 'd-none': (isScreenSharing || !opponentCameraStatus) },
                )}
                autoPlay
                playsInline
              />
              <video
                ref={localVideoRef}
                className={classNames(
                  'local-video',
                  { isDisabled: !isCameraEnabled },
                )}
                autoPlay
                muted
                playsInline
              />
              <video
                ref={localScreenShareRef}
                className={classNames('screen-share', !isScreenSharing && 'd-none')}
                autoPlay
                muted
                playsInline
              />
            </div>
          )}
          {isChatOpen && (
            <VideoCallChat callDuration={time} />
          )}
        </div>
      </div>
      <div className="toolbar d-flex justify-content-between align-items-center border">
        {!isMobileOnly && (
          /* <div className="list-products pl-5 d-flex align-items-center">
            <ListIcon />
            <span className="mx-10">Products</span>
            <KeyboardArrowUpIcon />
          </div> */
          <div />
        )}
        <div className={classNames('toolbar-controls d-flex',
          isTablet ? 'justify-content-around' : 'justify-content-between')}
        >
          <Controls
            callDuration={time}
            toggleChat={toggleChat}
          />
        </div>
        {!isMobileOnly && (
          <div className="toolbar-chat pr-10 d-flex align-items-center">
            <Button onClick={toggleChat}>
              <ForumOutlinedIcon />
              <span className="mx-10">Chat</span>
              {isChatOpen && (<KeyboardArrowDownIcon />)}
              {!isChatOpen && (<KeyboardArrowUpIcon />)}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoSpaceRoom
