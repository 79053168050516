const convertArrayOfObjectsToCSV = (data) => {
  if (data == null || !data.length) {
    return null
  }

  const columnDelimiter = ','
  const lineDelimiter = '\n'

  const keys = Object.keys(data[0])

  let result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach((item) => {
    let ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter

      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}
export const downloadCSV = (data, fileName) => {
  let csv = convertArrayOfObjectsToCSV(data)
  if (csv == null) return

  const filename = `${fileName}.csv` || 'export.csv'

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
  }

  const link = document.createElement('a')
  link.setAttribute('href', csv)
  link.setAttribute('download', filename)
  link.click()
}
