import React, { useState, useEffect } from 'react'
import CreateUpdateWorkflowModal from 'Components/CreateUpdateWorkflowModal'
import './styles.scss'
import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { RemoveCircleOutline } from '@material-ui/icons'
import { useApolloLazyQuery } from 'GraphQL/useApolloLazyQuery'
import { GET_WORKFLOWS } from 'GraphQL/queries/workflow'
import { useAuthContext } from 'Providers/AuthProvider'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { DELETE_WORKFLOW, RECOVER_WORKFLOW } from 'GraphQL/mutations/workflow'
import { toast } from 'react-toastify'
import { useDebounce } from 'Hooks/useDebounce'
import { DEBOUNCED_SEARCH_DELAY } from 'Constants/common'
import TablePagination from 'Pages/settings/components/TablePagination'
import { PRE_QUESTION_TYPES } from 'Constants/preChatQuestions'
import { WorkflowHeader } from './workflowHeader'
import { WorkflowTable } from './workflowTable'
import WorkflowToolbar from './workflowToolbar'

const initPagination = {
  pagination: {
    offset: 0,
    size: 5,
  },
}
const sorting = {
  field: 'deleted',
  direction: 'ASC',
}
const WorkflowPage = () => {
  const { getOrganizationId } = useAuthContext()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const debouncedSearch = useDebounce(search, DEBOUNCED_SEARCH_DELAY)
  const [isOpenCreateUpdateWorkflowModal, setIsOpenCreateUpdateWorkflowModal] = useState(false)
  const [workflowId, setWorkflowId] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [positionedConfirmOpen, setPositionedConfirmOpen] = useState(false)
  const [positionedConfirmPlacement, setPositionedConfirmPlacement] = useState()
  const organizationId = getOrganizationId()

  const [
    loadWorkflowsQuery,
    { data: dataWorkflowsQuery, refetch: refetchWorkflowsQuery },
  ] = useApolloLazyQuery(GET_WORKFLOWS, {
    variables: {
      organization: organizationId,
      sorting,
      ...initPagination,
    },
  })
  const workflowsPagination = dataWorkflowsQuery?.Workflows.pagination
  const workflowsData = dataWorkflowsQuery?.Workflows.items

  const pagination = {
    offset: 0,
    limit: initPagination.pagination.size,
    total: workflowsPagination?.totalItems,
  }

  const countPage = Math.ceil(pagination.total / pagination.limit)

  const changePagination = (_, currentPage) => {
    refetchWorkflowsQuery({
      pagination: {
        offset: (currentPage - 1) * pagination.limit,
        size: pagination.limit,
      },
    })
    setPage(currentPage)
  }

  const [deleteWorkflow] = useApolloMutation(DELETE_WORKFLOW, {
    onCompleted: (data) => {
      setWorkflowId('')
      refetchWorkflowsQuery()
      const { DeleteWorkflow: { name } } = data
      toast.success(`${name} Workflow deactivated`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [reactiveWorkflow] = useApolloMutation(RECOVER_WORKFLOW, {
    onCompleted: (data) => {
      refetchWorkflowsQuery()
      const { RecoverWorkflow: { name } } = data
      toast.success(`${name} Workflow reactivated`)
    },
  })

  const onClosePositionedConfirm = () => {
    setPositionedConfirmOpen(false)
  }
  const handleEdit = (id) => {
    setWorkflowId(id)
    setIsEditMode(true)
    setIsOpenCreateUpdateWorkflowModal(true)
  }
  const handleDeactivate = (id, name, newPlacement) => (event) => {
    setWorkflowId(id)
    setPositionedConfirmAnchorEl(event.currentTarget)
    setPositionedConfirmOpen(prev => positionedConfirmPlacement !== newPlacement || !prev)
    setPositionedConfirmPlacement(newPlacement)
  }

  const handleCloseCreateUpdateWorkflowModal = () => {
    setIsOpenCreateUpdateWorkflowModal(false)
  }
  const onDeactiveWorkflow = () => {
    deleteWorkflow({
      variables: {
        id: workflowId,
      },
    })
  }

  const onReactiveWorkflow = (id) => {
    reactiveWorkflow({
      variables: {
        id,
      },
    })
  }
  const actions = {
    onEdit: handleEdit,
    onDelete: handleDeactivate,
    onReactive: onReactiveWorkflow,
  }

  useEffect(() => {
    loadWorkflowsQuery()
  }, [])
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  useEffect(() => {
    if (debouncedSearch.length >= 2 || !debouncedSearch.length) {
      loadWorkflowsQuery({
        variables: {
          searchQuery: debouncedSearch,
          sorting,
          ...initPagination,
        },
      })
    }
  }, [debouncedSearch])
  return (
    <div className="workflows-page">
      <PositionedConfirmModal
        acceptIcon={<RemoveCircleOutline />}
        acceptTitle="Deactive"
        anchorEl={positionedConfirmAnchorEl}
        open={positionedConfirmOpen}
        title="Are you sure you want to deactive this workflow?"
        onAccept={onDeactiveWorkflow}
        onClose={onClosePositionedConfirm}
      />
      {
        isOpenCreateUpdateWorkflowModal && (
          <CreateUpdateWorkflowModal
            edit={isEditMode}
            open={isOpenCreateUpdateWorkflowModal}
            refetchWokflows={refetchWorkflowsQuery}
            typesOfQuestion={PRE_QUESTION_TYPES}
            width={450}
            workflowId={workflowId}
            onClose={handleCloseCreateUpdateWorkflowModal}
          />
        )
      }

      <WorkflowHeader />
      <WorkflowToolbar
        handleCreateWorkflow={() => {
          setIsEditMode(false)
          setWorkflowId('')
          setIsOpenCreateUpdateWorkflowModal(true)
        }}
        handleSearch={handleSearch}
      />

      <div className="table-responsive">
        <WorkflowTable
          actions={actions}
          refetchWorkflows={refetchWorkflowsQuery}
          workflows={workflowsData}
        />
      </div>
      <div className="table-pagination-container">
        <TablePagination
          count={countPage}
          page={page}
          onChange={changePagination}
        />
      </div>
    </div>
  )
}
export default WorkflowPage
