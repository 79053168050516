import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import classNames from 'classnames'
import { useStyles } from './styles'

const STARS_COUNT = 5
export const StarsRating = ({ value, title }) => {
  const classes = useStyles()

  const stars = Array.from({ length: STARS_COUNT })

  return (
    <div className={classes.starRatingWrapper}>
      <Typography
        className={classes.title}
        component="legend"
      >
        {title}
      </Typography>
      <div className={classes.starsWrapper}>
        {stars.map((val, index) => (
          <StarIcon
            key={index}
            className={classNames(
              !(index < value) && classes.grayIcon,
              (index < value) && classes.orangeIcon,
            )}
          />
        ))}
      </div>
    </div>
  )
}

StarsRating.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string,
}

StarsRating.defaultProps = {
  title: '',
}
