import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import ButtonWithIcon from '../ButtonWithIcon'
import './styles.scss'

export const Item = ({
  id,
  index,
  value,
  options,
  setOptions,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(value)

  const deleteChoice = () => {
    if (options.length === 2) return
    const newOptions = options.filter(opt => opt.id !== id)

    setOptions(newOptions)
  }

  const editToggle = () => {
    if (isEditing) {
      const newOptions = [...options]
      const optionIndex = newOptions.findIndex(opt => opt.id === id)
      const newOption = { ...newOptions[optionIndex], value: title }
      newOptions.splice(optionIndex, 1, newOption)
      setOptions(newOptions)
    }
    setIsEditing(prevState => !prevState)
  }

  return (
    <li className="ant-choices-item">
      <div className="item-left-wrapper">
        <div className="item-index-wrapper">
          <span className="ti-menu drag-mark" />
          <span className="index-mark">{index + 1}</span>
        </div>
      </div>
      {
        isEditing
          ? (
            <Input
              className={classNames('title', { 'error-border': !title })}
              defaultValue={title}
              placeholder="Type a choice"
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          )
          : <span className={classNames('title non-editable-choice', { 'error-border': !title })}>{title}</span>
      }
      <div className="item-right-wrapper">
        <ButtonWithIcon
          aria-label="Edit"
          className={classNames('edit-button', isEditing && 'active')}
          component="span"
          onClick={editToggle}
        >
          <span className="ti-pencil" />
        </ButtonWithIcon>
        <ButtonWithIcon
          aria-label="Remove"
          component="span"
          onClick={deleteChoice}
        >
          <span className="ti-trash" />
        </ButtonWithIcon>
      </div>
    </li>
  )
}

const limitLength = 6

export const Choices = ({
  options,
  setOptions,
}) => {
  const addChoice = () => {
    if (options.length < limitLength) {
      setOptions([...options, { id: uuid(), value: '' }])
    }
  }

  return (
    <div className="ant-choices">
      <div className="ant-choices-header">
        <span className="title">Choices:</span>
      </div>
      <div className="ant-choices-body">
        <ul className="ant-choices-list">
          {options?.map((opt, i) => (
            <Item
              key={opt.id}
              id={opt.id}
              index={i}
              options={options}
              setOptions={setOptions}
              value={opt.value}
            />
          ))}
        </ul>
      </div>
      <div className="ant-choices-footer">
        <Button
          className="button"
          color="link"
          onClick={addChoice}
        >
          <span className="ti-plus icon" />
          Add choice
        </Button>
        <span className="limit">
          Choice limit:
          {' '}
          {`${options.length}/${limitLength}`}
        </span>
      </div>
    </div>
  )
}

Item.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
}

Choices.propTypes = {
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
}
