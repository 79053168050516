import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const CustomLauncherPreview = ({ settings }) => (
  <div className="custom-launcher-container">
    <div>
      <span className="settings-label">Custom launcher</span>
      <br />
      <span className="settings-label-description">
        Make your Messenger’s launcher
      </span>
    </div>
    <div className="launcher-preview">
      <span className="settings-label">Launcher preview</span>
      <div
        className={`launcher-btn ${settings.launcherShape} ${settings.launcherSize}`}
        style={{ backgroundColor: settings.launcherBackgroundColor }}
      >
        <span
          className={`zmdi zmdi-${settings.launcherIcon}`}
          style={{ color: settings.launcherIconColor }}
        />
      </div>
    </div>
  </div>
)

export default CustomLauncherPreview

CustomLauncherPreview.propTypes = {
  settings: PropTypes.string.isRequired,
}
