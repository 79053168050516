import React from 'react'
import { useAuthContext } from 'Providers/AuthProvider'
import { useApolloQuery } from 'GraphQL/apollo'
import { GET_ORGANIZATION_QUERY } from 'GraphQL/queries/organization'
import { WebsiteContent } from './WebsiteContent'
import { DevelopersInstructions } from './DevelopersInstructions'
import './styles.scss'

const WidgetInstallCode = () => {
  const { getOrganizationId } = useAuthContext()
  const organizationId = getOrganizationId()

  const { data: organizationData } = useApolloQuery(GET_ORGANIZATION_QUERY, {
    variables: {
      id: organizationId,
    },
  })

  const code = organizationData?.Organization?.nmgId ? `
<div id="primeworks-customer-widget" nmg-id="${organizationData?.Organization?.nmgId}"></div>
    <script defer src="https://widget.nmgservices.org/lib/build.widget.min.js?1b21612f13d4ac4d5a7c559e06eb8f0e517aaa59"></script>
    ` : ''

  return (
    <div className="widget-code-page p-20">
      <section className="settings-page-title d-flex flex-column">
        <span><small>General settings</small></span>
        <h3 className="pt-5">Widget install code</h3>
      </section>
      <div className="settings-page-rows pt-10">
        <WebsiteContent code={code} />
        <DevelopersInstructions nmgId={organizationData?.Organization?.nmgId} />
      </div>
    </div>
  )
}

export default WidgetInstallCode
