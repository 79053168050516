import { AGENT_STATUSES } from 'Constants/common'
import { useAgentStatusContext } from 'Providers/AgentStatusProvider'
import React, { useEffect, useState } from 'react'
import LanguageIcon from '@material-ui/icons/Language'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Input from 'Components/common/Input'
import { LinkButton } from 'Components/common/LinkButton'
import { useAuthContext } from 'Providers/AuthProvider'
import {
  ADMIN_LOGIN_PATH,
  ADMIN_SIGNUP_PATH,
  FORGOT_PASSWORD,
  SIGNUP_PATH,
} from 'Constants/routerPaths'
import queryString from 'query-string'
import { validationSchema } from './validationSchema'
import { styles } from './styles'

const ButtonMemberNet = withStyles(styles)(Button)

export const SignInPage = () => {
  const [userOrganizationName, setUserOrganizationName] = useState()
  const isPlatformAdminLogin = window.location.pathname === ADMIN_LOGIN_PATH
  const isPlatformAdminSignUp = window.location.pathname === ADMIN_SIGNUP_PATH
  const isSignUp = window.location.pathname === SIGNUP_PATH || isPlatformAdminSignUp
  const { changeAvailability } = useAgentStatusContext()

  const isPlatformAdmin = isPlatformAdminLogin || isPlatformAdminSignUp

  const { login, loginWithMemberNet, authError } = useAuthContext()
  const sessionTitle = (isPlatformAdminLogin || !isSignUp) ? 'Login' : 'Sign up'
  const buttonText = isSignUp ? 'Sign up' : 'Login'

  const {
    getValues,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const handleLogin = () => {
    if (!Object.keys(errors).length && getValues('email') && getValues('password')) {
      login(getValues('email'), getValues('password'), changeAvailability(AGENT_STATUSES.AVAILABLE))
    }
  }
  useEffect(() => {
    if (isSignUp) {
      const { password, organizationName, email } = queryString.parse(window.location.search)
      setValue('password', password)
      setValue('email', email)
      setUserOrganizationName(organizationName)
    }
  }, [window.location])
  useEffect(() => {
    const EnterKeyCode = 13
    const handleKeyPress = (event) => {
      if (event.keyCode === EnterKeyCode) {
        handleLogin()
      }
    }
    document.addEventListener('keypress', handleKeyPress)
    return () => document.removeEventListener('keypress', handleKeyPress)
  }, [])

  const handleLoginWithMemberNet = () => loginWithMemberNet(`${process.env.REACT_APP_URL}/login`)

  return (
    <div className="session-screen">
      <div className="session-container">
        <div className="session-logo-wrapper">
          <img
            alt="logo"
            className="logo"
            src={`${process.env.PUBLIC_URL}/assets/images/logo/main-logo-dark.png`}
          />
        </div>
        {!isPlatformAdmin && isSignUp && userOrganizationName && (
          <span className="session-invited-description">
            {`You have been invited to join ${userOrganizationName}`}
          </span>
        )}
        <span className="session-title">{sessionTitle}</span>
        <form className="session-form">
          <div className="input-wrapper">
            <Input
              control={control}
              error={errors.email}
              errorMessage={errors.email?.message}
              label="Email"
              name="email"
              placeholder="Email"
              type="email"
              fullWidth
            />
          </div>

          <div className="input-wrapper">
            <Input
              control={control}
              error={errors.password}
              errorMessage={errors.password?.message}
              label="Password"
              name="password"
              placeholder="Password"
              type="password"
              fullWidth
            />
            {authError && (
              <span className="signIn-error-text">{authError}</span>
            )}
          </div>
          <div className="password-actions">
            {!isPlatformAdmin && !isSignUp && (
              <LinkButton
                color="secondary"
                component={Link}
                to={FORGOT_PASSWORD}
              >
                Forgot password?
              </LinkButton>
            )}
          </div>

          <Button
            className="button text-white fluid sign-in-btn"
            size="large"
            variant="contained"
            onClick={handleLogin}
          >
            {buttonText}
          </Button>
          {!isPlatformAdmin && (
            <>
              <span className="button-separator">or</span>

              <ButtonMemberNet
                className="button fluid sign-in-membernet-btn"
                icon={LanguageIcon}
                size="large"
                variant="contained"
                onClick={handleLoginWithMemberNet}
              >
                <span className="icon-wrapper"><LanguageIcon /></span>
                Sign in with MemberNet
              </ButtonMemberNet>
            </>
          ) }
        </form>
      </div>
    </div>
  )
}
