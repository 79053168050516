/* eslint-disable */
import React, { Component } from 'react'
import './styles.scss'
import { NavLink } from 'reactstrap';

const FallBack = ({resetState}) => {
  return(
    <div className="error-boundary-wrapper d-flex justify-content-center align-items-center">
      <img
        alt="lang-icon"
        className="mr-10"
        height="160"
        src={`${process.env.PUBLIC_URL}/assets/images/error/404.png`}
        width="250"
      />
      <div className="error-content d-flex align-items-center h-50 w-30">
        <h2 className="pr-20">404</h2>
        <h3 className="pr-20">PAGE NOT FOUND</h3>
        <div className="link-wrapper"><NavLink href="/admin/login" className="err-link" onClick={resetState}>Log in</NavLink> as a Platform Admin</div>
        <div className="link-wrapper"><NavLink href="/login" onClick={resetState} className="err-link">Log in </NavLink> Retailer Admin or Retailer</div>
      </div>
    </div>
  )
}

class ErrorBoundary extends Component {

  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' }
  };

  static getDerivedStateFromError = () => {
    return { hasError: true };
  };
  resetState = () => {
    this.setState({
      hasError: false,
      error: { message: '', stack: '' },
      info: { componentStack: '' }
    })
  }
  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <FallBack resetState={this.resetState}/> : children;
  }
}

export default ErrorBoundary
