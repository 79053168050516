export const developmentConfig = {
  apiKey: 'AIzaSyBFUpyfMc8IS4_RgH3fDCma-e2BwLGArHE',
  authDomain: 'nmg-messaging-platform-d-276d9.firebaseapp.com',
  databaseURL: 'https://nmg-messaging-platform-d-276d9-default-rtdb.firebaseio.com',
  projectId: 'nmg-messaging-platform-d-276d9',
  storageBucket: 'nmg-messaging-platform-d-276d9.appspot.com',
  messagingSenderId: '444070097580',
  appId: '1:444070097580:web:bb5f9a9ccdcb53d88c2509',
}

export const stagingConfig = {
  apiKey: 'AIzaSyB7ZrU94rMNAzB9QQ8r78wDIhEy_KrJjBs',
  authDomain: 'nmg-messaging-platform-staging.firebaseapp.com',
  databaseURL: 'https://nmg-messaging-platform-staging-default-rtdb.firebaseio.com',
  projectId: 'nmg-messaging-platform-staging',
  storageBucket: 'nmg-messaging-platform-staging.appspot.com',
  messagingSenderId: '345596959096',
  appId: '1:345596959096:web:d3c1031854bb8ab758508d',
}

export const productionConfig = {
  apiKey: 'AIzaSyDS6QwZey5ymKAKqY9t-wEGyoblcvr1Yqk',
  authDomain: 'nmg-messaging-platform.firebaseapp.com',
  databaseURL: 'https://nmg-messaging-platform-default-rtdb.firebaseio.com',
  projectId: 'nmg-messaging-platform',
  storageBucket: 'nmg-messaging-platform.appspot.com',
  messagingSenderId: '538951764237',
  appId: '1:538951764237:web:75306ad21e7029b5bb8844',
}

export const stagingStableConfig = {
  apiKey: 'AIzaSyAaj7SHFluxCHFOfJCjEBHc-88AMospV0w',
  authDomain: 'nmg-messaging-platform-stable.firebaseapp.com',
  databaseURL: 'https://nmg-messaging-platform-stable-default-rtdb.firebaseio.com',
  projectId: 'nmg-messaging-platform-stable',
  storageBucket: 'nmg-messaging-platform-stable.appspot.com',
  messagingSenderId: '346858293075',
  appId: '1:346858293075:web:3d63e0ee6247f2069bc3a6',
}
