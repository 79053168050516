import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const PaymentRequest = ({ amount }) => (
  <div className="payment-request-container d-flex justify-content-start align-items-center">
    <div className="payment-label">$</div>
    <div className="payment-amount">
      <span>
        $
        {amount}
      </span>
      <span>Payment request</span>
    </div>
  </div>
)

PaymentRequest.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default PaymentRequest
