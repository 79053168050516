import { makeStyles } from '@material-ui/core'

const BREAK_POINT_1700 = 1700

export const useStyles = makeStyles(theme => ({
  chartContainer: {
    border: '1px solid #c9c9c9',
    borderRadius: 5,
    padding: 10,
  },
  verticalChartWrapper: {
    width: '100%',
    height: 300,
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
      padding: 0,
    },
    [theme.breakpoints.up(BREAK_POINT_1700)]: {
      height: 350,
    },
    [theme.breakpoints.up('xl')]: {
      height: 520,
      padding: 25,
    },
  },
  chartTitle: {
    fontSize: 12,
    alignSelf: 'flex-start',
    fontWeight: 'bold',
    marginBottom: 10,
  },
}))
