import { useMutation, useLazyQuery } from '@apollo/client'
import { AUTHORIZE } from 'GraphQL/mutations/user'
import { Storage } from 'Services/store-service'
import { REFRESH_TOKEN } from 'Constants/common'
import { UNAUTHORIZED_CODE_STATUS } from 'Constants/codeStatuses'

const setToken = token => Storage.local.set('accessToken', token)

const setRefreshToken = refreshToken => Storage.local.set(REFRESH_TOKEN, refreshToken)
const getRefreshToken = () => Storage.local.get(REFRESH_TOKEN)

export const useApolloLazyQuery = (query, options = {}) => {
  const { onError, ...restOptions } = options

  const [authorize] = useMutation(AUTHORIZE, {
    onCompleted: (data) => {
      const { Authorize: { accessToken, refreshToken } } = data

      setToken(accessToken)
      setRefreshToken(refreshToken)
    },
  })

  const [name, { data, refetch }] = useLazyQuery(query, {
    onError: (errors) => {
      if (onError) onError(errors)

      const authError = errors.graphQLErrors?.find(
        err => err?.extension?.exception?.status === UNAUTHORIZED_CODE_STATUS,
      )

      if (authError) {
        authorize({ variables: { refreshToken: getRefreshToken() } }).then(() => refetch())
      }
    },
    ...restOptions,
  })

  return [name, { data, refetch }]
}
