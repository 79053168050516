import React from 'react'
import PropTypes from 'prop-types'
import { usePagination } from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

const useStyles = makeStyles({
  pagination: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    width: isMobile ? '100%' : 'auto',
    justifyContent: isMobile ? 'center' : 'normal',
  },
  paginationItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 14,
    height: 24,
    margin: '0 8px',
  },
  paginationItemChecked: {
    boxShadow: 'inset 0px -1px 0px #FF7A59',
    fontWeight: 'bold',
  },
  paginationButton: {
    background: 'transparent',
    border: 'none',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    cursor: 'pointer',
  },
  paginationButtonDisabled: {
    opacity: '0.4',
    cursor: 'default',
  },
})

const TablePagination = ({ count, onChange, page }) => {
  const classes = useStyles()
  const { items } = usePagination({
    count,
    onChange,
    page,
  })

  const renderButton = (item, title, arrowDirection) => (
    <button
      className={classNames([classes.paginationButton, item.disabled && classes.paginationButtonDisabled])}
      type="button"
      {...item}
    >
      {arrowDirection === 'left' && <i className={`zmdi zmdi-chevron-${arrowDirection}`} /> }
      {title}
      {arrowDirection === 'right' && <i className={`zmdi zmdi-chevron-${arrowDirection}`} /> }
    </button>
  )
  return (
    <nav className={isMobile ? 'w-100' : ''}>
      <ul className={classNames(classes.pagination, 'sm-center')}>
        {items.map(({
          // eslint-disable-next-line no-shadow
          page, type, selected, ...item
        }, index) => {
          let children = null

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…'
          } else if (type === 'page') {
            children = (
              <span
                className={classNames([classes.paginationItem, selected && classes.paginationItemChecked])}
                {...item}
              >
                {page}
              </span>
            )
          } else if (type === 'previous') {
            children = renderButton(item, 'prev', 'left')
          } else if (type === 'next') {
            children = renderButton(item, 'next', 'right')
          }

          // eslint-disable-next-line react/no-array-index-key
          return <li key={index}>{children}</li>
        })}
      </ul>
    </nav>
  )
}

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}

TablePagination.defaultProps = {
  onChange: () => {},
}

export default TablePagination
