import React, { useEffect, useState } from 'react'

import { isMobile } from 'react-device-detect'
import UserList from './UserList'
import { Inbox } from './Inbox/Inbox'

function ChatSidebar() {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setIsOpen(true)
    }
  }, [])

  return (
    <div className={!isOpen ? 'sidebar-opened' : ''}>
      <div className="chat-sidebar">
        <Inbox
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <UserList isOpen={isOpen} />
      </div>
    </div>
  )
}

export default ChatSidebar
