import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Input from 'Components/common/Input'
import Button from 'Components/common/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useApolloMutation } from 'GraphQL/apollo'
import { FORGOT_PASSWORD } from 'GraphQL/mutations/user'
import { toast } from 'react-toastify'

import { LinkButton } from 'Components/common/LinkButton'
import { LOGIN_PATH } from 'Constants/routerPaths'
import { Link } from 'react-router-dom'
import { validationSchema } from './validationSchema'

export const ForgotPassword = () => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const [forgotPassword] = useApolloMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      toast.success('If your email address exists in our database, you will receive '
        + 'a password recovery link at your email address in a few minutes.')
    },
    onError: (error) => {
      setError('email', {
        type: 'email',
        message: error.message,
      })
      console.error(error)
    },
  })

  const onSubmit = (data) => {
    const { email } = data

    forgotPassword({
      variables: {
        email,
      },
    })
  }

  const onError = (submitErrors, error) => console.error('email submitErrors', error)

  return (
    <div className="session-screen">
      <div className="session-container">
        <div className="forgot-password-back">
          <LinkButton
            component={Link}
            to={LOGIN_PATH}
          >
            <ArrowBackIosIcon className="arrow-spacing" />
            <span className="label">
              Back
            </span>
          </LinkButton>
        </div>
        <div>
          <div className="session-logo-wrapper">
            <img
              alt="logo"
              className="logo"
              src={`${process.env.PUBLIC_URL}/assets/images/logo/main-logo-dark.png`}
            />
          </div>

          <span className="session-title">
            Type your e-mail to recover forgotten password.
          </span>
          <form className="session-form">
            <div className="input-wrapper">
              <Input
                control={control}
                error={errors.email}
                errorMessage={errors.email?.message}
                label="Email"
                name="email"
                placeholder="Enter your registered email"
                type="email"
                fullWidth
              />
            </div>

            <Button
              className="button text-white fluid"
              color="primary"
              disabled={!isValid}
              size="large"
              variant="contained"
              onClick={handleSubmit(onSubmit, onError)}
            >
              Recover password
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
