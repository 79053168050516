export const peerConfig = {
  host: 'peer.showroomvideo.chat',
  secure: true,
  port: 443,
  path: '/conferences',
  config: {
    iceServers: [
      { urls: 'stun:stun1.l.google.com:19302' },
      {
        urls: 'turn:turn1.showroomvideo.chat:3478?transport=udp',
        username: 'platform',
        credential: 'stusanEfG3RDs1jacA',
      },
      {
        urls: 'turn:turn1.showroomvideo.chat:3478?transport=tcp',
        username: 'platform',
        credential: 'stusanEfG3RDs1jacA',
      },
      {
        urls: 'turn:turn1.showroomvideo.chat:443?transport=tcp',
        username: 'platform',
        credential: 'stusanEfG3RDs1jacA',
      },
    ],
  },
}
