import PropTypes from 'prop-types'

export const CreateUpdateStoreModalPropTypes = {
  storeId: PropTypes.string,
  edit: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  refetchStores: PropTypes.func,
  hasPermission: PropTypes.bool.isRequired,
}

export const CreateUpdateStoreModalDefaultProps = {
  storeId: null,
  edit: false,
  open: false,
  onClose: () => {},
  refetchStores: () => {},
}
