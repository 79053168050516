/* eslint-disable no-use-before-define */
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client'
import { authLink } from './Links/AuthLink'
import { httpLink } from './Links/HttpLink'
import { errorLink } from './Links/ErrorLink'

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
