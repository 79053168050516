import React from 'react'
import './styles.scss'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const MenuItem = (props) => {
  const {
    iconName,
    titleItem,
    description,
    link,
  } = props
  return (
    <NavLink
      activeClassName="active-link"
      className="item-link"
      to={link}
    >
      <div className="menu-item d-flex pt-10 pb-10 pl-20">
        <div>
          <i className={iconName} />
        </div>
        <div className="pl-3">
          <div className="item-title">{titleItem}</div>
          <div className="item-description">{description}</div>
        </div>
        <i className="ti-angle-right right-arrow" />
      </div>
    </NavLink>
  )
}

MenuItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  titleItem: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
}

MenuItem.defaultProps = {
  link: '',
}

export default MenuItem
