import { useStyles } from 'Pages/PaymentReport/StatisticsCharts/styles'
import PropTypes from 'prop-types'
import { VerticalChart } from './VerticalChart/VerticalChart'

export const StatisticsCharts = ({ analyticsData, dateRange }) => {
  const classes = useStyles()

  return (
    <div className={classes.chartsWrapper}>
      <VerticalChart
        dateRange={dateRange}
        paymentsPerDay={analyticsData.paymentsPerDay}
      />
    </div>
  )
}

StatisticsCharts.propTypes = {
  dateRange: PropTypes.shape({
    selectedDateFrom: PropTypes.string,
    selectedDateTo: PropTypes.string,
  }),
  analyticsData: PropTypes.shape({
    totalPayments: PropTypes.number.isRequired,
    totalPaymentsForPrevPeriod: PropTypes.number.isRequired,
    totalAchPayments: PropTypes.number.isRequired,
    totalAchPaymentsForPrevPeriod: 0,
    totalCcPayments: PropTypes.number.isRequired,
    totalCcPaymentsForPrevPeriod: PropTypes.number.isRequired,
    avgPaymentsPerOrg: PropTypes.number.isRequired,
    avgPaymentsPerOrgForPrevPeriod: PropTypes.number.isRequired,
    paymentsPerDay: PropTypes.shape({}),
  }),
}

StatisticsCharts.defaultProps = {
  dateRange: null,
  analyticsData: {
    totalPayments: 0,
    totalPaymentsForPrevPeriod: 0,
    totalAchPayments: 0,
    totalAchPaymentsForPrevPeriod: 0,
    totalCcPayments: 0,
    totalCcPaymentsForPrevPeriod: 0,
    avgPaymentsPerOrg: 0,
    avgPaymentsPerOrgForPrevPeriod: 0,
    paymentsPerDay: {},
  },
}
