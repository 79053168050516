import { CONVERSATION_STATUSES } from 'Constants/conversation'
import { filerTypes } from '../../Pages/messenger/chat/components/Inbox/config'

export const filterUserChat = ({ type, recentChatUsers, currentUser }) => {
  switch (type) {
    case filerTypes.YOUR:
      return recentChatUsers.filter(chat => chat.currentAgent === currentUser.id && chat.status
        !== CONVERSATION_STATUSES.RESOLVED)
    case filerTypes.UNASSIGNED:
      return recentChatUsers.filter(chat => !chat.currentAgent)
    case filerTypes.OPEN:
      return recentChatUsers.filter(chat => chat.status === CONVERSATION_STATUSES.UNASSIGNED || chat.status
      === CONVERSATION_STATUSES.ASSIGNED)
    case filerTypes.RESOLVED:
      return recentChatUsers.filter(chat => chat.status === CONVERSATION_STATUSES.RESOLVED
        || chat.status === CONVERSATION_STATUSES.CLOSED)
    default:
      return recentChatUsers
  }
}

export const identifyBrowser = (userAgent) => {
  let matchedVersion
  let matchedBrowserName = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(matchedBrowserName[1])) {
    matchedVersion = /\brv[ :]+(\d+)/g.exec(userAgent) || []
    return `IE ${matchedVersion[1] || ''}`
  }
  if (matchedBrowserName[1] === 'Chrome') {
    matchedVersion = userAgent.match(/\b(OPR|Edge)\/(\d+)/)
    if (matchedVersion != null) return matchedVersion.slice(1).join(' ').replace('OPR', 'Opera')
  }
  matchedBrowserName = matchedBrowserName[2] ? [matchedBrowserName[1], matchedBrowserName[2]] : [navigator.appName, navigator.appVersion, '-?']
  matchedVersion = userAgent.match(/version\/(\d+)/i)

  if (matchedVersion != null) {
    matchedBrowserName.splice(1, 1, matchedVersion[1])
  }
  return matchedBrowserName.join(' ')
}
