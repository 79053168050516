import { permissions } from 'config/permissions'
import { useAuthContext } from 'Providers/AuthProvider'

export const useCheckPermission = () => {
  const { currentUser } = useAuthContext()

  return (action) => {
    let isUserHasPermission = false
    currentUser.roles.forEach((role) => {
      if (permissions[action].includes(role)) {
        isUserHasPermission = true
      }
    })
    return isUserHasPermission
  }
}
