import {
  Save,
  Add,
  PersonOutline,
  VpnKeyOutlined,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import { useForm } from 'react-hook-form'
import Input from 'Components/common/Input'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchemaMerchant } from '../validationSchema'

export const CreateMerchantAccountModal = ({
  selectedIndex,
  open,
  onClose,
  defaultValues,
  appendAccount,
  updateAccount,
  ...modalProps
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchemaMerchant),
  })
  const [showPassword, setShowPassword] = useState(false)
  const isEdit = selectedIndex !== null

  const onSubmit = (data) => {
    if (isValid) {
      if (isEdit) {
        updateAccount(selectedIndex, data)
      } else {
        appendAccount({ ...data, active: true })
      }

      onClose()
    }
  }

  const onError = (submitErrors, e) =>
    console.error('Merchant account submitErrors', { submitErrors }, e)

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  return (
    <ModalBase
      {...modalProps}
      acceptIcon={isEdit ? <Save /> : <Add />}
      acceptTitle={isEdit ? 'Save' : 'Add'}
      open={open}
      title={isEdit ? 'Edit' : 'Add'}
      titleCancel="Cancel"
      onAccept={handleSubmit(onSubmit, onError)}
      onCancel={onClose}
      onClose={onClose}
    >
      <form>
        <Input
          control={control}
          error={errors.name}
          errorMessage={errors.name?.message}
          label="Merchant Account Name"
          labelIcon={<PersonOutline />}
          name="name"
          placeholder="Account Name"
          fullWidth
        />
        <Input
          control={control}
          error={errors.username}
          errorMessage={errors.username?.message}
          label="Paytrace Username"
          labelIcon={<PersonOutline />}
          name="username"
          placeholder="User Name"
          fullWidth
        />
        <Input
          control={control}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(v => !v)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
          error={errors.password}
          errorMessage={errors.password?.message}
          label="Paytrace Password"
          labelIcon={<VpnKeyOutlined />}
          name="password"
          placeholder="Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
        />
        <Input
          control={control}
          error={errors.integratorId}
          errorMessage={errors.integratorId?.message}
          label="Integrator ID"
          labelIcon={<VpnKeyOutlined />}
          name="integratorId"
          placeholder="ID"
          fullWidth
        />
      </form>
    </ModalBase>
  )
}

CreateMerchantAccountModal.propTypes = {
  selectedIndex: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  appendAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
}

CreateMerchantAccountModal.defaultProps = {
  selectedIndex: null,
  open: false,
  defaultValues: {},
  onClose: () => {},
}
