import {
  object, array, string, number, boolean,
} from 'yup'

import { PAYMENT_METHODS } from 'Constants/payment'

/* eslint max-len: ["error", 2000] */
const urlValidationRegEx = /^$|^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-_]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const validationSchema = object().shape({
  items: array().of(
    object().shape({
      url: string().matches(urlValidationRegEx, 'URL is not valid.'),
      name: string().required('Product name cannot be empty.'),
      quantity: number().required('Quantity cannot be empty.')
        .typeError('Quantity must be a numeric value.')
        .positive('Quantity must be a positive number.')
        .integer('Quantity must be an integer value.'),
      price: number().required('Rate cannot be empty.')
        .typeError('Rate must be a numeric value.')
        .positive('Rate must be a positive number'),
    }),
  ).min(1, 'There should be at least one line item.'),
  receivingMerchant: string().required('Merchant account cannot be empty.'),
  paymentMethods: array().of(string().oneOf(Object.values(PAYMENT_METHODS)))
    .min(1, 'There should be at least one payment method selected.'),
  timeUnit: string().required('Expiration type cannot be empty.'),
  expiresIn: number().required('Expiration cannot be empty.')
    .typeError('Expiration must be a numeric value.')
    .integer(),
  invoiceNumber: string(),
  receiptCheck: boolean(),
  receiptEmail: string()
    .when('receiptCheck', {
      is: true, // alternatively: (val) => val == true
      then: schema => schema.email('Receipt email must be valid.').required('Receipt email cannot be empty.'),
      otherwise: schema => schema.nullable(),
    }),
})
