import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { AnalyticsTooltip as Tooltip } from '../AnalyticsTooltip/AnalyticsTooltip'

import { useStyles } from './styles'
import { statisticsTooltipValues } from './config'

export const StatisticsItem = ({ item, onRenderValue }) => {
  const classes = useStyles()
  const { name, value, dynamic } = item

  return (
    <Tooltip title={statisticsTooltipValues[item.key]}>
      <div className={classes.statisticsItem}>
        <div className={classes.top}>
          <Typography className={classes.name}>{name}</Typography>
        </div>
        <div className={classes.bottom}>
          <Typography
            className={classes.value}
          >
            {onRenderValue ? onRenderValue(value) : value}
          </Typography>
          <Typography className={classes.dynamic}>{dynamic}</Typography>
        </div>
      </div>
    </Tooltip>
  )
}

StatisticsItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    dynamic: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  onRenderValue: PropTypes.func.isRequired,
}
