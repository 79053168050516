import { gql } from '@apollo/client'

export const UPDATE_QUESTION = gql`
mutation UpdateFeedback(
  $id: ID!, 
  $active: Boolean,
  $type: FeedbackTypes,
  $title: String,
  $description: String,
  $min: Int,
  $max: Int,
  $options: [FeedbackOptionInput!],
  $reviewDestinations: [String!],
  $conversation: String
){
  UpdateFeedback(
    id: $id, 
    active: $active,
    type: $type,
    title: $title,
    description: $description,
    min: $min,
    max: $max,
    options: $options,
    reviewDestinations: $reviewDestinations 
    conversation: $conversation 
  ){
    id,
    active,
    type,
    title,
    description
  }
}
`

export const DELETE_QUESTION = gql`
mutation DeleteFeedback($id: ID!){
  DeleteFeedback(id: $id){
    id,    
  }
}
`

export const CREATE_QUESTION = gql`
mutation CreateFeedback(
  $store: String!,
  $type: FeedbackTypes!,
  $active: Boolean!,
  $relation: RelationTypes!,
  $title: String!,
  $description: String!,
  $min: Int!,
  $max: Int!,
  $options: [FeedbackOptionInput!],
  $reviewDestinations: [String!],
  $conversation: String
){
  CreateFeedback(
      store: $store, 
      type: $type,
      active: $active,
      relation: $relation,
      title: $title,
      description: $description,
      min: $min,
      max: $max,
      options: $options
      reviewDestinations: $reviewDestinations 
      conversation: $conversation 
  ){
    id,
  }
}
`

export const CREATE_CHAT_MESSAGE = gql`
mutation CreateFeedbackMessage($store: String!, $relation: RelationTypes!, $body: String!){
  CreateFeedbackMessage(store: $store, relation: $relation, body: $body){
    store,
    relation,
    body
  }
}
`

export const UPDATE_CHAT_MESSAGE = gql`
mutation UpdateFeedbackMessage($id: ID!, $body: String) {
  UpdateFeedbackMessage(id: $id, body: $body) {
    id
    store
    relation
    body
  }
}
`
