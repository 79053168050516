import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import './styles.scss'

export const StoreSelect = ({ storeId, changeStore, stores }) => {
  const value = storeId ?? ''

  return (
    <FormControl
      className="store-select"
      variant="outlined"
    >
      <Select
        className="question-select-header"
        value={value}
        displayEmpty
        onChange={changeStore}
      >
        <MenuItem
          value=""
          disabled
        >
          <em>-</em>
        </MenuItem>
        {stores?.map(store => (
          <MenuItem
            key={store.id}
            value={store.id}
          >
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

StoreSelect.propTypes = {
  storeId: PropTypes.string,
  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  changeStore: PropTypes.func.isRequired,
}

StoreSelect.defaultProps = {
  storeId: '',
}
