import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Input from 'Components/common/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { validationSchema } from './validationSchema'
import ButtonWithIcon from '../ButtonWithIcon'

export const CardTitleAndDescription = ({
  questionTitle,
  setQuestionTitle,
  questionDescription,
  setQuestionDescription,
  onDeleteQuestion,
  showTitle,
  showDescription,
  id,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const openModal = (e) => {
    setAnchorEl(e.currentTarget)
    setIsConfirmModal(true)
  }
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  })

  const editToggle = () => {
    const { title, description } = getValues()
    if (isEditing && !errors.title && !errors.description) {
      if (title) {
        setQuestionTitle(title)
      }
      if (description) {
        setQuestionDescription(description)
      }
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  const closeModal = () => setIsConfirmModal(false)

  return (
    <>
      {isEditing && (
        <form>
          {showTitle && (
            <Input
              className="p-1"
              control={control}
              defaultValue={questionTitle}
              error={errors.title}
              errorMessage={errors.title?.message}
              name="title"
              wrapperClassName="m-0"
              fullWidth
            />
          )}
          {showDescription && (
            <Input
              className="p-1"
              control={control}
              defaultValue={questionDescription}
              error={errors.description}
              errorMessage={errors.description?.message}
              name="description"
              wrapperClassName="m-0"
              fullWidth
            />
          )}
        </form>
      )}
      {!isEditing && (
        <>
          <span className="title">{questionTitle}</span>
          <p className="description">{questionDescription}</p>
        </>
      )}
      <div className="action-btn-wrapper">
        <ButtonWithIcon
          aria-label="Edit"
          className={classNames('edit-button', isEditing && 'active')}
          component="span"
          onClick={editToggle}
        >
          <span className="ti-pencil" />
        </ButtonWithIcon>
        <PositionedConfirmModal
          acceptTitle="Delete"
          anchorEl={anchorEl}
          open={isConfirmModal}
          placement="top"
          title="Are you sure you want to delete this question?"
          onAccept={onDeleteQuestion}
          onCancel={closeModal}
          onClose={closeModal}
        />
        <ButtonWithIcon
          aria-label="Remove"
          component="span"
          onClick={openModal}
        >
          <span
            className="ti-trash"
            data-card={id}
          />
        </ButtonWithIcon>
      </div>
    </>
  )
}

CardTitleAndDescription.propTypes = {
  questionTitle: PropTypes.string.isRequired,
  setQuestionTitle: PropTypes.func.isRequired,
  questionDescription: PropTypes.string.isRequired,
  setQuestionDescription: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  showDescription: PropTypes.bool,
  id: PropTypes.string.isRequired,
}

CardTitleAndDescription.defaultProps = {
  showTitle: true,
  showDescription: true,
}
