import React, {
  useEffect,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import {
  FormGroup,
  Label,
} from 'reactstrap'

import { useAuthContext } from 'Providers/AuthProvider'

import { AddQuestionModal } from 'Components/AddQuestionModal/AddQuestionModal'
import { QuestionsPageHeader } from 'Components/QuestionsPageHeader'
import { FeedbackMessage } from 'Components/FeedbackMessage'

import { useApolloQuery, useApolloLazyQuery } from 'GraphQL/apollo'
import { GET_CHAT_QUESTIONS } from 'GraphQL/queries/questions'
import { GET_STORES } from 'GraphQL/queries/store'
import { Card } from '../components/Card'

function PostChatQuestion() {
  const [activePostChatQuestions, setActivePostChatQuestions] = useState([])
  const [inactivePostChatQuestions, setInactivePostChatQuestions] = useState([])
  const [isAddQuestionModalOpened, setIsAddQuestionModalOpened] = useState(false)
  const [storeId, setStoreId] = useState(null)
  const { currentUser } = useAuthContext()

  const filterQuestions = (questions) => {
    const activeQuestions = questions.filter(question => question.active)
    setActivePostChatQuestions(activeQuestions)
    const inactiveQuestions = questions.filter(question => !question.active)
    setInactivePostChatQuestions(inactiveQuestions)
  }
  const setData = (data) => {
    filterQuestions(data)
  }
  const [getFeedbacks, { data: questionItems, refetch: refetchQuestions }] = useApolloLazyQuery(GET_CHAT_QUESTIONS, {
    variables: { store: storeId, relation: 'postChat' },
  })

  const organizationId = currentUser.accessList[currentUser.accessList.length - 1].organization

  const { data: stores } = useApolloQuery(GET_STORES, {
    variables: {
      organization: organizationId,
    },
  })

  useEffect(() => {
    if (stores) {
      const { Stores: { items: stores$ } } = stores

      setStoreId(stores$[0]?.id)
    }
  }, [stores])

  useEffect(() => {
    if (storeId) {
      getFeedbacks()
    }
  }, [storeId])

  useEffect(() => {
    const result = questionItems?.Feedbacks?.items
    if (result) {
      setData(result)
    }
  }, [questionItems?.Feedbacks?.items])

  const changeStore = event => setStoreId(event.target.value)

  const activeQuestionsLength = activePostChatQuestions.length
  const inactiveQuestionsLength = inactivePostChatQuestions.length

  return (
    <div className="auto-responder-page">
      <AddQuestionModal
        activeQuestionsLength={activeQuestionsLength}
        inactiveQuestionsLength={inactiveQuestionsLength}
        isOpen={isAddQuestionModalOpened}
        refetchQuestions={refetchQuestions}
        storeId={storeId}
        type="postChat"
        onCancel={() => setIsAddQuestionModalOpened(false)}
      />
      <QuestionsPageHeader
        changeStore={changeStore}
        openQuestionModal={setIsAddQuestionModalOpened}
        storeId={storeId}
        stores={stores?.Stores.items}
        title="Post-chat questions"
      />
      <div className={isMobile ? 'settings-page-rows pt-10 w-100' : 'settings-page-rows pt-10 w-80'}>
        {/* label ACTIVE PRE-CHAT MESSAGE & QUESTIONS */}
        <section className="page-row d-flex">
          <div className={isMobile ? '"w-100 d-flex flex-column' : '"w-40 d-flex flex-column'}>
            <span className="settings-label">
              Active post-chat message & questions
            </span>
            <span className="settings-label-description">
              Add up to 3 active questions to ask after your conversation with a user is over.
            </span>
            <span className="settings-label-description" />
          </div>
          <div className={isMobile ? 'd-flex flex-column w-100 mt-20' : 'd-flex flex-column w-60 ml-20'}>
            <FeedbackMessage
              configureMessageTitle="Post-chat message"
              relation="postChat"
              storeId={storeId}
            />
            <FormGroup className="mb-15">
              <Label className="d-flex align-items-center">
                <span className="ti-help-alt" />
                <span className="profile-label pl-10">
                  Active post-chat questions
                  (
                  {activePostChatQuestions.length}
                  /3)
                </span>
              </Label>

              <div>
                {activePostChatQuestions.map(card => (
                  <Card
                    key={card.id}
                    activeQuestionsLength={activeQuestionsLength}
                    id={card.id}
                    inactiveQuestionsLength={inactivePostChatQuestions}
                    isActive={card.active}
                    refetchQuestions={refetchQuestions}
                    relation="postChat"
                    storeId={storeId}
                    drag
                  />
                ))}
              </div>
            </FormGroup>
          </div>
        </section>

        {/* label INACTIVE PRE-CHAT MESSAGE & QUESTIONS */}
        <section className="page-row d-flex">
          <div className={isMobile ? 'w-100 d-flex flex-column' : 'd-flex flex-column'}>
            <span className="settings-label">
              Inactive post-chat message & questions
            </span>
            <span className="settings-label-description">
              Add up to 3 active questions to ask after your conversation with a user is over.
            </span>
          </div>
          <div className={isMobile ? 'd-flex flex-column w-100 mt-20' : 'd-flex flex-column w-60 ml-20'}>
            <FormGroup className="mb-15">
              <Label className="d-flex align-items-center">
                <span className="ti-help-alt" />
                <span className="profile-label pl-10">
                  Inactive post-chat questions
                  (
                  {inactivePostChatQuestions.length}
                  )
                </span>
              </Label>
              <div className="inactive-questions-container">
                {inactivePostChatQuestions.map(card => (
                  <Card
                    key={card.id}
                    activeQuestionsLength={activeQuestionsLength}
                    id={card.id}
                    inactiveQuestionsLength={inactiveQuestionsLength}
                    isActive={card.active}
                    refetchQuestions={refetchQuestions}
                    relation="postChat"
                    storeId={storeId}
                    drag
                  />
                ))}
              </div>
            </FormGroup>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PostChatQuestion
