import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'

import { TableCell, TableRow } from 'Pages/settings/components/TableWithStyles'

const renderAvatar = avatar => (
  <div className="agent-avatar-block-wrapper">
    <div className="agent-avatar-block">
      <Avatar
        className="agent-avatar"
        src={avatar}
      >
        <span className="zmdi zmdi-account-o" />
      </Avatar>
    </div>
  </div>
)

export const PaymentsTableRow = ({ historyData }) => {
  const {
    amount,
    createdAt,
    paymentMethod,
    retailer,
  } = historyData

  return (
    <TableRow>
      <TableCell>
        <span className="page-table-cell">
          {moment(createdAt, 'YYYY-MM-DD').format('MM/DD/YYYY')}
        </span>
      </TableCell>
      <TableCell>
        <span className="page-table-cell">
          $
          {amount}
        </span>
      </TableCell>
      <TableCell><span className="page-table-cell">{paymentMethod === 'credit_card' ? 'Credit Card' : 'ACH'}</span></TableCell>
      <TableCell className="agents-table-cell">
        <div className="media">
          {renderAvatar(retailer.logo)}
          <div className="media-body pt-5">
            <h5 className="mb-5 fw-bold">{retailer.name}</h5>
            <span>{retailer.site}</span>
          </div>
        </div>
      </TableCell>
      <TableCell><span className="page-table-cell">{retailer.address}</span></TableCell>
    </TableRow>
  )
}

PaymentsTableRow.propTypes = {
  historyData: PropTypes.shape({
    amount: PropTypes.number,
    createdAt: PropTypes.string,
    paymentMethod: PropTypes.string,
    retailer: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      stie: PropTypes.string,
    })).isRequired,
  }).isRequired,
}
