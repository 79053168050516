import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { FormGroup, Input, Label } from 'reactstrap'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import './styles.scss'

const DelayBox = ({
  label,
  inputChangeHandler,
  value,
  option,
  optionChangeHandler,
}) => (
  <>
    <Label>
      <span className="ti-alarm-clock" />
      <span className="profile-label pl-10">{label}</span>
    </Label>
    <div className="d-flex">
      <FormGroup className="mb-15 w-30">
        <Input
          type="number"
          valid={false}
          value={value}
          onChange={event => inputChangeHandler(event)}
        />
      </FormGroup>
      <FormGroup className={isMobile ? 'mb-15 w-70 ml-10' : 'mb-15 w-50 ml-10'}>
        <FormControl
          className="select-time-type"
          variant="outlined"
        >
          <Select
            className="time-select"
            value={option}
            fullWidth
            onChange={event => optionChangeHandler(event)}
          >
            <MenuItem value="seconds">Seconds</MenuItem>
            <MenuItem value="minutes">Minutes</MenuItem>
          </Select>
        </FormControl>
      </FormGroup>
    </div>
  </>
)

DelayBox.propTypes = {
  label: PropTypes.string,
  inputChangeHandler: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  option: PropTypes.string.isRequired,
  optionChangeHandler: PropTypes.func.isRequired,
}

DelayBox.defaultProps = {
  label: '',
}

export default DelayBox
