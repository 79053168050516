import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import './styles.scss'
import {
  DeleteForeverOutlined,
  EditOutlined,
  PowerSettingsNewOutlined,
  VisibilityOutlined,
} from '@material-ui/icons'
import { isMobile } from 'react-device-detect'
import SyncIcon from '@material-ui/icons/Sync'
import { useReadMode } from 'Hooks/useReadMode'

const BUTTONSTEXT = {
  delete: 'Delete',
  activate: 'Reactivate',
  deactivate: 'Deactivate',
}
export const TableActions = ({
  onEdit,
  onDelete,
  onReactive,
  row: { id, name, active },
  hasPermission,
  deactivate,
  reInviteUser,
  isRetailerAdmin,
  isPlatformAdmin,
  isPasswordExpired,
}) => {
  const { isReadOnly } = useReadMode()

  const getActions = () => {
    if (!deactivate) return 'delete'
    if (!active) {
      return 'activate'
    }
    return 'deactivate'
  }

  const handleClick = (e) => {
    if (getActions() === 'activate') {
      onReactive(id)
    } else {
      onDelete(id, name, 'bottom-end')(e)
    }
  }
  return (
    <div className="action-btn-group">
      {(isRetailerAdmin || isPlatformAdmin) && isPasswordExpired && isMobile && (
        <Button
          className="action-btn"
          disabled={isReadOnly}
          onClick={reInviteUser}
        >
          <SyncIcon />
        </Button>
      )}
      <Button
        className="action-btn"
        disabled={isReadOnly || !active}
        onClick={() => {
          if (isReadOnly || !active) { return }
          onEdit(id)
        }}
      >
        {hasPermission ? <EditOutlined /> : <VisibilityOutlined />}
        {' '}
        {!isMobile && <span>{hasPermission ? 'Edit' : 'View'}</span>}
      </Button>
      {(hasPermission || !isMobile) && (
        <Button
          className="action-btn"
          disabled={isReadOnly || !hasPermission}
          onClick={handleClick}
        >
          {deactivate ? <PowerSettingsNewOutlined /> : <DeleteForeverOutlined />}
          {!isMobile && <span>{BUTTONSTEXT[getActions()]}</span>}
        </Button>
      )}
    </div>
  )
}

TableActions.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onReactive: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    active: PropTypes.bool,
  }),
  hasPermission: PropTypes.bool,
  deactivate: PropTypes.bool,
  reInviteUser: PropTypes.func,
  isRetailerAdmin: PropTypes.bool,
  isPasswordExpired: PropTypes.bool,
  isPlatformAdmin: PropTypes.bool,
}

TableActions.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  onReactive: () => {},
  row: {},
  hasPermission: true,
  deactivate: false,
  reInviteUser: () => {},
  isRetailerAdmin: false,
  isPasswordExpired: false,
  isPlatformAdmin: false,
}
