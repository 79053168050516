import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { updateSelectedUser } from 'Store/Actions'
import { CLIENT_INFORMATION } from 'GraphQL/queries/userInfo'
import { useFirebaseContext } from 'Providers/FirebaseProvider'
import { useLazyQuery } from '@apollo/client'

export const useChatArea = () => {
  const dispatch = useDispatch()
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser } = chatAppReducer

  const [conversation, setConversation] = useState({})
  const [conversationClosed, setConversationClosed] = useState(false)
  const [needUpdateSurvey, setNeedUpdateSurvey] = useState(false)
  const { db, eventTypes } = useFirebaseContext()
  const [getClientInfo, { data: clientData }] = useLazyQuery(CLIENT_INFORMATION)
  const conversationPath = selectedUser?.conversationPath || ''

  if (selectedUser) {
    db.ref(`${conversationPath}/${selectedUser?.storeId}/conversations/${selectedUser.conversationId}`).on(eventTypes.CHILD_CHANGED, (snapMessage) => {
      if (snapMessage.val() === 'closed') {
        setConversationClosed(true)
      }
    })
  }

  useEffect(() => {
    if (conversationClosed) {
      setNeedUpdateSurvey(true)
    }
  }, [conversationClosed])

  useEffect(() => {
    const clientInfo = clientData?.ClientInformation
    if (clientInfo) {
      dispatch(updateSelectedUser({
        email: clientInfo.email,
        phone: clientInfo.phone,
      }))
    }
  }, [clientData])

  useEffect(() => {
    setConversationClosed(false)
    if (selectedUser) {
      getClientInfo({
        variables: {
          client: selectedUser.id,
        },
      })
    }
  }, [selectedUser?.id])

  useEffect(() => {
    if (selectedUser) {
      const rootRef = db.ref()
      const urlRef = rootRef.child(`${conversationPath}/${selectedUser.storeId}/conversations/${selectedUser.conversationId}`)
      urlRef.on('value', (snapshot) => {
        setConversation(snapshot.val())
      })
    }
  }, [selectedUser])

  return {
    conversation,
    needUpdateSurvey,
    setNeedUpdateSurvey,
  }
}
