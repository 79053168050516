import { gql } from '@apollo/client'

export const GET_AUTO_RESPONSES = gql`
query AutoResponses($storeId: String!) {
  AutoResponses(store: $storeId) {
    id,
    type
  }
}
`

export const GET_AUTO_RESPOND = gql`
query AutoRespond($id: ID!) {
  AutoRespond(id: $id) {
    active
    body
    delay {
      value
      option
    }
  }
}
`
