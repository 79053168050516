import { CHANGE_HEADER } from '../Actions/types'

const INITIAL_STATE = {
  chatName: '',
  messengerStep: 0,
}

const chatHeader = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_HEADER:
      return {
        ...state,
        chatName: action.payload?.chatName || state.chatName,
        messengerStep: action.payload?.messengerStep,
      }

    default: return { ...state }
  }
}

export default chatHeader
