import React, { useRef, useState } from 'react'
import { Button, Input } from 'reactstrap'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import copyToClipboard from 'Services/copy-to-clipboard-service'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

export const WebsiteContent = ({ code }) => {
  const inputWidgetCodeRef = useRef(null)
  const [isCodeCopied, setIsCodeCopied] = useState(false)
  const buttonText = isCodeCopied ? 'Copied' : 'Copy'

  const copyWidgetCode = async () => {
    const copyValue = inputWidgetCodeRef.current?.value
    if (copyValue) {
      await copyToClipboard(copyValue)
      toast.success('Widget code is copied')
      setIsCodeCopied(true)
    }
  }
  return (
    <section className="page-row d-flex">
      <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-30 d-flex flex-column'}>
        <span className="settings-label">Website</span>
        <span className="settings-label-description">
          Add this code snippet inside the  &#60;body&#62; tag of each page where you would like to enable chat
        </span>
      </div>
      <div className={isMobile ? 'd-flex flex-column align-items-end w-100' : 'd-flex flex-column align-items-end w-40'}>
        <Input
          className="widget-code-textarea"
          defaultValue={code}
          innerRef={inputWidgetCodeRef}
          type="textarea"
          disabled
        />
        <Button
          className={classNames('mt-10 mb-10', { 'color-green': isCodeCopied })}
          color="secondary"
          outline
          onClick={copyWidgetCode}
        >
          <i className="zmdi zmdi-copy" />
          {' '}
          {buttonText}
        </Button>
      </div>
    </section>
  )
}

WebsiteContent.propTypes = {
  code: PropTypes.string.isRequired,
}
