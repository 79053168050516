import React, { useEffect, useState } from 'react'
import { CollapsedBlock } from 'Pages/messenger/UserInfo/CollapsedBlock/CollapsedBlock'
import './styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import { useLazyQuery } from '@apollo/client'
import { CLIENT_INFORMATION } from 'GraphQL/queries/userInfo'
import { identifyBrowser } from 'Providers/conversation/helpers'
import { updateSelectedUser } from 'Store/Actions'
import { useConversation } from 'Hooks/useConversation'
import { AddDataModal } from '../AddDataModal'

export const UserClientInfo = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('email')
  const [clientInformation, setClientInformation] = useState({})
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser } = chatAppReducer
  const [getClientInfo, { data: clientData }] = useLazyQuery(CLIENT_INFORMATION, {
    variables: {
      client: selectedUser.id,
    },
  })
  const conversation = useConversation()

  useEffect(() => {
    getClientInfo()
  }, [])

  useEffect(() => {
    const clientInfo = clientData?.ClientInformation
    setClientInformation(clientInfo)
    if (clientInfo) {
      dispatch(updateSelectedUser({
        email: clientInfo.email,
        phone: clientInfo.phone,
      }))
    }
  }, [clientData])

  const email = clientInformation?.email
  const phone = clientInformation?.phone
  const address = clientInformation?.address
  const time = clientInformation?.time
  const storeUrl = clientInformation?.site
  const systemInfo = clientInformation?.userAgent
    && identifyBrowser(clientInformation?.userAgent)
  const ip = clientInformation?.userIp

  const handleSetIsOpen = () => {
    setIsOpen(state => !state)
  }

  const handleOpenModal = (type) => {
    setModalType(type)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    getClientInfo()
    setIsModalOpen(false)
  }
  return (
    <div className="user-client-info-wrapper">
      <ModalBase
        modalBodyOutlined={false}
        open={isModalOpen}
        onClose={closeModal}
      >
        {modalType === 'email' ? (
          <AddDataModal
            type="email"
            onClose={closeModal}
          />
        ) : (
          <AddDataModal
            type="phone"
            onClose={closeModal}
          />
        )}
      </ModalBase>
      <CollapsedBlock
        isOpen={isOpen}
        title="Client information"
        toggle={handleSetIsOpen}
      >
        <div className="user-client-info">
          <div className="user-info-row editable">
            <i className="zmdi zmdi-email icon" />
            {/* //TODO until we add method to init the data on widget */}
            <span className="user-info-text">
              {conversation?.customer?.email || email}
            </span>
            <i
              className="zmdi zmdi-edit icon"
              onClick={() => handleOpenModal('email')}
            />
          </div>
          <div className="user-info-row editable">
            <i className="zmdi zmdi-phone icon" />
            <span className="user-info-text">
              {conversation?.customer?.phone || phone}
            </span>
            <i
              className="zmdi zmdi-edit icon"
              onClick={() => handleOpenModal('phone')}
            />
          </div>
          <div className="user-info-row">
            <i className="zmdi zmdi-pin icon" />
            <span className="user-info-text">{address}</span>
          </div>
          <div className="user-info-row">
            <i className="zmdi zmdi-time icon" />
            <span className="user-info-text">{time}</span>
          </div>
          <div className="user-info-row">
            <i className="zmdi zmdi-globe-alt icon" />
            <a
              className="user-info-text"
              href={storeUrl}
            >
              {storeUrl}
            </a>
          </div>
          <div className="user-info-row">
            <i className="zmdi zmdi-desktop-windows icon" />
            <span className="user-info-text">{systemInfo}</span>
          </div>
          <div className="user-info-row">
            <i className="zmdi zmdi-cloud icon" />
            <span className="user-info-text">{ip}</span>
          </div>
        </div>
      </CollapsedBlock>
    </div>
  )
}
