// eslint-disable-next-line max-len
export const phoneRegExp = /^\+[1-9][0-9]*$/
export const phoneRegExpWithoutCode = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.)/

export const DEBOUNCED_SEARCH_DELAY = 800

export const ALLOWED_FILE_TYPES = ['png', 'jpg', 'jpeg', 'pdf', 'heic']

export const REVIEW_ANSWERS = ['bad', 'poor', 'average', 'great', 'excellent']

export const SCROLL_TIMEOUT = 100

export const ENVIRONMENTS = {
  STAGING: 'staging',
  STAGING_STABLE: 'staging-stable',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
}

export const AGENT_STATUSES = {
  BUSY: 'busy',
  OFFLINE: 'offline',
  AVAILABLE: 'available',
}

export const STORE_CONV_ASSIGN_TYPES = {
  LESS_BUSY: 'LESS_BUSY',
  FIRST_TO_RESPOND: 'FIRST_TO_RESPOND',
}

export const STORE_CONV_ASSIGN_OPTIONS = [
  {
    label: 'Round Robin',
    value: STORE_CONV_ASSIGN_TYPES.LESS_BUSY,
  },
  {
    label: 'First to respond',
    value: STORE_CONV_ASSIGN_TYPES.FIRST_TO_RESPOND,
  },

]

export const FeedbackTypes = {
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  REVIEW: 'review',
}

export const RelationTypes = {
  PRE_CHAT: 'preChat',
  POST_CHAT: 'postChat',
  IN_CHAT: 'inChat',
  WORKFLOW_ACTION: 'workflowAction',
}

export const REFRESH_TOKEN = 'refreshToken'
export const USER = 'currentUser'

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
