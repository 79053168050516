import { useEffect } from 'react'

import { GET_ORGANIZATIONS_QUERY } from 'GraphQL/queries/organization'
import { useApolloLazyQuery } from 'GraphQL/apollo'

export const useOrganizations = (initPagination) => {
  const [loadOrganizationsQuery,
    { data: dataOrganizationsQuery, refetch: refetchOrganizationsQuery }] = useApolloLazyQuery(
    GET_ORGANIZATIONS_QUERY, {
      variables: {
        ...initPagination,
      },
    },
  )

  const organizationsPagination = dataOrganizationsQuery?.Organizations.pagination
  const organizationsData = dataOrganizationsQuery?.Organizations.items ?? []

  useEffect(() => {
    loadOrganizationsQuery()
  }, [])

  return {
    organizationsPagination,
    refetchOrganizationsQuery,
    organizationsData,
    loadOrganizationsQuery,
  }
}
