import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { v4 as uuid } from 'uuid'
import { CHOICES_LIMIT } from 'Constants/preChatQuestions'
import { AddQuestionModalChoice } from './AddQuestionModalChoice'
import './styles.scss'

export const ModalChoices = ({
  questionType,
  choices,
  setChoices,
}) => {
  const addChoice = () => {
    if (choices.length >= CHOICES_LIMIT) return
    const newChoice = {
      id: uuid(),
      value: `Choice #${choices.length + 1}`,
    }
    setChoices([...choices, newChoice])
  }

  return (
    <>
      {choices.map((choice, index) => (
        <AddQuestionModalChoice
          key={choice.id}
          choices={choices}
          id={choice.id}
          number={index + 1}
          setChoices={setChoices}
          title={choice.value}
        />
      ))}
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={addChoice}
      >
        Add choice
      </Button>
      {questionType !== 'input' && (
        <label className="add-icon-label">
          (Add at least 2 choices)
        </label>
      )}
    </>
  )
}

ModalChoices.propTypes = {
  questionType: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  setChoices: PropTypes.func.isRequired,
}
