import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  wrapper: {
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  titleHeader: {
  },
  pickersWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  label: {
    color: '#757575',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  exportButton: {
    height: 40,
    marginLeft: 10,
    position: 'inherit',
    right: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
}))
