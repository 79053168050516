import { gql } from '@apollo/client'

export const GET_ORGANIZATIONS_QUERY = gql`
  query Organizations($search: String, $pagination: PaginationInput, $sorting: SortingInput
  ) {
    Organizations(searchQuery: $search, pagination: $pagination, sorting: $sorting ) {
      items {
        id
        name
        nmgId
        address
        phoneNumber {
            id
            organization
            store
        }
        merchantAccounts {
          id
          name
          username
          password
          integratorId
          active
        }
        reviewDestinations {
          id
          title
          url
          active
        }
        logo
        admin {
          name
          email
          active
          isOnboarded
          isPasswordExpired
        }
        paymentEnabled
        active
        deleted
      }
      pagination {
        offset
        size
        totalItems
        hasNext
        hasPrevious
      }
    }
  }
`

export const GET_ORGANIZATION_QUERY = gql`
  query Organization($id: ID!) {
    Organization(id: $id) {
      id
      name
      nmgId
      site
      address
      logo
      launcherVisible
      phoneNumber {
        id
        organization
        store
        phoneNumber
      }
      merchantAccounts {
        id
        name
        username
        password
        integratorId
        active
      }
      reviewDestinations {
        id
        title
        url
        active
      }
      admin {
        id
        name
        email
      }
      paymentEnabled
      active
      deleted
    }
  }
`

export const GET_ORGANIZATION_PHONES = gql`
    query OrganizationNumbers($organization: String!) {
        OrganizationNumbers(organization: $organization) {
            store
            phoneNumber
            id
        }
    }
`
