import moment from 'moment'
import React, { useRef } from 'react'
import StorefrontIcon from '@material-ui/icons/Storefront'
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { GET_STORE } from 'GraphQL/queries/store'
import { useApolloQuery } from 'GraphQL/useApolloQuery'

import './styles.scss'
import ChatArea from '../../messenger/chat/components/ChatArea'

export const VideoCallChat = ({ callDuration }) => {
  const callChatRef = useRef(null)
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)
  const customerShortInfo = JSON.parse(window.localStorage.getItem('customerShortInfo'))

  const customerName = personalCall?.customerName || customerShortInfo.name
  const { data: storeInfoData } = useApolloQuery(GET_STORE, {
    variables: {
      id: customerShortInfo.storeId,
    },
  })
  const storeInfo = storeInfoData?.Store

  return (
    <div className="incoming-call-chat">
      <div className="header">
        <div className="header-title-wrap">
          <div>
            Chat with
            <span className="support-info">{` ${customerName}`}</span>
          </div>
        </div>
      </div>
      <div className="store-location">
        {!personalCall && (
          <>
            <StorefrontIcon
              className="store-icon"
              fontSize="small"
            />
            <>
              <span className="location-name">{` ${storeInfo?.address}`}</span>
              <span className="support-info">{`, ${storeInfo?.postalCode}`}</span>
            </>
          </>
        )}
      </div>

      <div className="call-time-wrap">
        <PhoneEnabledIcon
          className="phone-icon"
          fontSize="large"
        />
        <div className="call-time">
          Ongoing call
          <span>{moment(callDuration * 1000).format('mm:ss')}</span>
        </div>
      </div>
      <ChatArea
        chatRef={callChatRef}
        isCall
      />
    </div>
  )
}

VideoCallChat.propTypes = {
  callDuration: PropTypes.number.isRequired,
}
