import * as yup from 'yup'

export const bankInfoValidationSchema = yup.object().shape({
  name: yup.string()
    .required(),
  accountNumber: yup
    .string()
    .required(),
  routingNumber: yup
    .string()
    .required(),
})
