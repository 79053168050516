export const styles = {
  root: {
    flex: 1,
    padding: 8,
    border: '1px solid #D8DADD',
    borderRadius: 4,
  },
  selected: {
    borderColor: '#D8392F',
  },
}
