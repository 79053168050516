/* eslint-disable max-lines */
// import { getEnvironment } from 'Helpers/helpers'
// TODO Remove and refactor
import React, {
  useState, useEffect, useCallback, useMemo, // useRef,
} from 'react'
import { isMobile } from 'react-device-detect'
import {
  // FormGroup,
  Button,
} from 'reactstrap'
// import Input from 'Components/common/Input'
import { ProfilePhoto } from 'Components/ProfilePhoto'

// import copyToClipboard from 'Services/copy-to-clipboard-service'
import { toast } from 'react-toastify'

// import { VideocamOutlined } from '@material-ui/icons'

import { useApolloMutation, useApolloQuery } from 'GraphQL/apollo'
import { GET_CURRENT_USER } from 'GraphQL/queries/users'
import { UPDATE_MY_PROFILE } from 'GraphQL/mutations/user'

import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { ChangePassword } from 'Pages/settings/agent-profile/ChangePassword'
import { ProfileForm } from 'Pages/settings/agent-profile/ProfileForm'
import { Notifications } from 'Pages/settings/agent-profile/Notification'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuthContext } from 'Providers/AuthProvider'
import { profileValidationSchema } from './validationSchema'

const USER_FIELDS = ['name', 'email', 'phoneNumber', 'browserNotificationStatus', 'smsNotificationStatus', 'emailNotificationStatus']

const AgentProfile = () => {
  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [isPositionedConfirmOpen, setIsPositionedConfirmOpen] = useState(false)
  const { currentUser, refetchUser } = useAuthContext()
  const [initialFormData, setInitialFormData] = useState({})
  // const environment = getEnvironment()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileValidationSchema),
  })
  const { data: currentUserData } = useApolloQuery(GET_CURRENT_USER)
  const user = useMemo(() => currentUserData?.Me ?? {}, [currentUserData])

  const resetFieldValues = useCallback((initState) => {
    const formData = {}
    USER_FIELDS.forEach((field) => {
      const notifStrPos = field.indexOf('Notification')
      const value = notifStrPos > -1
        ? ((initState.notifications || {})[field.substr(0, notifStrPos)] ?? false)
        : initState[field]
      formData[field] = value
      setValue(field, value)
    })
    setInitialFormData(formData)
  }, [setValue])

  useEffect(() => {
    if (user) {
      resetFieldValues(user)
    }
  }, [user, resetFieldValues])

  const [updateMyProfile] = useApolloMutation(UPDATE_MY_PROFILE, {
    onCompleted: () => {
      refetchUser()
      toast.success('You have successfully updated your profile')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  // const inputUrlRef = useRef()

  // const copyURL = async () => {
  //   const copyValue = inputUrlRef.current?.value
  //   if (copyValue) {
  //     await copyToClipboard(copyValue)
  //     toast.success('Url is copied')
  //   }
  // }

  const handlePositionedConfirmReset = (event) => {
    setPositionedConfirmAnchorEl(event.currentTarget)
    setIsPositionedConfirmOpen(prev => !prev)
  }

  const closePositionedConfirm = () => setIsPositionedConfirmOpen(false)

  const onSubmit = (data) => {
    const sentData = {}
    Object.keys(initialFormData).forEach((key) => {
      if (data[key] !== initialFormData[key]) {
        sentData[key] = data[key]
      }
    })

    updateMyProfile({
      variables: sentData,
    }).then(() => {
      refetchUser()
    })
  }

  return (
    <div className="agent-profile-page">
      <PositionedConfirmModal
        acceptTitle="Yes"
        anchorEl={positionedConfirmAnchorEl}
        cancelTitle="No"
        open={isPositionedConfirmOpen}
        placement="top"
        title="Are you sure you want to reset the changes?"
        onAccept={() => resetFieldValues(currentUser)}
        onClose={closePositionedConfirm}
      />

      <section className="settings-page-title d-flex flex-column">
        <span><small>General settings</small></span>
        <h3 className="pt-5">Agent profile</h3>
      </section>

      <div className="profile-rows pt-10">
        <section className="profile-row d-flex">
          <div className={isMobile ? 'appearance-description w-100 d-flex flex-column' : 'appearance-description w-30 d-flex flex-column'}>
            <span className="profile-label">Appearance</span>
            <span className="profile-label-description">
              Setup how you will look like on the chat: name, photo, etc
            </span>
          </div>
          <div className={isMobile ? 'w-100' : 'w-50'}>
            <ProfilePhoto size={isMobile ? 'mb' : 'lg'} />
            <ProfileForm
              control={control}
              errors={errors}
            />
          </div>
        </section>
        {/* <section className="profile-row d-flex">
          <div
            className={isMobile ? 'change-password-description d-flex flex-column w-100'
              : 'change-password-description w-30 d-flex flex-column'}
          >
            <span className="profile-label">Personal call URL</span>
          </div>
          <div
            className={isMobile ? 'change-password-fields w-100' : 'change-password-fields w-50'}
          >
            <div className="new-password">
              <FormGroup className="mb-15">
                <div className="d-flex">
                  <Input
                    defaultValue={
                      `https://personalroom.${environment}.showroomvideo.chat/live-chat/${currentUser.personalCallId}`
                    }
                    inputRef={inputUrlRef}
                    label="Personal audio/video room"
                    labelIcon={<VideocamOutlined />}
                    disabled
                    fullWidth
                  />
                  <div className="copy-btn-wrapper">
                    <Button
                      className="ml-10"
                      color="secondary"
                      outline
                      onClick={copyURL}
                    >
                      <i className="zmdi zmdi-copy" />
                      {' '}
                      Copy
                    </Button>
                  </div>
                </div>
              </FormGroup>
            </div>
          </div>
        </section> */}
        <Notifications control={control} />
        <section className="profile-row password-buttons d-flex justify-content-between pt-25 pb-50 w-80">
          <Button
            color="secondary"
            outline
            onClick={handlePositionedConfirmReset}
          >
            <i className="ti-reload" />
            <span className="pl-5">Reset changes</span>
          </Button>
          <Button
            className="btn-custom"
            onClick={handleSubmit(onSubmit)}
          >
            <span className="pl-5">Save changes</span>
          </Button>
        </section>
        <ChangePassword />
      </div>
    </div>
  )
}

export default AgentProfile
