import { gql } from '@apollo/client'

export const UPDATE_WIDGET = gql`
mutation UpdateWidget($id: ID!, $whitelistAddresses: [String!], $styles: JSONObject) {
  UpdateWidget(id: $id, whitelistAddresses: $whitelistAddresses, styles: $styles) {
    whitelistAddresses
    styles
  }
}
`
export const SEND_EMAIL = gql`
mutation SendEmail($type: EmailTemplateTypes!, $recipient: [String!]!, $payload: JSONObject!) {
  SendEmail(type: $type, recipient: $recipient, payload: $payload) {
    id
    type
    recipient
    payload
  }
}
`
