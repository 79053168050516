import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './styles.scss'

export const Timepicker = ({
  onChange,
  label,
  error,
  errorMessage,
  ...timepickerProps
}) => (
  <div className="timepicker-wrapper">
    <div className="timepicker">
      {label && (
        <span>
          {label}
          :
        </span>
      )}
      <input
        {...timepickerProps}
        className={classNames('timepicker-input', error && 'error')}
        type="time"
        onChange={onChange}
      />
    </div>
    {error && <span className="error-message text-center mt-1">{errorMessage}</span>}
  </div>
)

Timepicker.defaultProps = {
  onChange: () => {},
  label: null,
  error: false,
  errorMessage: null,
}

Timepicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}
