import { toast } from 'react-toastify'
import { useApolloMutation } from 'GraphQL/apollo'

import { RECOVER_AGENT } from 'GraphQL/mutations/retailer'

export const useAgentsMutations = (query, callback) => useApolloMutation(query, {
  onCompleted: () => {
    callback()
    toast.success(`Agent ${query === RECOVER_AGENT ? 're-activated' : 'deactivated'}`)
  },
  onError: (error) => {
    toast.error(error.message)
  },
})
