import { GET_ORGANIZATIONS_BILLING_QUERY } from 'GraphQL/queries/billing'
import { useApolloLazyQuery } from 'GraphQL/apollo'

export const useOrganizationCSV = ({ onCompleted }) => {
  const [getOrganizationBillingCSV] = useApolloLazyQuery(
    GET_ORGANIZATIONS_BILLING_QUERY, {
      onCompleted: data => onCompleted(data?.OrganizationsBilling.items),
    },
  )
  return getOrganizationBillingCSV
}
