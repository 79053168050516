import React from 'react'
import dateFnsUtils from '@date-io/date-fns'

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import PropTypes from 'prop-types'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useStyles } from './styles'

export const DatePicker = ({ selectedDate, handleChange, ...rest }) => {
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={dateFnsUtils}>
      <KeyboardDatePicker
        maxDate={new Date()}
        {...rest}
        className={classes.datePicker}
        format="MMM dd, yyyy"
        id="date-picker-inline"
        InputProps={{
          disableUnderline: true,
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        keyboardIcon={<ArrowDropDownIcon />}
        margin="normal"
        value={selectedDate}
        variant="inline"
        disableToolbar
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePicker.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}
