/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { ENVIRONMENTS } from 'Constants/common'
import { getEnv } from 'Helpers/getEnv'

import {
  developmentConfig,
  productionConfig,
  stagingConfig,
  stagingStableConfig,
} from './config'

const getFirebaseConfig = () => {
  switch (getEnv()) {
    case ENVIRONMENTS.DEVELOPMENT:
      return developmentConfig
    case ENVIRONMENTS.PRODUCTION:
      return productionConfig
    case ENVIRONMENTS.STAGING:
      return stagingConfig
    case ENVIRONMENTS.STAGING_STABLE:
      return stagingStableConfig
    default:
      return developmentConfig
  }
}

const firebaseConfig = getFirebaseConfig()

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
const database = firebase.database()

export {
  auth,
  googleAuthProvider,
  database,
}
