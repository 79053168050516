import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react'
import List from '@material-ui/core/List'
import { useDispatch, useSelector } from 'react-redux'
import uniqBy from 'lodash/uniqBy'

import { useStoresContext } from 'Providers/StoresProvider'
import { useConversationPath } from 'Hooks/useConversationPath'
import useQuery from 'Hooks/useQuery'
import {
  chatWithSelectedUser,
  markConversationAsRead,
  updateSelectedUser,
} from 'Store/Actions'
import { isMobile } from 'react-device-detect'
import { useAuthContext } from 'Providers/AuthProvider'
import { filterUserChat } from 'Providers/conversation/helpers'
import { changeHeaderData } from 'Store/Actions/ChatHeader'
import { useApolloMutation } from 'GraphQL/apollo'
import { MARK_CONVERSATION_AS_READ } from 'GraphQL/mutations/conversations'
import UserListItem from './UserListItem'

function RecentChatUsers() {
  const [users, setUsers] = useState([])
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const query = useQuery()
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedStores } = useStoresContext()
  const { currentUser } = useAuthContext()
  const {
    recentChatUsers, allChatUsers, selectedUser, selectedChatFilter, searchUsers,
  } = chatAppReducer
  const { setConversationPath, setConversationId } = useConversationPath()
  const [markAsRead] = useApolloMutation(MARK_CONVERSATION_AS_READ)

  const filteredArr = useMemo(() => uniqBy(users, 'conversationId'), [users])

  const switchChatWithUser = useCallback((user) => {
    if (isMobile) {
      dispatch(changeHeaderData({
        chatName: user.name,
        messengerStep: 2,
      }))
    }

    setConversationPath(user.conversationPath)
    setConversationId(user.conversationId)
    window.localStorage.setItem('customerShortInfo', JSON.stringify({ storeId: user.storeId, name: user.name }))
    dispatch(chatWithSelectedUser(user))

    if (user.currentAgent === currentUser.id) {
      markAsRead({
        variables: {
          conversation: user.conversationId,
        },
      }).then(() => {
        dispatch(markConversationAsRead(user.conversationId))
        dispatch(updateSelectedUser({ isRead: true }))
      })
    }
  }, [dispatch, setConversationPath, setConversationId, markAsRead])

  useEffect(() => {
    const matchedUsers = recentChatUsers.filter(
      user => (user.name && searchUsers ? user.name.toLowerCase().indexOf(searchUsers.toLowerCase()) > -1 : true),
    )

    if (!matchedUsers?.length) {
      setUsers([])
    }

    const filteredByTypeChats = filterUserChat({
      type: selectedChatFilter,
      recentChatUsers: matchedUsers,
      currentUser,
    })

    if (!selectedStores.length) {
      const sortedUsers = filteredByTypeChats.sort((user, nextUser) => nextUser.ordering - user.ordering)
      setUsers(sortedUsers)
    } else if (matchedUsers.length) {
      const filteredUsers = filteredByTypeChats.filter(user => selectedStores.includes(user.storeId))
      const sortedUsers = filteredUsers.sort((user, nextUser) => nextUser.ordering - user.ordering)
      setUsers(sortedUsers)
    }
  }, [recentChatUsers, selectedStores, selectedChatFilter, searchUsers])

  useEffect(() => {
    if (allChatUsers?.length > 0 && query.get('c') && !loaded) {
      const targetUser = allChatUsers.find(u => u.conversationId === query.get('c'))

      if (targetUser) {
        setLoaded(true)
        switchChatWithUser(targetUser)
      }
    }
  }, [allChatUsers, query, loaded, setLoaded, switchChatWithUser])

  if (!recentChatUsers) {
    return (
      <div className="no-found-user-wrap">
        <h4>No User Found</h4>
      </div>
    )
  }

  return (
    <List className="p-0 mb-0">
      {filteredArr.map((user, index) => (
        <UserListItem
          key={user.conversationId + user.name}
          isLastUser={filteredArr.length - 1 === index}
          selectedUser={selectedUser}
          user={user}
          onClickListItem={() => switchChatWithUser(user)}
        />
      ))}
    </List>
  )
}

export default RecentChatUsers
