import React, { useState, useMemo, useEffect } from 'react'
import { Autocomplete } from 'Components/common/Autocomplete'
import { IconButton } from '@material-ui/core'
import { Add, DeleteOutline } from '@material-ui/icons'
import { useApolloMutation, useApolloQuery } from 'GraphQL/apollo'
import { GET_USERS } from 'GraphQL/queries/users'
import PropTypes from 'prop-types'
import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { useFieldArray } from 'react-hook-form'
import { REMOVE_USER_FROM_STORE } from 'GraphQL/mutations/user'
import { LinkButton } from 'Components/common/LinkButton'
import { useCheckPermission } from 'Hooks/useCheckPermission'
import { PERMISSION_NAMES } from 'config/permissions'
import { useAuthContext } from 'Providers/AuthProvider'

export const UsersInStore = ({
  retailers,
  editMode,
  storeId,
  control,
  errors,
  permission,
}) => {
  const {
    fields: usersFields, append, remove,
  } = useFieldArray({
    control,
    name: 'users',
  })
  const { getOrganizationId } = useAuthContext()
  const organizationId = getOrganizationId()

  const [user, setUser] = useState(null)
  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [positionedConfirmOpen, setPositionedConfirmOpen] = useState(false)
  const [positionedConfirmPlacement, setPositionedConfirmPlacement] = useState('bottom-end')
  const [selectedUsers, setSelectedUsers] = useState([])

  const checkPermission = useCheckPermission()
  const isUserHasEditStorePermission = checkPermission(PERMISSION_NAMES.EDIT_STORE)
  const [isUserDeletePermitted, setIsUserDeletePermitted] = useState(true)
  const { initialRetailers, setInitialRetailers } = retailers

  useEffect(() => {
    setSelectedUsers(usersFields)

    if (usersFields.length === 1) {
      setIsUserDeletePermitted(false)
    } else {
      setIsUserDeletePermitted(true)
    }
  }, [usersFields])

  const [removeUserFromStore] = useApolloMutation(REMOVE_USER_FROM_STORE)

  const { data: dataUsersByOrganization } = useApolloQuery(GET_USERS, {
    variables: {
      organization: organizationId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const handleChange = (data) => {
    const selectedUsers$ = [...usersFields]

    if (selectedUsers$[usersFields.length - 1]) {
      selectedUsers$[usersFields.length - 1].email = data
    }
    setSelectedUsers(selectedUsers$)
  }

  const removeUser = (user$, index) => {
    if (editMode) {
      const isExtantUser = !!initialRetailers.find(agent => agent.email === user$.email)

      // If the user is tied to the edited Store, request to delete
      if (isExtantUser) {
        removeUserFromStore({
          variables: {
            storeId,
            userId: user$.id,
          },
        }).then(() => {
          // Remove the User from the list of assigned Users
          setInitialRetailers((prevState) => {
            const copyPrevState = [...prevState]
            copyPrevState.splice(index, 1)
            return copyPrevState
          })

          // Remove the User from the view list
          remove(index)
        })
        return
      }
    }
    remove(index)
  }

  const usersEmail = useMemo(() => selectedUsers.map(user$ => user$.email), [selectedUsers])

  const activeUsers = useMemo(() => dataUsersByOrganization?.Users.items.filter(userItem => userItem.active) ?? [],
    [dataUsersByOrganization, usersEmail])
  const availableUsers = activeUsers.filter(activeUser => !usersEmail.includes(activeUser.email))
  const userListOptions = useMemo(() => availableUsers.map(option => option.email), [activeUsers])
  const closePositionedConfirm = () => setPositionedConfirmOpen(false)

  const isAddUser = activeUsers.length > usersFields.length

  const handleAccept = () => {
    const { field, index } = user
    const selectedUsers$ = [...selectedUsers]
    selectedUsers$.splice(index, 1)
    setSelectedUsers(selectedUsers$)
    removeUser(field, index)
    setUser(null)
  }

  const handlePositionedConfirmDelete = (field, index, newPlacement) => (event) => {
    setUser({
      field,
      index,
    })
    setPositionedConfirmAnchorEl(event.currentTarget)
    setPositionedConfirmOpen(prev => positionedConfirmPlacement !== newPlacement || !prev)
    setPositionedConfirmPlacement(newPlacement)
  }

  return (
    <>
      <PositionedConfirmModal
        acceptIcon={false}
        acceptTitle="Yes"
        anchorEl={positionedConfirmAnchorEl}
        open={positionedConfirmOpen}
        placement={positionedConfirmPlacement}
        title={`Are you sure you want to remove ${user?.field.email}`}
        onAccept={handleAccept}
        onClose={closePositionedConfirm}
      />
      <ul className="users-in-store-list">
        {usersFields.map((field, index) => (
          <li
            key={field.id}
            className="users-in-store-item"
          >
            <Autocomplete
              className="users-in-store-input"
              control={control}
              handleChange={handleChange}
              inputProps={{
                error: !!errors?.users?.[index],
                errorMessage: errors?.users?.[index]?.email?.message,
                readOnly: !permission,
                wrapperClassName: 'no-margin',
                placeholder: 'select an agent',
              }}
              name={`users.${index}.email`}
              options={userListOptions}
              readOnly
            />
            <IconButton
              aria-label="delete"
              disabled={!isUserDeletePermitted || !isUserHasEditStorePermission}
              disableRipple
              onClick={event => handlePositionedConfirmDelete(field, index, 'bottom-end')(event)}
            >
              <DeleteOutline />
            </IconButton>
          </li>
        ))}
      </ul>
      <LinkButton
        color="secondary"
        disabled={!isUserHasEditStorePermission || !isAddUser}
        startIcon={<Add />}
        onClick={() => append({ email: '', name: '' })}
      >
        Add Agent
      </LinkButton>
    </>
  )
}

UsersInStore.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({
    users: PropTypes.shape({}),
  }).isRequired,
  permission: PropTypes.bool,
  retailers: PropTypes.shape({
    initialRetailers: PropTypes.array,
    setInitialRetailers: PropTypes.func,
  }).isRequired,
  editMode: PropTypes.bool,
  storeId: PropTypes.string.isRequired,

}

UsersInStore.defaultProps = {
  permission: false,
  editMode: true,
}
