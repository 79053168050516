import { CONVERSATION_STATUSES } from 'Constants/conversation'
import { ROLE_TYPES } from 'Constants/rolesConstants'
import { useAgentAvailability } from 'Hooks/useAgentAvailability'
import { useConversation } from 'Hooks/useConversation'
import { useAuthContext } from 'Providers/AuthProvider'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { ASSIGN_AGENT_TO_CONVERSATION, MARK_CONVERSATION_AS_READ } from 'GraphQL/mutations/conversations'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { useClickOutside } from 'Hooks/useClickOutside'
import AsyncSelect from 'react-select/async'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { changeAssignedAgent, markConversationAsRead, updateSelectedUser } from 'Store/Actions/ChatAppActions'
import { GET_USERS } from 'GraphQL/queries/users'
import { useApolloLazyQuery } from 'GraphQL/useApolloLazyQuery'
import { Avatar } from 'Components/Avatar/Avatar'
import { useAssignedAgent } from 'Hooks/useAssignedAgent'
import { CollapsedBlock } from '../CollapsedBlock/CollapsedBlock'
import { useMessengerContext } from '../../useMessenger'
import './styles.scss'

const customStyle = {
  container: () => ({
    width: 150,
    position: 'absolute',
  }),
  menu: () => ({
    width: 150,
    border: '1px solid #d6d6d6',
    position: 'absolute',
    backgroundColor: 'white',
    marginTop: 2,
  }),
}
export const AssignedAgent = () => {
  const dispatch = useDispatch()

  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser } = chatAppReducer
  const { conversationId } = selectedUser
  const [isOpen, setIsOpen] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [usersOptions, setUsersOptions] = useState([])
  const [isSelectVisible, setIsSelectVisible] = useState(false)
  const conversation = useConversation()
  const assignedAgent = useAssignedAgent()
  const { currentUser } = useAuthContext()
  const { currentStore } = useMessengerContext()
  const availability = useAgentAvailability(assignedAgent.id)

  const handleSetIsOpen = () => {
    setIsOpen(state => !state)
  }
  const currentRef = useClickOutside({
    onClick: () => setIsSelectVisible(false),
  })
  const [markAsRead] = useApolloMutation(MARK_CONVERSATION_AS_READ)

  const [getUsers, { data: usersData, refetch: refetchUsers }] = useApolloLazyQuery(GET_USERS, {
    variables: {
      store: selectedUser.storeId,
      organization: currentUser.accessList?.[0]?.organization,
      searchQuery,
    },
  })

  useEffect(() => {
    getUsers()
  }, [])

  const makeOptions = items => items?.map(item => ({ label: item.name, value: item.id }))

  useEffect(() => {
    const activeUsers = usersData?.Users?.items.filter(user => user.active)
    const options = makeOptions(activeUsers)
    const filteredOptions = options?.filter(option => option.value !== assignedAgent.id)
    if (filteredOptions) {
      setUsersOptions(filteredOptions)
    }
  }, [usersData?.Users?.items, assignedAgent.id])

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      if (inputValue) {
        refetchUsers().then((res) => {
          const filteredResult = res?.data?.Users.items.filter(user => user.active && user.id !== assignedAgent.id)
          const options = makeOptions(filteredResult)
          callback(options)
        })
      }
    }, 1000)
  }

  const DropdownIndicator = () => (<ArrowDropDownIcon />)
  const [assignAgent] = useApolloMutation(ASSIGN_AGENT_TO_CONVERSATION, {
    onCompleted: () => {
      dispatch(changeAssignedAgent({ agentId: assignedAgent.id, customerId: selectedUser.id }))
      toast.success('Successfully assigned')
    },
  })

  const handleUserChange = (option) => {
    if (option.value && conversationId) {
      assignAgent({
        variables: {
          user: option.value,
          conversation: conversationId,
        },
      }).then(() => {
        if (option.value === currentUser.id) {
          markAsRead({
            variables: {
              conversation: selectedUser.conversationId,
            },
          }).then(() => {
            dispatch(markConversationAsRead(selectedUser.conversationId))
            dispatch(updateSelectedUser({ isRead: true }))
          })
        }
      })
    }
  }
  const isRetailerAdmin = currentUser.roles.includes(ROLE_TYPES.RETAILER_ADMIN)
  const isRetailerFromUsersStore = currentUser.accessList.find(accessItem => accessItem.store === selectedUser.storeId)

  const isReassignButtonDisabled = conversation?.status === CONVERSATION_STATUSES.RESOLVED || conversation?.status
    === CONVERSATION_STATUSES.CLOSED
    || (!isRetailerAdmin && (!isRetailerFromUsersStore || !currentStore?.allowReassignChatByAgent))

  return (
    <div className="assigned-agent">
      <CollapsedBlock
        isOpen={isOpen}
        title="Assigned agent"
        toggle={handleSetIsOpen}
      >
        {assignedAgent.name && (
          <div className="assigned-agent-body">
            <Avatar
              avatarSrc={assignedAgent.avatar}
              status={availability}
              userName={assignedAgent.name}
            />
            <div className="name-block">
              <span>{assignedAgent.name}</span>
              {!isReassignButtonDisabled && (
                <a
                  className="reassign-link"
                  onClick={() => setIsSelectVisible(true)}
                >
                  Reassign
                </a>
              )}
              {isSelectVisible && (
                <div
                  ref={currentRef}
                  className="retailers-select-wrapper"
                >
                  <AsyncSelect
                    className="retailers-select"
                    components={{ DropdownIndicator }}
                    defaultOptions={usersOptions}
                    loadOptions={loadOptions}
                    styles={customStyle}
                    TextFieldProps={{
                      label: 'Organization',
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    menuIsOpen
                    onChange={option => handleUserChange(option)}
                    onInputChange={query => setSearchQuery(query)}
                    onMenuClose={() => setIsSelectVisible(false)}
                  />
                </div>
              )}
            </div>
          </div>

        )}
      </CollapsedBlock>
    </div>
  )
}
