import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    marginLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 20,
      marginLeft: 20,
      width: '90%',
    },
  },
  tooltip: {
    height: 50,
    fontSize: 12,
    backgroundColor: '#acacac',
    display: 'flex',
    alignItems: 'center',
  },
  statisticsItem: {
    width: '20%',
    border: '1px solid #c9c9c9',
    borderRadius: 5,
    marginRight: 15,
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      width: '94%',
      marginTop: 10,
    },
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
  },
  dynamic: {
    marginLeft: 10,
    fontSize: 12,
    color: '#1BC47D',
  },
  value: {
    fontSize: 24,
  },
  name: {
    fontSize: 12,
    fontWeight: 'bold',
  },
}))
