export const workflowsHeaders = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'deleted',
  },
  {
    title: 'Creation Date',
    field: 'createdAt',
  },
  {
    title: 'Actions',
    field: 'actions',
    isSorting: false,
  },
]
