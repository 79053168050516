import {
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { useAuthContext } from 'Providers/AuthProvider'
import { useStoresContext } from 'Providers/StoresProvider'
import { addNewCustomers, updateCustomers, removeCustomers } from 'Store/Actions/ChatAppActions'
// import { useConversationPath } from 'Hooks/useConversationPath'
import { notificationSound } from 'Helpers/notificationSound'
import { database } from '../Firebase'

const FirebaseContext = createContext({
  pathBuilder: null,
  db: null,
  eventTypes: {},
})

export const useFirebaseContext = () => useContext(FirebaseContext)

const getPath = organizationId => ({
  path: `organizations/${organizationId}/stores`,
  conversation(conversationId) {
    this.path += conversationId
    return this
  },
  customer(userId) {
    this.path += userId
    return this
  },

  message(messageId) {
    this.path = `${this.path }messages/${ messageId}`
    return this
  },
  build() {
    return this.path
  },

})

const eventTypes = {
  CHILD_ADDED: 'child_added',
  CHILD_REMOVED: 'child_removed',
  VALUE: 'value',
  CHILD_CHANGED: 'child_changed',
}

const FirebaseProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { getOrganizationId } = useAuthContext()
  // const { setConversationPath } = useConversationPath()
  const { stores } = useStoresContext()
  const organizationId = getOrganizationId()
  const rootRef = getPath(organizationId).build()
  const customersRef = database.ref(rootRef)

  const getCustomer = useCallback((snap) => {
    const {
      customer,
      messages = {},
      id,
      store,
      agents = [],
      status,
      currentAgent,
    } = snap
    const lastMessage = Array.isArray(messages)
      ? messages[messages.length - 1]
      : messages?.[Object.keys(messages)[Object.keys(messages).length - 1]]
    const order = lastMessage?.sentAt ?? new Date()
    const storeAddress = stores?.find(item => item.id === store)
    const conversationPath = `organizations/${snap.organization}/stores`

    // if (snap.organization) {
    //   setConversationPath(conversationPath)
    // }

    return {
      id: customer?.client || Date.now(),
      name: customer?.name,
      email: customer?.email,
      phone: customer?.phon,
      createdAt: snap?.createdAt,
      photo_url: `${process.env.PUBLIC_URL}/assets/images/avatars/user-15.jpg`,
      isActive: true,
      agents,
      status,
      online: true,
      last_chat: lastMessage?.content || '',
      new_message_count: 1,
      isSelectedChat: false,
      storeId: store,
      storeAddress: storeAddress ? `${storeAddress?.address}, ${storeAddress?.state} ${storeAddress?.postalCode}` : '',
      currentAgent,
      conversationPath,
      customerChannel: snap.customerChannel,
      isRead: snap.isRead,
      previousChats: [
        {
          messageId: Date.now(),
          message: lastMessage?.message,
          sent: Date.now(),
          isAdmin: Boolean(customer?.isAdmin),
        },
      ],
      ordering: snap.ordering,
      order,
      conversationId: id,
      lastMessage,
    }
  }, [stores])

  const handleUpdateCustomers = useCallback((storeKey, snapValue, actionType) => {
    const conversations = snapValue.conversations
    const customers = []
    Object.keys(conversations).forEach((key) => {
      customers.push(
        actionType === 'remove' ? { conversationId: key } : getCustomer(conversations[key]),
      )
    })

    switch (actionType) {
      case 'add':
        dispatch(addNewCustomers(customers))
        break
      case 'update':
        notificationSound(customers)
        dispatch(updateCustomers(storeKey, customers))
        break
      case 'remove':
        dispatch(removeCustomers(customers))
        break
      default:
        break
    }
  }, [dispatch, getCustomer])

  useEffect(() => {
    if (stores?.length) {
      customersRef.on(eventTypes.CHILD_ADDED, (snap) => {
        const snapValue = snap.val()
        console.log('child added: ', snapValue)
        if (snapValue.conversations) {
          handleUpdateCustomers(snap.key, snapValue, 'add')
        }
      })

      customersRef.on(eventTypes.CHILD_CHANGED, (snap) => {
        const snapValue = snap.val()
        console.log('child changed: ', snapValue)
        if (snapValue.conversations) {
          handleUpdateCustomers(snap.key, snapValue, 'update')
        }
      })

      customersRef.on(eventTypes.CHILD_REMOVED, (snap) => {
        const snapValue = snap.val()
        console.log('child removed: ', snapValue)
        if (snapValue.conversations) {
          handleUpdateCustomers(snap.key, snapValue, 'remove')
        }
      })
    }
  }, [stores, handleUpdateCustomers])

  const contextValue = {
    pathBuilder: getPath(organizationId),
    db: database,
    eventTypes,
  }

  return (
    <FirebaseContext.Provider value={contextValue}>
      { children }
    </FirebaseContext.Provider>
  )
}

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FirebaseProvider
