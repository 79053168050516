/* eslint-disable */
const DEFAULT_BUFFER_SIZE = 512
const DEFAULT_VOLUME_FALL = 0.95
export const createAudioStreamProcessor = (audioContext, callback, config) => {
  // TODO refactor this file
  const scriptProcessor = audioContext.createScriptProcessor(
    config?.bufferSize || DEFAULT_BUFFER_SIZE,
    config?.inputChannels || 1,
    1,
  )

  scriptProcessor.onaudioprocess = onAudioStreamProcess
  scriptProcessor.audioProcessCallback = callback
  scriptProcessor.close = close

  scriptProcessor.volume = 0
  scriptProcessor.config = {
    volumeFall: config?.volumeFall || DEFAULT_VOLUME_FALL, /* (0,1) more means volume wave will be fall slower */
    throttle: config?.throttle || 1, /* [1, 10] higher value means more percent of data will be omited, 1 = 100% */
  }

  scriptProcessor.connect(audioContext.destination)

  return scriptProcessor
}

function onAudioStreamProcess(event) {
  let volumeSum, volumeCount, buffer, sample
  volumeSum = volumeCount = 0

  for (let i = 0; i < event.inputBuffer.numberOfChannels; i++) {
    buffer = event.inputBuffer.getChannelData(i)

    for (let j = 0; j < buffer.length; j += this.config.throttle) {
      sample = buffer[~~j]
      volumeSum += sample * sample
    }

    volumeCount += buffer.length
  }

  const rms = Math.sqrt(volumeSum / volumeCount)
  this.volume = Math.max(rms, this.volume * this.config.volumeFall)

  this.audioProcessCallback()
}

function close() {
  this.disconnect()
  this.onaudioprocess = null
}
