import { makeStyles } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

export const useStyles = makeStyles(() => ({
  storesWrapper: {
    marginTop: isMobile ? '70px' : '10px',
  },
  storeWrapper: {
    display: 'flex',
    height: 50,
    fontSize: 14,
    alignItems: 'center',
    padding: 5,
    marginTop: 5,
  },
  storeCheckbox: {
    height: 15,
    width: 15,
    padding: 15,
    marginRight: 10,
    color: '#bababa',
    '&:checked': {
      backgroundColor: 'red',
    },
  },
  allStores: {
    padding: 5,
    marginTop: 5,
  },
  storeInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  storeAddress: {
    color: '#646B77',
  },
  storeIcon: {
    fontSize: 20,
    marginRight: 15,
    marginLeft: 5,
    color: '#646B77',
  },
  title: {
    fontSize: 16,
    padding: 5,
    alignItems: 'center',
    display: 'flex',
  },
  checked: {
    color: '#FF7A59',
  },
  isActive: {
    borderLeft: '3px solid #FF7A59',
    backgroundColor: 'white',
  },
}))
