import { GET_PAYMENT_HISTORY } from 'GraphQL/queries/payment'
import { useApolloLazyQuery } from 'GraphQL/apollo'

export const PaymentReportCSV = ({ onCompleted }) => {
  const [getPaymentReportCSV] = useApolloLazyQuery(
    GET_PAYMENT_HISTORY, {
      onCompleted: data => onCompleted(data?.PaymentHistory.items),
    },
  )
  return getPaymentReportCSV
}
