import { useEffect } from 'react'

import { GET_ORGANIZATION_PHONES } from 'GraphQL/queries/organization'
import { useApolloLazyQuery } from 'GraphQL/useApolloLazyQuery'
import { useAuthContext } from 'Providers/AuthProvider'

export const useOrganizationPhoneNumbers = () => {
  const { getOrganizationId } = useAuthContext()
  const [getOrganizationPhones, { data: phonesData }] = useApolloLazyQuery(GET_ORGANIZATION_PHONES)

  useEffect(() => {
    const organizationId = getOrganizationId()
    if (organizationId) {
      getOrganizationPhones({
        variables: {
          organization: organizationId,
        },
      })
    }
  }, [])

  return phonesData?.OrganizationNumbers ?? []
}
