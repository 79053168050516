const MessageAudio = new Audio('/assets/sound-effect/message.mp3')
MessageAudio.muted = false

let isPlaying = false

const playMessageAudio = () => {
  MessageAudio.play()
  isPlaying = true
  setTimeout(() => {
    isPlaying = false
  }, 1000)
}

export const notificationSound = (chats = []) => {
  const isNewMessage = chats.find(user => user.new_message_count === 1 && user.lastMessage?.sender !== 'agent')
  if (!isPlaying && isNewMessage) {
    playMessageAudio()
  }
}
