/* eslint-disable no-return-assign,no-param-reassign */
import { AGENT_STATUSES } from 'Constants/common'
import { useConversation } from 'Hooks/useConversation'
import { useConversationPath } from 'Hooks/useConversationPath'
import { useAgentStatusContext } from 'Providers/AgentStatusProvider'
import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useCallSettingsContext } from 'Providers/CallSettingsProvider'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { APP_PATH, MESSENGER_PATH } from 'Constants/routerPaths'
import { IconButton } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { isMobileOnly, isMobile } from 'react-device-detect'
import { APPEND_MESSAGE } from 'GraphQL/mutations/chat'
import { useApolloMutation } from 'GraphQL/apollo'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined'
import VideocamOutlined from '@material-ui/icons/VideocamOutlined'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare'
import MicNoneIcon from '@material-ui/icons/MicNone'
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined'
import ChatIcon from '@material-ui/icons/Chat'

import './style.scss'
import { usePeerContext } from 'Providers/PeerProvider/PeerProvider'
import { SettingsModal } from '../../waiting-room/SettingsModal/SettingsModal'

export const Controls = ({ toggleChat, callDuration }) => {
  const history = useHistory()
  const {
    isMicrophoneEnabled,
    isCameraEnabled,
    setIsCameraEnabled,
    setIsMicrophoneEnabled,
  } = useCallSettingsContext()
  const { conversationId } = useConversationPath()
  const conversation = useConversation()
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const { changeAvailability } = useAgentStatusContext()

  const {
    localVideoRef,
    endCall,
    setVideoDeviceId,
    screenShareToggle,
    isScreenSharing,
    localScreenShareRef,
    toggleCamera: togglePeerCamera,
  } = usePeerContext()

  const toggleCamera = () => {
    localVideoRef.current.srcObject?.getVideoTracks().forEach(track => track.enabled = !track.enabled)
    setIsCameraEnabled(state => !state)
    togglePeerCamera()
  }

  const toggleMicrophone = () => {
    setIsMicrophoneEnabled(state => !state)
    if (isScreenSharing) {
      localScreenShareRef.current.srcObject?.getAudioTracks().forEach(
        track => track.enabled = isMicrophoneEnabled,
      )
    } else {
      localVideoRef.current.srcObject?.getAudioTracks().forEach(
        track => track.enabled = !track.enabled,
      )
    }
  }
  const [sendCallEndMessage] = useApolloMutation(APPEND_MESSAGE, {
    variables: {
      type: 'text',
      conversation: conversationId,
      channel: 'video',
      content: `Call ended  ${moment(callDuration * 1000).format('mm:ss')}`,
      sentAt: new Date().getTime(),
    },
  })

  const cancelCall = () => {
    changeAvailability(AGENT_STATUSES.AVAILABLE)
    endCall()
    if (conversation.call) {
      sendCallEndMessage()
    }

    history.push(`${APP_PATH}${MESSENGER_PATH}`)
  }

  const triggerSettingsModal = () => {
    setIsSettingsModalOpen(state => !state)
  }

  return (
    <div className="call-controls-wrapper">
      <IconButton
        aria-label="settings"
        className={classNames(
          'settings-button',
          'button',
        )}
        onClick={triggerSettingsModal}
      >
        <SettingsIcon />
        {!isMobileOnly && (<span className="button-text">Settings</span>)}
      </IconButton>

      <Divider className="controls-divider" />

      <div className="call-buttons-wrapper">
        <IconButton
          className={classNames(
            { 'disabled-button': !isMicrophoneEnabled },
          )}
          onClick={toggleMicrophone}
        >
          {isMicrophoneEnabled
            ? <MicNoneIcon className="button-icon" />
            : <MicOffOutlinedIcon className="button-icon" />}
        </IconButton>
        <IconButton
          className={classNames(
            { 'disabled-button': !isCameraEnabled },
          )}
          onClick={toggleCamera}
        >
          {isCameraEnabled
            ? <VideocamOutlined className="button-icon" />
            : <VideocamOffOutlinedIcon className="button-icon" />}
        </IconButton>

        {isMobileOnly && (
          <IconButton
            onClick={toggleChat}
          >
            <ChatIcon />
          </IconButton>
        )}
        {!isMobile && (
          <IconButton
            className={classNames(
              'button',
              'screen-share-button',
              { 'disabled-button': isScreenSharing },
            )}
          >
            {isScreenSharing
              ? (
                <StopScreenShareIcon
                  className="button-icon"
                  onClick={screenShareToggle}
                />
              )
              : (
                <ScreenShareIcon
                  className="button-icon"
                  onClick={screenShareToggle}
                />
              )}
          </IconButton>
        )}
      </div>

      <Divider className="controls-divider" />

      <IconButton
        className={classNames(
          'end-call-button',
          'button',
        )}
        onClick={cancelCall}
      >
        <ExitToAppIcon className="leave-icon" />
        {!isMobileOnly && (<span className="button-text">Leave</span>)}
      </IconButton>

      <SettingsModal
        closeModal={triggerSettingsModal}
        isOpen={isSettingsModalOpen}
        setVideoDeviceId={setVideoDeviceId}
        videoElementRef={localVideoRef}
      />
    </div>
  )
}

Controls.propTypes = {
  toggleChat: PropTypes.func.isRequired,
  callDuration: PropTypes.string.isRequired,
}
