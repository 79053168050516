import React from 'react'
import PropTypes from 'prop-types'
import Picker, { SKIN_TONE_DARK } from 'emoji-picker-react'

const defaultPickerStyle = {
  position: 'absolute',
  bottom: '20px',
  left: '10px',
}

const EmojiPicker = ({
  onEmojiClick,
  className,
  disableSkinTonePicker,
  disableSearchBar,
  pickerStyle,
}) => {
  const styles = { ...defaultPickerStyle, ...pickerStyle }
  return (
    <div className={className}>
      <Picker
        disableSearchBar={disableSearchBar}
        disableSkinTonePicker={disableSkinTonePicker}
        pickerStyle={styles}
        skinTone={SKIN_TONE_DARK}
        onEmojiClick={onEmojiClick}
      />
    </div>
  )
}

EmojiPicker.propTypes = {
  onEmojiClick: PropTypes.func,
  className: PropTypes.string,
  disableSkinTonePicker: PropTypes.bool,
  disableSearchBar: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  pickerStyle: PropTypes.any,
}

EmojiPicker.defaultProps = {
  disableSkinTonePicker: true,
  disableSearchBar: true,
  onEmojiClick: () => {},
  className: '',
  pickerStyle: PropTypes.any,
}

export default EmojiPicker
