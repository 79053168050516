import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import { useStyles } from './styles'

export const UsersDropdownList = ({
  users,
  id,
  anchorEl,
  setAnchorEl,
}) => {
  const classes = useStyles()

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        root: classes.usersDropdownList,
      }}
      id={id}
      open={anchorEl?.dataset.id === id}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={() => setAnchorEl(null)}
    >
      <div className={classes.usersWrapper}>
        {users?.map(user => (
          <div
            key={user.id}
            className={classes.userWrapper}
          >
            <div>
              <Avatar
                src={user.avatar}
              />
            </div>
            <div className={classes.userInfoWrapper}>
              <span>{user.name}</span>
              <span>{user.email}</span>
            </div>
          </div>
        ))}
      </div>
    </Popover>
  )
}

UsersDropdownList.propTypes = {
  users: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  anchorEl: PropTypes.element.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
}
