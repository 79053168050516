import React from 'react'

const Customers = () => (
  <div className="customers-wrapper">
    <h1>Search your Customers</h1>
    <h2>Coming soon...</h2>
  </div>
)

export default Customers
