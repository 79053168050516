import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { generateCallId } from 'Store/Actions'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'

import { CONVERSATION_STATUSES } from 'Constants/conversation'
import { useConversation } from 'Hooks/useConversation'
import { useAuthContext } from 'Providers/AuthProvider'
import { useAgentStatusContext } from 'Providers/AgentStatusProvider'
import { AGENT_STATUSES } from 'Constants/common'

import './styles.scss'

const CallControls = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const conversation = useConversation()
  const { selectedUser } = useSelector(state => state?.chatAppReducer)
  const { currentUser } = useAuthContext()
  const { availability } = useAgentStatusContext()

  const isCallAvailable = conversation?.customerChannel === 'widget'
  const isChannelSMS = conversation?.customerChannel === 'sms'

  const isReassignButtonDisabled = conversation?.status === CONVERSATION_STATUSES.RESOLVED || conversation?.status
    === CONVERSATION_STATUSES.CLOSED || currentUser.id !== conversation?.currentAgent || !isCallAvailable

  const buttonTooltipText = !isCallAvailable ? `${isChannelSMS ? 'Text' : 'E-mail'} chat option selected` : 'Start a call'

  const invokeVideoCall = () => {
    dispatch(generateCallId())
    history.push('/video-call')
  }
  return (
    <div className="controls-wrapper">
      {currentUser?.id && currentUser?.id === selectedUser?.currentAgent
        && availability === AGENT_STATUSES.AVAILABLE && (
        <span className="call-title">
          <span className="available-dot" />
          {!isCallAvailable ? 'Unavailable' : 'Available'}
          {' '}
          for call now
        </span>
      )}
      <Tooltip title={buttonTooltipText}>
        <div className="call-controls">
          <Button
            className={classNames(
              'start-call-button',
              { disabled: isReassignButtonDisabled },
            )}
            onClick={invokeVideoCall}
          >
            <i className="zmdi zmdi-mic-outline microphone-icon" />
            Start a call
          </Button>
          <Button
            className={classNames(
              'camera-button',
              { disabled: isReassignButtonDisabled },
            )}
            onClick={invokeVideoCall}
          >
            <i className="zmdi zmdi-videocam camera-icon" />
          </Button>
        </div>
      </Tooltip>
    </div>
  )
}

export default CallControls
