import * as yup from 'yup'

export const schema = yup.object().shape({
  title: yup.string().required('Question header is a required field'),
  question: yup.string(),
  isRequired: yup.bool(),
  isLongReply: yup.bool(),
  isMultipleChoice: yup.bool(),
})

export const ratingSchema = yup.object().shape({
  rating: yup.string().required('Rating question is a required field'),
})
