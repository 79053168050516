import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2'
import Typography from '@material-ui/core/Typography'
import { getPercentage } from 'Helpers/helpers'
import { useStyles } from './styles'

const TYPE_OF_CHATS = [
  {
    name: 'Back Office',
    key: 'backoffice',
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Video',
    key: 'video',
  },
  {
    name: 'Widget',
    key: 'widget',
  },
  {
    name: 'SMS',
    key: 'sms',
  },
]

export const DoughnutChart = ({ typeOfChat }) => {
  const classes = useStyles()

  const doughnutChartItems = useMemo(
    () => TYPE_OF_CHATS.map(item => ({ ...item, count: typeOfChat[item.key] })),
    [typeOfChat],
  )

  const totalCount = useMemo(
    () => doughnutChartItems.reduce((acc, value) => acc + value.count, 0),
    [doughnutChartItems],
  )

  const chartData = useMemo(
    () => doughnutChartItems.map(item => getPercentage(item.count, totalCount, 1)),
    [doughnutChartItems],
  )

  chartData.sort((a, b) => (a > b ? -1 : 1))
  doughnutChartItems.sort((a, b) => (a.count > b.count ? -1 : 1))

  const chartLabels = useMemo(
    () => doughnutChartItems.map(item => item.key),
    [doughnutChartItems],
  )

  const backgroundColors = [
    '#FF7A59',
    '#fd8c6e',
    '#FFA28B',
    '#FFCABD',
  ]

  const data = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  }

  const chartOption = {
    plugins: {
      legend: {
        display: false,
      },
      label: {
        display: true,
      },
    },
  }

  return (
    <div className={classes.blockWrapper}>
      <Typography className={classes.chartTitle}>Message type counter</Typography>
      <div className={classes.doughnutChartWrapper}>
        <Doughnut
          data={data}
          options={chartOption}
          redraw={false}
        />
        <div className={classes.labelWrapper}>
          <span className={classes.doughnutLabel}>Total</span>
          <span className={classes.doughnutNumber}>{totalCount}</span>
        </div>
      </div>
      <div className={classes.legend}>
        {doughnutChartItems.map((item, index) => (
          <div
            key={item.name}
            className={classes.legendItem}
          >
            <div className={classes.titleWrapper}>
              <span
                className={classes.dot}
                style={{
                  backgroundColor: backgroundColors[index],
                }}
              />
              <span className={classes.legendItemTitle}>{item.name}</span>
            </div>
            <span>{item.count}</span>
            <span className={classes.legendItemTitle}>{`${getPercentage(item.count, totalCount, 1)}%`}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

DoughnutChart.propTypes = {
  typeOfChat: PropTypes.shape({
    backoffice: PropTypes.number.isRequired,
    email: PropTypes.number.isRequired,
    video: PropTypes.number.isRequired,
    widget: PropTypes.number.isRequired,
  }),
}

DoughnutChart.defaultProps = {
  typeOfChat: {
    backoffice: 0,
    email: 0,
    video: 0,
    widget: 0,
  },
}
