import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  replyControlsButton: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: 'none',
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  activeTitle: {
    fontWeight: 'bold',
  },
  channelsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 235,
  },
  iconWrapper: {
    width: 35,
    marginRight: 5,
  },
  title: {
    lineHeight: '14px',
  },
  channel: {
    padding: 5,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  disabledChannel: {
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: 'none',
    },
  },
}))
