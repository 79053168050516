import { gql } from '@apollo/client'

export const GET_ORGANIZATIONS_BILLING_QUERY = gql`
    query OrganizationsBilling(
        $pagination: PaginationInput, 
        $sorting: SortingInput,
        $organization: String,
        $date: DateInput
    ) {
        OrganizationsBilling(pagination: $pagination, sorting: $sorting,  date: $date, organization: $organization) {
            items {
                id
                name
                activeUsers
                address
                nmgId
                activeUsers
                totalUsers
                site
                createdAt
            }
            pagination {
                offset
                size
                totalItems
                hasNext
                hasPrevious
            }
        }
    }
`
