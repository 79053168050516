import { useEffect, useState } from 'react'
import { useConversation } from 'Hooks/useConversation'
import { GET_USER_BY_ID } from 'GraphQL/queries/users'
import { useApolloLazyQuery } from 'GraphQL/apollo'

export const useAssignedAgent = () => {
  const conversation = useConversation()
  const [assignedAgent, setAssignedAgent] = useState({})
  const [getAgent, { data: assignedAgentData }] = useApolloLazyQuery(GET_USER_BY_ID)

  useEffect(() => {
    if (conversation.currentAgent) {
      getAgent({
        variables: {
          id: conversation?.currentAgent,
        },
      })
    }
    if (!conversation.currentAgent && conversation.id) {
      setAssignedAgent({})
    }
  }, [conversation])

  useEffect(() => {
    if (assignedAgentData?.User) {
      setAssignedAgent(assignedAgentData.User)
    }
  }, [assignedAgentData])

  return assignedAgent
}
