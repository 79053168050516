export const filerTypes = {
  ALL: 'All',
  YOUR: 'Your',
  UNASSIGNED: 'Unassigned',
  OPEN: 'Open',
  RESOLVED: 'Resolved',
}

export const MessengerPages = {
  INBOX: 0,
  CHAT_LIST: 1,
  CHAT: 2,
  USERINFO: 3,
}

export const filters = [
  {
    label: filerTypes.ALL,
    value: '',
  },
  {
    label: filerTypes.YOUR,
    value: 'Your',
  },
  {
    label: filerTypes.UNASSIGNED,
    value: 'Unassigned',
  },
  {
    label: filerTypes.OPEN,
    value: 'Open',
  },
  {
    label: filerTypes.RESOLVED,
    value: 'Resolved',
  },
]
