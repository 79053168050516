import { makeStyles } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

export const useStyles = makeStyles(theme => ({
  billingTable: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  billingTableHeader: {
    display: isMobile ? 'none' : 'block',
  },
  organizationAddress: {
    display: 'flex',
    flexDirection: 'column',
  },
  website: {
    fontSize: 13,
    color: !isMobile && '#646B77',
    paddingBottom: isMobile ? '5px' : 0,
  },
  name: {
    fontWeight: isMobile ? 'normal' : 'bold',
    fontSize: isMobile ? '14px' : 'inherit',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 20,
  },
  tableContainer: {
    [theme.breakpoints.down('sm')]: {
      width: isMobile ? '100%' : '350px',
      marginTop: 20,
    },
  },
}))
