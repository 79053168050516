import { useConversation } from 'Hooks/useConversation'
import { useAuthContext } from 'Providers/AuthProvider'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { MessageType } from 'Constants/chatConstants'
import { sendFileMessage } from 'Store/Actions'
import { useFileUpload } from 'Hooks/useFileUpload'
import { useSendMessage } from 'Pages/messenger/chat/components/useSendMessage'

const MAX_FILE_SIZE = 15728640 // 15Mb
export const useChatFileUpload = ({ chatScroll }) => {
  const dispatch = useDispatch()
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)
  const conversation = useConversation()
  const { selectedUser } = useSelector(state => state?.chatAppReducer)
  const { currentUser } = useAuthContext()
  const { uploadFile } = useFileUpload()
  const { conversationId } = selectedUser ?? {}
  const { sendMessage } = useSendMessage()

  const fileUploadPath = personalCall ? `/personal-calls/${currentUser.id}` : `/conversations/${conversationId}`

  const handleSendFileMessage = (result, customerChannel) => {
    const file = result.data.files[0]
    const data = {
      file,
      sentAt: Date.now(),
      type: MessageType.FILE,
      downloadUrl: file.downloadUrl,
      sender: 'agent',
    }
    if (!personalCall) {
      dispatch(sendFileMessage(data))
    }
    sendMessage(data, customerChannel)
    chatScroll.current?.scrollToBottom()
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadFile({
        file,
        uploadPath: fileUploadPath,
        maxFileSize: MAX_FILE_SIZE,
        customerChannel: conversation.customerChannel,
        callback: handleSendFileMessage,
      })
    })
  }, [conversationId, conversation])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop,
  })

  return {
    onDrop,
    getRootProps,
    getInputProps,
  }
}
