
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { formatedNumber } from '../utils'
import { Avatar } from '../../../Components/Avatar/Avatar'

const PaymentSummary = ({
  gotoCardCheck,
  storeName,
  storeAddress,
  totalAmount,
  createdAt,
  lineItems,
  storeLogo,
  customerName,
  paymentMethod,
}) => (
  <div id="payment-summary">
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-2 justify-content-center align-self-center">
            <Avatar
              avatarSrc={storeLogo}
              className={classNames(
                'message-avatar',
                'response',
              )}
              userName={storeName}
            />
          </div>
          <div className="col justify-content-center align-self-center">
            <p
              className="text-heading"
              id="var-store-name-summmary"
            >
              {storeName}
            </p>
            <p
              className="text-description"
              id="var-store-address-summmary"
            >
              {storeAddress}
            </p>
          </div>
        </div>
      </div>
      <div className="pcard-body">
        <div className="row">
          <div className="col-6 justify-content-center align-self-center">
            <p className="text-description">Bill To:</p>
          </div>
          <div className="col-6 justify-content-center align-self-center">
            <p className="text-end text-description">Total amount</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6 justify-content-center align-self-center">
            <p className="text-content-highlight">{customerName}</p>
          </div>
          <div className="col-6 justify-content-center align-self-center">
            <p
              className="text-end text-content-highlight"
              id="var-summary-total-price"
            >
              {`$${formatedNumber(totalAmount)}`}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 justify-content-center align-self-center">
            <p
              className="text-end text-description"
              id="var-payment-requested-on"
            >
              {`Payment requested on: ${moment(createdAt).format('MMMM DD, YYYY')}`}
            </p>
          </div>
        </div>
      </div>
      <hr className="hr-no-margin" />
      <div className="pcard-body">
        <div
          className="row"
          id="products"
        >
          {lineItems && lineItems.map(product => (
            <>
              <div className="col-9">
                <p className="text-content">
                  $
                  {product.name}
                </p>
                <p className="text-description">
                  {product.quantity}
                  {' '}
                  x $
                  {formatedNumber(product.price)}
                </p>
              </div>
              <div className="col">
                <p className="text-end text-content">
                  $
                  {formatedNumber(product.quantity * product.price)}
                </p>
              </div>
            </>
          ))}
        </div>
        <hr />
        <div className="row">
          <div className="col-6 justify-content-center align-self-center">
            <p className="text-content-highlight">Total due</p>
          </div>
          <div className="col-6 justify-content-center align-self-center">
            <p
              className="text-end text-content-highlight"
              id="var-summary-total-due"
            >
              {`$${formatedNumber(totalAmount)}`}
            </p>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <button
          className="btn payment_button"
          type="button"
          onClick={gotoCardCheck}
        >
          {paymentMethod === 'credit_card' ? 'Pay with card' : 'Pay with bank transfer'}
        </button>
        <br />
        <br />
        {/* <p className="text-description text-center mx-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Fusce ut placerat orci nulla pellentesque dignissim.</p> */}
      </div>
    </div>
  </div>
)

PaymentSummary.propTypes = {
  storeName: PropTypes.string,
  storeAddress: PropTypes.string,
  totalAmount: PropTypes.string,
  createdAt: PropTypes.string,
  lineItems: PropTypes.array,
  gotoCardCheck: PropTypes.func,
  storeLogo: PropTypes.string,
  customerName: PropTypes.string,
  paymentMethod: PropTypes.string,
}

PaymentSummary.defaultProps = {
  storeLogo: '',
  storeName: '',
  storeAddress: '',
  totalAmount: '',
  createdAt: '',
  customerName: '',
  lineItems: [],
  paymentMethod: '',
  gotoCardCheck: () => {},
}

export default PaymentSummary
