import React from 'react'
import PropTypes from 'prop-types'

const AddNew = ({ onClick }) => (
  <tr>
    <th
      className="expend-column pt-5"
      onClick={onClick}
    >
      <span className="text-primary cursor-pointer">
        <i className="zmdi zmdi-plus pr-1" />
        <span>
          Add line
        </span>
      </span>
    </th>
  </tr>
)

AddNew.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default AddNew
