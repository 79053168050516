import React from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'
import ColorPicker from '../Colors/ColorPicker'

const LauncherColor = ({
  settings,
  onChange,
}) => (
  <div className="launcher-color-container mt-4">
    <div className="row">
      <div className="col-6">
        <ColorPicker
          icon={<GetAppIcon />}
          label="Background color"
          value={settings.launcherBackgroundColor}
          onChange={value => onChange('launcherBackgroundColor', value)}
        />
      </div>
      <div className="col-6">
        <ColorPicker
          icon={<GetAppIcon />}
          label="Icon color"
          value={settings.launcherIconColor}
          onChange={value => onChange('launcherIconColor', value)}
        />
      </div>
    </div>
  </div>
)

export default LauncherColor

LauncherColor.propTypes = {
  settings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
