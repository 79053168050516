import { AssignedAgent } from './AssignedAgent/AssignedAgent'
import { UserInfoBlock } from './UserInfoBlock'
import CallControls from './CallControls/CallControls'
import { UserClientInfo } from './UserClientInfo/UserClientInfo'
import { LastBrowsedPages } from './LastBrowsedPages'
import { PrivateNotes } from '../PrivateNotes/PrivateNotes'
import Ratings from './Ratings'
import './styles.scss'

export const UserInfo = () => (
  <div className="user-info-wrapper">
    <UserInfoBlock />
    <CallControls />
    <Ratings />
    <AssignedAgent />
    <UserClientInfo />
    <LastBrowsedPages />
    <PrivateNotes />
  </div>
)
