import PropTypes from 'prop-types'

export const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  activeQuestionsLength: PropTypes.number.isRequired,
  inactiveQuestionsLength: PropTypes.number.isRequired,
  storeId: PropTypes.string.isRequired,
}
