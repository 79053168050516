import React from 'react'
import PropTypes from 'prop-types'
import { useFieldArray } from 'react-hook-form'

import Item from './Row'
import AddNew from './AddNew'
import TableFooter from './TableFooter'

import './styles.scss'

const PaymentTable = ({
  control,
  errors,
  revalidate,
  onProductInputClick,
}) => {
  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: 'items',
    },
  )

  const handleAdd = () => {
    append({
      name: '', url: '', quantity: 1, price: 0.00,
    })
    revalidate('items')
  }

  const handleRemove = (index) => {
    remove(index)
    revalidate('items')
  }

  return (
    <div className="payment-table-container">
      <table>
        <thead>
          <tr>
            <th className="expend-column label">Item</th>
            <th className="label">Qty</th>
            <th className="label">Rate</th>
            <th className="label">Total Amount</th>
            <th>{' '}</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <Item
              key={field.id}
              control={control}
              errors={errors?.items?.[index]}
              name={`items.${index}`}
              onProductInputClick={type => onProductInputClick(index, type)}
              onRemove={() => handleRemove(index)}
            />
          ))}
          {errors?.items?.message && (
            <tr>
              <td colSpan={5}>
                <span className="error-message">{errors?.items?.message}</span>
              </td>
            </tr>
          )}
          <AddNew onClick={handleAdd} />
        </tbody>
        <tfoot>
          <TableFooter control={control} />
        </tfoot>
      </table>
    </div>
  )
}

PaymentTable.propTypes = {
  errors: PropTypes.shape({
    items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  revalidate: PropTypes.func.isRequired,
  onProductInputClick: PropTypes.func.isRequired,
}

export default PaymentTable
