export const organizationHeader = [
  {
    title: 'Organization name',
    field: 'name',
  },
  {
    title: 'NMG ID',
    field: 'nmgId',
  },
  {
    title: 'Address',
    field: 'address',
  },
  {
    title: 'Status',
    isSorting: false,
  },
  {
    title: 'Organization administrator name',
    isSorting: false,
  },
  {
    title: 'Organization administrator email',
    isSorting: false,
  },
  {
    id: 7,
    title: 'Actions',
    field: 'actions',
    isSorting: false,
  },
]
