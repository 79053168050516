import {
  FormGroup,
  Label,
} from 'reactstrap'
import { Controller } from 'react-hook-form'
import { isMobile } from 'react-device-detect'
import { Checkbox } from 'Pages/settings/components/Checkbox'
import PropTypes from 'prop-types'

export const Notifications = ({ control }) => (
  <section className="profile-row d-flex no-border">
    <div className={isMobile ? 'change-password-description w-100 d-flex flex-column' : 'change-password-description w-30 d-flex flex-column'}>
      <span className="profile-label">Incoming customer inquiry alerts</span>
    </div>
    <div className="change-password-fields w-50">
      <div className="new-password">
        <FormGroup className="mb-15">
          <Label>
            <span className="ti-bell" />
            <span className="profile-label pl-5">Receive alerts on</span>
          </Label>
          <div className="d-flex flex-column pl-10">
            <Controller
              control={control}
              name="browserNotificationStatus"
              render={({
                field: {
                  onChange, onBlur, value, ref,
                },
              }) => (
                <Checkbox
                  checked={value}
                  inputRef={ref}
                  title="Browser notification"
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="smsNotificationStatus"
              render={({
                field: {
                  onChange, onBlur, value, ref,
                },
              }) => (
                <Checkbox
                  checked={value}
                  inputRef={ref}
                  title="Text messages"
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="emailNotificationStatus"
              render={({
                field: {
                  onChange, onBlur, value, ref,
                },
              }) => (
                <Checkbox
                  checked={value}
                  inputRef={ref}
                  title="Email notification"
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </FormGroup>
      </div>
    </div>
  </section>
)

Notifications.propTypes = {
  control: PropTypes.PropTypes.shape({}),
}

Notifications.defaultProps = {
  control: null,
}
