import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  Button,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import Input from 'Components/common/Input'
import { useApolloMutation } from 'GraphQL/apollo'
import { toast } from 'react-toastify'
import { SEND_EMAIL } from 'GraphQL/mutations/widget'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { validationSchema } from './validationSchema'
import './styles.scss'

export const DevelopersInstructions = ({ nmgId }) => {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const createInstructions = () => {
    const div = `${`<div id="primeworks-customer-widget" nmg-id="${nmgId}"></div>`
      .replace(/</g, '&lt;').replace(/>/g, '&gt;') }<br>`
    const script = `${'<script defer src="https://widget.nmgservices.org/lib/build.widget.min.js?1b21612f13d4ac4d5a7c559e06eb8f0e517aaa59"></script>'
      .replace(/</g, '&lt;').replace(/>/g, '&gt;') }<br>`
    const body = '<body></body>'.replace(/</g, '&lt;').replace(/>/g, '&gt;')

    return `
1) Copy the code below:<br>
<br>
${div}
${script}
<br>
2) Open your application source code<br>
3) In the source code, find and open the index.html file<br>
4) Paste the copied code in ${body} tag. Please mind that install code should be inside "body" tag.<br>
Note: Widget will not affect your application styles.<br>
    `
  }

  const [sendEmail] = useApolloMutation(SEND_EMAIL, {
    onCompleted(data) {
      toast.success(`Email was sent to the ${data.SendEmail.recipient}`)
      setIsEmailSent(true)
    },
  })

  const onSubmit = (data) => {
    sendEmail({
      variables: {
        type: 'instructions',
        recipient: [data.email],
        payload: { code: createInstructions() },
      },
    })
  }

  return (
    <section className="page-row d-flex">
      <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-30 d-flex flex-column'}>
        <span className="settings-label">Email instructions to your developer</span>
        <span className="settings-label-description">
          Send instructions to developer
        </span>
      </div>
      <div className={isMobile ? 'd-flex flex-column justify-content-between w-100 mb-10' : 'd-flex flex-column justify-content-between w-40 mb-10'}>
        <div>
          <span className="ti-email mr-2" />
          <span className="profile-label pl-5">Developer email</span>
        </div>
        <form
          className="w-100 d-flex justify-content-between align-bottom"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            className="mt-10"
            control={control}
            error={errors.email}
            errorMessage={errors.email?.message}
            name="email"
            placeholder="mail@gmail.com"
            wrapperClassName="m-0 w-75"
            fullWidth
          />
          <Button
            className={classNames('send-button', { 'color-green': isEmailSent })}
            color="secondary"
            type="submit"
            outline
          >
            <i className="zmdi zmdi-mail-send" />
            {' '}
            Send
          </Button>
        </form>
      </div>
    </section>
  )
}

DevelopersInstructions.propTypes = {
  nmgId: PropTypes.string.isRequired,
}
