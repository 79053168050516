import { useAuthContext } from 'Providers/AuthProvider'
import { useFirebaseContext } from 'Providers/FirebaseProvider'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { MessageContentType } from 'Constants/chatConstants'

import { APPEND_MESSAGE, SEND_EMAIL_MESSAGE, SEND_SMS_MESSAGE } from 'GraphQL/mutations/chat'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { CONVERSATION_CHANNELS } from 'Pages/messenger/chat/components/config'

export const useSendMessage = () => {
  const { db } = useFirebaseContext()
  const { currentUser } = useAuthContext()
  const rootPath = db.ref()

  const callMessagesPath = rootPath.child(
    `organizations/${currentUser?.accessList[0].organization}/personal-calls/${currentUser.personalCallId}/messages`,
  )
  const { selectedUser } = useSelector(state => state?.chatAppReducer)
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)

  const sendToPersonalCall = data => callMessagesPath.push().set(data)
  const {
    conversationId,
    id,
    customerChannel,
    email,
    phone,
  } = selectedUser ?? {}
  const activeConversationChannel = customerChannel ?? CONVERSATION_CHANNELS[0].value

  const [sendMessageMutation] = useApolloMutation(APPEND_MESSAGE, {
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [sendEmailMessageMutation] = useApolloMutation(SEND_EMAIL_MESSAGE, {
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [sendSMSMessageMutation] = useApolloMutation(SEND_SMS_MESSAGE, {
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const sendWebMessage = (data) => {
    if (personalCall) {
      sendToPersonalCall(data)
      return
    }
    sendMessageMutation({
      variables: {
        contentType: data.contentType || MessageContentType.PLAIN,
        conversation: conversationId,
        type: data.type,
        channel: 'backoffice',
        content: data.content || '',
        ...(data.file && {
          attachments: [{
            name: data.file.name,
            url: data.file.url,
            contentType: data.file.contentType,
            downloadUrl: data.file.downloadUrl,
          }],
        }),
      },
    })
  }

  const requestData = ({
    conversation: conversationId,
    client: id,
  })

  const sendEmailMessage = (data) => {
    sendEmailMessageMutation({
      variables: {
        ...requestData,
        type: 'message',
        recipient: email,
        content: data.content || '',
        contentType: data.contentType || MessageContentType.PLAIN,
        ...(data.file && {
          attachments: [{
            name: data.file.name,
            url: data.file.url,
            contentType: data.file.contentType,
            downloadUrl: data.file.downloadUrl,
          }],
        }),
      },
    })
  }

  const sendSMSMessage = ({ content, contentType }) => {
    sendSMSMessageMutation({
      variables: {
        ...requestData,
        recipient: [`+1${phone}`],
        content,
        contentType: contentType || MessageContentType.PLAIN,
      },
    })
  }

  const sendMessage = (data, conversationChannel) => {
    if (personalCall) {
      return sendWebMessage(data)
    }
    switch (conversationChannel) {
      case 'chat':
        return sendWebMessage(data)
      case 'email':
        return sendEmailMessage(data)
      case 'sms':
        return sendSMSMessage(data)
      default:
        return sendWebMessage(data)
    }
  }

  return {
    sendMessage,
    activeConversationChannel,
  }
}
