import React from 'react'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { Timepicker } from 'Components/common/Timepicker'

export const Break = ({
  control,
  permission,
  name,
  errors,
  handleRemove,
}) => {
  const {
    field: fieldFrom,
  } = useController({
    name: `${name}.from`,
    control,
  })
  const {
    field: fieldTo,
  } = useController({
    name: `${name}.to`,
    control,
  })
  return (
    <div className="picker-wrapper mb-1">
      <Timepicker
        {...fieldFrom}
        error={!!errors?.from}
        errorMessage={errors?.from?.message}
        name={`${name}.from`}
        readOnly={!permission}
      />
      <span className="mt-2 pt-1">-</span>
      <Timepicker
        {...fieldTo}
        error={!!errors?.to}
        errorMessage={errors?.to?.message}
        name={`${name}.to`}
        readOnly={!permission}
      />
      <IconButton
        aria-label="delete"
        className="action-btn"
        disabled={!permission}
        size="small"
        onClick={handleRemove}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  )
}

Break.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape(),
  permission: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

Break.defaultProps = {
  errors: {},
}
