import { gql } from '@apollo/client'

export const CREATE_WORKFLOW = gql`
mutation CreateWorkflow($organization: String!, $name: String!, $triggers: [WorkflowTriggerInput!]) {
  CreateWorkflow(organization: $organization, name: $name, triggers: $triggers) {
    id
    organization
    name
    triggers {
      conditions {
        type
        value
        operator
      }
      actions {
        type
        questionFeedback
        questionInfo {
          type
          title
          description
          min
          max
          resultOptions
        }
      }
    }
    active
    deleted
    createdAt
    updatedAt
  }
}
`

export const UPDATE_WORKFLOW = gql`
mutation UpdateWorkflow($id: ID!, $name: String, $triggers: [WorkflowTriggerInput!]) {
  UpdateWorkflow(id: $id, name: $name, triggers: $triggers) {
    id
    organization
    name
    triggers {
      conditions {
        type
        value
        operator
      }
      actions {
        type
        questionFeedback
        questionInfo {
          type
          title
          description
          min
          max
          resultOptions
        }
      }
    }
    active
    deleted
    createdAt
    updatedAt
  }
}
`
export const DELETE_WORKFLOW = gql`
mutation DeleteWorkflow($id: ID!) {
  DeleteWorkflow(id: $id) {
    id
    organization
    name
    active
    deleted
    createdAt
    updatedAt
  }
}
`
export const RECOVER_WORKFLOW = gql`
mutation RecoverWorkflow($id: ID!) {
  RecoverWorkflow(id: $id) {
    id
    organization
    name
    active
    deleted
    createdAt
    updatedAt
  }
}
`
