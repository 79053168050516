import React from 'react'
import PropTypes from 'prop-types'

import { Redirect, Route, Switch } from 'react-router-dom'
import { PeerProvider } from 'Providers/PeerProvider/PeerProvider'
import { CallSettingsProvider } from 'Providers/CallSettingsProvider'
import classNames from 'classnames'
import WaitingRoom from './waiting-room'
import VideoSpaceRoom from './video-space-room'
import { useDeviceVirtualHeight } from '../../Hooks/useDeviceVirtualHeight'
import { getDeviceTypeClassName } from '../../Helpers/helpers'

const VideoCall = ({ match }) => {
  useDeviceVirtualHeight()

  const deviceType = getDeviceTypeClassName()

  return (
    <CallSettingsProvider>
      <PeerProvider>
        <div className={classNames(deviceType, 'video-call-wrapper')}>
          <Switch>
            <Redirect
              from={`${match.url}/`}
              to={`${match.url}/waiting-room`}
              exact
            />
            <Route
              component={WaitingRoom}
              path={`${match.url}/waiting-room/:personalCallId?`}
            />
            <Route
              component={VideoSpaceRoom}
              path={`${match.url}/video-space-room`}
            />
          </Switch>
        </div>
      </PeerProvider>
    </CallSettingsProvider>
  )
}

VideoCall.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      personalCallId: PropTypes.string,
    }),
  }).isRequired,
}

export default VideoCall
