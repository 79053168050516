export const useDirtyFields = (watchFields, dirtyFields, getValues) => {
  let values = {}

  watchFields.forEach((field) => {
    if (dirtyFields[field.key]) {
      values = {
        ...values,
        [field.field]: getValues(field.key),
      }
    }
  })

  return values
}
