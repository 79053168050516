import React from 'react'
import './styles.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const Avatar = ({
  avatarSrc,
  status,
  size,
  userName,
}) => {
  const [firstName, lastName] = userName ? userName.split(' ') : ['&', '']
  const userAbbreviation = `${firstName?.charAt(0)}${lastName?.charAt(0) ?? ''}`

  return (
    <div className={classNames('avatar-block', [size])}>
      <div className={classNames('avatar', [size])}>
        {avatarSrc && (
          <img
            alt="user profile"
            className={classNames('avatar-img', [size])}
            src={avatarSrc}
          />
        )}
        {!avatarSrc && (
          <span className={classNames('user-abbreviation', [size])}>
            {userAbbreviation}
          </span>
        )}
        {status && <span className={classNames('status', [status])} /> }
      </div>
    </div>
  )
}

Avatar.propTypes = {
  status: PropTypes.string,
  size: PropTypes.string,
  userName: PropTypes.string,
  avatarSrc: PropTypes.string,
}

Avatar.defaultProps = {
  status: null,
  size: 'md',
  avatarSrc: '',
  userName: '',
}
