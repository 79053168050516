import { removeDuplicates } from 'Helpers/removeDuplicates'
import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useStyles } from 'Pages/messenger/chat/components/Inbox/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthContext } from 'Providers/AuthProvider'
import { setActiveFilter } from 'Store/Actions'
import { filterUserChat } from 'Providers/conversation/helpers'
import { useStoresContext } from 'Providers/StoresProvider'
import { filters } from './config'

export const MessagesFilters = () => {
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { recentChatUsers, selectedChatFilter } = chatAppReducer
  const { currentUser } = useAuthContext()
  const dispatch = useDispatch()
  const { selectedStores } = useStoresContext()

  const storeUsers = useMemo(() => {
    const users = removeDuplicates(recentChatUsers)

    return users.filter(user => selectedStores.includes(user.storeId))
  }, [recentChatUsers, selectedStores])

  const filterChatCount = useCallback(type => filterUserChat({
    type,
    recentChatUsers: storeUsers,
    currentUser,
  })?.length, [storeUsers])

  const classes = useStyles()
  return (
    <div className={classes.filtersWrapper}>
      {filters.map(filter => (
        <div
          key={filter.label}
          className={classNames(
            classes.filterRow,
            selectedChatFilter === filter.label && classes.active,
          )}
          onClick={() => dispatch(setActiveFilter(filter.label))}
        >
          <span>{filter.label}</span>
          <span className={classes.messagesCount}>{filterChatCount(filter.label)}</span>
        </div>
      ))}
    </div>
  )
}
