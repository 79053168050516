import { React } from 'react'
import PropTypes from 'prop-types'
import { Info, Check, Close } from '@material-ui/icons'

import { useConversation } from 'Hooks/useConversation'
import { MessageContentType } from 'Constants/chatConstants'

import './styles.scss'

const InfoRequestPopover = ({
  onClose, onProceed, onSubmitMessage,
}) => {
  const conversation = useConversation()
  const { name, email, phone } = conversation?.customer || {}

  const onRequest = async (requestType) => {
    let content = requestType

    if (requestType === 'all') {
      const missingItems = []

      if (!name) missingItems.push('name')
      if (!email) missingItems.push('email')
      if (!phone) missingItems.push('phone')

      content = missingItems.join(',')
    }

    const paymentMessage = {
      content,
      contentType: MessageContentType.INFORMATION_REQUEST,
    }

    await onSubmitMessage(null, paymentMessage)
    onClose()
  }

  const onSend = () => {
    onProceed()
    onClose()
  }

  return (
    <div className="info-request-popover-container">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Info
            className="icon-lg"
            htmlColor="#ff7a5a"
          />
        </div>
        <i
          className="zmdi zmdi-close icon icon-cl"
          onClick={onClose}
        />
      </div>
      <p>
        Looks like you&apos;re trying to send a payment request link, but some customer
        information is missing. Please request missing information, or fill it in
        manually
      </p>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center">
          {name ? (
            <Check
              className="icon-sm"
              htmlColor="#1bc47d"
            />
          ) : (
            <Close
              className="icon-sm"
              htmlColor="#ff7a5a"
            />
          )}
          <span>Full name</span>
        </div>
        {!name && (
          <span
            className="text-primary"
            onClick={() => onRequest('name')}
          >
            Request
          </span>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center">
          {email ? (
            <Check
              className="icon-sm"
              htmlColor="#1bc47d"
            />
          ) : (
            <Close
              className="icon-sm"
              htmlColor="#ff7a5a"
            />
          )}
          <span>Email address</span>
        </div>
        {!email && (
          <span
            className="text-primary"
            onClick={() => onRequest('email')}
          >
            Request
          </span>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center">
          {phone ? (
            <Check
              className="icon-sm"
              htmlColor="#1bc47d"
            />
          ) : (
            <Close
              className="icon-sm"
              htmlColor="#ff7a5a"
            />
          )}
          <span>Phone number</span>
        </div>
        {!phone && (
          <span
            className="text-primary"
            onClick={() => onRequest('phone')}
          >
            Request
          </span>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center w-100 pt-3">
        <span
          className="text-primary"
          onClick={() => onRequest('all')}
        >
          Request all missing information
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center w-100 pt-3">
        <span
          className="text-primary"
          onClick={onSend}
        >
          Proceed Anyway
        </span>
      </div>
    </div>
  )
}

InfoRequestPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onSubmitMessage: PropTypes.func.isRequired,
}

export default InfoRequestPopover
