import React, { useEffect, useState } from 'react'
import { useApolloMutation, useApolloQuery } from 'GraphQL/apollo'
import CheckIcon from '@material-ui/icons/Check'
import { useForm } from 'react-hook-form'
import { GET_NOTES_QUERY } from 'GraphQL/queries/privateNotes'
import Input from 'Components/common/Input'
import { CREATE_NOTE } from 'GraphQL/mutations/privateNotes'
import { useAuthContext } from 'Providers/AuthProvider'
import { useConversationPath } from 'Hooks/useConversationPath'
import { PrivateNote } from './PrivateNote'
import { CollapsedBlock } from '../UserInfo/CollapsedBlock/CollapsedBlock'

export const PrivateNotes = () => {
  const [notes, setNotes] = useState(null)
  const [isOpen, setIsOpen] = useState(true)
  const { currentUser } = useAuthContext()
  const { conversationId } = useConversationPath()

  const getNotesParams = {
    conversation: conversationId,
    userId: currentUser.id,
    pagination: {
      offset: 0,
      size: 100,
    },
    sorting: {
      field: 'updatedAt',
      direction: 'DESC',
    },
  }

  const { data, refetch: refetchNotes } = useApolloQuery(GET_NOTES_QUERY, {
    variables: getNotesParams,
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const [createNote] = useApolloMutation(CREATE_NOTE, {
    onCompleted() {
      refetchNotes()
      setValue('note', '')
    },
  })

  const title = notes?.length > 0 ? `Private notes (${notes.length})` : 'Private Notes'

  useEffect(() => {
    const result = data?.Notes?.items
    if (result) setNotes(result)
  }, [data?.Notes])

  const onSubmit = ({ note }) => {
    if (note) {
      createNote({
        variables: {
          conversation: conversationId,
          userId: currentUser.id,
          body: note,
        },
      })
    }
  }

  const handleSetIsOpen = () => setIsOpen(prevState => !prevState)

  return (
    <div
      className="p-2 pb-4"
    >
      <CollapsedBlock
        isOpen={isOpen}
        title={title}
        toggle={handleSetIsOpen}
      >
        <form
          className="mt-2"
          onBlur={handleSubmit(onSubmit)}
        >
          <Input
            control={control}
            endAdornment={(
              <div className="send-note-icon">
                <CheckIcon />
              </div>
            )}
            error={errors.note}
            errorMessage={errors.note?.message}
            inputProps={{ maxLength: 255 }}
            name="note"
            placeholder="Add a note..."
            wrapperClassName="m-0"
            fullWidth
            multiline
          />
        </form>
        <div className="d-flex justify-content-end align-top">
          <span>255 characters</span>
        </div>
        <div className="notes-container">
          {
            notes?.map(note => (
              <PrivateNote
                key={note.id}
                body={note.body}
                id={note.id}
                lastTimeTouched={note.updatedAt}
                refetch={refetchNotes}
              />
            ))
          }
        </div>
      </CollapsedBlock>
    </div>
  )
}
