import { combineReducers } from 'redux'
import settings from './settings'
import sidebarReducer from './SidebarReducer'
import chatAppReducer from './ChatAppReducer'
import chatHeader from './ChatHeader'

const reducers = combineReducers({
  settings,
  chatAppReducer,
  chatHeader,
  sidebar: sidebarReducer,
})

export default reducers
