import React from 'react'
import './styles.scss'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { SearchInput } from 'Components/common/SearchInput'

export const AgentsToolbar = ({ handleSearch, isUserHasAddAgentPermission, handleOpenInviteAgentModal }) => (
  <div className="page-toolbar">
    <section className="search-and-filter">
      <SearchInput onChange={handleSearch} />
    </section>
    <Button
      className="btn-custom"
      disabled={!isUserHasAddAgentPermission}
      onClick={handleOpenInviteAgentModal}
    >
      <i className="zmdi zmdi-account-add" />
      {' '}
      <span>Invite agents</span>
    </Button>
  </div>
)

AgentsToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  isUserHasAddAgentPermission: PropTypes.bool.isRequired,
  handleOpenInviteAgentModal: PropTypes.func.isRequired,
}

AgentsToolbar.defaultProps = {
}
