/* eslint-disable no-magic-numbers */
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Scrollbars } from 'react-custom-scrollbars'

// redux actions
import { collapsedSidebarAction } from 'Store/Actions'
import { isMobile } from 'react-device-detect'
import SidebarContent from './SidebarContent'

class Sidebar extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions()
  }

  shouldComponentUpdate(nextProps) {
    const {
      enableSidebarBackgroundImage, selectedSidebarImage, isDarkSidenav, locale,
    } = this.props
    if (enableSidebarBackgroundImage !== nextProps.enableSidebarBackgroundImage
      || selectedSidebarImage !== nextProps.selectedSidebarImage
      || isDarkSidenav !== nextProps.isDarkSidenav || locale) {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const { state, props } = this
    const { windowWidth } = state

    if (prevProps.location !== props.location) {
      if (windowWidth <= 1199) {
        props.collapsedSidebarAction(false)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

updateDimensions = () => {
  this.setState({ windowWidth: window.innerWidth })
}

render() {
  const { enableSidebarBackgroundImage, isDarkSidenav, toggleSlider } = this.props
  return (
    <>
      <div className={classNames('rct-sidebar', { 'background-none': !enableSidebarBackgroundImage })}>
        <div className={classNames('rct-sidebar-content', {
          'sidebar-overlay-dark': isDarkSidenav,
          'sidebar-overlay-light': !isDarkSidenav,
          'sidebar-no-opacity': isMobile,
        })}
        >
          <div className="rct-sidebar-head">
            <div className="site-logo">
              <NavLink
                className="logo-mini"
                to="/"
              >
                <img
                  alt="logo"
                  className="logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo/main-logo-light.png`}
                  style={{
                    width: isMobile ? '50%' : '100%',
                  }}
                />
              </NavLink>
            </div>
            <div
              className="close-slider"
              onClick={() => toggleSlider(false)}
            >
              <i className="ti-close" />
            </div>
          </div>
          <div className="rct-sidebar-wrap">
            <Scrollbars
              autoHideDuration={100}
              className="rct-scroll"
              style={{ height: 'calc(100vh - 60px)' }}
              autoHide
            >
              <SidebarContent toggleSlider={toggleSlider} />
            </Scrollbars>
          </div>
        </div>
      </div>
    </>
  )
}
}

const mapStateToProps = ({ settings }) => {
  const {
    enableSidebarBackgroundImage, selectedSidebarImage, collapsedSidebar, isDarkSidenav, locale,
  } = settings
  return {
    enableSidebarBackgroundImage, selectedSidebarImage, collapsedSidebar, isDarkSidenav, locale,
  }
}

Sidebar.propTypes = {
  enableSidebarBackgroundImage: PropTypes.bool.isRequired,
  selectedSidebarImage: PropTypes.string.isRequired,
  isDarkSidenav: PropTypes.bool.isRequired,
  locale: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  collapsedSidebarAction: PropTypes.func.isRequired,
  toggleSlider: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, {
  collapsedSidebarAction,
})(Sidebar))
