import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Mic from '@material-ui/icons/Mic'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined'
import VideocamOutlined from '@material-ui/icons/VideocamOutlined'
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined'
import { isMobileOnly } from 'react-device-detect'

import { useCallSettingsContext } from 'Providers/CallSettingsProvider'
import { usePeerContext } from 'Providers/PeerProvider/PeerProvider'
import { useAuthContext } from 'Providers/AuthProvider'
import { useWaitingRoom } from 'Pages/video-call/waiting-room/useWaitingRoom'
import { useMediaDevices } from 'Pages/video-call/waiting-room/useMediaDevices'
import { SettingsModal } from './SettingsModal/SettingsModal'

const useStyles = makeStyles(() => ({
  root: props => ({
    backgroundColor: props.active ? 'transparent' : 'red',
    color: 'white',
    border: props.active ? '1px solid white' : '',
    height: 55,
    fontSize: 15,
    borderRadius: 30,
  }),
}))

const WaitingRoom = () => {
  const { currentUser } = useAuthContext()

  const {
    isSettingsModalOpen,
    triggerSettingsModal,
    joinToCall,
    toggleMicrophone,
    toggleCamera,
  } = useWaitingRoom()
  const { cameras, microphones, setMediaDevices } = useMediaDevices()

  const mediaPermissionsDenied = (Boolean(cameras.length) && (!cameras[0].deviceId || !cameras[0].label))
    || (Boolean(microphones.length) && (!microphones[0].deviceId || !microphones[0].label))

  const {
    isMicrophoneEnabled,
    isCameraEnabled,
  } = useCallSettingsContext()

  const {
    setVideoDeviceId,
    mediaStream,
    localVideoRef,
    isConnected,
  } = usePeerContext()

  useEffect(() => {
    if (mediaStream || mediaPermissionsDenied) {
      setMediaDevices()
    }
  }, [mediaStream, mediaPermissionsDenied])

  const styledCamera = useStyles({ active: isCameraEnabled })
  const styledMic = useStyles({ active: isMicrophoneEnabled })
  const styledSettings = useStyles({ active: true })

  const joinButtonText = isConnected ? 'Join now' : 'Connecting'

  return (
    <div className={cn('waiting-room-wrapper d-flex justify-content-center align-items-center', {
      'waiting-room-wrapper-mobile': isMobileOnly,
    })}
    >
      <div className="waiting-screen d-flex flex-column align-items-center">
        <>
          {isMobileOnly && (
            <div className={cn('room-call-header', { 'is-blurred': isCameraEnabled })}>
              <span
                className="slider-toggle ti-arrow-left drag-mark"
                onClick={() => { window.history.back() }}
              >
                &nbsp;&nbsp;Back
              </span>
            </div>
          )}
          {!mediaPermissionsDenied && (<span className="title-question fw-bold pb-10">Ready to join?</span>)}
          <section className="settings-screen position-relative">
            {!mediaPermissionsDenied && (
              <>
                <div className="settings-screen-camera d-flex flex-column justify-content-center align-items-center">
                  {!isCameraEnabled && isMobileOnly && (
                    <div className="camera-disabled">
                      <div>
                        <img
                          alt="avatar"
                          src={currentUser.avatar}
                        />
                        <b>{currentUser.name}</b>
                        <span>{ isMicrophoneEnabled ? <Mic /> : <MicOffOutlinedIcon /> }</span>
                        <span><VideocamOffOutlinedIcon /></span>
                      </div>
                    </div>
                  )}
                  <video
                    ref={localVideoRef}
                    className="video-preview"
                    autoPlay
                    muted
                    playsInline
                  />
                </div>
                <div className="settings-controls d-flex justify-content-center">
                  {isCameraEnabled && isMobileOnly && <div className="settings-controls-blurred" />}
                  <Fab
                    className={styledSettings.root}
                    variant="extended"
                    onClick={triggerSettingsModal}
                  >
                    <SettingsOutlinedIcon />
                    {!isMobileOnly ? <span className="button-text">Settings</span> : null}
                  </Fab>
                  {isMobileOnly && <div className="settings-controls-delimiter" />}
                  <Fab
                    className={cn('mx-20', styledMic.root)}
                    disabled={!mediaStream}
                    onClick={toggleMicrophone}
                  >
                    { isMicrophoneEnabled ? <Mic /> : <MicOffOutlinedIcon /> }
                  </Fab>
                  <Fab
                    className={styledCamera.root}
                    disabled={!mediaStream}
                    onClick={toggleCamera}
                  >
                    { isCameraEnabled ? <VideocamOutlined /> : <VideocamOffOutlinedIcon />}
                  </Fab>
                </div>
              </>
            )}
            {mediaPermissionsDenied && (
              <div className="waiting-screen-error">
                <span>
                  Сannot access your microphone and camera - please give permission in your browser setting.
                </span>
              </div>
            )}
          </section>
        </>
        <section className="customer-section">
          <div className="pt-30 pb-30 d-flex justify-content-center">
            <Button
              className="btn-join-now"
              disabled={mediaPermissionsDenied}
              onClick={joinToCall}
            >
              {joinButtonText}
            </Button>
          </div>
        </section>
      </div>
      <SettingsModal
        closeModal={triggerSettingsModal}
        isOpen={isSettingsModalOpen}
        setVideoDeviceId={setVideoDeviceId}
        videoElementRef={localVideoRef}
      />
    </div>
  )
}

export default WaitingRoom
