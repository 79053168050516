export const styles = {
  root: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    border: '1px solid #D8DADD',
    borderRadius: 4,
    color: '#0D172A',
  },
  error: {
    borderColor: '#FF4545',
    color: '#FF4545',
  },
}

export const muiStyles = {
  root: {
    padding: '2px 40px 2px 6px !important',
  },
  error: {
    color: '#FF4545',
  },
  notchedOutline: {
    border: 'none',
  },
}
