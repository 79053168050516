import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import ThemeProvider from 'Providers/ThemeProvider'
import AuthProvider from 'Providers/AuthProvider'
import { SignInPage } from 'Pages/SignInPage/SignInPage'
import { ForgotPassword } from 'Pages/forgotPassword'
import { ResetPassword } from 'Pages/resetPassword'
import { CreateProfile } from 'Pages/createProfile'
import { ReviewsPage } from 'Pages/review'
import { PaymentPage } from 'Pages/payment'
import PropTypes from 'prop-types'
import VideoCall from 'Pages/video-call'
import ErrorBoundary from 'Components/ErrorBoundary'
import FirebaseProvider from 'Providers/FirebaseProvider'
import { StoresProvider } from 'Providers/StoresProvider'
import { AgentStatusProvider } from 'Providers/AgentStatusProvider'

import {
  ADMIN_SIGNUP_PATH,
  ADMIN_LOGIN_PATH,
  APP_PATH,
  LOGIN_PATH,
  VIDEO_CALL_PATH,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CREATE_PROFILE,
  SIGNUP_PATH,
  REVIEWS,
  CHECKOUT,
} from 'Constants/routerPaths'
import DefaultLayout from './DefaultLayout'

function App({ location }) {
  if (location.pathname === '/') {
    return (<Redirect to={APP_PATH} />)
  }
  return (
    <ThemeProvider>
      <AuthProvider>
        <StoresProvider>
          <FirebaseProvider>
            <AgentStatusProvider>
              <ErrorBoundary>
                <Route
                  component={DefaultLayout}
                  path={APP_PATH}
                />
                <Route
                  component={VideoCall}
                  path={VIDEO_CALL_PATH}
                />
                <Route
                  component={SignInPage}
                  path={[LOGIN_PATH, ADMIN_SIGNUP_PATH, ADMIN_LOGIN_PATH, SIGNUP_PATH]}
                />
                <Route
                  component={ForgotPassword}
                  path={FORGOT_PASSWORD}
                />
                <Route
                  component={ResetPassword}
                  path={RESET_PASSWORD}
                />
                <Route
                  component={CreateProfile}
                  path={CREATE_PROFILE}
                />
                <Route
                  component={PaymentPage}
                  path={CHECKOUT}
                />
                <Route
                  component={ReviewsPage}
                  path={REVIEWS}
                />
              </ErrorBoundary>
            </AgentStatusProvider>
          </FirebaseProvider>
        </StoresProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default App
