import { useApolloMutation, useApolloLazyQuery, useApolloQuery } from 'GraphQL/apollo'
import { ROLE_TYPES } from 'Constants/rolesConstants'
import {
  CONFIRM_MEMBER_NET_AUTHENTICATION,
  CREATE_MEMBER_NET_AUTHENTICATION,
  LOGIN,
} from 'GraphQL/mutations/user'
import { GET_CURRENT_USER } from 'GraphQL/queries/users'
/*eslint-disable*/
import PropTypes from 'prop-types'
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { Storage } from 'Services/store-service'
import {
  APP_PATH,
  SETTINGS_PATH,
  AGENT_PROFILE_PATH,
  LOGIN_PATH,
  ADMIN_LOGIN_PATH,
  CREATE_PROFILE,
  HOME_PATH,
} from 'Constants/routerPaths'
import { getLocationHashValue, getParamsFromLocation } from 'Helpers/helpers'
import { toast } from 'react-toastify'
import { REFRESH_TOKEN, USER } from 'Constants/common'
import { GET_ORGANIZATION_QUERY } from "../GraphQL/queries/organization";

const AuthContext = createContext({
  login: () => {},
  logout: () => {},
  loginWithMemberNet: () => {},
  isAuth: () => {},
  getToken: () => {},
  getNmgId: () => {},
  getOrganizationId: () => {},
  getOrganization: () => {},
  currentUser: null,
  isPlatformAdmin: null,
  isPlatformAnalyst: null,
  isRetailerAdmin: null,
  authError: null,
  refetchUser: () => {},
  setCurrentUser: () => {},
  getRefreshToken: () => {},
  updateOrganization: () => {},
})

const invalidCredentialsErrorText = 'Please check your e-mail and password to sign in'
const invalidCredentialsServerErrorText = 'Email / Password invalid'
export const useAuthContext = () => useContext(AuthContext)

const getUserRole = (accessList) => {
  const isPlatformAdmin = accessList.find(accessListItem => accessListItem.roles.includes(ROLE_TYPES.PLATFORM_ADMIN))
  const isPlatformAnalyst = accessList.find(accessListItem => accessListItem.roles.includes(ROLE_TYPES.PLATFORM_ANALYST))
  const isRetailerAdmin = accessList.find(accessListItem => accessListItem.roles.includes(ROLE_TYPES.RETAILER_ADMIN))

  if(isPlatformAnalyst) {
    return [ROLE_TYPES.PLATFORM_ANALYST]
  }

  if(isPlatformAdmin) {
    return [ROLE_TYPES.PLATFORM_ADMIN]
  }

  if(isRetailerAdmin) {
    return [ROLE_TYPES.RETAILER_ADMIN]
  }

  return [ROLE_TYPES.RETAILER]
}

const AuthProvider = ({ children }) => {
  const history = useHistory()

  const setToken = token => Storage.local.set('accessToken', token)
  const getToken = () => Storage.local.get('accessToken')
  
  const setRefreshToken = refreshToken => Storage.local.set(REFRESH_TOKEN, refreshToken)
  const getRefreshToken = () => Storage.local.get(REFRESH_TOKEN)
  
  const getUserData = user => ({
    ...user,
    roles: getUserRole(user.accessList),
  })
  const setCurrentUser = (user) => {
    const $currentUser = getUserData(user)
    Storage.local.set(USER, $currentUser)
  }
  const getCurrentUser = () => Storage.local.get(USER)

  const getOrganizationId = () => {
    const user$ = getCurrentUser()
    return user$?.accessList[0].organization
  }

  const currentUser = getCurrentUser()

  const isPlatformAdminLogin = window.location.pathname === ADMIN_LOGIN_PATH
  const isPlatformAdmin = currentUser?.roles.includes(ROLE_TYPES.PLATFORM_ADMIN)
  const isRetailerAdmin = currentUser?.roles.includes(ROLE_TYPES.RETAILER_ADMIN)
  const isPlatformAnalyst = currentUser?.roles.includes(ROLE_TYPES.PLATFORM_ANALYST)
  const [authError, setAuthError] = useState('')
  const [orgData, setOrgData] = useState(null)

  const setUser = (user) => {
    const { isOnboarded } = user
    const formattedUser = getUserData(user)
    const isPlatformAdminOrAnalyst$ = formattedUser?.roles.some(role => role === ROLE_TYPES.PLATFORM_ADMIN || role === ROLE_TYPES.PLATFORM_ANALYST)

    
    if ((isPlatformAdminLogin && !isPlatformAdminOrAnalyst$) || (!isPlatformAdminLogin && isPlatformAdminOrAnalyst$)) {
      setAuthError(invalidCredentialsErrorText)
      return
    }

    setCurrentUser(user)
    
    if (!isOnboarded && !isPlatformAdminOrAnalyst$) {
      history.push(CREATE_PROFILE)
    } else {
      history.push(APP_PATH + SETTINGS_PATH + AGENT_PROFILE_PATH)
    }
  }

  const auth = ({accessToken, refreshToken, user}) =>{
    setToken(accessToken)
    setRefreshToken(refreshToken)
    setUser(user)
  }

  const [getUserByIdQuery] = useApolloLazyQuery(GET_CURRENT_USER, {
    onCompleted: (data) => {
      const { Me: user } = data
      setUser(user)
    },
    fetchPolicy: 'no-cache',
  })

  const [confirmMemberNetAuthentication] = useApolloMutation(CONFIRM_MEMBER_NET_AUTHENTICATION, {
    onCompleted: (data) => {
      const { ConfirmMemberNetAuthentication: { accessToken, refreshToken, user } } = data

      auth({accessToken, refreshToken, user})
    },
    onError: (error) => {
      toast.error(error.message)
      window.history.pushState("", "", "/login");
    },
  })

  const [loginMutation] = useApolloMutation(LOGIN, {
    onCompleted: (result) => {
      const { Authenticate: { accessToken, refreshToken, user } } = result

      if (!user.accessList.length) {
        setAuthError(invalidCredentialsErrorText)
      } else {
        setAuthError('')
        auth({accessToken, refreshToken, user})
      }
    },
    onError: (error) => {

      const errorText = error.toString().replace('Error:', '')
      toast.error(errorText)
      if (errorText.trim() === invalidCredentialsServerErrorText.trim()) {
        setAuthError(invalidCredentialsErrorText)
      }
    },
  })
  const [createMemberNetAuthentication] = useApolloMutation(CREATE_MEMBER_NET_AUTHENTICATION, {
    onCompleted: (data) => {
      const { CreateMemberNetAuthentication: { authenticationUrl } } = data

      window.location.href = authenticationUrl
    },
  })

  useApolloQuery(GET_ORGANIZATION_QUERY, {
    variables: {
      id: getOrganizationId(),
      skipCondition: !getOrganizationId(),
    },
    onCompleted: (data) => { 
      setOrgData(data?.Organization)
    }
  })

  const getOrganization = () => {
    return orgData
  }

  const getNmgId = () => {
    const user$ = getCurrentUser()
    return orgData ? orgData.nmgId : user$.accessList[0].nmgId
  }

  const handleAccessDenied = () => {
    const accessTokenError = getParamsFromLocation('error_description')
    toast.error(accessTokenError)
    window.history.pushState("", "", "/login");
  }

  useEffect(() => {
    const accessToken = getLocationHashValue('access_token')
    const accessTokenStatus = getParamsFromLocation('error')
    if (accessTokenStatus === "access_denied"){
      handleAccessDenied()
    } else if (accessToken) {
      confirmMemberNetAuthentication({
        variables: {
          accessToken,
        },
      })
    }
  }, [])

  const isAuth = () => !!currentUser

  const login = (email, password, callback) => {
    loginMutation({
      variables: {
        email,
        password,
      },
    }).then(() => callback)
  }

  const logout = () => {
    Storage.local.remove('accessToken')
    Storage.local.remove(REFRESH_TOKEN)
    Storage.local.remove(USER)

    if (isPlatformAdmin || isPlatformAnalyst) {
      history.push(ADMIN_LOGIN_PATH)
      return
    }
    history.push(LOGIN_PATH)
  }

  const loginWithMemberNet = (redirectUrl) => {
    createMemberNetAuthentication({
      variables: {
        redirectUrl,
      },
    })
  }

  const authValue = {
    login,
    logout,
    loginWithMemberNet,
    isAuth,
    getNmgId,
    getOrganizationId,
    getToken,
    getRefreshToken,
    currentUser,
    isPlatformAdmin,
    isPlatformAnalyst,
    isRetailerAdmin,
    authError,
    refetchUser: getUserByIdQuery,
    setCurrentUser,
    getOrganization,
    updateOrganization: setOrgData
  }

  return (
    <AuthContext.Provider value={authValue}>
      { children }
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
