export const storesHeaders = [
  {
    field: 'name',
    title: 'Stores',
  },
  {
    field: 'address',
    title: 'Address',
  },
  {
    id: 'agents',
    title: 'Assigned agents',
  },
  {
    title: 'Actions',
    field: 'actions',
    isSorting: false,
  },
]
