import React, { useState } from 'react'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import {
  Drawer, AppBar, Toolbar, // Button,
} from '@material-ui/core'
// import CheckIcon from '@material-ui/icons/Check'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { routes } from 'Pages'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeaderData } from 'Store/Actions/ChatHeader'
import { MessengerPages } from 'Pages/messenger/chat/components/Inbox/config'
// import { useAuthContext } from 'Providers/AuthProvider'
// import { GET_CURRENT_USER } from 'GraphQL/queries/users'
// import { useApolloQuery } from 'GraphQL/apollo'
// import { getEnvironment } from 'Helpers/helpers'
import DashboardOverlay from '../DashboardOverlay'
import { UserProfileBlock } from '../UserProfileBlock'

function Header({ location, toggleSlider }) {
  const dispatch = useDispatch()
  const [customizer, setCustomizer] = useState(false)
  // const [isCopied, setIsCopied] = useState(false)
  const regexActiveMenu = /\/app\/(\w+)/
  const lastPath = location.pathname.replace(/^.*[\\/]/, '')
  const mainPath = location.pathname.match(regexActiveMenu)[1]
  const title = isMobile ? lastPath.replace(/-/g, ' ') : mainPath
  const capitalizeTitle = title.charAt(0).toUpperCase() + title.slice(1)
  const chatHeaderReducer = useSelector(state => state.chatHeader)
  const { messengerStep, chatName } = chatHeaderReducer
  // const { getToken } = useAuthContext()
  // const accessToken = getToken()
  // const environment = getEnvironment()

  // const { data: currentUserData } = useApolloQuery(GET_CURRENT_USER, {
  //   variables: {
  //     accessToken,
  //   },
  // })

  const closeDashboardOverlay = () => {
    const e = document.getElementsByClassName('dashboard-overlay')[0]
    e.classList.remove('show')
    e.classList.add('d-none')
    document.body.style.overflow = ''
  }
  const mainRoute = routes.filter(route => route.path === mainPath)[0]

  const getTitle = () => {
    if (isMobile && messengerStep === MessengerPages.CHAT) {
      return chatName
    } if (isMobile && messengerStep === MessengerPages.USERINFO) {
      return 'Back to chat'
    } if (capitalizeTitle === 'Payments') {
      return 'Payment Reports'
    }
    return capitalizeTitle
  }

  // const copyToClipboard = () => {
  //   setIsCopied(true)
  //   navigator.clipboard.writeText(
  //     `https://personalroom.${environment}.showroomvideo.chat/live-chat/${currentUserData.Me.personalCallId}`
  //   )
  // }

  return (
    <AppBar
      className="rct-header"
      position="static"
    >
      <Toolbar className={classNames(
        'd-flex  w-100',
        { 'justify-content-between': !isMobile },
      )}
      >
        {messengerStep > 0 && (
          <div onClick={() => dispatch(changeHeaderData({ messengerStep: messengerStep - 1 }))}>
            <span
              className="slider-toggle ti-arrow-left drag-mark"
            />
          </div>
        )}
        {lastPath !== mainPath && !messengerStep && (
          <Link to={`app/${mainRoute.path}`}>
            <span
              className="slider-toggle ti-arrow-left drag-mark"
            />
          </Link>
        )}
        {lastPath === mainPath && !messengerStep && (
          <span
            className="slider-toggle  ti-menu drag-mark"
            onClick={toggleSlider}
          />
        )}
        <span className="title">{getTitle()}</span>
        {/* {mainPath === 'messenger' && !isMobile && (
          <span className="info-link-block">
            <span
              className="client-info-link"
            >
              Personal Call URL
            </span>
            <Button
              className={classNames(
                'client-info-link-copy',
                { isCopied },
              )}
              onClick={copyToClipboard}
            >
              {isCopied ? 'Copied' : 'Copy'}
              {isCopied ? <CheckIcon /> : <i className="zmdi zmdi-copy" />}

            </Button>
          </span>
        )} */}
        <div className="d-inline-flex align-items-center" />
        {(!isMobile || messengerStep !== MessengerPages.CHAT) && (
          <ul className={classNames(
            'navbar-right list-inline mb-0',
            { 'd-none': messengerStep === MessengerPages.USERINFO },
          )}
          >
            <li className="list-inline-item">
              <UserProfileBlock />
            </li>
          </ul>
        )}
        {(isMobile && messengerStep === MessengerPages.CHAT) && (
          <button
            className="btn btn-transparent user-info-btn"
            type="button"
            onClick={() => dispatch(changeHeaderData({ messengerStep: messengerStep + 1 }))}
          >
            Client Info
          </button>
        )}
        <Drawer
          anchor="right"
          open={customizer}
          onClose={() => setCustomizer(false)}
        />
      </Toolbar>
      {/* {mainPath === 'messenger' && isMobile && (
        <div className="info-link-block mb-10">
          <span
            className="client-info-link"
          >
            Personal Call URL
          </span>
          <Button
            className={classNames(
              'client-info-link-copy',
              { isCopied },
            )}
            onClick={copyToClipboard}
          >
            {isCopied ? 'Copied' : 'Copy'}
            {isCopied ? <CheckIcon /> : <i className="zmdi zmdi-copy" />}

          </Button>
        </div>

      )} */}
      <DashboardOverlay
        onClose={() => closeDashboardOverlay()}
      />
    </AppBar>
  )
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: '',
  }).isRequired,
  toggleSlider: PropTypes.func.isRequired,
}

export default withRouter(Header)
