import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import primaryTheme from '../Container/themes/primaryTheme'
import darkTheme from '../Container/themes/darkTheme'
import secondaryTheme from '../Container/themes/secondaryTheme'

function ThemeProvider({ children }) {
  const settings = useSelector(state => state.settings)
  const {
    darkMode,
    rtlLayout,
    activeTheme,
  } = settings

  let theme = ''
  switch (activeTheme.id) {
    case 1:
      theme = primaryTheme
      break
    case 2:
      theme = secondaryTheme
      break
    default:
      break
  }

  if (darkMode) {
    theme = darkTheme
  }

  if (rtlLayout) {
    theme.direction = 'rtl'
  } else {
    theme.direction = 'ltr'
  }

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
