import React from 'react'
import PropTypes from 'prop-types'
import { InsertLink, Folder } from '@material-ui/icons'
import { FormControl, Select, MenuItem } from '@material-ui/core'

import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import Input from 'Components/common/Input'
import Button from 'Components/common/Button'

const ProductInputModal = ({
  control,
  data: { index, type },
  open,
  onClose,
  errors,
}) => {
  const renderProductURL = () => (
    <Input
      control={control}
      error={!!errors?.items?.[index]?.url}
      errorMessage={errors?.items?.[index].url?.message}
      label="Enter product URL"
      labelIcon={<InsertLink />}
      name={`items.${index}.url`}
      fullWidth
      multiline
    />
  )

  const renderProductCategory = () => (
    <>
      <FormControl
        className="store-select w-100 mb-2"
        variant="outlined"
      >
        <label className="label d-flex justify-content-start align-items-center">
          <Folder className="mr-2" />
          Select product category
        </label>
        <Select
          className="w-100"
          control={control}
          name="productCategory"
          displayEmpty
        >
          <MenuItem>Category</MenuItem>
        </Select>
      </FormControl>
      <Input
        label="Find product"
        labelIcon={<Folder />}
        name="keyword"
        placeholder="Search"
        fullWidth
      />
    </>
  )

  return (
    <ModalBase
      open={open}
      width={400}
      withControls={false}
      onClose={onClose}
    >
      {type === 'url' ? renderProductURL() : renderProductCategory()}
      <div className="d-flex justify-content-end align-items-center">
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Add Product
        </Button>
      </div>
    </ModalBase>
  )
}

ProductInputModal.propTypes = {
  control: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    index: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
}

export default ProductInputModal
