export const HOME_PATH = '/'
export const ADMIN_LOGIN_PATH = '/admin/login'
export const ADMIN_SIGNUP_PATH = '/admin/signup'
export const MESSENGER_PATH = '/messenger'
export const CUSTOMERS_PATH = '/customers'
export const ANALYTICS_PATH = '/analytics'
export const PAYMENTS_PATH = '/payments'
export const ORGANIZATIONS_PATH = '/organizations'
export const WORKFLOWS_PATH = '/workflows'
export const BILLING_PATH = '/billing'
export const SETTINGS_PATH = '/settings'
export const APP_PATH = '/app'
export const VIDEO_CALL_PATH = '/video-call'
export const LOGIN_PATH = '/login'
export const SIGNUP_PATH = '/signup'
export const PAYMENT_PATH = '/payment'

export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const AGENT_PROFILE_PATH = '/agent-profile'
export const AGENTS_PATH = '/agents'
export const STORES_PATH = '/stores'
export const WIDGET_CODE_PATH = '/widget-code'
export const AUTORESPONDER_PATH = '/auto-responder'
export const PRE_QUALIFIED_QUESTIONS_PATH = '/pre-qualified-questions'
export const POST_CHAT_QUESTION_PATH = '/post-chat-question'
export const STYLE_YOUR_MESSENGER_PATH = '/style-your-messenger'
export const CREATE_PROFILE = '/create-profile'
export const CHECKOUT = '/checkout'
export const REVIEWS = '/review'
