import { gql } from '@apollo/client'

export const ADD_CLIENT_INFO = gql`
mutation AddClientInformation(
  $client: String!
  $phone: String
  $email: String
) {
  AddClientInformation(client: $client, phone: $phone, email: $email) {
    phone
    address
    email
  }
}
`
