import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const AntSwitchWithStyle = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 4,
    color: '#D8DADD',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#FF7A59',
        borderColor: '#FF7A59',
      },
    },
  },
  thumb: {
    width: 8,
    height: 8,
    boxShadow: 'none',
  },
  track: {
    border: '1px solid #D8DADD',
    borderRadius: 24,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

const AntSwitch = ({ title, ...rest }) => (
  <Tooltip title={title}>
    <span className="d-block">
      <AntSwitchWithStyle {...rest} />
    </span>
  </Tooltip>
)

export default AntSwitch

AntSwitch.propTypes = {
  title: PropTypes.string,
}

AntSwitch.defaultProps = {
  title: '',
}
