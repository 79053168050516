import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { CREATE_RETAILER_ADMIN, UPDATE_RETAILER_ADMIN } from 'GraphQL/mutations/retailerAdmin'
import { toast } from 'react-toastify'
import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION } from 'GraphQL/mutations/organization'

export const useCreateUpdateOrganization = ({ user, onCompleted, refetchQueries }, updateUser) => {
  const [createRetailerAdminMutation] = useApolloMutation(CREATE_RETAILER_ADMIN, {
    onCompleted: (admin) => {
      const { CreateRetailerAdmin: { name } } = admin

      onCompleted()
      toast.success(`${name} Organization Admin created`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })
  const [updateRetailerAdminMutation] = useApolloMutation(UPDATE_RETAILER_ADMIN, {
    onCompleted: (admin) => {
      const { UpdateUser: { name } } = admin

      onCompleted()
      toast.success(`${name} Organization Admin updated`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [createOrganizationMutation] = useApolloMutation(CREATE_ORGANIZATION, {
    onCompleted: (data) => {
      const { CreateOrganization: { id, name, nmgId } } = data

      toast.success(`${name} Organization created`)
      createRetailerAdminMutation({
        variables: {
          organization: id,
          nmgId,
          ...user,
        },
        refetchQueries,
      })
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })
  const [updateOrganizationMutation] = useApolloMutation(UPDATE_ORGANIZATION, {
    onCompleted: (organization) => {
      const { UpdateOrganization: { name, admin } } = organization
      toast.success(`${name} Organization updated`)

      if (updateUser) {
        updateRetailerAdminMutation({
          variables: {
            // todo Once the number of admins has been agreed, remove id from 0 array
            id: admin[0].id,
            ...user,
          },
          refetchQueries,
        })
      } else {
        onCompleted()
      }
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })
  const [updateOrganizationMutationWithoutRetailerUpdate] = useApolloMutation(UPDATE_ORGANIZATION, {
    onCompleted: (organization) => {
      const { UpdateOrganization: { name } } = organization
      toast.success(`${name} Organization updated`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  return {
    createRetailerAdminMutation,
    updateRetailerAdminMutation,
    createOrganizationMutation,
    updateOrganizationMutation,
    updateOrganizationMutationWithoutRetailerUpdate,
  }
}
