import Button from 'Components/common/Button'
import React from 'react'
import { TableCell } from 'Pages/Components/TableWithStyles'
import PropTypes from 'prop-types'
import SyncIcon from '@material-ui/icons/Sync'
import { useReadMode } from 'Hooks/useReadMode'

export const getAminStatus = (admin) => {
  const {
    active: adminActive,
    isOnboarded: adminIsOnboarded,
  } = admin
  let adminStatus

  if (adminActive) {
    adminStatus = (adminIsOnboarded ? 'Active' : 'New')
  } else {
    adminStatus = 'Deactivated'
  }
  return adminStatus
}

export const AdminCell = ({ admin, reinviteUser }) => {
  const { isReadOnly } = useReadMode()
  return (
    <>
      <TableCell>
        {!admin.isPasswordExpired && (
          <span className="page-table-cell">
            <div>{getAminStatus(admin)}</div>
          </span>
        )}
        {admin.isPasswordExpired && (
          <Button
            className="reinvite-org-admin-btn"
            disabled={isReadOnly}
            onClick={() => reinviteUser(admin?.email)}
          >
            <SyncIcon />
            <span className="reinvite-button-text">Invite again</span>
          </Button>
        )}
      </TableCell>
      <TableCell><span className="page-table-cell"><div>{admin?.name}</div></span></TableCell>
      <TableCell><span className="page-table-cell"><div>{admin?.email}</div></span></TableCell>
    </>
  )
}

AdminCell.propTypes = {
  admin: PropTypes.shape({
    isPasswordExpired: PropTypes.bool,
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  reinviteUser: PropTypes.func.isRequired,
}
