import React from 'react'
import PropTypes from 'prop-types'
import { InputAdornment } from '@material-ui/core'
import { useWatch } from 'react-hook-form'

import Input from 'Components/common/Input'

const Row = ({
  control, onRemove, onProductInputClick, name, errors,
}) => {
  const itemData = useWatch({ control, name })

  return (
    <tr>
      <td className="expend-column">
        <Input
          control={control}
          endAdornment={(
            <InputAdornment
              className="product-end-adornment"
              position="end"
            >
              <i
                className="zmdi zmdi-link"
                onClick={() => onProductInputClick('url')}
              />
              <i
                className="zmdi zmdi-folder"
                onClick={() => onProductInputClick('search')}
              />
            </InputAdornment>
          )}
          error={!!errors?.name}
          errorMessage={errors?.name?.message}
          name={`${name}.name`}
          placeholder="Enter Product"
          fullWidth
        />
      </td>
      <td>
        <Input
          control={control}
          error={!!errors?.quantity}
          errorMessage={errors?.quantity?.message}
          name={`${name}.quantity`}
        />
      </td>
      <td>
        <Input
          control={control}
          error={!!errors?.price}
          errorMessage={errors?.price?.message}
          name={`${name}.price`}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </td>
      <td>
        <Input
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          value={parseFloat(itemData.price) * parseInt(itemData.quantity, 10)}
          disabled
        />
      </td>
      <td className="delete-btn px-3">
        <i
          className="zmdi zmdi-delete"
          onClick={onRemove}
        />
      </td>
    </tr>
  )
}

Row.propTypes = {
  control: PropTypes.shape({}).isRequired,
  onRemove: PropTypes.func.isRequired,
  onProductInputClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.shape({ message: PropTypes.string }),
    quantity: PropTypes.shape({ message: PropTypes.string }),
    price: PropTypes.shape({ message: PropTypes.string }),
  }),
}

Row.defaultProps = {
  errors: {},
}

export default Row
