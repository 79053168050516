/* eslint-disable max-lines */
import { GET_STORES } from 'GraphQL/queries/store'
import React, { useEffect, useState, useMemo } from 'react'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import {
  Add,
  Link as LinkIcon,
  PersonOutline,
  Save,
  Storefront,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import Input from 'Components/common/Input'
import { useApolloMutation, useApolloLazyQuery, useApolloQuery } from 'GraphQL/apollo'
import { CREATE_RETAILER, UPDATE_RETAILER } from 'GraphQL/mutations/retailer'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { GET_USER_BY_ID } from 'GraphQL/queries/users'
import { toast } from 'react-toastify'
import { useDirtyFields } from 'Hooks/useDirtyFields'
import { useAuthContext } from 'Providers/AuthProvider'
import { StoresSelect } from 'Pages/settings/agents/StoresSelect'
import { validationSchema } from './validationSchema'
import './styles.scss'

const RETAILER_WATCH_FIELDS = [
  {
    key: 'name',
    field: 'name',
  },
  {
    key: 'email',
    field: 'email',
  },
]

export const CreateUpdateAgentModal = ({
  agentId,
  edit,
  open,
  onClose,
  refetchUsers,
  hasPermission,
  ...modalProps
}) => {
  const { getNmgId, getOrganizationId } = useAuthContext()
  const [selectedStores, setSelectedStores] = useState([])
  const [showErrorText, setShowErrorText] = useState(false)

  const nmgId = getNmgId()
  const organizationId = getOrganizationId()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { data: storesData, refetch: refetchStores } = useApolloQuery(GET_STORES, {
    variables: {
      organization: getOrganizationId(),
    },
  })

  const [updateRetailer] = useApolloMutation(UPDATE_RETAILER, {
    onCompleted: (data) => {
      const { UpdateUser: { name } } = data
      refetchUsers()
      toast.success(`${name} Agent updated`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })
  const [createRetailerMutation] = useApolloMutation(CREATE_RETAILER, {
    onCompleted: (data) => {
      const { CreateRetailer: { name } } = data
      // todo When we save pagination in localStorage transfer here pagination
      reset()
      toast.success(`${name} Agent added`)
    },
    onError: (error) => {
      if (error.message.includes('email is already retailer')) {
        toast.warn('Agent with the same email has already been created')
      } else {
        toast.error(error.message)
      }
    },
  })

  const [loadAgentQuery, { data: dataAgentQuery }] = useApolloLazyQuery(GET_USER_BY_ID)

  useEffect(() => {
    if (edit) {
      loadAgentQuery({
        variables: {
          id: agentId,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (edit && dataAgentQuery) {
      const agent = dataAgentQuery.User
      const fields = ['name', 'email']
      fields.forEach((field) => {
        setValue(field, agent[field])
      })
    }
  }, [dataAgentQuery])

  const selectedStoreIds = useMemo(() => selectedStores.map(selectedStore => selectedStore.value))

  const createAgent = (data) => {
    createRetailerMutation({
      variables: {
        stores: selectedStoreIds,
        nmgId,
        organization: organizationId,
        ...data,
      },
      refetchQueries: refetchUsers,
    })
  }

  const updateAgent = (id) => {
    // todo extract hook with method
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userChangedData = useDirtyFields(RETAILER_WATCH_FIELDS, dirtyFields, getValues)

    if (Object.keys(userChangedData).length) {
      updateRetailer({
        variables: {
          id,
          ...userChangedData,
        },
      })
    } else {
      onClose()
    }
  }

  const onSubmit = (data) => {
    // eslint-disable-next-line no-unused-expressions
    edit ? updateAgent(agentId, data) : createAgent(data)
    onClose()
  }

  const onError = (submitErrors, e) => console.error('Agent submitErrors', { submitErrors }, e)

  const handleCancelInviteAgentModal = () => {
    reset()
  }
  const permittedOperationsTitle = edit ? 'Edit agent' : 'Invite agent'
  const isSelectedAgentActive = dataAgentQuery?.User.active
  const isAcceptButtonDisabled = (!isSelectedAgentActive && edit) || (!edit && !selectedStores.length)
  const handleOnBlur = () => {
    if (!storesData?.Stores.items.length) {
      setShowErrorText(true)
    }
  }

  return (
    <ModalBase
      {...modalProps}
      acceptIcon={edit ? <Save /> : <Add />}
      acceptTitle={edit ? 'Save' : 'Invite Agent'}
      isAcceptButtonDisabled={isAcceptButtonDisabled}
      open={open}
      title={hasPermission ? permittedOperationsTitle : 'View agent'}
      titleCancel="Cancel"
      withControls={hasPermission}
      onAccept={handleSubmit(onSubmit, onError)}
      onCancel={handleCancelInviteAgentModal}
      onClose={onClose}
    >
      <form>
        <Input
          control={control}
          disabled={!isSelectedAgentActive && edit}
          error={errors.name}
          errorMessage={errors.name?.message}
          label="Agent name"
          labelIcon={<PersonOutline />}
          name="name"
          placeholder="Kasandra Lopez"
          readOnly={!hasPermission}
          fullWidth
        />
        <Input
          control={control}
          disabled={!isSelectedAgentActive && edit}
          error={errors.email}
          errorMessage={errors.email?.message}
          label="Agent email"
          labelIcon={<LinkIcon />}
          name="email"
          placeholder="Reston@gmail.com"
          readOnly={!hasPermission}
          fullWidth
        />
        {!edit && (
          <>
            <div className="store-select-title">
              <Storefront />
              <span>Agent Store</span>
            </div>
            {showErrorText && (
              <span className="store-select-error-text"> At least one store should exist</span>
            )}
            <StoresSelect
              handleOnBlur={handleOnBlur}
              refetchStores={refetchStores}
              setSelectedStores={setSelectedStores}
              stores={storesData?.Stores.items}
            />
          </>
        )}
      </form>
    </ModalBase>
  )
}

CreateUpdateAgentModal.propTypes = {
  agentId: PropTypes.string,
  edit: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  refetchUsers: PropTypes.func,
  hasPermission: PropTypes.bool.isRequired,
}

CreateUpdateAgentModal.defaultProps = {
  agentId: null,
  edit: false,
  open: false,
  onClose: () => {},
  refetchUsers: () => {},
}
