import { useEffect } from 'react'

export const useDeviceVirtualHeight = () => {
  useEffect(() => {
    const calcDeviceVirtualHeight = () => {
      // eslint-disable-next-line no-magic-numbers
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    calcDeviceVirtualHeight()
    // We listen to the resize event
    window.addEventListener('resize', calcDeviceVirtualHeight)
    return () => {
      document.removeEventListener('resize', calcDeviceVirtualHeight)
    }
  })
}
