import { phoneRegExp } from 'Constants/common'
import {
  object, array, string, boolean,
} from 'yup'

const MIN_LENGTH_PHONE = 8
const MAX_LENGTH_PHONE = 16

const minLengthText = 2
const MAX_LENGTH_TEXT = 255

/* eslint max-len: ["error", 2000] */
const urlValidationRegEx = /^$|^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-_]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const validationSchemaMerchant = object().shape({
  id: string(),
  name: string().required('Account name cannot be empty'),
  username: string().required('Paytrace username cannot be empty'),
  password: string().required('Password cannot be empty'),
  integratorId: string().required('Integrator ID cannot be empty'),
  active: boolean(),
})

export const validationSchemaReview = object().shape({
  id: string(),
  title: string().required('Title cannot be empty'),
  url: string().matches(urlValidationRegEx, 'URL is not valid').required('URL cannot be empty'),
  active: boolean(),
})

export const validationSchema = object().shape({
  name: string().trim()
    .min(minLengthText, `Minimum number of symbols ${minLengthText}`)
    .max(MAX_LENGTH_TEXT, `Maximum number of symbols ${MAX_LENGTH_TEXT}`)
    .required(),
  nmgId: string().trim()
    .max(MAX_LENGTH_TEXT, `Maximum number of symbols ${MAX_LENGTH_TEXT}`)
    .required(),
  sites: array().of(
    string().trim()
      .url('Incorrect Url address')
      .max(MAX_LENGTH_TEXT, `Maximum number of symbols ${MAX_LENGTH_TEXT}`)
      .required('Organization URL is a required field'),
  ),
  phones: array().of(
    object().shape({
      phone: object().shape({
        phoneNumber: string()
          .matches(phoneRegExp, { message: 'Phone number should contain leading ‘+’ and consist of digits only', excludeEmptyString: true })
          .min(MIN_LENGTH_PHONE, 'Minimum 7 digits')
          .max(MAX_LENGTH_PHONE, 'Maximum 15 digits'),
      }),
    }),
  ),
  merchantAccounts: array().of(validationSchemaMerchant),
  address: string().trim()
    .min(minLengthText, `Minimum number of symbols ${minLengthText}`)
    .max(MAX_LENGTH_TEXT, `Maximum number of symbols ${MAX_LENGTH_TEXT}`)
    .required(),
  retailerAdministratorName: string().trim()
    .min(minLengthText, `Minimum number of symbols ${minLengthText}`)
    .max(MAX_LENGTH_TEXT, `Maximum number of symbols ${MAX_LENGTH_TEXT}`)
    .required('Organization administrator name is a required field'),
  retailerAdministratorEmail: string().trim()
    .email('Must be a valid email')
    .required('Organization administrator email is a required field'),
})
