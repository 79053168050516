import React, { useState } from 'react'
import axios from 'axios'
import './style.css'
import useScript from 'Hooks/useScript'
import useQuery from 'Hooks/useQuery'
import { formatedNumber, getAPIURLBasedENV, ptPaymentStyles } from './utils'
import PaymentSummary from './PaymentSummary'
import PaymentSuccess from './PaymentSuccess'
import PaymentFailure from './PaymentFailure'
import CardCheck from './CardCheck'
import AchCheck from './AchCheck'

const RESPONSE_200_SUCCESS = 200
const RESPONSE_201_SUCCESS = 201

export const PaymentPage = () => {
  const [paymentStep, setPaymentStep] = useState(0)
  const [isCardChecked, setCardChecked] = useState(false)
  const [isPaid, setIsPaid] = useState(false)
  const [requestErrorString, setRequestErrorString] = useState(null)

  const query = useQuery()
  const requestId = query.get('requestId')
  const paymentMethod = query.get('method')
  const [paymentObject, setPaymentObject] = useState(null)

  const initialize = async () => {
    setPaymentStep(-1)
    setCardChecked(false)
    setIsPaid(false)

    const request = `${getAPIURLBasedENV()}/payments/request/${requestId}`
    try {
      const { data, status } = await axios.get(request)
      if (status === RESPONSE_200_SUCCESS) {
        setPaymentObject(data)
        if (data != null) {
          setPaymentStep(0)
        }
      }
    } catch (err) {
      setRequestErrorString(err?.response?.data?.message || 'Oops! There was a problem. Please try again later.')
    }
  }

  useScript('https://protect.paytrace.com/js/protect.min.js', 'payment_script', () => {
    initialize()
  })

  const handleError = () => {
    setPaymentStep(1)
    setCardChecked(true)
    setIsPaid(false)
  }

  const goBack = () => {
    setPaymentStep(0)
    setCardChecked(false)
    setIsPaid(false)
  }

  const submitACHPayment = async (v) => {
    const paymentToSend = {
      requestId,
      access_token: paymentObject.accessToken,
      name: v.name,
      paymentMethod,
      account_number: v.accountNumber,
      routing_number: v.routingNumber,
    }
    const jsonPaymentToSend = JSON.stringify(paymentToSend)
    try {
      const { data, status } = await axios.post(
      `${getAPIURLBasedENV()}/payments/checkout`,
      jsonPaymentToSend,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      )
      if (status === RESPONSE_200_SUCCESS || status === RESPONSE_201_SUCCESS) {
        setPaymentStep(1)
        setCardChecked(true)
        if (data.success) {
          setIsPaid(true)
        } else {
          setIsPaid(false)
        }
      } else {
        setPaymentStep(1)
        setCardChecked(true)
        setIsPaid(false)
      }
    } catch (err) {
      setPaymentStep(1)
      setCardChecked(true)
      setIsPaid(false)
    }
  }

  const submitPayment = async (r) => {
    const rMessage = JSON.parse(JSON.stringify(r.message))
    const encKey = rMessage.enc_key
    const hpfToken = rMessage.hpf_token

    const paymentToSend = {
      requestId,
      hpf_token: hpfToken,
      enc_key: encKey,
      paymentMethod,
      access_token: paymentObject.accessToken,
    }
    const jsonPaymentToSend = JSON.stringify(paymentToSend)
    const { data, status } = await axios.post(
      `${getAPIURLBasedENV()}/payments/checkout`,
      jsonPaymentToSend,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (status === RESPONSE_200_SUCCESS || status === RESPONSE_201_SUCCESS) {
      setPaymentStep(1)
      setCardChecked(true)
      if (data.success) {
        setIsPaid(true)
      } else {
        setIsPaid(false)
      }
    } else {
      setPaymentStep(1)
      setCardChecked(true)
      setIsPaid(false)
    }
  }
  const gotoCardCheck = () => {
    setPaymentStep(1)
    setCardChecked(false)
    setIsPaid(false)

    window.PTPayment.setup({
      styles: ptPaymentStyles,
      authorization: { clientKey: paymentObject && paymentObject.clientKey },
    }).then((instance) => {
      window.PTPayment.getControl('securityCode').label.text('Security Code')
      window.PTPayment.getControl('creditCard').label.text('Card Number')
      window.PTPayment.getControl('expiration').label.text('Expiration Date')
      document.getElementById('SubmitButton').value = `Pay $${formatedNumber(paymentObject.paymentRequest.totalAmount)}`
      document.getElementById('ProtectForm').addEventListener('submit', (e) => {
        e.preventDefault()
        e.stopPropagation()

        window.PTPayment.style({ cc: { border_color: '#D8DADD' } })
        window.PTPayment.style({ exp: { border_color: '#D8DADD' } })

        window.PTPayment.validate((validationErrors) => {
          if (validationErrors.length >= 1) {
            validationErrors.forEach((error) => {
              const respCode = error.responseCode
              if (respCode === '35') {
                window.PTPayment.style({ cc: { border_color: 'red' } })
              } else if (respCode === '44' || respCode === '43') {
                window.PTPayment.style({ exp: { border_color: 'red' } })
              }
            })
          } else {
            instance.process()
              .then(r => submitPayment(r))
              .catch(err => handleError(err))
          }
        })
      })
    })
  }

  const tryAgain = () => {
    setPaymentStep(1)
    setCardChecked(false)
    setIsPaid(false)
    window.location.reload(true)
  }

  return (
    <div className="payment_container container">
      {paymentStep === 0 && (
        <PaymentSummary
          createdAt={paymentObject && paymentObject.paymentRequest.createdAt}
          customerName={paymentObject && paymentObject.customer && paymentObject.customer.name}
          gotoCardCheck={gotoCardCheck}
          lineItems={paymentObject && paymentObject.paymentRequest.lineItems}
          paymentMethod={paymentMethod}
          storeAddress={paymentObject && paymentObject.store.address}
          storeLogo={paymentObject && paymentObject.store.logo}
          storeName={paymentObject && paymentObject.store.name}
          totalAmount={paymentObject && paymentObject.paymentRequest.totalAmount}
        />
      )}
      { paymentMethod === 'credit_card' ? (
        <CardCheck
          goBack={goBack}
          isCardChecked={isCardChecked}
          isPaid={isPaid}
          paymentStep={paymentStep}
        />
      ) : (
        <AchCheck
          goBack={goBack}
          isCardChecked={isCardChecked}
          isPaid={isPaid}
          paymentStep={paymentStep}
          submitPayment={submitACHPayment}
        />
      )}
      {
        paymentStep === 1 && isCardChecked && isPaid && (
          <PaymentSuccess
            storeAddress={paymentObject && paymentObject.store.address}
            storeLogo={paymentObject && paymentObject.store.logo}
            storeName={paymentObject && paymentObject.store.name}
          />
        )
      }
      {
        paymentStep !== 0 && isCardChecked && !isPaid && (
          <PaymentFailure
            storeAddress={paymentObject && paymentObject.store.address}
            storeLogo={paymentObject && paymentObject.store.logo}
            storeName={paymentObject && paymentObject.store.name}
            tryAgain={tryAgain}
          />
        )
      }
      {
        !!requestErrorString && (
          <div>
            <div className="text-center">
              <svg
                className="bi bi-x-circle"
                fill="currentColor"
                height="80"
                style={{ color: '#FF4545' }}
                viewBox="0 0 16 16"
                width="80"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
              <p className="text-content-highlight mx-5 my-3">{requestErrorString}</p>
            </div>
          </div>
        )
      }
    </div>
  )
}
