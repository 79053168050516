import uniqBy from 'lodash/uniqBy'
import differenceBy from 'lodash/differenceBy'

import { CONVERSATION_STATUSES } from 'Constants/conversation'
import update from 'react-addons-update'
import { getUuid } from 'Helpers/helpers'
import {
  CHAT_WITH_SELECTED_USER,
  SEND_MESSAGE_TO_USER,
  SEND_FILE_MESSAGE_TO_USER,
  UPDATE_USERS_SEARCH,
  ADD_NEW_CUSTOMERS,
  UPDATE_CUSTOMERS,
  REMOVE_CUSTOMERS,
  GET_MESSAGE_FROM_CUSTOMER,
  GENERATE_CALL_ID,
  SET_ACTIVE_FILTER,
  CHANGE_ASSIGNED_AGENT,
  SET_CONVERSATION_RESOLVED,
  MARK_CONVERSATION_AS_READ,
  UPDATE_SELECTED_USER,
  RESET_USER_SELECTION,
  SET_PERSONAL_CALL,
} from '../Actions/types'

const INITIAL_STATE = {
  recentChatUsers: [],
  allRecentChatUsers: [],
  allChatUsers: [],
  selectedUser: null,
  searchUsers: '',
  selectedChatFilter: null,
  personalCall: null,
}

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_USER_SELECTION:
      return INITIAL_STATE

    case GENERATE_CALL_ID:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          call: {
            customerId: getUuid(),
            retailerId: getUuid(),
          },
        },
      }

    case ADD_NEW_CUSTOMERS:
      return {
        ...state,
        recentChatUsers: uniqBy([...action.payload, ...state.recentChatUsers], 'conversationId'),
        allChatUsers: uniqBy([...action.payload, ...state.allChatUsers], 'conversationId'),
      }

    case UPDATE_CUSTOMERS:
      const existingStoreUsers = state.recentChatUsers?.filter(u => u.storeId === action.store)
      return {
        ...state,
        recentChatUsers: uniqBy([
          ...state.recentChatUsers?.filter(u => u.storeId !== action.store),
          ...action.payload?.map((u) => {
            const existingUser = existingStoreUsers.find(u1 => u1.conversationId === u.conversationId)

            return (!existingUser || u.ordering > existingUser.ordering) ? u : existingUser
          }),
        ], 'conversationId'),
        allChatUsers: uniqBy([
          ...state.allChatUsers?.filter(u => u.storeId !== action.store),
          ...action.payload,
        ], 'conversationId'),
      }

    case REMOVE_CUSTOMERS:
      return update(state, {
        recentChatUsers: differenceBy(state.recentChatUsers, action.payload, 'conversationId'),
        allChatUsers: differenceBy(state.allChatUsers, action.payload, 'conversationId'),
      })

    // chat with selected user
    case CHAT_WITH_SELECTED_USER:
      const indexOfSelectedUser = state.recentChatUsers.indexOf(action.payload)
      if (indexOfSelectedUser === -1) {
        return update(state, {
          selectedUser: { $set: action.payload },
        })
      }
      return update(state, {
        selectedUser: { $set: state.recentChatUsers[indexOfSelectedUser] },
        recentChatUsers: {
          [indexOfSelectedUser]: {
            isSelectedChat: { $set: true },
            new_message_count: { $set: 0 },
          },
        },
      })

    case CHANGE_ASSIGNED_AGENT:
      const user = state.recentChatUsers.find(u => u.id === action.payload.customerId)
      const selectedUserIndex = state.recentChatUsers.indexOf(user)
      return update(state, {
        recentChatUsers: {
          [selectedUserIndex]: {
            currentAgent: { $set: action.payload.agentId },
          },
        },
      })

    case SET_CONVERSATION_RESOLVED:
      const user$ = state.recentChatUsers.find(u => u.id === action.payload.id)
      const selectedUserIndex$ = state.recentChatUsers.indexOf(user$)
      return update(state, {
        recentChatUsers: {
          [selectedUserIndex$]: {
            status: { $set: CONVERSATION_STATUSES.RESOLVED },
          },
        },
      })

    case MARK_CONVERSATION_AS_READ:
      const conversation = state.recentChatUsers.find(u => u.conversationId === action.payload)
      const conversationIndex$ = state.recentChatUsers.indexOf(conversation)

      return update(state, {
        recentChatUsers: {
          [conversationIndex$]: {
            isRead: { $set: true },
          },
        },
      })
      // send message to user
    case SEND_MESSAGE_TO_USER:
      const adminReplyData = {
        isAdmin: action.payload?.isAdmin || false,
        message: action.payload?.message || 'lorem',
        sent: action.payload?.time || Date.now(),
        isRead: !!action.payload?.isRead,
      }
      const pos = state.selectedUser.previousChats.length
      return update(state, {
        selectedUser: { previousChats: { $splice: [[pos, 0, adminReplyData]] } },
      })

    // get message from customer
    case GET_MESSAGE_FROM_CUSTOMER:
      const { customerId, message, sent } = action.payload
      const indexOfCustomer = state.recentChatUsers.findIndex(u => u.id === customerId)
      return update(state, {
        recentChatUsers: {
          [indexOfCustomer]: {
            new_message_count: { $set: 1 },
            last_chat: { $set: message },
            order: { $set: sent },
          },
        },
      })

      // send file message to user
    case SEND_FILE_MESSAGE_TO_USER:
      const adminFileReplyData = {
        isAdmin: action.payload.isAdmin,
        message: action.payload.message,
        message_type: action.payload.message_type,
        media: action.payload.media,
        sent: action.payload.time,
        isRead: action.payload.isRead,
      }
      const posIdx = state.selectedUser.previousChats.length
      return update(state, {
        selectedUser: { previousChats: { $splice: [[posIdx, 0, adminFileReplyData]] } },
      })

    // update search
    case UPDATE_USERS_SEARCH:
      return { ...state, searchUsers: action.payload }

    case UPDATE_SELECTED_USER:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          ...action.payload,
        },
      }

    case SET_ACTIVE_FILTER:
      return { ...state, selectedChatFilter: action.payload, selectedUser: null }

    case SET_PERSONAL_CALL:
      return {
        ...state,
        personalCall: action.payload,
      }

    default:
      return { ...state }
  }
}

export default chatReducer
