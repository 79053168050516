const settingsMenu = {
  general: [
    {
      iconName: 'zmdi zmdi-account-o',
      title: 'Agent profile',
      description: 'Your profile details',
      link: 'agent-profile',
    },
    {
      iconName: 'zmdi zmdi-accounts-outline',
      title: 'Agents',
      description: 'Invite and manage agents',
      link: 'agents',
    },
    {
      iconName: 'zmdi zmdi-shopping-cart-plus',
      title: 'Stores',
      description: 'Manage store locations',
      link: 'stores',
    },
  ],
  chat: [
    {
      iconName: 'zmdi zmdi-code',
      title: 'Widget install code',
      description: 'Developer instructions',
      link: 'widget-code',
    },
    {
      iconName: 'zmdi zmdi-accounts-outline',
      title: 'Auto responder',
      description: 'Manage automated responses for your customers',
      link: 'auto-responder',
    },
    {
      iconName: 'zmdi zmdi-help-outline',
      title: 'Pre-chat questions',
      description: 'Prompt customers before the conversation',
      link: 'pre-qualified-questions',
    },
    {
      iconName: 'zmdi zmdi-whatsapp',
      title: 'Post-chat question',
      description: 'Prompt customers after the conversation',
      link: 'post-chat-question',
    },
    {
      iconName: 'zmdi zmdi-palette',
      title: 'Style your Messenger',
      description: 'Customize the look and feel of your Messenger',
      link: 'style-your-messenger',
    },
  ],
}

export {
  settingsMenu,
}
