import { React, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useAuthContext } from 'Providers/AuthProvider'
import { useApolloMutation } from 'GraphQL/apollo'
import { useConversation } from 'Hooks/useConversation'
import { CREATE_QUESTION } from 'GraphQL/mutations/questions'
import { MessageContentType } from 'Constants/chatConstants'
import { FeedbackTypes, RelationTypes } from 'Constants/common'
import { toast } from 'react-toastify'
import AccountItem from './AccountItem'
import { validationSchema } from './validationSchema'

import './styles.scss'

const DEFAULT_VALUES = {
  reviewAccounts: [],
  requestMessage: '',
}

const RequestReviewModal = ({
  open, onClose, onSubmitMessage, customerName,
}) => {
  const {
    control, reset, setValue, getValues, trigger, handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  })

  const conversation = useConversation()
  const { getOrganization } = useAuthContext()
  const destinationsData = getOrganization().reviewDestinations

  const handleChangeMessage = (event) => {
    setValue('requestMessage', event.target.value)
    trigger('requestMessage')
  }

  const [createReviewRequestMutation] = useApolloMutation(CREATE_QUESTION, {
    onCompleted: async (res) => {
      const reqData = res.CreateFeedback
      const { requestMessage } = getValues()
      const reqMessage = { requestId: reqData.id, message: requestMessage }
      const paymentMessage = {
        content: JSON.stringify(reqMessage),
        contentType: MessageContentType.REVIEW_REQUEST,
      }

      await onSubmitMessage(null, paymentMessage)
      onClose()
      toast.success('Review request created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const onError = (submitErrors, e) => console.error('Store submitErrors', { submitErrors }, e)

  const submitRequestReview = () => {
    const {
      requestMessage, reviewAccounts,
    } = getValues()
    if (requestMessage && reviewAccounts?.length > 0) {
      const variables = {
        store: conversation.store,
        type: FeedbackTypes.REVIEW,
        active: true,
        relation: RelationTypes.IN_CHAT,
        title: 'Review Request',
        description: requestMessage,
        min: 0,
        max: 5,
        reviewDestinations: reviewAccounts,
        conversation: conversation.id,
      }
      createReviewRequestMutation({ variables })
    } else {
      handleSubmit(onError)()
    }
  }

  useEffect(() => {
    if (open) {
      reset({
        reviewAccounts: [],
        requestMessage: `Hey ${customerName}!\nWe appreciate your business, if you'd like to leave us a review:`,
      })
    }
  }, [open])

  return (
    <ModalBase
      acceptTitle="Request a review"
      open={open}
      title="Request a review"
      titleCancel="Cancel"
      width={600}
      onAccept={submitRequestReview}
      onCancel={onClose}
      onClose={onClose}
    >
      <form>
        <div className="request-payment-modal-container">
          <div>
            <i className="zmdi zmdi-city-alt mr-2" />
            <span className="accountTitle">Business account</span>
            <div className="mt-2">
              <Controller
                control={control}
                name="reviewAccounts"
                render={({ field }) => (
                  <>
                    {destinationsData?.map(destinationData => (
                      <AccountItem
                        destinationData={destinationData}
                        field={field}
                        revalidate={trigger}
                        setValue={setValue}
                      />
                    ))}
                    {errors?.reviewAccounts && (
                      <span className="error-message">{errors?.reviewAccounts?.message}</span>
                    )}
                  </>
                )}
              />
            </div>
            <div className="mt-4">
              <Label className="d-flex align-items-center">
                <span className="ti-comment" />
                <span className="accountTitle pl-10">
                  Message
                </span>
              </Label>
              <textarea
                className="reviewMsg"
                control={control}
                name="requestMessage"
                value={getValues().requestMessage}
                onChange={handleChangeMessage}
              />
              {errors?.requestMessage && (
                <span className="error-message">{errors?.requestMessage?.message}</span>
              )}
            </div>
          </div>
        </div>
      </form>
    </ModalBase>
  )
}

RequestReviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitMessage: PropTypes.func.isRequired,
  customerName: PropTypes.string.isRequired,
}

export default RequestReviewModal
