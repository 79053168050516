/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import './styles.scss'
import { useLocation, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from 'react-sidebar'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { Scrollbars } from 'react-custom-scrollbars'
import classnames from 'classnames'

// Components
import Header from 'Components/Header/Header'
import SidebarContent from 'Components/Sidebar'

import PreloadHeader from 'Components/PreloadLayout/PreloadHeader'
import PreloadSidebar from 'Components/PreloadLayout/PreloadSidebar'

// actions
import { collapsedSidebarAction } from 'Store/Actions'

const SeparatorWidth = 1199
const MainApp = ({ children }) => {
  const [loadingHeader, setLoadingHeader] = useState(true)
  const [loadingSidebar, setLoadingSidebar] = useState(true)
  const [isSliderOpened, setIsSliderOpened] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const location = useLocation()
  const dispatch = useDispatch()
  const settings = useSelector(state => state.settings)
  const { navCollapsed, rtlLayout, miniSidebar } = settings

  const updateDimensions = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    setTimeout(() => {
      setLoadingHeader(false)
      setLoadingSidebar(false)
    }, 114)
    updateDimensions()

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if ((windowWidth <= SeparatorWidth) || isMobile) {
      dispatch(collapsedSidebarAction(false))
    }
  }, [windowWidth, location.pathname])

  const toggleSlider = () => {
    setIsSliderOpened(!isSliderOpened)
  }
  const renderSidebar = () => {
    if (loadingSidebar) {
      return <PreloadSidebar />
    }
    return <SidebarContent toggleSlider={toggleSlider} />
  }

  const renderHeader = () => {
    if (loadingHeader) {
      return <PreloadHeader />
    }
    return <Header toggleSlider={toggleSlider} />
  }

  const getScrollBarStyle = () => {
    const headerHeight = isMobileOnly ? '100px' : '64px'
    return {
      height: `calc(100vh - ${headerHeight})`,
    }
  }

  const renderPage = () => (
    <Scrollbars
      autoHideDuration={100}
      className="rct-scroll"
      style={getScrollBarStyle()}
      autoHide
    >
      <div className="rct-page-content">
        {children}
      </div>
    </Scrollbars>
  )

  return (
    <div className="app">
      <div className="app-main-container">
        <Sidebar
          contentClassName={classnames({ 'app-conrainer-wrapper': miniSidebar })}
          docked={(windowWidth > SeparatorWidth) && !isMobile ? !navCollapsed : false}
          open={navCollapsed || isSliderOpened}
          overlayClassName="rtc-class-overlay"
          pullRight={rtlLayout}
          sidebar={renderSidebar()}
          sidebarClassName="rtc-root-sidebar"
          styles={{
            content: { overflowY: '' },
            width: '100%',
          }}
          onSetOpen={() => dispatch(collapsedSidebarAction(false))}
        >
          <div className="app-container">
            <div className="rct-app-content">
              <div className="app-header">
                {renderHeader()}
              </div>
              <div className="rct-page">
                {renderPage()}
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
  )
}

export default withRouter(MainApp)
