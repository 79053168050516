export const paymentsTableData = [
  {
    title: 'Date',
    field: 'createdAt',
  },
  {
    title: 'Amount',
    field: 'amount',
  },
  {
    title: 'Payment Method',
    field: 'paymentMethod',
  },
  {
    title: 'Retailer name',
    field: 'retailer.name',
  },
  {
    title: 'Location',
    field: 'retailer.address',
  },
]
