import React from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import classNames from 'classnames'
import SyncIcon from '@material-ui/icons/Sync'
import { toast } from 'react-toastify'

import { useApolloMutation } from 'GraphQL/apollo'
import { POSITION_TITLES, ROLE_TYPES } from 'Constants/rolesConstants'
import { PERMISSION_NAMES } from 'config/permissions'
import { useAgentAvailability } from 'Hooks/useAgentAvailability'
import { useCheckPermission } from 'Hooks/useCheckPermission'
import { TableCell, TableRow } from 'Pages/settings/components/TableWithStyles'
import { TableActions } from 'Components/common/Table/TableActions'
import Button from 'Components/common/Button'
import { pluralize } from 'Helpers/helpers'
import { REINVITE_USER } from 'GraphQL/mutations/user'
import { useAuthContext } from 'Providers/AuthProvider'

const getUserStatus = (status, isOnboarded) => {
  if (status) { return isOnboarded ? 'Active' : 'New' }
  return 'Deactivated'
}

const renderAvatar = (avatar, status) => (
  <div className="agent-avatar-block-wrapper">
    <div className="agent-avatar-block">
      <Avatar
        className="agent-avatar"
        src={avatar}
      >
        <span className="zmdi zmdi-account-o" />
      </Avatar>
      {status && <span className={classNames('status', [status])} /> }
    </div>
  </div>
)

export const UserTableRow = ({ user, actions, refetchUsers }) => {
  const {
    id,
    name,
    email,
    phoneNumber,
    avatar,
    active,
    isOnboarded,
    accessList,
    isPasswordExpired,
  } = user
  const checkPermission = useCheckPermission()
  const { isRetailerAdmin } = useAuthContext()

  const [reInviteUser] = useApolloMutation(REINVITE_USER, {
    variables: {
      email,
    },
    onCompleted() {
      refetchUsers()
      toast.success('Invitation sent')
    },
  })

  const getUserPosition = () => {
    let position = POSITION_TITLES.AGENT
    for (let index = 0; index < accessList.length; index++) {
      const accessListItemRoles = accessList[index]
      if (accessListItemRoles.roles.includes(ROLE_TYPES.PLATFORM_ADMIN)) {
        position = POSITION_TITLES.PLATFORM_ADMIN
        break
      } else if (accessListItemRoles.roles.includes(ROLE_TYPES.PLATFORM_ANALYST)) {
        position = POSITION_TITLES.PLATFORM_ANALYST
        break
      } else if (accessListItemRoles.roles.includes(ROLE_TYPES.RETAILER_ADMIN)) {
        position = POSITION_TITLES.RETAILER_ADMIN
        break
      }
    }
    return position
  }

  const isUserHasEditStorePermission = checkPermission(PERMISSION_NAMES.EDIT_AGENT)
  const availability = useAgentAvailability(id)
  const userPosition = getUserPosition()
  const userStores = accessList.filter(accessListItem => accessListItem.store)

  return (
    <TableRow
      key={email}
      classes={{
        root: !active && 'deactive',
      }}
      hover
    >
      <TableCell className="agents-table-cell">
        <div className="media">
          {renderAvatar(avatar, availability)}
          <div className="media-body pt-5">
            <h5 className="mb-5 fw-bold">{name}</h5>
            <span>{userPosition}</span>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {!isPasswordExpired && (
          <span className="page-table-cell">{getUserStatus(active, isOnboarded)}</span>
        )}
        {isPasswordExpired && isRetailerAdmin && !isMobile && (
          <Button
            className="reinvite-agent-btn"
            onClick={reInviteUser}
          >
            <SyncIcon />
            <span className="reinvite-button-text">Invite again</span>
          </Button>
        )}
      </TableCell>
      <TableCell><span className="page-table-cell">{email}</span></TableCell>
      <TableCell><span className="page-table-cell">{phoneNumber || '-'}</span></TableCell>
      <TableCell><span className="page-table-cell">{pluralize(userStores.length, 'store')}</span></TableCell>
      <TableCell>
        <span className="page-table-cell">
          <TableActions
            {...actions}
            hasPermission={isUserHasEditStorePermission}
            isPasswordExpired={isPasswordExpired}
            isRetailerAdmin={isRetailerAdmin}
            reInviteUser={reInviteUser}
            row={{ id, active }}
            size={isMobile ? 'mb' : 'lg'}
            deactivate
          />
        </span>
      </TableCell>
    </TableRow>
  )
}

UserTableRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    avatar: PropTypes.string,
    availability: PropTypes.string,
    active: PropTypes.string,
    isOnboarded: PropTypes.string,
    accessList: PropTypes.arrayOf(PropTypes.shape()),
    roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isPasswordExpired: PropTypes.bool,
  }).isRequired,
  refetchUsers: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  }).isRequired,
}
