import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  usersDropdownList: {
    pointerEvents: 'none',
  },
  usersWrapper: {
    backgroundColor: '#D8DADD',
    padding: '5px 10px',
  },
  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    marginTop: 10,
    '&:last-child': {
      marginBottom: 10,
    },
  },

  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
