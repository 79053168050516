import { withStyles } from '@material-ui/core/styles'
import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core'

const FormControlLabel = withStyles(() => ({
  root: {
    display: 'flex',
    marginBottom: 8,
  },
  label: {
    lineHeight: '16px',
    fontSize: '12px',
  },
}))(MuiFormControlLabel)

export default FormControlLabel
