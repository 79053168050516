
import { useState } from 'react'
import PropTypes from 'prop-types'
import UserHeaderBlock from 'Components/UserHeaderBlock'
import ReactStars from 'react-rating-stars-component'
import { REVIEW_ANSWERS } from 'Constants/common'

const REVIEW_EXCELLENT = 5
const PostReview = ({
  storeName,
  clientName,
  clientLogo,
  postAnswer,
}) => {
  const [reviewPoint, setReviewPoint] = useState(REVIEW_EXCELLENT)
  const [reviewMsg, setReviewMsg] = useState(null)
  return (
    <div className="post_card">
      <div className="card-header justify-content-center d-flex">
        <p className="text-heading">{storeName}</p>
      </div>
      <div className="pcard-body">
        <UserHeaderBlock
          address="Posting publicly"
          logo={clientLogo}
          name={clientName}
        />
        <div className="row">
          <div className="col-2" />
          <div className="col-10 reviewArea">
            <ReactStars
              activeColor="#FAA61A"
              classNames="reviewStar"
              count={5}
              size={26}
              value={reviewPoint}
              onChange={setReviewPoint}
            />
            <textarea
              className="txtMsg"
              name="requestMessage"
              placeholder="Share details of your own experience at this place"
              value={reviewMsg}
              onChange={e => setReviewMsg(e.target.value)}
            />
            {reviewMsg === '' && (
              <span className="error-message">Message should not be empty</span>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn mr-2"
            type="button"
            onClick={() => { window.close() }}
          >
            Cancel
          </button>
          <button
            className="btn btnPost"
            disabled={!reviewMsg}
            type="button"
            onClick={() => postAnswer(REVIEW_ANSWERS[reviewPoint - 1], reviewMsg)}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  )
}

PostReview.propTypes = {
  storeName: PropTypes.string,
  clientName: PropTypes.string,
  clientLogo: PropTypes.string,
  postAnswer: PropTypes.func,
}

PostReview.defaultProps = {
  storeName: '',
  clientName: '',
  clientLogo: '',
  postAnswer: () => {},
}

export default PostReview
