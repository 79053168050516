import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import { TextField } from 'Components/common/TextField'
import { useController } from 'react-hook-form'

export const Autocomplete = ({
  control,
  name,
  inputProps,
  handleChange,
  ...autocompleteProps
}) => {
  const {
    field: {
      ref, onChange, value, ...fieldProps
    },
  } = useController({
    name,
    control,
    defaultValue: '',
  })

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      inputValue={value}
      renderInput={params => (
        <TextField
          {...params}
          {...fieldProps}
          {...inputProps}
          inputRef={ref}
          variant="outlined"
          onChange={onChange}
        />
      )}
      disableClearable
      onChange={(_, data) => {
        onChange(data)
        handleChange(data)
      }}
    />
  )
}

Autocomplete.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  inputProps: PropTypes.shape({
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    readOnly: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  handleChange: PropTypes.func,
}

Autocomplete.defaultProps = {
  control: null,
  inputProps: null,
  handleChange: () => {},
}
