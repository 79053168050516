import React, { useCallback, useState, useEffect } from 'react'
import className from 'classnames'
import Typography from '@material-ui/core/Typography'
import { useOrganizations } from 'Hooks/useOrganizations'
import { isMobile } from 'react-device-detect'
import AsyncSelect from 'react-select/async'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { DatePicker } from 'Components/DatePicker/DatePicker'
import { useAuthContext } from 'Providers/AuthProvider'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useStyles } from './styles'

import './styles.scss'

const allOrganizationsOption = { value: '', label: 'All' }

const initPagination = {
  pagination: {
    offset: 0,
    size: 1000,
  },
}

export const AnalyticsPageHeader = ({
  selectedDateFrom,
  selectedDateTo,
  selectedOrganization,
  setSelectedDateFrom,
  setSelectedDateTo,
  setSelectedOrganization,
  title,
  handleExport,
  withExport,
}) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const [searchQuery, setSearchQuery] = useState('')
  const { organizationsData } = useOrganizations(initPagination)

  const {
    getOrganizationId, isPlatformAdmin, isPlatformAnalyst, getOrganization,
  } = useAuthContext()

  const organizationId = getOrganizationId()

  const isPlatformAdminOrAnalyst = isPlatformAdmin || isPlatformAnalyst

  const getOrganizationsOptions = useCallback(() => {
    const organizations = organizationsData.map(organization => ({ value: organization.id, label: organization.name }))

    return [allOrganizationsOption, ...organizations]
  }, [organizationsData])

  useEffect(() => {
    if (!isPlatformAdminOrAnalyst) {
      const selectedOrg = getOrganization()
      if (selectedOrg) {
        const formattedOrg = {
          value: selectedOrg.id,
          label: selectedOrg.name,
        }
        setSelectedOrganization(formattedOrg)
      }
    }
  }, [isPlatformAdminOrAnalyst, organizationId, getOrganization])

  const loadOrganizationOptions = value => new Promise((resolve) => {
    const filterOperations = getOrganizationsOptions().filter(
      row => String(row.label).toLocaleLowerCase().includes(String(value).toLocaleLowerCase()),
    )
    resolve(filterOperations)
  })

  const customStyle = {
    control: provided => ({
      ...provided,
      border: 0,
    }),
    indicatorSeparator: () => {},
    valueContainer: () => ({
      padding: 0,
    }),
    menu: () => ({
      position: 'absolute',
      backgroundColor: 'white',
      width: 190,
      zIndex: 1,
    }),
  }
  const handleChange = organization => setSelectedOrganization(organization)

  const DropdownIndicator = () => (<ArrowDropDownIcon />)

  const handleInputChange = (value) => {
    setSearchQuery(value)
    return value
  }

  const defaultOrganizationsValue = getOrganizationsOptions()[0].value

  return (
    <div className={classes.pageHeader}>
      <div className={classes.mobileHeader}>
        <Typography variant={isMobile ? 'h6' : 'h2'}>{title}</Typography>
        {isMobile && withExport && (
          <Button
            aria-label="Download CSV"
            className={classes.exportButton}
            startIcon={<GetAppIcon />}
            variant="outlined"
            disableRipple
            onClick={handleExport}
          >
            Export billing CSV
          </Button>
        )}
      </div>
      <div className={classes.pickersWrapper}>
        <div>
          <Typography
            className={classes.label}
            display="block"
            variant="caption"
          >
            Date range:
          </Typography>
          <DatePicker
            handleChange={setSelectedDateFrom}
            maxDate={selectedDateTo}
            selectedDate={selectedDateFrom}
          />
          <DatePicker
            handleChange={setSelectedDateTo}
            minDate={selectedDateFrom}
            selectedDate={selectedDateTo}
          />
        </div>
        <div className={classes.organizationWrapper}>
          <Typography
            className={classes.label}
            display="block"
            variant="caption"
          >
            Organization
          </Typography>
          {/* TODO add debounce */}
          <AsyncSelect
            className={className(classes.organizationsSelect, 'r-custom-async-select-root')}
            components={{ DropdownIndicator }}
            defaultInputValue={defaultOrganizationsValue}
            defaultOptions={getOrganizationsOptions()}
            isDisabled={!isPlatformAdminOrAnalyst}
            loadOptions={loadOrganizationOptions}
            styles={customStyle}
            value={selectedOrganization}
            onChange={handleChange}
            onInputChange={handleInputChange}
          />
        </div>
        {!isMobile && withExport && (
          <Button
            aria-label="Download CSV"
            className={classes.exportButton}
            startIcon={<GetAppIcon />}
            variant="outlined"
            disableRipple
            onClick={handleExport}
          >
            Export billing CSV
          </Button>
        )}
      </div>
    </div>
  )
}

AnalyticsPageHeader.propTypes = {
  selectedDateFrom: PropTypes.instanceOf(Date).isRequired,
  selectedDateTo: PropTypes.instanceOf(Date).isRequired,
  selectedOrganization: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedDateFrom: PropTypes.func.isRequired,
  setSelectedOrganization: PropTypes.func.isRequired,
  setSelectedDateTo: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleExport: PropTypes.func,
  withExport: PropTypes.bool,
}

AnalyticsPageHeader.defaultProps = {
  handleExport: () => {},
  withExport: false,
}
