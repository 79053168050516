import React from 'react'
import MuiTab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './styles'

export const Tab = withStyles(styles)(props => (
  <MuiTab
    disableRipple
    {...props}
  />
))
