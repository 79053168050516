import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { styles } from './styles'

export const LinkButton = withStyles(styles)(({
  title,
  icon: Icon,
  children,
  ...restProps
}) => (
  <MuiButton
    {...restProps}
    disableRipple
  >
    {Icon && <span className="icon-wrapper"><Icon /></span>}
    {' '}
    {title || children}
  </MuiButton>
))

LinkButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
  icon: PropTypes.elementType,
}

LinkButton.defaultProps = {
  title: '',
  className: '',
  children: '',
  icon: null,
}
