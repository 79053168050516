import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import moment from 'moment'
import range from 'lodash.range'
import { useStyles } from './styles'

export const VerticalChart = ({ paymentsPerDay, dateRange }) => {
  const classes = useStyles()
  const paymentsValue = useMemo(() => paymentsPerDay.reduce((r, v) =>
    ({ ...r, [v.date]: v.value }), {}), [paymentsPerDay])

  const fullData = range(moment(dateRange.to).diff(moment(dateRange.from), 'days') + 1).map(
    (delta) => {
      const dateStr = moment(dateRange.from).add(delta, 'days').format('YYYY-MM-DD')
      return {
        date: dateStr,
        value: paymentsValue[dateStr],
      }
    },
  )

  const labels = fullData.map(row => moment(row.date, 'YYYY-MM-DD').format('MM/DD'))
  const ccValues = fullData.map(row => row.value?.credit_card || 0)
  const achValues = fullData.map(row => row.value?.bank || 0)

  const chartData = {
    labels,
    datasets: [
      {
        display: 'auto',
        label: 'ACH',
        backgroundColor: '#017AFF',
        data: achValues,
      },
      {
        display: 'auto',
        label: 'Credit Card',
        backgroundColor: '#FF7A59',
        data: ccValues,
      },
    ],
  }

  const options = {
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          callback(value) {
            return `$${value}`
          },
        },
        stacked: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    maintainAspectRatio: false,
  }

  return (
    <div className={classes.chartContainer}>
      <Typography className={classes.chartTitle}>Payments per day</Typography>
      <div className={classes.verticalChartWrapper}>
        <Bar
          data={chartData}
          options={options}
        />
      </div>
    </div>
  )
}

VerticalChart.propTypes = {
  paymentsPerDay: PropTypes.arrayOf(PropTypes.element),
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
}

VerticalChart.defaultProps = {
  dateRange: null,
  paymentsPerDay: {},
}
