import { ROLE_TYPES } from 'Constants/rolesConstants'

export const PERMISSION_NAMES = {
  ADD_STORE: 'addStore',
  EDIT_STORE: 'editStore',
  ADD_AGENT: 'addAgent',
  EDIT_AGENT: 'editAgent',
}

export const permissions = {
  [PERMISSION_NAMES.ADD_STORE]: [ROLE_TYPES.RETAILER_ADMIN],
  [PERMISSION_NAMES.EDIT_STORE]: [ROLE_TYPES.RETAILER_ADMIN],
  [PERMISSION_NAMES.ADD_AGENT]: [ROLE_TYPES.RETAILER_ADMIN],
  [PERMISSION_NAMES.EDIT_AGENT]: [ROLE_TYPES.RETAILER_ADMIN],
}
