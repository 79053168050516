
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Avatar } from 'Components/Avatar/Avatar'

const UserHeaderBlock = ({
  logo, name, address,
}) => (
  <div className="row">
    <div className="col-2">
      <Avatar
        avatarSrc={logo}
        className={classNames(
          'message-avatar',
          'response',
        )}
        userName={name}
      />
    </div>
    <div className="col-10">
      <p
        className="text-heading"
        id="var-store-name-summmary"
      >
        {name}
      </p>
      <p
        className="text-description"
        id="var-store-address-summmary"
      >
        {address}
      </p>
    </div>
  </div>
)

UserHeaderBlock.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
  address: PropTypes.string,
}

UserHeaderBlock.defaultProps = {
  name: '',
  logo: '',
  address: '',
}

export default UserHeaderBlock
