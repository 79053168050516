
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Avatar } from '../../../Components/Avatar/Avatar'

const PaymentFailure = ({
  tryAgain,
  storeName,
  storeAddress,
  storeLogo,
}) => (
  <div id="payment-failure">
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-2 justify-content-center align-self-center">
            <Avatar
              avatarSrc={storeLogo}
              className={classNames(
                'message-avatar',
                'response',
              )}
              userName={storeName}
            />
          </div>
          <div className="col justify-content-center align-self-center">
            <p
              className="text-heading"
              id="var-store-name-failure"
            >
              {storeName}
            </p>
            <p
              className="text-description"
              id="var-store-address-failure"
            >
              {storeAddress}
            </p>
          </div>
        </div>
      </div>
      <div className="pcard-body">
        <div className="text-center">
          <svg
            className="bi bi-x-circle"
            fill="currentColor"
            height="80"
            style={{ color: '#FF4545' }}
            viewBox="0 0 16 16"
            width="80"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
          <p className="text-content-highlight mx-5 my-3">Oops! There was a problem...</p>
          <button
            className="btn payment_button btn-failure"
            type="button"
            onClick={tryAgain}
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  </div>
)

PaymentFailure.propTypes = {
  tryAgain: PropTypes.func,
  storeName: PropTypes.string,
  storeAddress: PropTypes.string,
  storeLogo: PropTypes.string,
}

PaymentFailure.defaultProps = {
  tryAgain: () => {},
  storeName: '',
  storeAddress: '',
  storeLogo: '',
}

export default PaymentFailure
