export const styles = {
  root: {
    padding: '5px 0',
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'initial',
    },
  },
  textSecondary: {
    color: '#017AFF',
    '&:hover': {
      color: '#0056bd',
    },
    '&:focus': {
      color: '#017AFF',
    },
    '&:active': {
      color: '#1587ff',
    },
  },
  textPrimary: {
    color: '#FF7A59',
    '&:hover': {
      color: '#D8392F',
    },
    '&:active': {
      color: '#FF4545',
    },
  },
  disabled: {
    color: '#D8DADD',
  },
}
