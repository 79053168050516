import * as yup from 'yup'
import { phoneRegExp, passwordRegExp } from 'Constants/common'

const MIN_LENGTH_NAME = 3
const MAX_LENGTH_TEXT = 255

const MIN_LENGTH_PHONE = 8
const MAX_LENGTH_PHONE = 16

export const validationSchema = yup.object().shape({
  name: yup.string()
    .min(MIN_LENGTH_NAME)
    .max(MAX_LENGTH_TEXT)
    .required(),
  phoneNumber: yup.string()
    .matches(phoneRegExp, { message: 'Phone number should contain leading ‘+’ and consist of digits only', excludeEmptyString: true })
    .min(MIN_LENGTH_PHONE, 'Minimum 7 digitals')
    .max(MAX_LENGTH_PHONE, 'Maximum 15 digitals')
    .required(),
  timezone: yup.string()
    .required(),
  password: yup.string()
    .matches(passwordRegExp, 'Password should contain numbers, symbols, upper- and lower-case letters')
    .required(),
})
