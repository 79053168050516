import { gql } from '@apollo/client'

export const CREATE_RETAILER = gql`
  mutation CreateRetailer(
    $organization: String!
    $stores: [String!]!
    $name: String!
    $email: String!
    $password: String
    $nmgId: String!
  ) {
    CreateRetailer(
      organization: $organization
      stores: $stores
      name: $name
      email: $email
      password: $password
      nmgId: $nmgId
    ) {
      id
      nmgId
      name
      email
      phoneNumber
      accessList {
        organization
        store
        roles
      }
    }
  }
`

export const UPDATE_RETAILER = gql`
mutation UpdateUser($id: ID!, $name: String, $email: String){
  UpdateUser(id: $id , name: $name, email: $email) {
    id
    name
    email
    avatar
  }
}
`

export const DELETE_RETAILER = gql`
    mutation RemoveFromStore($id: ID!, $store: String!){
        RemoveFromStore(id: $id , store: $store) {
            result
        }
    }
`

export const DEACTIVATE_RETAILER = gql`
  mutation DeactivateUser($userId: ID!) {
    DeactivateUser(id: $userId) {
      result
    }
  }
`

export const RECOVER_AGENT = gql`
  mutation RecoverUser($id: ID!) {
    RecoverUser(id: $id) {
      result
    }
  }
`
