import AppConfig from 'Constants/AppConfig'
import {
  COLLAPSED_SIDEBAR,
  DARK_MODE,
  BOXED_LAYOUT,
  RTL_LAYOUT,
  MINI_SIDEBAR,
  SEARCH_FORM_ENABLE,
  CHANGE_THEME_COLOR,
  TOGGLE_SIDEBAR_IMAGE,
  SET_SIDEBAR_IMAGE,
  SET_LANGUAGE,
  START_USER_TOUR,
  STOP_USER_TOUR,
  TOGGLE_DARK_SIDENAV,
  CHANGE_AGENCY_LAYOUT_BG,
} from '../Actions/types'

const INIT_STATE = {
  navCollapsed: AppConfig.navCollapsed,
  darkMode: AppConfig.darkMode,
  boxLayout: AppConfig.boxLayout,
  rtlLayout: AppConfig.rtlLayout,
  miniSidebar: AppConfig.miniSidebar,
  searchFormOpen: false, // search form by default false
  startUserTour: false,
  isDarkSidenav: AppConfig.isDarkSidenav,
  themes: [
    {
      id: 1,
      name: 'primary',
    },
    {
      id: 2,
      name: 'secondary',
    },
  ],
  activeTheme: {
    id: 1,
    name: 'primary',
  },
  sidebarBackgroundImages: [
      `${process.env.PUBLIC_URL}/assets/images/img/sidebar-1.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/img/sidebar-2.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/img/sidebar-3.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/img/sidebar-4.jpg`,
  ],
  enableSidebarBackgroundImage: AppConfig.enableSidebarBackgroundImage, // default enable sidebar background
  selectedSidebarImage: AppConfig.sidebarImage, // default sidebar background image
  locale: AppConfig.locale,
  languages: [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'en',
    },
  ],
  agencyLayoutBgColors: [
    {
      id: 1,
      class: 'bg-grdnt-violet',
      active: true,
    },
    {
      id: 2,
      class: 'bg-grdnt-youtube',
    },
    {
      id: 3,
      class: 'bg-grdnt-primary-danger',
    },
    {
      id: 4,
      class: 'bg-grdnt-purple-danger',
    },
    {
      id: 5,
      class: 'bg-grdnt-purple-dark',
    },
  ],
}

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COLLAPSED_SIDEBAR:
      return { ...state, navCollapsed: action.isCollapsed }

    case START_USER_TOUR:
      return { ...state, startUserTour: true }

    case STOP_USER_TOUR:
      return { ...state, startUserTour: false }

    case CHANGE_THEME_COLOR:
      return { ...state, activeTheme: action.payload }

    case DARK_MODE:
      return { ...state, darkMode: action.payload }

    case BOXED_LAYOUT:
      return { ...state, boxLayout: action.payload }

    case RTL_LAYOUT:
      return { ...state, rtlLayout: action.payload }

    case MINI_SIDEBAR:
      return { ...state, miniSidebar: action.payload }

    case SEARCH_FORM_ENABLE:
      document.body.classList.toggle('search-active', !state.searchFormOpen)
      return { ...state, searchFormOpen: !state.searchFormOpen }

    case TOGGLE_SIDEBAR_IMAGE:
      return { ...state, enableSidebarBackgroundImage: !state.enableSidebarBackgroundImage }

    case SET_SIDEBAR_IMAGE:
      return { ...state, selectedSidebarImage: action.payload }

    case SET_LANGUAGE:
      return { ...state, locale: action.payload }

    case TOGGLE_DARK_SIDENAV:
      return { ...state, isDarkSidenav: !state.isDarkSidenav }

    case CHANGE_AGENCY_LAYOUT_BG:
      // eslint-disable-next-line no-case-declarations
      const layoutsBg = []
      // eslint-disable-next-line no-restricted-syntax
      for (const layoutBg of state.agencyLayoutBgColors) {
        if (layoutBg.id === action.payload.id) {
          layoutBg.active = true
        } else {
          layoutBg.active = false
        }
        layoutsBg.push(layoutBg)
      }
      return {
        ...state,
        agencyLayoutBgColors: layoutsBg,
      }

    default: return { ...state }
  }
}

export default Settings
