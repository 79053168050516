import { gql } from '@apollo/client'

export const GET_PAGE_HISTORY = gql`
query PageHistory(
  $client: String!
  $pagination: PaginationInput
  $sorting: SortingInput
) {
  PageHistory(client: $client, pagination: $pagination, sorting: $sorting) {
    items {
      client
      url
      referer
      createdAt
    }
  }
}
`
