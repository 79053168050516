export const DEFAULT_MESSENGER_STYLE_SETTINGS = {
  logo: '',
  messengerBrandColor: '#D8392F',
  messengerHeaderBackground: '#3a1c1c',
  messengerChatBackground: '#F8F9F9',
  messengerActiveAllBackground: '#253342',
  launcherShape: 'launcher-shape-6',
  launcherIcon: 'comments',
  launcherBackgroundColor: '#D8392F',
  launcherIconColor: '#FFFFFF',
  launcherSize: 'launcher-size-xl',
  launcherPositionDesktop: 'launcher-position-bottom-right',
  launcherPositionMobile: 'launcher-position-mobile-bottom-right',
}

export const ICONS = [
  'comments',
  'phone',
  'phone-setting',
  'comment',
  'comment-text',
  'comment-video',
  'comment-outline',
  'comment-alert',
]

export const LAUNCHER_POSITION_DESKTOP = [
  'launcher-position-bottom-right',
  'launcher-position-bottom-center',
  'launcher-position-bottom-left',
  'launcher-position-top-left',
  'launcher-position-top-right',
]

export const LAUNCHER_POSITION_MOBILE = [
  'launcher-position-mobile-bottom-right',
  'launcher-position-mobile-bottom-center',
  'launcher-position-mobile-bottom-left',
  'launcher-position-mobile-top-left',
  'launcher-position-mobile-top-right',
]

export const SHAPE_CLASSES = [
  'launcher-shape-1',
  'launcher-shape-2',
  'launcher-shape-3',
  'launcher-shape-4',
  'launcher-shape-5',
  'launcher-shape-6',
  'launcher-shape-7',
  'launcher-shape-8',
  'launcher-shape-9',
  'launcher-shape-10',
]

export const LAUNCHER_SIZES = [
  { key: 'launcher-size-sm', name: 'Small' },
  { key: 'launcher-size-md', name: 'Medium' },
  { key: 'launcher-size-lg', name: 'Large' },
  { key: 'launcher-size-xl', name: 'Giant' },
]
