import { makeStyles } from '@material-ui/core'

const BREAK_POINT_1700 = 1700

export const useStyles = makeStyles(theme => ({
  blockWrapper: {
    height: 310,
    border: '1px solid #c9c9c9',
    borderRadius: 5,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up(BREAK_POINT_1700)]: {
      height: 350,
    },
    maxWidth: 650,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    [theme.breakpoints.up('xl')]: {
      height: 520,
    },
  },
  doughnutChartWrapper: {
    position: 'relative',
    width: 160,
    margin: '15px auto',
    [theme.breakpoints.up('xl')]: {
      width: 360,
    },
  },
  doughnutLabel: {
    fontSize: 15,
    fontWeight: 'bold',
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
    },
  },
  doughnutNumber: {
    fontSize: 10,
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
  chartTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
    },
  },
  labelWrapper: {
    position: 'absolute',
    top: '40%',
    left: '39%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xl')]: {
      top: '42%',
      left: '42%',
    },
  },
  legend: {
    display: 'flex',
  },
  legendItem: {
    marginLeft: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: 13,
    '&:first-child': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 7,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 16,
      marginLeft: 25,
    },
  },
  legendItemTitle: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: 7,
    height: 7,
    borderRadius: 25,
    marginRight: 5,
  },
}))
