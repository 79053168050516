import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { isMobile } from 'react-device-detect'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import { Button } from 'reactstrap'
import VpnKeyOutlined from '@material-ui/icons/VpnKeyOutlined'

import Input from 'Components/common/Input'
import { passwordValidationSchema } from 'Pages/settings/agent-profile/validationSchema'
import { UPDATE_MY_PASSWORD } from 'GraphQL/mutations/user'
import { useAuthContext } from 'Providers/AuthProvider'

export const ChangePassword = () => {
  const { getToken } = useAuthContext()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)

  const accessToken = getToken()

  const [updateMyPassword] = useMutation(UPDATE_MY_PASSWORD, {
    onCompleted: () => {
      toast.success('You have successfully updated your password')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const handleShowPassword = () => {
    setIsPasswordVisible(state => !state)
  }
  const handleShowConfirmPassword = () => {
    setIsConfirmPasswordVisible(state => !state)
  }

  const {
    control: passwordControl,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors },
  } = useForm({
    resolver: yupResolver(passwordValidationSchema),
  })

  const passwordSubmit = (data) => {
    const { newPassword } = data

    updateMyPassword({
      variables: {
        accessToken,
        password: newPassword,
      },
    })
  }

  return (
    <>
      <section className="profile-row d-flex no-border">
        <div className={isMobile ? 'change-password-description w-100 d-flex flex-column' : 'change-password-description w-30 d-flex flex-column'}>
          <span className="profile-label">Change password</span>
          <span className="profile-label-description">
            Input your new password and repeat it to change the password.
          </span>
        </div>
        <div className={isMobile ? 'change-password-fields w-100' : 'change-password-fields w-50'}>
          <div className="new-password">
            <Input
              control={passwordControl}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              error={!!passwordErrors.newPassword}
              errorMessage={passwordErrors.newPassword?.message}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              label="New password"
              labelIcon={<VpnKeyOutlined />}
              name="newPassword"
              placeholder="* * * * * * * *"
              type={isPasswordVisible ? 'text' : 'password'}
              fullWidth
            />
          </div>
          <div className="repeat-new-password">
            <Input
              control={passwordControl}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmPassword}
                  >
                    {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              error={!!passwordErrors.confirmNewPassword}
              errorMessage={passwordErrors.confirmNewPassword?.message}
              inputProps={{
                autocomplete: 'repeat-new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              label="Repeat new password"
              labelIcon={<VpnKeyOutlined />}
              name="confirmNewPassword"
              placeholder="* * * * * * * *"
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              fullWidth
            />
          </div>
        </div>
      </section>

      <section className={isMobile ? 'd-flex justify-content-end pt-25 w-100 pb-25' : 'd-flex justify-content-end pt-25 w-80'}>
        <Button
          className="btn-custom"
          onClick={handlePasswordSubmit(passwordSubmit)}
        >
          <span className="pl-5">Save new password</span>
        </Button>
      </section>
    </>
  )
}
