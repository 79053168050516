import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

import classNames from 'classnames'
import { isMobileOnly } from 'react-device-detect'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import { AudioSettings } from './AudioSettings'
import { VideoSettings } from './VideoSettings'
import './styles.scss'

export const SettingsModal = ({ isOpen, closeModal }) => {
  const [activeBlock, setActiveBlock] = useState('video')

  const isAudioSettings = activeBlock === 'audio'
  const isVideoSettings = activeBlock === 'video'

  return (
    <ModalBase
      modalBodyOutlined={false}
      open={isOpen}
      onClose={closeModal}
    >
      <div className={classNames('settings-modal-body', {
        'settings-modal-body-mobile': isMobileOnly,
      })}
      >
        <div className="left-side">
          <h2>Settings</h2>
          <Button
            className={classNames(
              'button',
              { active: isVideoSettings },
            )}
            onClick={() => setActiveBlock('video')}
          >
            <i className="zmdi zmdi-videocam" />
            Video
          </Button>
          <Button
            className={classNames(
              'button',
              { active: isAudioSettings },
            )}
            onClick={() => setActiveBlock('audio')}
          >
            <i className="zmdi zmdi-mic-outline" />
            Audio
          </Button>

        </div>
        <div className="right-side">
          <div className="settings-modal-content">
            {isAudioSettings && (<AudioSettings />)}
            {isVideoSettings && (<VideoSettings />)}
          </div>
        </div>
      </div>
    </ModalBase>
  )
}

SettingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  videoElementRef: PropTypes.shape({
    current: PropTypes.shape({
      setSinkId: PropTypes.func,
      srcObject: PropTypes.shape({}),
    }),
  }).isRequired,
}
