
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getLogoFromURL, getThemeColorFromURL } from 'Helpers/helpers'
import { Button } from '@material-ui/core'
import UserHeaderBlock from 'Components/UserHeaderBlock'

const AccountSelection = ({
  storeInfo,
  reviewDestinations,
}) => (
  <div className="account_card">
    <div className="card-header">
      <UserHeaderBlock
        address={storeInfo?.address}
        logo={storeInfo?.logo}
        name={storeInfo?.name}
      />
    </div>
    <div className="option-body">
      <p className="text-bold mb-4">Leave your reviews on:</p>
      {reviewDestinations?.map(destination => (
        <div className="mb-2">
          <a
            className="w-100"
            href={destination.url}
            rel="noreferrer"
            target="_blank"
          >
            <Button
              className="btnSocialReview"
              style={{ backgroundColor: getThemeColorFromURL(destination.url) }}
            >
              <FontAwesomeIcon
                className="review-socialIcon mr-2"
                icon={getLogoFromURL(destination.url)}
              />
              <span className="review-title">{destination.title}</span>
            </Button>
          </a>
        </div>
      ))}
    </div>
  </div>
)

AccountSelection.propTypes = {
  reviewDestinations: PropTypes.array,
  storeInfo: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    address: PropTypes.string,
  }),
}

AccountSelection.defaultProps = {
  storeInfo: {
    name: '',
    logo: '',
    address: '',
  },
  reviewDestinations: [],
}

export default AccountSelection
