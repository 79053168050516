import * as yup from 'yup'
import { passwordRegExp, phoneRegExp } from 'Constants/common'

const minLengthName = 3
const maxLengthText = 255

const MIN_LENGTH_PHONE = 8
const MAX_LENGTH_PHONE = 16

export const profileValidationSchema = yup.object().shape({
  name: yup.string()
    .min(minLengthName)
    .max(maxLengthText)
    .required(),
  email: yup
    .string()
    .email('E-mail is incorrect')
    .max(maxLengthText)
    .required(),
  phoneNumber: yup.string()
    .matches(phoneRegExp, { message: 'Phone number should contain leading ‘+’ and consist of digits only', excludeEmptyString: true })
    .min(MIN_LENGTH_PHONE, 'Minimum 7 digitals')
    .max(MAX_LENGTH_PHONE, 'Maximum 15 digitals')
    .required(),
  browserNotificationStatus: yup.boolean(),
  smsNotificationStatus: yup.boolean(),
  emailNotificationStatus: yup.boolean(),
})

export const passwordValidationSchema = yup.object().shape({
  newPassword: yup.string()
    .matches(passwordRegExp, 'Password should contain numbers, symbols, upper- and lower-case letters')
    .required(),
  confirmNewPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords do not match')
    .required(),
})
