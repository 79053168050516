import { gql } from '@apollo/client'

export const GET_NOTES_QUERY = gql`
query Notes(
  $conversation: String!
  $pagination: PaginationInput
  $sorting: SortingInput
  $userId: String!
) {
  Notes(
    conversation: $conversation
    user: $userId
    pagination: $pagination
    sorting: $sorting
  ) {
    items {
      id
      body
      updatedAt
    }
  }
}`
