import React from 'react'
import PropTypes from 'prop-types'
import ReactStars from 'react-rating-stars-component'
import { REVIEW_ANSWERS } from 'Constants/common'
import './styles.scss'

const ReviewResponse = ({ name, rating, comment }) => (
  <div className="review-response">
    <span className="head-text">
      {name}
      {' '}
      left a review...
    </span>
    <ReactStars
      activeColor="#FAA61A"
      count={5}
      edit={false}
      size={26}
      value={REVIEW_ANSWERS.findIndex(obj => obj === rating) + 1}
    />
    <span>{comment}</span>
  </div>
)

ReviewResponse.propTypes = {
  name: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
}

export default ReviewResponse
