import { useSelector } from 'react-redux'
import { useAuthContext } from 'Providers/AuthProvider'
import { ROLE_TYPES } from 'Constants/rolesConstants'

export const useMenuItems = () => {
  const sidebar = useSelector(state => state.sidebar)
  const { sidebarMenus } = sidebar
  const { currentUser } = useAuthContext()

  const roles = currentUser?.roles

  const isPlatformAdminOrAnalyst = roles.some(
    role => role === ROLE_TYPES.PLATFORM_ADMIN || role === ROLE_TYPES.PLATFORM_ANALYST,
  )
  const isRetailerAdmin = roles.includes(ROLE_TYPES.RETAILER_ADMIN)

  if (isPlatformAdminOrAnalyst) {
    return sidebarMenus.platformAdminLinks
  }
  if (isRetailerAdmin) {
    const analyticsRoute = sidebarMenus.retailerAdminLinks.find(menu => menu.menu_title.includes('Analytics'))
    const paymentsRoute = sidebarMenus.retailerAdminLinks.find(menu => menu.menu_title.includes('Payment Reports'))
    const workflowsRoute = sidebarMenus.retailerAdminLinks.find(menu => menu.menu_title.includes('Workflows'))
    return [...sidebarMenus.retailerLinks, analyticsRoute, paymentsRoute, workflowsRoute]
  }
  return [...sidebarMenus.retailerLinks]
}
