import React, { useState } from 'react'

import { isMobile } from 'react-device-detect'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { useAuthContext } from 'Providers/AuthProvider'
import { useApolloQuery, useApolloMutation } from 'GraphQL/apollo'
import { GET_WIDGET } from 'GraphQL/queries/widget'
import { UPDATE_ORGANIZATION } from 'GraphQL/mutations/organization'
import { UPDATE_WIDGET } from 'GraphQL/mutations/widget'
import ChatStylePreview from './components/ChatStylePreview'
import LauncherPreview from './components/LauncherPreview'
import CompanyLogoControl from './components/Controls/CompanyLogo'
import ColorsControl from './components/Controls/Colors'
import ShapesControl from './components/Controls/LauncherShapes'
import LauncherIconComponent from './components/Controls/LauncherIcons'
import LauncherColor from './components/Controls/LauncherColor'
import LauncherSizes from './components/Controls/LauncherSize'
import LauncherPositionDesktop from './components/Controls/LauncherPositionDesktop'
import LauncherPositionMobile from './components/Controls/LauncherPositionMobile'

import { DEFAULT_MESSENGER_STYLE_SETTINGS } from './config'

import './styles.scss'

const StyleYourMessenger = () => {
  const { getOrganizationId } = useAuthContext()
  const organizationId = getOrganizationId()
  const [updateOrganization] = useApolloMutation(UPDATE_ORGANIZATION)

  const [settings, setSetting] = useState(DEFAULT_MESSENGER_STYLE_SETTINGS)
  const { data: widgetData } = useApolloQuery(GET_WIDGET, {
    variables: {
      organizationID: organizationId,
    },
    onCompleted: (data) => {
      if (data.Widget.styles) {
        setSetting(data.Widget.styles)
      }
    },
  })

  const [updateWidget] = useApolloMutation(UPDATE_WIDGET, {
    refetchQueries: [{
      query: GET_WIDGET,
      variables: { organizationID: organizationId },
    }],
    onError: (error) => {
      toast.error(error.message)
    },
    onCompleted: () => {
      if (settings.logo) {
        updateOrganization({
          variables: {
            id: getOrganizationId(),
            logo: settings.logo,
          },
        })
      }
      toast.success('Successfully updated')
    },
  })

  const handleChangeSetting = (key, value) => {
    setSetting({
      ...settings,
      [key]: value,
    })
  }

  const handleSubmit = () => {
    updateWidget({
      variables: {
        id: widgetData.Widget.id,
        styles: settings,
      },
    })
  }

  const handleReset = () => {
    setSetting(DEFAULT_MESSENGER_STYLE_SETTINGS)
    handleSubmit()
  }

  return (
    <div className="style-your-messenger-container p-20">
      <section className="settings-page-title d-flex flex-column">
        <span>
          <small>Chat Setting</small>
        </span>
        <h3 className="pt-5">Style your Messenger</h3>
      </section>
      <div className="settings-page-rows pt-10">
        <section className="page-row d-flex">
          <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-30 d-flex flex-column'}>
            <span className="settings-label">Chat appearance</span>
            <span className="settings-label-description">Add your logo and brand colors to make the chat look more natural on the website.</span>
          </div>
          <div className={isMobile ? 'd-flex flex-column mt-4 w-100' : 'd-flex flex-column ml-3 w-60'}>
            <CompanyLogoControl
              label="500x500"
              settings={settings}
              onChange={handleChangeSetting}
            />
          </div>
        </section>
        <section className="page-row d-flex">
          {!isMobile && (
            <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-30 d-flex flex-column'}>
              <ChatStylePreview settings={settings} />
            </div>
          )}
          <div className={isMobile ? 'd-flex flex-column mt-4 w-100' : 'd-flex flex-column ml-3 w-60'}>
            <ColorsControl
              settings={settings}
              onChange={handleChangeSetting}
            />
          </div>
        </section>
        {!isMobile && <hr />}
        <section className="page-row d-flex">
          {!isMobile && (
            <div className={isMobile ? 'w-100 d-flex flex-column' : 'w-30 d-flex flex-column'}>
              <LauncherPreview settings={settings} />
            </div>
          )}
          <div className={isMobile ? 'd-flex flex-column w-100' : 'd-flex flex-column ml-3 w-60'}>
            <ShapesControl
              settings={settings}
              onChange={handleChangeSetting}
            />
            <LauncherIconComponent
              settings={settings}
              onChange={handleChangeSetting}
            />
            <LauncherColor
              settings={settings}
              onChange={handleChangeSetting}
            />
            <LauncherSizes
              settings={settings}
              onChange={handleChangeSetting}
            />
            <LauncherPositionDesktop
              settings={settings}
              onChange={handleChangeSetting}
            />
            <LauncherPositionMobile
              settings={settings}
              onChange={handleChangeSetting}
            />
          </div>
        </section>
        <hr />
        <section className="page-row d-flex">
          <div className={isMobile ? 'w-100' : 'w-30'}>
            <Button
              className="form-button-cancel mr-10"
              onClick={handleReset}
            >
              <span className="ti-reload mr-10" />
              {isMobile ? 'Reset' : 'Reset Changes'}
            </Button>
          </div>
          <div className={isMobile ? 'mt-4 w-100' : 'ml-3 w-60 d-flex justify-content-end'}>
            <Button
              className="form-button-accept"
              color="primary"
              onClick={handleSubmit}
            >
              <span className="ti-save mr-10" />
              {isMobile ? 'Save' : 'Save Changes'}
            </Button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default StyleYourMessenger
