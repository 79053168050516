import React, { useState, useEffect } from 'react'

import { getDefaultDateFrom, convertDateToTimeStamp } from 'Helpers/helpers'
import { useApolloLazyQuery } from 'GraphQL/apollo'
import { GET_PAYMENT_ANALYTICS, GET_PAYMENT_HISTORY } from 'GraphQL/queries/payment'
import { toast } from 'react-toastify'
import { downloadCSV } from 'Helpers/json2CSV'
import { PaymentReportPageHeader } from './PaymentReportPageHeader/PaymentReportPageHeader'
import { GeneralStatistics } from './GeneralStatistics/GeneralStatistics'
import { StatisticsCharts } from './StatisticsCharts/StatisticsCharts'
import { PaymentTable } from './PaymentTable/PaymentTable'
import { useStyles } from './styles'

const allOrganizationsOption = { value: '', label: 'All' }

export const PaymentReport = () => {
  const classes = useStyles()

  const [selectedDateFrom, setSelectedDateFrom] = useState(getDefaultDateFrom())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const [selectedOrganization, setSelectedOrganization] = useState(allOrganizationsOption)

  const [getAnalytics, { data: analyticsData }] = useApolloLazyQuery(GET_PAYMENT_ANALYTICS)

  const [getHistory, { data: historyData }] = useApolloLazyQuery(GET_PAYMENT_HISTORY)

  const pagination = {
    offset: 0,
    size: 5,
  }

  const dateRange = {
    from: selectedDateFrom.toISOString(),
    to: selectedDateTo.toISOString(),
  }

  const requestDownloadCSV = (data) => {
    const timeText = `${convertDateToTimeStamp(selectedDateFrom)} to ${convertDateToTimeStamp(selectedDateTo)}`
    const fileName = `Payment analytics report from ${timeText}`
    const result = []
    data.forEach((element) => {
      const achValue = element.value.bank ? element.value.bank : 0
      const ccValue = element.value.credit_card ? element.value.credit_card : 0
      const obj = {
        Date: convertDateToTimeStamp(element.date).replace(',', ''),
        'Total ACH Payments': `$${ achValue}`,
        'Total CC Payments': `$${ ccValue}`,
        'Total Payments': `$${ achValue + ccValue}`,
      }
      result.push(obj)
    })
    downloadCSV(result, fileName)
    toast.success('successfully exported')
  }

  useEffect(() => {
    getAnalytics({
      variables: {
        organization: selectedOrganization.value,
        date: dateRange,
      },
    })
  }, [selectedDateFrom, selectedDateTo, selectedOrganization])

  useEffect(() => {
    getHistory({
      variables: {
        organization: selectedOrganization.value,
        date: dateRange,
        pagination,
      },
    })
  }, [selectedDateFrom, selectedDateTo, selectedOrganization])

  return (
    <div className={classes.PaymentReportPageWrapper}>
      <PaymentReportPageHeader
        disableExport={!analyticsData?.PaymentAnalytics?.paymentsPerDay?.length}
        handleExport={() => requestDownloadCSV(analyticsData?.PaymentAnalytics.paymentsPerDay)}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        selectedOrganization={selectedOrganization}
        setSelectedDateFrom={setSelectedDateFrom}
        setSelectedDateTo={setSelectedDateTo}
        setSelectedOrganization={setSelectedOrganization}
        title="Payment Reports"
      />
      {analyticsData ? (
        <>
          <GeneralStatistics
            analyticsData={analyticsData?.PaymentAnalytics}
          />
          <StatisticsCharts
            analyticsData={analyticsData?.PaymentAnalytics}
            dateRange={dateRange}
          />
          <PaymentTable
            dateRange={dateRange}
            historyData={historyData}
            orgId={selectedOrganization.value}
          />
        </>
      ) : (
        <div className={classes.analyticsEmptyContainer}>
          {`Analytics of "${selectedOrganization.label}" for given period not found!`}
        </div>
      )}
    </div>
  )
}
