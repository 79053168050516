export const AUTO_RESPONSE_TYPES = {
  DEFAULT: 'default',
  WORKING_HOURS: 'working',
  BREAKS: 'breaks',
  WEEKEND_RESPONSE: 'weekends',
}

export const emptyResponses = {
  [AUTO_RESPONSE_TYPES.DEFAULT]: {
    store: '',
    active: true,
    type: AUTO_RESPONSE_TYPES.DEFAULT,
    title: '',
    description: '',
    body: '',
    delay: '',
  },
  [AUTO_RESPONSE_TYPES.WORKING_HOURS]: {
    store: '',
    active: false,
    type: AUTO_RESPONSE_TYPES.WORKING_HOURS,
    title: '',
    description: '',
    body: '',
    delay: '',
  },
  [AUTO_RESPONSE_TYPES.BREAKS]: {
    store: '',
    active: false,
    type: AUTO_RESPONSE_TYPES.BREAKS,
    title: '',
    description: '',
    body: '',
    delay: '',
  },
  [AUTO_RESPONSE_TYPES.WEEKEND_RESPONSE]: {
    store: '',
    active: false,
    type: AUTO_RESPONSE_TYPES.WEEKEND_RESPONSE,
    title: '',
    description: '',
    body: '',
    delay: '',
  },
}

export const emptyStore = { id: '', name: '' }

export const RESPONSE_DESCRIPTIONS = {
  DEFAULT: 'The automated message your customers will receive outside working hours. You can set a delay for this message for cases when an agent might reply immediately.',
  WORKING_HOURS: 'The automated message your customers will receive every day during working hours. You can set a delay for this message for cases when an agent might reply immediately.',
  BREAKS: 'The automated message your users will receive during breaks you indicate in your store hours. You can set a delay for this message for cases when an agent might reply immediately.',
  WEEKEND_RESPONSE: 'The automated message your customers will receive on Saturdays and Sundays outside working hours. You can set a delay for this message for cases when an agent might reply immediately.',
}

export const RESPONSE_TITLES = {
  DEFAULT: 'Non-working hours respond',
  WORKING_HOURS: 'Working hours respond',
  BREAKS: 'Breaks respond',
  WEEKEND_RESPONSE: 'Weekends respond',
}
