import moment from 'moment-timezone'

const timeZonesAbbrs = {
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
}

moment.fn.zoneName = function () {
  const abbr = this.zoneAbbr()
  return timeZonesAbbrs[abbr] || abbr
}

const timezones = moment.tz.zonesForCountry('US')

// Add Timezone for Guam
if (timezones.indexOf('Pacific/Guam')) {
  timezones.push('Pacific/Guam')
}

export const TIMEZONES = timezones
