import { TableHeader } from 'Components/TableHeader'
import { ROLE_TYPES } from 'Constants/rolesConstants'
import { REINVITE_USER } from 'GraphQL/mutations/user'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { useAuthContext } from 'Providers/AuthProvider'
import { toast } from 'react-toastify'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'Pages/Components/TableWithStyles'
import { TableActions } from 'Components/common/Table/TableActions'
import React from 'react'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { AdminCell, getAminStatus } from './AdminCell'
import { organizationHeader } from './organizationTableData'

export const OrganizationTable = ({ refetchOrganizations, organizations, actions }) => {
  const { currentUser } = useAuthContext()
  const isPlatformAdmin = currentUser?.roles.includes(ROLE_TYPES.PLATFORM_ADMIN)

  const handleCellClick = (field, direction) => {
    refetchOrganizations({
      sorting: {
        field,
        direction,
      },
    })
  }

  const [reInviteUser] = useApolloMutation(REINVITE_USER, {
    onCompleted() {
      refetchOrganizations()
      toast.success('Invitation sent')
    },
  })

  const handleReinviteUser = (email) => {
    reInviteUser({
      variables: {
        email,
      },
    })
  }

  return (
    <Table>
      {!isMobileOnly && (
        <TableHeader
          handleCellClick={handleCellClick}
          headerTitles={organizationHeader}
        />
      )}

      <TableBody>
        <>
          {organizations && organizations.map((organization) => {
            const {
              id,
              nmgId,
              name,
              address,
              admin,
            } = organization

            return (
              <TableRow
                key={id}
                hover
              >
                <TableCell className="main-cell">
                  <span className="page-table-cell">{name}</span>
                  {isMobileOnly && (
                    <div className="cell-item">
                      <div className="cell-label">Address</div>
                      <div>{address}</div>
                    </div>
                  )}
                  {isMobileOnly && (
                    <div className="cell-item">
                      <div className="cell-label">NMG</div>
                      <div>{nmgId}</div>
                    </div>
                  )}
                  {isMobileOnly && (
                    <div className="cell-item">
                      <div className="cell-label">Status</div>
                      <div>{getAminStatus(admin)}</div>
                    </div>
                  )}

                  {isMobileOnly && (
                    <div className="cell-item">
                      <div className="cell-label">Organization administrator email</div>
                      <div>{admin[0]?.email}</div>
                    </div>
                  )}
                </TableCell>
                {!isMobileOnly && <TableCell><span className="page-table-cell">{nmgId}</span></TableCell>}
                {!isMobileOnly && <TableCell><span className="page-table-cell">{address}</span></TableCell>}
                {!isMobileOnly && (
                  <AdminCell
                    admin={admin[0] ?? {}}
                    reinviteUser={handleReinviteUser}
                  />
                )}
                <TableCell className="buttons-cell">
                  <span className="page-table-cell">
                    <TableActions
                      {...actions}
                      isPasswordExpired={admin[0]?.isPasswordExpired}
                      isPlatformAdmin={isPlatformAdmin}
                      reInviteUser={handleReinviteUser}
                      row={organization}
                    />
                  </span>
                </TableCell>
              </TableRow>
            )
          })}
        </>
      </TableBody>
    </Table>
  )
}

OrganizationTable.propTypes = {
  organizations: PropTypes.array,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  }),
  refetchOrganizations: PropTypes.func,
}

OrganizationTable.defaultProps = {
  organizations: [],
  refetchOrganizations: () => {},
  actions: {
    onEdit: () => { },
    onDelete: () => { },
  },
}
