import { AGENT_STATUSES } from 'Constants/common'

export const statuses = [
  {
    title: 'Available',
    value: AGENT_STATUSES.AVAILABLE,
    color: 'green',
  },
  {
    title: 'Busy',
    value: AGENT_STATUSES.BUSY,
    color: 'yellow',
  },
  {
    title: 'Offline',
    value: AGENT_STATUSES.OFFLINE,
    color: 'gray',
  },
]
