export const mockPreQualifiedQuestions = 'While you’re waiting for the agent to join the chat, please fill out the form below:'

export const mockPreChatQuestions = [
  {
    id: '1',
    label: 'Choice',
    title: 'Hello! Have a question? I’m here to help!',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    checkboxes: [
      {
        id: '1',
        title: 'Allow multiple choice selection',
        checked: false,
      },
      {
        id: '2',
        title: 'Required',
        checked: true,
      },
    ],
    choices: [
      {
        id: '1',
        title: 'I am looking for a specific product',
      },
      {
        id: '2',
        title: 'I can’t find what I need',
      },
      {
        id: '3',
        title: 'I don’t know what I need',
      },
      {
        id: '4',
        title: 'Just browsing',
      },
    ],
  },
  {
    id: '2',
    label: 'Choice',
    title: 'Would you like to get on a video tour?',
    description: 'You don’t need to use camera.',
    checkboxes: [
      {
        id: '1',
        title: 'Allow multiple choice selection',
        checked: false,
      },
      {
        id: '2',
        title: 'Required',
        checked: true,
      },
    ],
    choices: [
      {
        id: '1',
        title: 'Yes',
      },
      {
        id: '2',
        title: 'No',
      },
      {
        id: '3',
        title: 'Not sure yet',
      },
    ],
  },
  {
    id: '3',
    label: 'Choice',
    title: 'Would you like to get on a video tour?',
    description: 'You don’t need to use camera.',
    checkboxes: [
      {
        id: '1',
        title: 'Allow multiple choice selection',
        checked: false,
      },
      {
        id: '2',
        title: 'Required',
        checked: true,
      },
    ],
  },
]
export const mockInactivePreChatQuestions = [
  {
    id: '1',
    label: 'Text reply',
    title: 'What is your name?',
    description: 'Please introduce yourself',
    checkboxes: [
      {
        id: '1',
        title: 'Allow long reply (240 characters)',
        checked: false,
      },
      {
        id: '2',
        title: 'Required',
        checked: true,
      },
    ],
  },
  {
    id: '2',
    label: 'Text reply',
    title: 'What are you looking for?',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    checkboxes: [
      {
        id: '1',
        title: 'Allow long reply (240 characters)',
        checked: true,
      },
      {
        id: '2',
        title: 'Required',
        checked: false,
      },
    ],
  },
  {
    id: '3',
    label: 'Scale',
    title: 'What price category are you looking for?',
    description: 'Rate on a scale from 1 to 10, 1 is the cheapest, 10 is the most expensive',
    checkboxes: [
      {
        id: '1',
        title: 'Required',
        checked: false,
      },
    ],
  },
  {
    id: '4',
    label: 'Rating',
    title: 'How good was your experience with Store name?',
    description: 'Rate on a scale from 1 to 5',
    checkboxes: [
      {
        id: '1',
        title: 'Required',
        checked: false,
      },
    ],
  },
]
