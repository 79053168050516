import { useEffect } from 'react'

import { GET_ORGANIZATION_QUERY } from 'GraphQL/queries/organization'
import { useApolloLazyQuery } from 'GraphQL/useApolloLazyQuery'
import { useDirtyFields } from 'Hooks/useDirtyFields'
import { useCreateUpdateOrganization } from 'Pages/organization/CreateUpdateOrganizationModal/hooks/useCreateUpdateOrganization'

import { validationSchemaMerchant, validationSchemaReview } from '../validationSchema'

const ORGANIZATION_FIELDS = ['name', 'nmgId', 'address', 'paymentEnabled']

const ADMIN_FIELDS = [
  {
    key: 'retailerAdministratorName',
    field: 'name',
  },
  {
    key: 'retailerAdministratorEmail',
    field: 'email',
  },
]

const ADMIN_WATCH_FIELDS = [
  ...ADMIN_FIELDS,
  {
    key: 'retailerAdministratorPassword',
    field: 'password',
  },
]

export const useOrganizationModal = ({
  reset,
  organizationId,
  dirtyFields,
  setValue,
  getValues,
  onClose,
  edit,
  refetchQueries,
}) => {
  const userChangedData = useDirtyFields(ADMIN_WATCH_FIELDS, dirtyFields, getValues)
  const isUserDirtyFields = Object.keys(userChangedData).length > 0

  const {
    createRetailerAdminMutation,
    createOrganizationMutation,
    updateOrganizationMutation,
    updateOrganizationMutationWithoutRetailerUpdate,
  } = useCreateUpdateOrganization({
    user: userChangedData,
    onCompleted: onClose,
    refetchQueries,
  }, isUserDirtyFields)

  const [loadOrganizationQuery, { data: dataOrganizationQuery }] = useApolloLazyQuery(GET_ORGANIZATION_QUERY)

  useEffect(() => {
    if (edit) {
      loadOrganizationQuery({
        variables: {
          id: organizationId,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (edit && dataOrganizationQuery) {
      const organization = dataOrganizationQuery.Organization
      const {
        site: sites, admin, phoneNumber, merchantAccounts, reviewDestinations,
      } = organization
      const phones = phoneNumber?.map(phone$ => ({ phone: phone$ }))

      reset({
        sites,
        phones,
        merchantAccounts: merchantAccounts.map(acct =>
          Object.keys(validationSchemaMerchant.fields).reduce(
            (r, k) => ({ ...r, [k]: acct[k] }),
            {},
          )),
        reviewDestinations: reviewDestinations.map(acct =>
          Object.keys(validationSchemaReview.fields).reduce(
            (r, k) => ({ ...r, [k]: acct[k] }),
            {},
          )),
      })

      ORGANIZATION_FIELDS.forEach((field) => {
        setValue(field, organization[field])
      })
      if (admin.length) {
        ADMIN_FIELDS.forEach((adminField) => {
          setValue(adminField.key, admin[0][adminField.field])
        })

        setValue('retailerAdministratorPassword', '')
      }
    }
  }, [dataOrganizationQuery])

  const createOrganization = (data) => {
    createOrganizationMutation({
      variables: {
        ...data,
      },
    })
  }

  const updateOrganization = (id, data, updateRetailer = true) => {
    if (updateRetailer) {
      updateOrganizationMutation({
        variables: {
          id,
          ...data,
        },
        refetchQueries,
      })
      return
    }
    updateOrganizationMutationWithoutRetailerUpdate({
      variables: {
        id,
        ...data,
      },
      refetchQueries,
    })
  }

  const onSubmit = (data) => {
    const {
      retailerAdministratorName,
      retailerAdministratorEmail,
      retailerAdministratorPassword,
      sites: site,
      phones,
      merchantAccounts,
      reviewDestinations,
      ...restOrganizationData
    } = data
    const phoneNumber = phones?.map(phone$ => phone$.phone.phoneNumber)

    if (edit) {
      const { Organization: { admin, id, nmgId } } = dataOrganizationQuery
      const {
        name,
        nmgId: nmgIdField,
        sites: sitesField,
        address: addressField,
        phones: phoneField,
        merchantAccounts: accountsField,
        reviewDestinations: destinationsField,
      } = dirtyFields

      if (!admin.length) {
        createRetailerAdminMutation({
          variables: {
            organization: id,
            nmgId,
            name: retailerAdministratorName,
            email: retailerAdministratorEmail,
            password: retailerAdministratorPassword,
          },
          refetchQueries,
        })
          .then(() => {
            if (name || nmgIdField || sitesField || addressField || phoneField || accountsField || destinationsField) {
              updateOrganization(organizationId, {
                site,
                phoneNumber,
                merchantAccounts,
                reviewDestinations,
                ...restOrganizationData,
              }, false)
            }
          })
      } else {
        updateOrganization(organizationId, {
          site,
          phoneNumber,
          merchantAccounts,
          reviewDestinations,
          ...restOrganizationData,
        })
      }

      return
    }
    createOrganization({
      site,
      phoneNumber,
      merchantAccounts,
      reviewDestinations,
      ...restOrganizationData,
    })
  }

  const onError = (submitErrors, e) => console.error('Organization submitErrors', { submitErrors }, e)

  const handleCancelAddOrganizationModal = () => {
    reset()
  }
  return {
    onError,
    handleCancelAddOrganizationModal,
    onSubmit,
  }
}
