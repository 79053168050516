import React from 'react'
import PropTypes from 'prop-types'
import ControlHeader from '../../ControlHeader'

import './styles.scss'

const ColorPicker = ({
  icon,
  label,
  value,
  onChange,
}) => {
  const handleValueChange = event => onChange(event.target.value)
  return (
    <div className="color-picker-container">
      <ControlHeader
        icon={icon}
        label={label}
      />
      <label
        className="color-input-container"
        htmlFor={label}
      >

        <span>
          {value}
        </span>
        <input
          className="color-input"
          id={label}
          type="color"
          value={value}
          onChange={handleValueChange}
        />
        <div
          className="preview"
          style={{ backgroundColor: value }}
        />
      </label>
    </div>
  )
}

export default ColorPicker

ColorPicker.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
