import React, { useState } from 'react'
import {
  PersonOutlineOutlined,
  PhoneOutlined,
  TimerOutlined,
} from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import VpnKeyOutlined from '@material-ui/icons/VpnKeyOutlined'

import Input from 'Components/common/Input'
import { SelectWithStyles } from 'Components/common/Select'
import { InputAdornment, IconButton } from '@material-ui/core'
import './styles.scss'
import PropTypes from 'prop-types'

export const ProfileForm = ({
  control,
  errors,
  renderSelectItems,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const handleShowPassword = () => {
    setIsPasswordVisible(state => !state)
  }

  return (
    <form>
      <Input
        control={control}
        error={!!errors.name}
        errorMessage={errors.name?.message}
        label="Name"
        labelIcon={<PersonOutlineOutlined />}
        name="name"
        placeholder="Jane Cooper"
        fullWidth
      />
      <Input
        control={control}
        error={!!errors.phoneNumber}
        errorMessage={errors.phoneNumber?.message}
        label="Phone number"
        labelIcon={<PhoneOutlined />}
        name="phoneNumber"
        placeholder="+1 (xxx) xxx-xx-xx"
        fullWidth
      />
      <Input
        control={control}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleShowPassword}
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
        error={!!errors.password}
        errorMessage={errors.password?.message}
        inputProps={{
          autoComplete: 'new-password',
          form: {
            autoComplete: 'off',
          },
        }}
        label="Change password"
        labelIcon={<VpnKeyOutlined />}
        name="password"
        placeholder="* * * * * * * *"
        type={isPasswordVisible ? 'text' : 'password'}
        fullWidth
      />
      <SelectWithStyles
        control={control}
        error={!!errors.timezone}
        errorMessage={errors.timezone?.message}
        label="Time zone"
        labelIcon={<TimerOutlined />}
        name="timezone"
        displayEmpty
        fullWidth
      >
        <MenuItem value="">
          <em>Select time zone</em>
        </MenuItem>
        {renderSelectItems}
      </SelectWithStyles>
    </form>
  )
}

ProfileForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({
    timezone: PropTypes.shape({ message: PropTypes.string }),
    password: PropTypes.shape({ message: PropTypes.string }),
    phoneNumber: PropTypes.shape({ message: PropTypes.string }),
    name: PropTypes.shape({ message: PropTypes.string }),
  }).isRequired,
  renderSelectItems: PropTypes.array.isRequired,
}
