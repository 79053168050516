export const styles = {
  root: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    border: '1px solid #D8DADD',
    borderRadius: 4,
    color: '#0D172A',
    padding: '7px 12px',
    '&:focus': {
      borderRadius: 'inherit',
      backgroundColor: 'inherit',
    },
  },
}
