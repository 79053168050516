import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Add, Save } from '@material-ui/icons'

import { useOrganizationModal } from 'Pages/organization/CreateUpdateOrganizationModal/useOrganizationModal'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'

import { OragnizationForm } from './OragnizationForm'
import { validationSchema } from '../validationSchema'

export const CreateUpdateOrganizationModal = ({
  organizationId,
  open,
  onClose,
  refetchQueries,
  ...modalProps
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      nmgId: '',
      address: '',
      sites: [''],
      retailerAdministratorName: '',
      retailerAdministratorEmail: '',
      retailerAdministratorPassword: '',
    },
  })

  const {
    onSubmit,
    onError,
    handleCancelAddOrganizationModal,
  } = useOrganizationModal({
    reset,
    organizationId,
    dirtyFields,
    setValue,
    getValues,
    onClose,
    edit: !!organizationId,
    refetchQueries,
  })

  return (
    <ModalBase
      {...modalProps}
      acceptIcon={organizationId ? <Save /> : <Add />}
      acceptTitle={organizationId ? 'Save' : 'Add'}
      open={open}
      title={organizationId ? 'Edit organization' : 'Add organization'}
      titleCancel="Cancel"
      onAccept={handleSubmit(onSubmit, onError)}
      onCancel={handleCancelAddOrganizationModal}
      onClose={onClose}
    >
      <OragnizationForm
        control={control}
        errors={errors}
        organizationId={organizationId}
        setError={setError}
        values={getValues()}
      />
    </ModalBase>
  )
}

CreateUpdateOrganizationModal.propTypes = {
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}),
  organizationId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  refetchQueries: PropTypes.func.isRequired,
}

CreateUpdateOrganizationModal.defaultProps = {
  errors: {},
  control: {},
  organizationId: null,
  open: false,
  onClose: () => {},
}
