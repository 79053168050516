import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Input from 'Components/common/Input'
import { bankInfoValidationSchema } from './validationSchema'

const AchCheck = ({
  paymentStep,
  isPaid,
  isCardChecked,
  goBack,
  submitPayment,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(bankInfoValidationSchema),
    mode: 'onChange',
  })

  return (
    <div
      className={paymentStep === 1 && !isPaid && !isCardChecked ? 'd-block' : 'd-none'}
      id="ach-card-check"
    >
      <div className="card">

        <div className="pcard-body">
          <button
            className="back-button mb-2"
            type="button"
            onClick={goBack}
          >
            <svg
              className="bi bi-arrow-left"
              fill="currentColor"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                fillRule="evenodd"
              />
            </svg>
            <span className="text-description">  Back</span>
          </button>

          <form
            onSubmit={handleSubmit(submitPayment)}
          >
            <Input
              control={control}
              error={!!errors.name}
              errorMessage={errors.name?.message}
              inputProps={{
                autocomplete: 'name',
                form: {
                  autocomplete: 'off',
                },
              }}
              label="Name"
              name="name"
              placeholder="John Doe"
              fullWidth
            />
            <Input
              control={control}
              error={!!errors.accountNumber}
              errorMessage={errors.accountNumber?.message}
              inputProps={{
                autocomplete: 'accountNumber',
                form: {
                  autocomplete: 'off',
                },
              }}
              label="Account Number"
              name="accountNumber"
              placeholder="011401533"
              fullWidth
            />
            <Input
              control={control}
              error={!!errors.routingNumber}
              errorMessage={errors.routingNumber?.message}
              inputProps={{
                autocomplete: 'routingNumber',
                form: {
                  autocomplete: 'off',
                },
              }}
              label="Routing Number"
              name="routingNumber"
              placeholder="0123456"
              fullWidth
            />
            <button
              className="btn payment_button"
              type="submit"
            >
              Submit payment
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

AchCheck.propTypes = {
  paymentStep: PropTypes.bool,
  isPaid: PropTypes.bool,
  isCardChecked: PropTypes.bool,
  goBack: PropTypes.func,
  submitPayment: PropTypes.func,
}

AchCheck.defaultProps = {
  paymentStep: false,
  isPaid: false,
  isCardChecked: false,
  goBack: () => {},
  submitPayment: () => {},
}

export default AchCheck
