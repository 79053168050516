import React from 'react'
import { toast } from 'react-toastify'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'

import { useFileUpload } from 'Hooks/useFileUpload'
import { useAuthContext } from 'Providers/AuthProvider'
import loading from './loading.gif'
import './styles.scss'

const ALLOWED_FILE_TYPES = ['png', 'jpg', 'jpeg']
const MAX_FILE_SIZE = 3145728 // 3Mb

export const DropBox = ({
  onClose,
  open,
  onChange,
  maxFileDimension,
  uploadPath,
}) => {
  const { uploadFile, isLoading } = useFileUpload(ALLOWED_FILE_TYPES)
  const { currentUser } = useAuthContext()
  const fileUploadPath = uploadPath || `/users/${currentUser.id}`
  const token = JSON.parse(localStorage.getItem('accessToken'))

  const handleAvatarUpload = (result) => {
    onChange(result.data.files[0]?.url, token)
  }
  const onDrop = (acceptedFiles) => {
    const acceptedFile = acceptedFiles[0]
    const image = new Image()
    image.src = URL.createObjectURL(acceptedFile)
    image.onload = () => {
      if (image.height > maxFileDimension || image.width > maxFileDimension) {
        toast.error('Image width or height exceeds limit')
      } else {
        uploadFile({
          file: acceptedFile,
          uploadPath: fileUploadPath,
          callback: handleAvatarUpload,
          maxFileSize: MAX_FILE_SIZE,
        })
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  })

  return (
    <Modal
      aria-describedby="modal-description"
      aria-labelledby="modal-title"
      className="modal"
      open={open}
      onClose={onClose}
    >
      <div className="drop-box">
        <div
          className="drop-box-container"
          {...getRootProps()}
        >
          {!isLoading && (
            <>
              <span className="title">
                Drop your image here, or
                {' '}
                <span>
                  <input {...getInputProps()} />
                  browse
                </span>
              </span>
              <span className="supports">Supports: JPG, JPEG, PNG</span>
              <span className="max-size">{`Max image size: ${maxFileDimension}px x ${maxFileDimension}px`}</span>
              <span className="description">Image file can’t be larger than 3MB</span>
            </>
          )}
          {isLoading && (
            <div className="drop-box-progress-container">
              <img
                alt="loading"
                className="loading"
                src={loading}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

DropBox.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  maxFileDimension: PropTypes.number.isRequired,
  uploadPath: PropTypes.string,
}

DropBox.defaultProps = {
  onClose: () => {},
  open: false,
  uploadPath: null,
}
