/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react'
import {
  Label,
  Input as BootstrapInput,
} from 'reactstrap'
import { PersonOutline } from '@material-ui/icons'
import classNames from 'classnames'
import { useApolloMutation } from 'GraphQL/apollo'
import { CREATE_QUESTION } from 'GraphQL/mutations/questions'
import { QUESTIONS_LIMIT, POST_QUESTION_TYPES, PRE_QUESTION_TYPES } from 'Constants/preChatQuestions'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ModalBase } from 'Components/common/ModalBase/ModalBase'
import AntSwitch from 'Pages/settings/components/AntSwitch'
import Input from 'Components/common/Input'
import { toast } from 'react-toastify'
import { ratingSchema, schema } from './validationSchema'
import { propTypes } from './addQuestionModalPropTypes'
import { createQuestion } from './createQuestion'
import { ModalCheckBox } from './ModalCheckbox'
import { ModalChoices } from './ModalChoices'
import { AddQuestionModalCheckbox } from './AddQuestionModalCheckbox'

export const AddQuestionModal = ({
  isOpen,
  onCancel,
  type,
  activeQuestionsLength,
  storeId,
  refetchQuestions,
}) => {
  const [choices, setChoices] = useState([])
  const [questionType, setQuestionsType] = useState('choice')
  const [isActive, setIsActive] = useState(activeQuestionsLength < QUESTIONS_LIMIT)
  const [addQuestion] = useApolloMutation(CREATE_QUESTION, {
    onCompleted() {
      refetchQuestions()
    },
  })

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: questionType === 'rating' ? yupResolver(ratingSchema) : yupResolver(schema),
  })

  const isQuestionTypeText = questionType === 'text'
  const isChoice = questionType === 'choice'
  const activeQuestionSubmitCheck = activeQuestionsLength < QUESTIONS_LIMIT

  useEffect(() => {
    setIsActive(activeQuestionSubmitCheck)
  }, [activeQuestionSubmitCheck])

  const resetModal = () => {
    setChoices([])
    reset()
    onCancel()
  }
  const toggleIsActive = () => setIsActive(prevState => !prevState)
  const onSubmit = (data) => {
    if (isChoice && choices.length < 2) {
      toast.error('Question should contain at least 2 choices')
      return
    }
    const choicesCheck = choices.every(choice => !!choice.value)
    if (!choicesCheck) return
    const newQuestion = createQuestion({
      data,
      title: data.title || data.rating,
      question: data.question || '',
      store: storeId,
      choices,
      questionType,
      isActive,
    })
    addQuestion({
      variables: {
        ...newQuestion,
        relation: type,
      },
    })
    resetModal()
  }
  const questionTypeHandler = (event) => {
    setQuestionsType(event.target.value)
  }

  const typesOfQuestion = type === 'postChat' ? POST_QUESTION_TYPES : PRE_QUESTION_TYPES
  const antSwitchTooltip = !isActive && activeQuestionsLength >= QUESTIONS_LIMIT
    ? 'You can not add more than 3 active questions'
    : 'Active/Deactivate questions'

  return (
    <ModalBase
      acceptTitle="Add question"
      open={isOpen}
      titleCancel="Cancel"
      width={500}
      onAccept={handleSubmit(onSubmit)}
      onCancel={resetModal}
      onClose={resetModal}
    >
      <form
        className="add-question-modal-container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="add-question-modal-header">
          <div>
            <AntSwitch
              checked={isActive}
              disabled={!activeQuestionSubmitCheck}
              name="checked"
              title={antSwitchTooltip}
              onChange={toggleIsActive}
            />
            <label>{isActive ? 'Active' : 'Inactive'}</label>
          </div>
          <label>Select type of question</label>
          <div />
        </div>
        <div className={classNames('radio-button-container', { 'preChat-container': type === 'preChat' })}>
          {typesOfQuestion.map(item => (
            <div className={item === questionType && 'selected'}>
              <Label>
                <BootstrapInput
                  {...register('questionType')} // TODO rewrite using other Input
                  checked={questionType === item}
                  className="position-relative"
                  name="questionType"
                  type="radio"
                  value={item}
                  onChange={questionTypeHandler}
                />
                {item}
              </Label>
            </div>
          ))}
        </div>
        {questionType === 'rating' ? (
          <Input
            className="mb-2"
            error={errors?.rating}
            errorMessage={errors?.rating?.message}
            label="Rating"
            labelIcon={<PersonOutline />}
            name="rating"
            {...register('rating')}
            fullWidth
          />
        ) : (
          <>
            <Input
              error={errors?.title}
              errorMessage={errors?.title?.message}
              label="Question Header"
              labelIcon={<PersonOutline />}
              name="title"
              fullWidth
              {...register('title')}
              className="mb-2"
            />
            <Input
              error={errors?.question}
              errorMessage={errors?.question?.message}
              label="Question Description"
              labelIcon={<PersonOutline />}
              name="question"
              type="textarea"
              fullWidth
              {...register('question')}
            />
          </>
        )}
        {questionType === 'rating' && (
          <span>Rate on a scale 1 to 5</span>
        )}
        {questionType !== 'rating' && (
          <ModalCheckBox
            name="isRequired"
            text="Required"
          >
            <input
              {...register('isRequired')}
              className="mr-4"
              type="checkbox"
              value={1}
            />
          </ModalCheckBox>
        )}
        {questionType !== 'rating' && (
          <AddQuestionModalCheckbox
            isQuestionTypeText={isQuestionTypeText}
            register={register}
          />
        )}
        <div>
          {!isQuestionTypeText && questionType !== 'rating' && (
            <ModalChoices
              choices={choices}
              questionType={questionType}
              setChoices={setChoices}
            />
          )}
        </div>
      </form>
    </ModalBase>
  )
}
AddQuestionModal.propTypes = propTypes
