import { useConversation } from 'Hooks/useConversation'
import React, { useState, useMemo, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useApolloMutation } from 'GraphQL/apollo'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import { SWITCH_CONVERSATION_CHANNEL } from 'GraphQL/mutations/chat'
import { CONVERSATION_CHANNELS } from 'Pages/messenger/chat/components/config'
import { useAssignedAgent } from 'Hooks/useAssignedAgent'
import { useAuthContext } from 'Providers/AuthProvider'
import { updateSelectedUser } from 'Store/Actions'
import { useStyles } from './styles'

export const SenderTabs = ({
  activeTabIndex,
  handleChange,
  userEmail,
  userPhone,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const { selectedUser } = useSelector(state => state?.chatAppReducer)
  const assignedAgent = useAssignedAgent()
  const { currentUser } = useAuthContext()
  const conversation = useConversation()

  const isChangeChannelDisabled = assignedAgent.id !== currentUser.id
  const [activeConversationChannel, setActiveConversationChannel] = useState(conversation?.customerChannel)

  useEffect(() => {
    setActiveConversationChannel(conversation?.customerChannel)
  }, [conversation?.customerChannel])
  const id = anchorEl ? 'simple-popover' : undefined
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const [switchConversationChannel] = useApolloMutation(SWITCH_CONVERSATION_CHANNEL)

  const getRecipient = (channel) => {
    if (channel === 'sms' && userPhone) {
      return `+1${userPhone}`
    }
    return userEmail
  }

  const handleClick = (channel) => {
    if (activeConversationChannel !== channel) {
      setAnchorEl(null)
      switchConversationChannel({
        variables: {
          client: selectedUser.id,
          conversation: selectedUser.conversationId,
          recipient: getRecipient(channel),
          type: channel,
        },
      })
      setActiveConversationChannel(channel)
      dispatch(updateSelectedUser({ customerChannel: channel }))
    }
  }

  const getIsDisabled = channel => (channel === 'sms' && !userPhone)
    || (channel === 'email' && !userEmail) || (channel === 'widget')
  || isChangeChannelDisabled

  const tabTitle = useMemo(() => CONVERSATION_CHANNELS.find(
    channel => channel.value === activeConversationChannel,
  )?.title, [activeConversationChannel])

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: 'white' }}
    >
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={activeTabIndex}
        onChange={handleChange}
      >
        <Tab
          label={`Reply via: ${tabTitle}`}
          onClick={handleOpen}
        />
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            root: classes.replyControls,
          }}
          id={id}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => setAnchorEl(null)}
        >
          <div className={classes.channelsWrapper}>
            {CONVERSATION_CHANNELS.map(channel => (
              <div
                key={channel.value}
                className={classNames(
                  classes.channel,
                  { [classes.disabledChannel]: getIsDisabled(channel.value) },
                )}
              >
                <Button
                  key={channel.value}
                  className={classes.replyControlsButton}
                  disabled={getIsDisabled(channel.value)}
                  onClick={() => handleClick(channel.value)}
                >
                  <div className={classes.iconWrapper}>
                    {activeConversationChannel === channel.value && (<CheckIcon color="primary" />)}
                  </div>
                  <div className={classes.titleWrapper}>
                    <span className={classNames(
                      classes.title,
                      { [classes.activeTitle]: activeConversationChannel === channel.value },
                    )}
                    >
                      {channel.title}
                    </span>
                    <span>{channel.subTitle}</span>
                  </div>
                </Button>
              </div>
            ))}
          </div>
        </Popover>
        <Tab
          label="Notes"
          disabled
        />
        <Tab label="Reminder" />
      </Tabs>
    </AppBar>
  )
}

SenderTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
}

SenderTabs.defaultProps = {
  userEmail: '',
  userPhone: '',
}
