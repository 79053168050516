import React, { Fragment, useEffect, useState } from 'react'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useForm } from 'react-hook-form'
import { useApolloMutation } from 'GraphQL/apollo'
import classNames from 'classnames'
import Input from 'Components/common/Input'
import { DELETE_NOTE, UPDATE_NOTE } from 'GraphQL/mutations/privateNotes'
import { getTimeAgo } from 'Helpers/helpers'

const useStyles = makeStyles(() => ({
  icon: {
    color: 'black',
    transition: '0.1s',
    fontSize: 18,
    '&:hover': {
      color: '#FAA61A',
    },
  },
  activeIcon: {
    color: 'green',
  },
}))

const INTERVAL_TIMEOUT = 60000

const sanitizeNoteContent = (value) => {
  if (typeof value !== 'string') {
    return value
  }
  return value.split('\n').map((v, i) => (
    <Fragment key={i}>
      {v}
      <br />
    </Fragment>
  ))
}

export const PrivateNote = ({
  id,
  body,
  refetch,
  lastTimeTouched,
}) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [timeAgo, setTimeAgo] = useState(getTimeAgo(lastTimeTouched))
  const [deleteNote] = useApolloMutation(DELETE_NOTE, {
    onCompleted() {
      refetch()
    },
  })
  const [updateNote] = useApolloMutation(UPDATE_NOTE, {
    onCompleted() {
      setIsEditing(false)
      refetch()
    },
  })

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const interval = setInterval(() => {
      const value = getTimeAgo(lastTimeTouched)
      setTimeAgo(value)
    }, INTERVAL_TIMEOUT)

    return () => clearInterval(interval)
  }, [])

  const onSubmit = async ({ noteContent }) => {
    if (noteContent) {
      await updateNote({
        variables: {
          id,
          body: noteContent,
        },
      })
      setIsEditing(false)
    }
  }

  const onDelete = async () => {
    await deleteNote({
      variables: {
        id,
      },
    })
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <span>{timeAgo}</span>
      </div>
      <div
        className="private-note"
      >
        {isEditing && (
          <form onBlur={handleSubmit(onSubmit)}>
            <Input
              className="border-0 p-0"
              control={control}
              defaultValue={body}
              error={errors.noteContent}
              errorMessage={errors.noteContent?.message}
              inputProps={{ maxLength: 255 }}
              name="noteContent"
              wrapperClassName="m-0"
              fullWidth
              multiline
            />
          </form>
        )}
        {!isEditing && (
          <div className="private-note-content">
            {sanitizeNoteContent(getValues().noteContent || body)}
          </div>
        )}
        <div className="d-flex">
          <EditOutlinedIcon
            className={classNames(classes.icon, isEditing && classes.activeIcon)}
            onClick={() => setIsEditing(true)}
          />
          <DeleteOutlinedIcon
            className={classes.icon}
            onClick={onDelete}
          />
        </div>
      </div>
    </>
  )
}

PrivateNote.propTypes = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  lastTimeTouched: PropTypes.string.isRequired,
  refetch: () => {},
}

PrivateNote.defaultProps = {
  refetch: () => {},
}
