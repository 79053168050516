import React from 'react'
import { toast } from 'react-toastify'
import { useAuthContext } from 'Providers/AuthProvider'
import AntSwitch from 'Pages/settings/components/AntSwitch'
import { useApolloMutation } from 'GraphQL/apollo'
import { UPDATE_ORGANIZATION } from 'GraphQL/mutations/organization'

const LauncherVisibilitySwitch = () => {
  const { getOrganization, getOrganizationId, updateOrganization: updateLocalOrganization } = useAuthContext()

  const organizationData = getOrganization()

  const [updateOrganization] = useApolloMutation(UPDATE_ORGANIZATION, {
    onError: (error) => {
      toast.error(error.message)
    },
    onCompleted: (data) => {
      toast.success('Successfully updated')
      updateLocalOrganization({ ...organizationData, launcherVisible: data.UpdateOrganization.launcherVisible })
    },
  })

  const handleToggle = () => {
    updateOrganization({
      variables: {
        id: getOrganizationId(),
        launcherVisible: !organizationData?.launcherVisible,
      },
    })
  }

  return (
    <div className="launcher-visibility-switch">
      <AntSwitch
        checked={!!organizationData?.launcherVisible}
        name="checked"
        title="Launcher Visibility"
        onChange={handleToggle}
      />
      <label>
        Launcher is
        {' '}
        {organizationData?.launcherVisible ? 'visible' : 'invisible'}
      </label>
    </div>
  )
}

export default LauncherVisibilitySwitch
