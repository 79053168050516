import React from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'
import ControlHeader from '../ControlHeader'
import { LAUNCHER_SIZES } from '../../../config'

import './styles.scss'

const LauncherSizes = ({
  settings,
  onChange,
}) => {
  const handleOnChange = value => onChange('launcherSize', value)
  return (
    <div className="launcher-size-container mt-4">
      <ControlHeader
        icon={<GetAppIcon />}
        label="Launcher size"
      />
      <div className="launcher-sizes">
        {LAUNCHER_SIZES.map(item => (
          <div
            className={`launcher-container ${item.key} ${item.key === settings.launcherSize && 'selected'}`}
            onClick={() => handleOnChange(item.key)}
          >
            <div>
              <div className="launcher" />
            </div>
            <span>
              {item.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LauncherSizes

LauncherSizes.propTypes = {
  settings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
