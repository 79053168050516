import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGOUT_USER_FAILURE,
} from './types'

export const signinUserInFirebase = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
})

export const signinUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
})

export const signUpUserInFirebaseSuccess = user => ({
  type: SIGNUP_USER_SUCCESS,
  payload: user,
})

export const signUpUserInFirebaseFailure = error => ({
  type: SIGNUP_USER_FAILURE,
  payload: error,
})

export const signinUserFailure = error => ({
  type: LOGIN_USER_FAILURE,
  payload: error,
})

/**
 * Redux Action To Signout User From  Firebase
 */
export const logoutUserFromFirebase = () => ({
  type: LOGOUT_USER,
})

export const logoutUserFromFirebaseSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
})

export const logoutUserFromFirebaseFailure = () => ({
  type: LOGOUT_USER_FAILURE,
})

export const signupUserInFirebase = (user, history) => ({
  type: SIGNUP_USER,
  payload: { user, history },
})
