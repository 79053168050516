import * as yup from 'yup'

const MIN_LENGTH_NAME = 3
const MAX_LENGTH_TEXT = 255

export const validationSchema = yup.object().shape({
  name: yup.string().trim().min(MIN_LENGTH_NAME).max(MAX_LENGTH_TEXT)
    .required('Workflow name is a required field'),
  type: yup.string().required('Condition type is a required field'),
  operator: yup.string().required('Condition operator is a required field'),
  value: yup.string().required('Condition value is a required field'),
  title: yup.string().required('Question header is a required field'),
  question: yup.string().required('Question description is a required field'),
  isRequired: yup.bool(),
  isLongReply: yup.bool(),
  isMultipleChoice: yup.bool(),
})
