import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useCallSettingsContext } from 'Providers/CallSettingsProvider'
import { useFirebaseContext } from 'Providers/FirebaseProvider'
import { usePeerContext } from 'Providers/PeerProvider/PeerProvider'

import { getUuid, setInnerHeight } from 'Helpers/helpers'
import { useConversationPath } from 'Hooks/useConversationPath'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

export const useWaitingRoom = () => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)

  const { selectedUser } = useSelector(state => state?.chatAppReducer)
  const { conversationPath, conversationId } = useConversationPath()

  const customerShortInfo = JSON.parse(window.localStorage.getItem('customerShortInfo'))
  const { db } = useFirebaseContext()
  const history = useHistory()
  const {
    startCall,
    mediaStream,
    localVideoRef,
  } = usePeerContext()

  const {
    setIsCameraEnabled,
    setIsMicrophoneEnabled,
  } = useCallSettingsContext()

  useEffect(() => {
    const { customerId, retailerId } = selectedUser?.call ?? { customerId: getUuid(), retailerId: getUuid() }
    if (retailerId) {
      db.ref(`${conversationPath}/${customerShortInfo.storeId}/conversations/${conversationId}`).child('call').set({ retailerId, customerId }).then()
    }
  }, [selectedUser?.call?.retailerId])

  useEffect(() => {
    if (mediaStream && localVideoRef.current) {
      localVideoRef.current.srcObject = mediaStream
    }
  }, [mediaStream, localVideoRef.current])

  useEffect(() => {
    const setContainerHeight = () => isMobileOnly && setInnerHeight('settings-screen')
    setContainerHeight()
    window.addEventListener('resize', setContainerHeight)
    return () => {
      window.removeEventListener('resize', setContainerHeight)
    }
  }, [])

  const toggleCamera = () => {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    localVideoRef.current.srcObject?.getVideoTracks().forEach(track => track.enabled = !track.enabled)
    setIsCameraEnabled(state => !state)
  }

  const toggleMicrophone = () => {
    localVideoRef.current.srcObject?.getAudioTracks().forEach(
      // eslint-disable-next-line no-return-assign,no-param-reassign
      track => track.enabled = !track.enabled,
    )
    setIsMicrophoneEnabled(state => !state)
  }

  const joinToCall = () => {
    startCall()
    history.push({ pathname: '/video-call/video-space-room', state: { fromRealCall: true } })
  }

  const triggerSettingsModal = () => {
    setIsSettingsModalOpen(state => !state)
  }
  return {
    triggerSettingsModal,
    isSettingsModalOpen,
    joinToCall,
    toggleMicrophone,
    toggleCamera,
  }
}
