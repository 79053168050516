import React, { useEffect, useRef } from 'react'

import IconButton from '@material-ui/core/IconButton'
import MicNoneIcon from '@material-ui/icons/MicNone'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import { createAudioStreamProcessor } from 'Pages/video-call/waiting-room/SettingsModal/audioStreamMeter'
import { usePeerContext } from 'Providers/PeerProvider/PeerProvider'
import { useCallSettingsContext } from 'Providers/CallSettingsProvider'
import { useMediaDevices } from 'Pages/video-call/waiting-room/useMediaDevices'
import { SettingsSelect } from './SettingsSelect/SettingsSelect'
import testTone from './test_tone.mp3'

export const AudioSettings = () => {
  const { microphones, speakers, setMediaDevices } = useMediaDevices()

  const volumeRef = useRef(null)
  const audioRef = useRef(null)
  const {
    audioOutputDeviceId,
    setMicrophoneId,
    microphoneId,
    setAudioOutputDeviceId,
  } = useCallSettingsContext()

  const { mediaStream } = usePeerContext()

  useEffect(() => {
    if (audioOutputDeviceId && audioOutputDeviceId !== 'default') {
      audioRef.current.setSinkId(audioOutputDeviceId)
    }
  }, [audioOutputDeviceId])

  useEffect(() => {
    const audioContext = new AudioContext()
    const audioStream = mediaStream && audioContext.createMediaStreamSource(mediaStream)
    const meter = createAudioStreamProcessor(audioContext, () => {
      if (volumeRef.current) {
        // eslint-disable-next-line no-magic-numbers
        volumeRef.current.style.width = `${meter.volume * 400 }%`
      }
    })

    audioStream?.connect(meter)
    return () => {
      meter.close(meter)
    }
  }, [mediaStream])

  useEffect(() => {
    if (!microphoneId && microphones[0]?.deviceId) {
      setMicrophoneId(microphones[0]?.deviceId)
    }
  }, [microphoneId, microphones])

  useEffect(() => {
    if (!audioOutputDeviceId && speakers[0]?.deviceId) {
      setAudioOutputDeviceId(speakers[0]?.deviceId)
    }
  }, [audioOutputDeviceId, speakers])

  useEffect(() => {
    if ((microphones.length && !microphones[0].label) || (speakers.length && !speakers[0].label)) {
      setMediaDevices()
    }
  }, [microphones, speakers])

  return (
    <div className="audio">
      <div className="settings-block">
        <SettingsSelect
          inputProps={{ value: microphoneId }}
          label="Microphone"
          options={microphones}
          valueField="deviceId"
          onChange={event => setMicrophoneId(event.target.value)}
        />
        <div className="control">
          <MicNoneIcon className="microphone-icon" />
          <div className="volume-wrapper">
            <div
              ref={volumeRef}
              className="volume"
            />
          </div>
        </div>

      </div>
      <audio
        ref={audioRef}
        src={testTone}
      />
      <div className="settings-block">
        <SettingsSelect
          inputProps={{ value: audioOutputDeviceId }}
          label="Speakers"
          options={speakers}
          valueField="deviceId"
          onChange={event => setAudioOutputDeviceId(event.target.value)}
        />
        <div className="control">
          <IconButton
            className="sound-test-button"
            onClick={() => audioRef.current.play()}
          >
            <VolumeUpIcon className="sound-icon" />
            <span className="button-text">Test</span>
          </IconButton>
        </div>
      </div>
    </div>
  )
}
