import React, { memo, useEffect } from 'react'
import moment from 'moment-timezone'
import {
  ExitToAppOutlined,
  ReplayOutlined,
} from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem'

import { LinkButton } from 'Components/common/LinkButton'
import Button from 'Components/common/Button'
import { ProfilePhoto } from 'Components/ProfilePhoto'

import { useAuthContext } from 'Providers/AuthProvider'
import { useApolloMutation, useApolloQuery } from 'GraphQL/apollo'
import { GET_CURRENT_USER } from 'GraphQL/queries/users'
import { UPDATE_MY_PROFILE, UPDATE_MY_PASSWORD } from 'GraphQL/mutations/user'
import { TIMEZONES } from 'Constants/timezones'
import { HOME_PATH } from 'Constants/routerPaths'

import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validationSchema'
import './styles.scss'
import { ProfileForm } from './ProfileForm'

const timezoneFormat = zone => `(z Z) zz ([${zone}])`
const currentTimezone = moment.tz.guess()
const getFormattedTimezone = timezone => moment.tz(timezone).format(timezoneFormat(timezone))
const isUSATimezones = TIMEZONES.includes(currentTimezone)

export const CreateProfile = memo(() => {
  const { logout, setCurrentUser } = useAuthContext()
  const history = useHistory()

  const { data: userData, loading } = useApolloQuery(GET_CURRENT_USER, {
    // todo remove set current user
    onCompleted: (user) => {
      setCurrentUser(user?.Me)
    },
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: { name: '', phoneNumber: '' },
  })

  const user = userData?.Me
  const defaultUserData = {
    ...user,
    phoneNumber: user?.phoneNumber ?? getValues().phoneNumber,
    timezone: user?.timezone ?? (isUSATimezones ? getFormattedTimezone(currentTimezone) : getValues().timezone),
  }

  const [updateMyProfile] = useApolloMutation(UPDATE_MY_PROFILE, {
    onCompleted: () => {
      toast.success('You have successfully updated your profile')
      history.push(HOME_PATH)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updatePassword] = useApolloMutation(UPDATE_MY_PASSWORD, {
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    const fields = ['name', 'timezone', 'phoneNumber']

    if (defaultUserData) {
      fields.forEach((field) => {
        setValue(field, defaultUserData[field])
      })
    }
  }, [user])

  const renderSelectItems = TIMEZONES.map(timezone => (
    <MenuItem
      key={timezone}
      value={getFormattedTimezone(timezone)}
    >
      {getFormattedTimezone(timezone)}
    </MenuItem>
  ))

  const onSubmit = (profileData) => {
    updateMyProfile({
      variables: {
        ...profileData,
      },
    })
    updatePassword({
      variables: { password: profileData.password },
    })
  }

  const onError = (submitErrors, error) => console.error('Store submitErrors', { submitErrors }, error)

  const handleReset = () => {
    const fields = ['name', 'timezone', 'phoneNumber', 'password']

    fields.forEach((field) => {
      setValue(field, user[field] ?? '')
    })
  }

  if (loading) {
    return <div>...loading</div>
  }

  return (
    <div className=" create-profile-page">
      <div className="create-profile">
        <div className="create-profile-header">
          <div className="create-profile-user-info">
            <span className="signed">You signed in as:</span>
            <span className="email">{user.email}</span>
          </div>
          <LinkButton
            color="secondary"
            startIcon={<ExitToAppOutlined />}
            title="Sign out"
            onClick={logout}
          />
        </div>
        <div className="create-profile-body">
          <div className="profile-photo-wrapper">
            <ProfilePhoto />
          </div>
          <ProfileForm
            control={control}
            errors={errors}
            renderSelectItems={renderSelectItems}
          />
        </div>
        <div className="create-profile-footer">
          <Button
            color="primary"
            startIcon={<ReplayOutlined />}
            variant="outlined"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
})
