/* eslint-disable no-use-before-define */
import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  InMemoryCache,
} from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error'
import { Storage } from 'Services/store-service'
import { REFRESH_TOKEN } from 'Constants/common'
import { UNAUTHORIZED_CODE_STATUS } from 'Constants/codeStatuses'
import { AUTHORIZE } from 'GraphQL/mutations/user'
import { httpLink } from './HttpLink'

const setToken = token => Storage.local.set('accessToken', token)

const setRefreshToken = refreshToken => Storage.local.set(REFRESH_TOKEN, refreshToken)

const getRefreshToken = () => Storage.local.get(REFRESH_TOKEN)

export const getNewToken = () => {
  const apolloClient = new ApolloClient({
    link: ApolloLink.from([httpLink]),
    cache: new InMemoryCache(),
  })

  return apolloClient.mutate({
    mutation: AUTHORIZE,
    variables: { refreshToken: getRefreshToken() },
  })
}

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.forEach(callback => callback())
  pendingRequests = []
}

export const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
}) => {
  if (graphQLErrors) {
    const isTokenExpired = graphQLErrors?.find(
      err => (
        err?.extensions?.exception?.status === UNAUTHORIZED_CODE_STATUS
        || err?.message === 'Access token is expired'
      ),
    )

    if (isTokenExpired) {
      let newOperation$

      if (!isRefreshing) {
        isRefreshing = true
        newOperation$ = fromPromise(
          getNewToken()
            .then(({ data }) => {
              const { Authorize: { accessToken, refreshToken } } = data
              setToken(accessToken)
              setRefreshToken(refreshToken)
              resolvePendingRequests()

              return accessToken
            })
            .catch(() => {
              pendingRequests = []
              // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
            })
            .finally(() => {
              isRefreshing = false
            }),
        ).filter(value => Boolean(value))
      } else {
        newOperation$ = fromPromise(
          new Promise((resolve) => {
            pendingRequests.push(() => resolve())
          }),
        )
      }

      return newOperation$.flatMap(() => forward(operation))
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }

  return undefined
})
