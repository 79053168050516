import { React, useState } from 'react'
import './styles.scss'
import { useConversation } from 'Hooks/useConversation'
import { MessageContentType } from 'Constants/chatConstants'
import ReactStars from 'react-rating-stars-component'
import { REVIEW_ANSWERS } from 'Constants/common'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeaderData } from 'Store/Actions/ChatHeader'
import { isMobile } from 'react-device-detect'
import { useMessengerContext } from '../../useMessenger'
import { CollapsedBlock } from '../CollapsedBlock/CollapsedBlock'

const Ratings = () => {
  const dispatch = useDispatch()
  const chatHeaderReducer = useSelector(state => state.chatHeader)
  const { messengerStep } = chatHeaderReducer
  const { setModalState } = useMessengerContext()
  const conversation = useConversation()
  const [isOpen, setIsOpen] = useState(true)
  const messages = conversation?.messages && Object.keys(conversation?.messages).map(key => ({
    messageId: key,
    ...conversation?.messages[key],
  }))
  const reviewResponseMsgs = messages && messages.filter(msg => msg.contentType === MessageContentType.REVIEW_RESPONSE)
    .sort((a, b) => (new Date(b.sentAt) - new Date(a.sentAt)))

  const onAskReview = () => {
    if (isMobile) { dispatch(changeHeaderData({ messengerStep: messengerStep - 1 })) }
    setModalState(ms => ({ ...ms, requestReview: true }))
  }
  if (!reviewResponseMsgs?.length) {
    return (
      <div className="controls-wrapper">
        <span>No ratings yet...</span>
        <span
          className="text-askReview"
          onClick={onAskReview}
        >
          <br />
          Ask for review
        </span>
      </div>
    )
  }
  return (
    <div className="controls-wrapper">
      <CollapsedBlock
        isOpen={isOpen}
        title={`${ reviewResponseMsgs?.length } Rating(s)`}
        toggle={() => setIsOpen(!isOpen)}
      >
        {reviewResponseMsgs?.map(msg => (
          <div className="review-items">
            <div className="row">
              <div className="col-6">
                <ReactStars
                  activeColor="#FAA61A"
                  count={5}
                  edit={false}
                  size={20}
                  value={REVIEW_ANSWERS.indexOf(JSON.parse(msg.content).rating) + 1}
                />
              </div>
              <div className="col-6">
                <span className="review-time">{moment(msg.sentAt).format('MMM DD, YYYY, h:mm a')}</span>
              </div>
            </div>
            <span>{JSON.parse(msg.content).comment}</span>
          </div>
        ))}
      </CollapsedBlock>
    </div>
  )
}

export default Ratings
