
import PropTypes from 'prop-types'

const CardCheck = ({
  paymentStep,
  isPaid,
  isCardChecked,
  goBack,
}) => (
  <div
    className={paymentStep === 1 && !isPaid && !isCardChecked ? 'd-block' : 'd-none'}
    id="payment-card-check"
  >
    <div className="card">
      <div className="pcard-body">
        <button
          className="back-button"
          type="button"
          onClick={goBack}
        >
          <svg
            className="bi bi-arrow-left"
            fill="currentColor"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              fillRule="evenodd"
            />
          </svg>
          <span className="text-description">Back</span>
        </button>
        <form
          className="mt-3"
          id="ProtectForm"
        >
          <div
            className="mb-3"
            id="pt_hpf_form"
          />
          <div
            custom-attribute="authToken"
            id="pt_auth_token"
          />
          <input
            className="btn payment_button"
            id="SubmitButton"
            style={{ width: '100%' }}
            type="submit"
            value="Pay"
          />
        </form>
      </div>
    </div>
  </div>
)

CardCheck.propTypes = {
  paymentStep: PropTypes.bool,
  isPaid: PropTypes.bool,
  isCardChecked: PropTypes.bool,
  goBack: PropTypes.func,
}

CardCheck.defaultProps = {
  paymentStep: false,
  isPaid: false,
  isCardChecked: false,
  goBack: () => {},
}

export default CardCheck
