import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { isMobile, isMobileOnly } from 'react-device-detect'

import { SearchInput } from 'Components/common/SearchInput'
import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { OrganizationTable } from 'Pages/organization/organizationTable'

import { useDebounce } from 'Hooks/useDebounce'
import { useApolloMutation } from 'GraphQL/apollo'
import { DELETE_ORGANIZATION } from 'GraphQL/mutations/organization'
import { DEBOUNCED_SEARCH_DELAY } from 'Constants/common'
import { useOrganizations } from 'Hooks/useOrganizations'
import { useReadMode } from 'Hooks/useReadMode'

import { CreateUpdateOrganizationModal } from './CreateUpdateOrganizationModal'
import TablePagination from '../settings/components/TablePagination'

import './styles.scss'

const initPagination = {
  pagination: {
    offset: 0,
    size: 25,
  },
}

const Organization = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isOrgModalOpen, setOrgModalOpen] = useState(false)
  const debouncedSearch = useDebounce(search, DEBOUNCED_SEARCH_DELAY)

  const { isReadOnly } = useReadMode()

  const {
    refetchOrganizationsQuery,
    organizationsPagination,
    organizationsData,
    loadOrganizationsQuery,
  } = useOrganizations(initPagination, debouncedSearch)

  useEffect(() => {
    if (debouncedSearch.length >= 2 || !debouncedSearch.length) {
      loadOrganizationsQuery({
        variables: {
          search: debouncedSearch,
          ...initPagination,
        },
      })
    }
  }, [debouncedSearch])
  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [positionedConfirmOpen, setPositionedConfirmOpen] = useState(false)
  const [positionedConfirmPlacement, setPositionedConfirmPlacement] = useState()

  const [organizationName, setOrganizationName] = useState('')
  const [organizationId, setOrganizationId] = useState('')

  const pagination = {
    offset: 0,
    limit: initPagination.pagination.size,
    total: organizationsPagination?.totalItems,
  }

  const resetPage = () => {
    let currentPage = page
    if (page > 1 && organizationsData?.length === 1) {
      currentPage = page - 1
      setPage(currentPage)
    }
    return currentPage
  }

  const [deleteOrganization] = useApolloMutation(DELETE_ORGANIZATION, {
    onCompleted: (organization) => {
      const { DeleteOrganization: { name } } = organization

      const currentPage = resetPage()
      setOrganizationId('')
      setOrganizationName('')
      refetchOrganizationsQuery({
        pagination: {
          offset: (currentPage - 1) * pagination.limit,
          size: pagination.limit,
        },
      })

      toast.success(`${name} Organization deleted`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const deleteOrg = (id) => {
    deleteOrganization({
      variables: {
        id,
      },
      refetchQueries: refetchOrganizationsQuery,
    })
  }

  const handleAdd = () => {
    setOrganizationId('')
    setOrgModalOpen(true)
  }

  const handlePositionedConfirm = (id, name, newPlacement) => (event) => {
    setOrganizationId(id)
    setOrganizationName(name)
    setPositionedConfirmAnchorEl(event.currentTarget)
    setPositionedConfirmOpen(prev => positionedConfirmPlacement !== newPlacement || !prev)
    setPositionedConfirmPlacement(newPlacement)
  }

  const handleEdit = (id) => {
    setOrganizationId(id)
    setOrgModalOpen(true)
  }
  const handlePositionedConfirmDelete = (id, name, newPlacement) => (event) => {
    handlePositionedConfirm(id, name, newPlacement)(event)
  }

  const closePositionedConfirm = () => setPositionedConfirmOpen(false)

  const handleAccept = () => {
    deleteOrg(organizationId)
  }

  const actions = {
    onEdit: handleEdit,
    onDelete: handlePositionedConfirmDelete,
  }

  const countPage = Math.ceil(pagination.total / pagination.limit)

  const changePagination = (_, currentPage) => {
    refetchOrganizationsQuery({
      pagination: {
        offset: (currentPage - 1) * pagination.limit,
        size: pagination.limit,
      },
    })
    setPage(currentPage)
  }

  return (
    <div className={isMobile ? 'organization-page' : 'organization-page p-20'}>
      <PositionedConfirmModal
        acceptTitle="Yes"
        anchorEl={positionedConfirmAnchorEl}
        cancelTitle="Cancel"
        open={positionedConfirmOpen}
        placement={positionedConfirmPlacement}
        title={`Are you sure you want to delete ${organizationName}? All Organization Administrators' accounts will be deactivated.`}
        onAccept={handleAccept}
        onClose={closePositionedConfirm}
      />

      {isOrgModalOpen && (
        <CreateUpdateOrganizationModal
          open={isOrgModalOpen}
          organizationId={organizationId}
          refetchQueries={refetchOrganizationsQuery}
          width={450}
          onClose={() => setOrgModalOpen(false)}
        />
      )}

      <div className="d-flex">
        {!isMobile && (
          <section className="settings-page-title d-flex flex-column">
            <span><small>General settings</small></span>
            <h3 className="pt-5">Organizations</h3>
          </section>
        )}
      </div>
      <div className={isMobileOnly ? 'd-flex justify-content-between align-items-center p-20' : 'd-flex justify-content-between align-items-center'}>
        <section className={isMobileOnly ? 'search-and-filter d-flex w-80' : 'search-and-filter d-flex w-30 pt-15'}>
          <SearchInput onChange={event => setSearch(event.target.value)} />
        </section>
        <Button
          className="btn-custom mr-5"
          disabled={isReadOnly}
          onClick={handleAdd}
        >
          {!isMobileOnly && <i className="zmdi zmdi-account-add" />}
          {isMobileOnly && <i className="zmdi zmdi-plus" />}
          {' '}
          {!isMobileOnly && 'Add new Organization'}
        </Button>
      </div>
      <div className="table-responsive pt-20">
        <OrganizationTable
          actions={actions}
          organizations={organizationsData}
          refetchOrganizations={refetchOrganizationsQuery}
        />
      </div>
      <div className={isMobile ? 'd-flex mobile-pagination mt-20 w-100' : 'd-flex justify-content-end mt-20'}>
        <TablePagination
          count={countPage}
          page={page}
          onChange={changePagination}
        />
      </div>
    </div>
  )
}

export default Organization
