import React, { useState } from 'react'
import { useApolloQuery } from 'GraphQL/apollo'
import { GET_PAGE_HISTORY } from 'GraphQL/queries/pageHistory'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { CollapsedBlock } from '../CollapsedBlock/CollapsedBlock'
import './styles.scss'

export const LastBrowsedPages = () => {
  const [isOpen, setIsOpen] = useState(true)
  const { selectedUser } = useSelector(state => state?.chatAppReducer)
  const { id } = selectedUser

  const pagination = {
    offset: 0,
    size: 100,
  }

  const { data: history } = useApolloQuery(GET_PAGE_HISTORY, {
    variables: {
      client: id,
      pagination,
    },
  })
  const historyLength = history?.PageHistory.items.length
  const title = `Last browsed pages ${ historyLength > 1 ? `(${historyLength})` : ''}`

  const handleOpenBlockToggle = () => setIsOpen(prevState => !prevState)

  const getTimeAfterView = (date) => {
    const timeDiff = Date.now() - new Date(date).getTime()
    const tempTime = moment.duration(timeDiff)
    if (tempTime.years()) {
      return `${tempTime.years()} y`
    }
    if (tempTime.months()) {
      return `${tempTime.months()} mth`
    }
    if (tempTime.days()) {
      return `${tempTime.days()} d`
    }
    if (tempTime.hours()) {
      return `${tempTime.hours()} h`
    }
    if (tempTime.minutes()) {
      return `${tempTime.minutes()} m`
    }
    return `${tempTime.seconds()} s`
  }

  return (
    <div className="collapsed-container">
      <CollapsedBlock
        isOpen={isOpen}
        title={title}
        toggle={handleOpenBlockToggle}
      >
        <div className="history-container">
          {history?.PageHistory.items.map(item => (
            <div
              key={item.client + item.url}
              className="page"
            >
              <i className="icon zmdi zmdi-globe-alt" />
              <div className="history-item-wrapper">
                <a
                  key={item.referer}
                  href={item.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  {item.url}
                </a>
                <div className="spend-time">{getTimeAfterView(item.createdAt)}</div>
              </div>
            </div>
          ))}
        </div>
      </CollapsedBlock>
    </div>
  )
}
