import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  chartsWrapper: {
    margin: 25,
    gridTemplateColumns: '100%',
    columnGap: 10,
    rowGap: 15,
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
      width: '90%',
      margin: 17,
    },
  },
}))
