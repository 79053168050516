import { makeStyles } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

export const useStyles = makeStyles(theme => ({
  mobileHeader: {
    display: isMobile ? 'flex' : 'block',
    flexFlow: 'row wrap',
    width: isMobile ? '100%' : 'auto',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  pickersWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  label: {
    color: '#757575',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  organizationsSelect: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #9e9e9e',
      borderRadius: 3,
      padding: 5,
      marginTop: 5,
      width: '95%',
    },
  },
  organizationWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 15,
      height: 55,
    },
  },
  exportButton: {
    marginTop: isMobile ? 0 : '15px',
    height: 40,
    marginLeft: 10,
    position: isMobile ? 'absolute' : 'inherit',
    right: 20,
  },
}))
