import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Input, Label,
} from 'reactstrap'

const MessageBox = (
  {
    label,
    text,
    limitMsg,
    maxlength,
    onChange,
  },
) => {
  const inputRef = useRef('')

  return (
    <>
      <Label>
        <span className="ti-comment" />
        <span className="profile-label pl-10">{label}</span>
      </Label>
      <Input
        className="auto-responder-textarea"
        innerRef={inputRef}
        maxLength={maxlength}
        type="textarea"
        value={text}
        onChange={onChange}
      />
      <div className="d-flex justify-content-between mt-10">
        <div>
          <span className="teaxt-area-limit">
            {limitMsg}
            {' '}
            {`${text.length}/${maxlength}`}
          </span>
        </div>
      </div>
    </>
  )
}

MessageBox.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  limitMsg: PropTypes.string,
  maxlength: PropTypes.number,
  delayOptions: PropTypes.shape({
    value: PropTypes.number.isRequired,
    option: PropTypes.string.isRequired,
  }).isRequired,
}

MessageBox.defaultProps = {
  label: '',
  limitMsg: 'Characters limit:',
  maxlength: 240,
}

export default MessageBox
