// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context'

export const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem('accessToken'))
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})
