import Settings from 'Pages/settings'
import Messenger from 'Pages/messenger'
import { AnalyticsPage } from 'Pages/AnalyticsPage/AnalyticsPage'
import { PaymentReport } from 'Pages/PaymentReport/PaymentReport'
import Organization from 'Pages/organization'
import Customers from 'Pages/customers'
import { BillingPage } from 'Pages/BillingPage/BillingPage'

import { ROLE_TYPES } from 'Constants/rolesConstants'
import WorkflowPage from './WorkflowsPage/WorkflowPage'

export const routes = [
  {
    path: 'messenger',
    component: Messenger,
    permissions: [
      ROLE_TYPES.RETAILER,
      ROLE_TYPES.RETAILER_ADMIN,
    ],
  },
  {
    path: 'customers',
    component: Customers,
    permissions: [
      ROLE_TYPES.RETAILER,
      ROLE_TYPES.RETAILER_ADMIN,
    ],
  },
  {
    path: 'analytics',
    component: AnalyticsPage,
    permissions: [
      ROLE_TYPES.PLATFORM_ADMIN,
      ROLE_TYPES.PLATFORM_ANALYST,
      ROLE_TYPES.RETAILER_ADMIN,
    ],
  },
  {
    path: 'payments',
    component: PaymentReport,
    permissions: [
      ROLE_TYPES.PLATFORM_ADMIN,
      ROLE_TYPES.PLATFORM_ANALYST,
      ROLE_TYPES.RETAILER_ADMIN,
    ],
  },
  {
    path: 'organizations',
    component: Organization,
    permissions: [
      ROLE_TYPES.PLATFORM_ADMIN,
      ROLE_TYPES.PLATFORM_ANALYST,
    ],
  },
  {
    path: 'billing',
    component: BillingPage,
    permissions: [
      ROLE_TYPES.PLATFORM_ADMIN,
      ROLE_TYPES.PLATFORM_ANALYST,
    ],
  },
  {
    path: 'settings',
    component: Settings,
    permissions: [
      ROLE_TYPES.RETAILER,
      ROLE_TYPES.RETAILER_ADMIN,
    ],
  },
  {
    path: 'workflows',
    component: WorkflowPage,
    permissions: [
      ROLE_TYPES.RETAILER_ADMIN,
    ],
  },
]
