function fallback(text) {
  const area = document.createElement('textarea')
  area.value = text
  area.contentEditable = true
  area.style.position = 'fixed'

  document.body.appendChild(area)
  area.focus()
  area.select()

  const res = document.execCommand('copy')

  area.remove()
  return res
}

function copyToClipboard(text) {
  // eslint-disable-next-line no-void
  return navigator.clipboard !== void 0
    ? navigator.clipboard.writeText(text)
    : new Promise((resolve, reject) => {
      const res = fallback(text)
      if (res) {
        resolve(true)
      } else {
        reject(res)
      }
    })
}

export default copyToClipboard
