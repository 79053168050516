import React from 'react'
import PropTypes from 'prop-types'

export const ModalCheckBox = ({ name, text, children }) => (
  <>
    <label
      className="mr-2"
      htmlFor={name}
    >
      {text}
    </label>
    {children}
  </>
)

ModalCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
