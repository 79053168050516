import { CONVERSATION_STATUSES } from 'Constants/conversation'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import {
  markConversationAsRead,
  setConversationResolved,
  updateSelectedUser,
} from 'Store/Actions/ChatAppActions'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Button from '@material-ui/core/Button'

import {
  ASSIGN_AGENT_TO_CONVERSATION,
  MARK_CONVERSATION_AS_READ,
  RESOLVE_CONVERSATION,
} from 'GraphQL/mutations/conversations'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { useConversationPath } from 'Hooks/useConversationPath'
import { useAuthContext } from 'Providers/AuthProvider'
import { changeAssignedAgent } from 'Store/Actions'

export const ChatControls = ({ conversation }) => {
  const dispatch = useDispatch()
  const chatAppReducer = useSelector(state => state.chatAppReducer)

  const { conversationId } = useConversationPath()
  const { currentUser } = useAuthContext()
  const { selectedUser } = chatAppReducer
  const [markAsRead] = useApolloMutation(MARK_CONVERSATION_AS_READ)

  const [assignAgent] = useApolloMutation(ASSIGN_AGENT_TO_CONVERSATION, {
    variables: {
      user: currentUser.id,
      conversation: conversationId,
    },
    onCompleted: () => {
      dispatch(changeAssignedAgent({ agentId: currentUser.id, customerId: selectedUser.id }))
      markAsRead({
        variables: {
          conversation: conversationId,
        },
      }).then(() => {
        dispatch(updateSelectedUser({ isRead: true }))
        dispatch(markConversationAsRead(conversationId))
      })
      toast.success('Successfully assigned')
    },
  })

  const [resolveConversation] = useApolloMutation(RESOLVE_CONVERSATION, {
    variables: {
      id: conversationId,
    },
    onCompleted: () => {
      dispatch(setConversationResolved(selectedUser))
    },
  })

  const handleResolveConversation = () => {
    resolveConversation()
  }

  const isConversationResolvedOrClosed = conversation?.status === CONVERSATION_STATUSES.CLOSED
    || conversation?.status === CONVERSATION_STATUSES.RESOLVED

  const assignButtonText = conversation?.currentAgent ? 'Assigned' : 'Assign to me'
  const resolveButtonText = isConversationResolvedOrClosed ? 'Resolved' : 'Resolve'

  return (
    <div className="chat-head-controls">
      <Button
        className={classNames(
          'resolve-conversation-button',
          {
            disabled: !conversation?.currentAgent
              || conversation?.currentAgent !== currentUser.id || isConversationResolvedOrClosed,
            resolved: isConversationResolvedOrClosed,
          },
        )}
        startIcon={<CheckCircleOutlineIcon />}
        onClick={handleResolveConversation}
      >
        {resolveButtonText}
      </Button>
      <Button
        className={classNames(
          'assign-conversation-button',
          {
            disabled: Boolean(conversation?.currentAgent),
            'right-button': isMobile,
          },
        )}
        startIcon={<AccountCircleIcon />}
        onClick={assignAgent}
      >
        {assignButtonText}
      </Button>
    </div>
  )
}

ChatControls.propTypes = {
  conversation: PropTypes.shape({
    currentAgent: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}
