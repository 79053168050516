import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './Reducers'
import RootSaga from '../Sagas'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

export function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )

  sagaMiddleware.run(RootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./Reducers/index', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./Reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
