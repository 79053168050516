import React from 'react'
import PropTypes from 'prop-types'
import { LONG_REPLY_LIMIT, SHORT_REPLY_LIMIT } from 'Constants/preChatQuestions'
import { Checkbox } from '../Checkbox'

export const CardCheckboxes = ({
  type,
  min,
  max,
  setMin,
  setMax,
  setType,
  optionsLength,
}) => {
  const isInput = type === 'input'
  const isCheckbox = type === 'checkbox'
  const isRadio = type === 'radio'

  const isMultipleOrLong = (() => {
    if (isCheckbox) return true
    if (isRadio) return false
    return max > SHORT_REPLY_LIMIT
  })()
  const longReplyTitle = `Allow long reply (${LONG_REPLY_LIMIT} characters)`
  const multipleChoiceTitle = 'Allow multiple choice selection'
  const checkboxTitle = !isInput ? multipleChoiceTitle : longReplyTitle

  const onLongReplyChange = () => {
    if (isInput) {
      return setMax(max === LONG_REPLY_LIMIT ? SHORT_REPLY_LIMIT : LONG_REPLY_LIMIT)
    }
    if (isCheckbox) {
      setMax(1)
      return setType('radio')
    }
    setMax(optionsLength)
    return setType('checkbox')
  }

  const onRequiredChange = () => {
    if (isRadio) {
      if (min) setMin(0)
      else setMin(1)
    }
    if (isCheckbox) {
      if (min) setMin(0)
      else setMin(1)
    }
    if (isInput) {
      if (min) setMin(0)
      else setMin(SHORT_REPLY_LIMIT)
    }
  }

  return (
    <>
      <Checkbox
        checked={isMultipleOrLong}
        title={checkboxTitle}
        onChange={onLongReplyChange}
      />
      <Checkbox
        checked={!!min}
        title="Required"
        onChange={onRequiredChange}
      />
    </>
  )
}

CardCheckboxes.propTypes = {
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  setMin: PropTypes.func.isRequired,
  setMax: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  optionsLength: PropTypes.number.isRequired,
}

CardCheckboxes.defaultProps = {
  type: null,
  min: null,
  max: null,
}
