import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { useStyles } from './styles'

export const AnalyticsTooltip = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        hidden: isMobile,
      }}
      placement="top"
      title={title}
    >
      {children}
    </Tooltip>
  )
}

AnalyticsTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
