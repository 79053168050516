import React from 'react'
import PropTypes from 'prop-types'
import DoneAll from '@material-ui/icons/DoneAll'
import PhoneEnabled from '@material-ui/icons/PhoneEnabled'

import './styles.scss'

const ChatStylePreview = ({ settings }) => {
  const renderLogo = () => {
    if (settings.logo) {
      return (
        <img
          alt=""
          className="logo-img"
          src={settings.logo}
        />
      )
    }
    return (
      <div
        className="logo"
        style={{ backgroundColor: settings.messengerBrandColor }}
      >
        &
      </div>
    )
  }
  return (
    <div
      className="chat-style-preview-container"
    >
      <span className="settings-label">Style Preview</span>
      <div className="chat-style-preview">
        <div
          className="chat-style-preview-header"
          style={{ backgroundColor: settings.messengerHeaderBackground }}
        >
          {renderLogo()}
          <div className="chat-title">
            <span>Standard TV & Appliance</span>
            <br />
            <span className="sub">Retailer manager</span>
          </div>
        </div>
        <div
          className="section"
          style={{ backgroundColor: settings.messengerChatBackground }}
        >
          <div className="chat">
            <img
              alt=""
              src="/logo192.png"
            />
            <div>
              <span className="message">
                Hello, John!
                <br />
                Our team typically replies within 30 minutes.
              </span>
              <span className="date">1:05 AM</span>
            </div>
          </div>
          <div className="chat mine">
            <img
              alt=""
              src="/logo192.png"
            />
            <div>
              <span className="message">
                Hi! I need a new big TV
              </span>
              <span className="date">
                1:05 AM
                <DoneAll />
              </span>
            </div>
          </div>
        </div>
        <div
          className="footer"
          style={{ backgroundColor: settings.messengerActiveAllBackground }}
        >
          <div className="icon">
            <PhoneEnabled />
          </div>
          <div className="on-going-call">
            <span>Ongoing call</span>
            <br />
            <span>02:45</span>
          </div>
          <div className="call-answer-btn">
            <PhoneEnabled />
            <span>Join</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatStylePreview

ChatStylePreview.propTypes = {
  settings: PropTypes.string.isRequired,
}
