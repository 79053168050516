import React from 'react'
import PropTypes from 'prop-types'
import Collapse from '@material-ui/core/Collapse'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

import './styles.scss'

export const CollapsedBlock = ({
  isOpen,
  toggle,
  children,
  title,
}) => (
  <>
    <div
      className="collapsed-block-header"
      onClick={toggle}
    >
      { isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
      <span>{title}</span>
    </div>
    <Collapse in={isOpen}>
      { children }
    </Collapse>
  </>
)

CollapsedBlock.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}

CollapsedBlock.defaultProps = {
  children: null,
}
