export const agentsHeaders = [
  {
    title: 'Agent name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'isOnboarded',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Phone',
    field: 'phoneNumber',
  },
  {
    title: 'Assigned stores',
    field: '',
    isSorting: false,
  },
  {
    title: 'Actions',
    field: 'actions',
    isSorting: false,
  },
]
