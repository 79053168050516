import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Search } from '@material-ui/icons'
import { Input as MuiInput } from '@material-ui/core'
import { styles } from './styles'

const Input = props => (
  <div className="search-input-wrapper">
    <MuiInput
      {...props}
      startAdornment={<Search />}
      disableUnderline
    />
  </div>
)

export const SearchInput = withStyles(styles)(Input)
