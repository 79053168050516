import React, { useState, memo } from 'react'

import { Avatar } from 'Components/Avatar/Avatar'
import { useAuthContext } from 'Providers/AuthProvider'
import { useAgentStatusContext } from 'Providers/AgentStatusProvider'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { POSITION_TITLES, ROLE_TYPES } from 'Constants/rolesConstants'
import { AGENT_STATUSES } from 'Constants/common'
import { useClickOutside } from 'Hooks/useClickOutside'
import { UserDropdownMenu } from './UserDropdownMenu'
import './styles.scss'

export const UserProfileBlock = memo(() => {
  const [isUserDropdownMenuOpen, setIsUserDropdownMenuOpen] = useState(false)
  const { currentUser, isPlatformAdmin } = useAuthContext()
  const { availability, changeAvailability } = useAgentStatusContext()

  const toggleUserDropdownMenu = () => {
    setIsUserDropdownMenuOpen(prev => !prev)
  }

  const userProfileBlockRef = useClickOutside({
    onClick: () => setIsUserDropdownMenuOpen(false),
  })

  const handleChangeStatus = (userStatus) => {
    changeAvailability(userStatus)
    toggleUserDropdownMenu()
  }

  const getPositionTitle = () => {
    const roles = currentUser.roles
    if (roles.includes(ROLE_TYPES.PLATFORM_ANALYST)) {
      return POSITION_TITLES.PLATFORM_ANALYST
    }
    if (roles.includes(ROLE_TYPES.RETAILER_ADMIN)) {
      return POSITION_TITLES.RETAILER_ADMIN
    }
    if (roles.includes(ROLE_TYPES.PLATFORM_ADMIN)) {
      return POSITION_TITLES.PLATFORM_ADMIN
    }
    return POSITION_TITLES.RETAILER
  }

  const name = !isPlatformAdmin ? currentUser.name : currentUser.email
  const position = getPositionTitle()
  const ArrowIcon = isUserDropdownMenuOpen ? ArrowDropUp : ArrowDropDown

  return (
    <div
      ref={userProfileBlockRef}
      className="user-profile-block-wrapper"
    >
      <div className="user-profile-block">
        <div className="user-info">
          <span className="name">{name}</span>
          <span className="position">{position}</span>
        </div>
        <div className="user-avatar">
          <Avatar
            avatarSrc={currentUser.avatar}
            size="sm"
            status={!isPlatformAdmin && (availability || AGENT_STATUSES.AVAILABLE) ? availability : ''}
            userName={currentUser.name}
          />
        </div>
        <div className="user-dropdown-toggle-wrapper">
          <span
            className="user-dropdown-toggle"
            onClick={toggleUserDropdownMenu}
          >
            <ArrowIcon />
          </span>
        </div>
      </div>

      {isUserDropdownMenuOpen && (
        <UserDropdownMenu
          availabilityStatus={availability}
          onChangeStatus={handleChangeStatus}
        />
      )}
    </div>
  )
})
