import * as yup from 'yup'

const MIN_LENGTH_NAME = 3
const MAX_LENGTH_TEXT = 255
export const validationSchema = yup.object().shape({
  name: yup.string().trim().min(MIN_LENGTH_NAME).max(MAX_LENGTH_TEXT)
    .required(),
  email: yup.string().trim().email('E-mail is incorrect').max(MAX_LENGTH_TEXT)
    .required(),
})
