import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Input as MuiInput } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './styles'
import './styles.scss'

const BaseInput = ({
  label,
  labelIcon,
  error,
  errorMessage,
  wrapperClassName,
  ...restProps
}) => (
  <div className={classNames('input-wrapper', wrapperClassName)}>
    {label && (
      <div className="label">
        {labelIcon && <span className="label-icon">{labelIcon}</span>}
        {label}
      </div>
    )}
    <MuiInput
      {...restProps}
      error={error}
      disableUnderline
    />
    {error && <span className="error-message">{errorMessage}</span>}
  </div>
)

BaseInput.propTypes = {
  control: PropTypes.shape({}),
  label: PropTypes.string,
  labelIcon: PropTypes.element,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  wrapperClassName: PropTypes.string,
}

BaseInput.defaultProps = {
  control: {},
  label: '',
  labelIcon: null,
  error: false,
  errorMessage: 'Error',
  wrapperClassName: '',
}

export default withStyles(styles)(BaseInput)
