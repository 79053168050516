import { AGENT_STATUSES } from 'Constants/common'
import { useAgentStatusContext } from 'Providers/AgentStatusProvider'
import React, { memo } from 'react'
import classNames from 'classnames'
import { statuses } from 'Components/UserProfileBlock/statuses.js'
import { LinkButton } from 'Components/common/LinkButton'
import { resetUserSelection } from 'Store/Actions'
import { ExitToAppOutlined } from '@material-ui/icons'
import { useAuthContext } from 'Providers/AuthProvider'
import PropTypes from 'prop-types'
import './styles.scss'
import { useDispatch } from 'react-redux'

export const UserDropdownMenu = memo(({ availabilityStatus, onChangeStatus }) => {
  const { isPlatformAdmin, isPlatformAnalyst, logout } = useAuthContext()
  const { changeAvailability } = useAgentStatusContext()
  const dispatch = useDispatch()

  const isPlatformAdminOrAnalyst = isPlatformAdmin || isPlatformAnalyst

  const handleLogout = () => {
    dispatch(resetUserSelection())
    changeAvailability(AGENT_STATUSES.OFFLINE)
    logout()
  }
  return (
    <div className="user-dropdown-menu">
      <div className="user-dropdown-menu-container">
        {!isPlatformAdminOrAnalyst && (
          <div className="user-dropdown-menu-status">
            <span className="user-dropdown-menu-status-title">Status </span>
            <ul className="status-list">
              {statuses.map(status => (
                <li
                  key={status.title}
                  className={classNames('status-item', availabilityStatus === status.value && 'active')}
                >
                  <div
                    className="status-item-container "
                    onClick={() => { onChangeStatus(status.value) }}
                  >
                    <span className={classNames('status', status.color)} />
                    <span className="title">{status.title}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="user-dropdown-menu-logout">
          <LinkButton
            startIcon={<ExitToAppOutlined />}
            title="Log out"
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  )
})

UserDropdownMenu.propTypes = {
  availabilityStatus: PropTypes.string,
  onChangeStatus: PropTypes.func,
}

UserDropdownMenu.defaultProps = {
  availabilityStatus: null,
  onChangeStatus: () => {},
}
