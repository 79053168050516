import React from 'react'
import { Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { withRouter } from 'react-router-dom'
import { isTablet } from 'react-device-detect'
import {
  updateUsersSearch,
} from 'Store/Actions'
import PropTypes from 'prop-types'
import RecentChatUsers from './RecentChatUsers'

function UserList({ isOpen }) {
  const dispatch = useDispatch()
  const chatAppReducer = useSelector(state => state.chatAppReducer)

  const updateSearch = (e) => {
    dispatch(updateUsersSearch(e.target.value))
  }
  const showSearch = (isTablet && !isOpen) || !isTablet

  const { searchUsers } = chatAppReducer
  return (
    <div className="users-list-wrapper">
      {showSearch && (
        <div className="search-wrapper mb-0 position-relative">
          <Input
            className="search-input rounded-0 py-2 px-3"
            id="search"
            name="search-users"
            placeholder="Search"
            type="text"
            value={searchUsers}
            onChange={e => updateSearch(e)}
          />
          <i className="zmdi zmdi-search search-icon" />
        </div>
      )}
      <div className={!isOpen ? 'chat-list chat-list-opened' : 'chat-list'}>
        <Scrollbars
          className="rct-scroll"
          style={{ height: '95vh' }}
          autoHide
        >
          <RecentChatUsers />
        </Scrollbars>
      </div>
    </div>
  )
}
UserList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default withRouter(UserList)
