import React from 'react'
import ContentLoader from 'react-content-loader'

const PreloadHeader = () => (
  <div className="px-md-4 px-3 preload-header">
    <div className="d-md-block d-none">
      <ContentLoader
        backgroundColor="rgba(0,0,0,0.05)"
        foregroundColor="rgba(0,0,0,0.04)"
        height={64}
        speed={1}
        width={1760}
      >
        <circle
          cx="25"
          cy="35"
          r="26"
        />
        <rect
          height="42"
          rx="0"
          ry="0"
          width="140"
          x="58"
          y="14"
        />
        <rect
          height="42"
          rx="0"
          ry="0"
          width="500"
          x="210"
          y="14"
        />

        <rect
          height="42"
          rx="0"
          ry="0"
          width="45"
          x="1225"
          y="14"
        />
        <rect
          height="42"
          rx="0"
          ry="0"
          width="120"
          x="1280"
          y="14"
        />
        <rect
          height="42"
          rx="0"
          ry="0"
          width="140"
          x="1410"
          y="14"
        />
        <circle
          cx="1580"
          cy="35"
          r="23"
        />
        <circle
          cx="1630"
          cy="35"
          r="23"
        />
        <rect
          height="42"
          rx="0"
          ry="0"
          width="45"
          x="1660"
          y="14"
        />
        <rect
          height="42"
          rx="0"
          ry="0"
          width="45"
          x="1715"
          y="14"
        />
      </ContentLoader>
    </div>
    <div className="d-md-none d-block py-2 py-md-0">
      <ContentLoader
        backgroundColor="rgba(0,0,0,0.05)"
        foregroundColor="rgba(0,0,0,0.04)"
        height={50}
        speed={1}
        width={750}
      >
        <circle
          cx="23"
          cy="27"
          r="23"
        />
        <circle
          cx="80"
          cy="27"
          r="23"
        />

        <rect
          height="40"
          rx="0"
          ry="0"
          width="40"
          x="415"
          y="6"
        />
        <rect
          height="40"
          rx="0"
          ry="0"
          width="80"
          x="465"
          y="6"
        />
        <rect
          height="40"
          rx="0"
          ry="0"
          width="60"
          x="555"
          y="6"
        />
        <circle
          cx="640"
          cy="25"
          r="21"
        />
        <circle
          cx="685"
          cy="25"
          r="21"
        />
        <rect
          height="40"
          rx="0"
          ry="0"
          width="40"
          x="710"
          y="6"
        />
      </ContentLoader>
    </div>
  </div>
)

export default PreloadHeader
