import React, { useState } from 'react'
import { Avatar } from 'Components/Avatar/Avatar'
import { DropBox } from 'Components/Drop-box'
import { useAuthContext } from 'Providers/AuthProvider'
import { useApolloMutation } from 'GraphQL/apollo'
import { SET_USER_AVATAR } from 'GraphQL/mutations/user'
import './styles.scss'
import PropTypes from 'prop-types'

const MAX_IMAGE_DIMENSION = 500

export const ProfilePhoto = ({ size }) => {
  const { currentUser, refetchUser } = useAuthContext()
  const [isVisiblePhotoAction] = useState(true)

  const [isOpenDropBox, setIsOpenDropBox] = useState(false)

  const [setAvatar] = useApolloMutation(SET_USER_AVATAR, {
    onCompleted() {
      refetchUser()
    },
  })
  const handleOpenDropBox = () => {
    setIsOpenDropBox(true)
  }

  const handleCloseDropBox = () => {
    setIsOpenDropBox(false)
  }

  const handleOnDropBoxChange = (url, token) => {
    setAvatar({
      variables: {
        avatar: url,
        accessToken: token,
      },
    }).then(() => handleCloseDropBox())
  }

  const removePhoto = () => setAvatar({
    variables: {
      avatar: '',
    },
  })

  return (
    <div className="profile-photo">
      <div className="profile-photo-title">
        <span className="ti-image" />
        <span className="profile-label pl-10">Profile photo</span>
      </div>
      <div className="d-flex pt-10 align-items-center">
        <div className="media">
          <Avatar
            avatarSrc={currentUser?.avatar}
            size={size}
            userName={currentUser?.name}
          />
        </div>
        <div className="d-flex flex-column">
          <span className="max-size-photo">{`Max image size: ${MAX_IMAGE_DIMENSION}px x ${MAX_IMAGE_DIMENSION}px`}</span>
          <span className="max-size-photo">Image file can’t be larger than 3MB</span>
          <DropBox
            maxFileDimension={MAX_IMAGE_DIMENSION}
            open={isOpenDropBox}
            onChange={handleOnDropBoxChange}
            onClose={handleCloseDropBox}
            onOpen={handleOpenDropBox}
          />
          {isVisiblePhotoAction && (
            <div className="pt-10">
              <span
                className="ti-download upload-photo"
                onClick={handleOpenDropBox}
              >
&nbsp;Upload new photo
              </span>
              <span
                className="ti-trash pl-10 remove-photo"
                onClick={removePhoto}
              >
&nbsp;Remove photo
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
ProfilePhoto.propTypes = {
  size: PropTypes.string,
}

ProfilePhoto.defaultProps = {
  size: 'lg',
}
