import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  analyticsPageWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10,
    },
  },
  analyticsEmptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
}))
