import React from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'
import ControlHeader from '../ControlHeader'
import { LAUNCHER_POSITION_DESKTOP } from '../../../config'

import './styles.scss'

const LauncherPositionDesktop = ({
  settings,
  onChange,
}) => {
  const handleOnChange = value => onChange('launcherPositionDesktop', value)
  return (
    <div className="launcher-position-container mt-4">
      <ControlHeader
        icon={<GetAppIcon />}
        label="Launcher position desktop"
      />
      <div className="launcher-positions">
        {LAUNCHER_POSITION_DESKTOP.map(
          item => (
            <div
              className={`launcher-container ${item} ${item === settings.launcherPositionDesktop && 'selected'}`}
              onClick={() => handleOnChange(item)}
            />
          ),
        )}
      </div>
    </div>
  )
}

export default LauncherPositionDesktop

LauncherPositionDesktop.propTypes = {
  settings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
