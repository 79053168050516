const heightButton = 40
const borderButton = 2

export const styles = {
  root: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: `${heightButton - borderButton}px`,
    padding: '0 25px',
    borderRadius: 4,
    border: '1px solid',
  },
  contained: {
    boxShadow: 'none',
  },
  label: {
    textTransform: 'capitalize',
  },
}
