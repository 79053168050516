import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  starRatingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  starsWrapper: {
    display: 'flex',
  },
  star: {
    width: 5,
    marginLeft: 5,
  },
  grayIcon: {
    color: '#EEEFF0',
    fontSize: 13,
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
  orangeIcon: {
    color: '#FF7A59',
    fontSize: 13,
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
  title: {
    fontSize: 13,
    fontWeight: 'bold',
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
}))
