import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, MenuItem } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import { SelectWithStyles } from 'Components/common/Select'
import Input from 'Components/common/Input'
import AntSwitch from 'Pages/settings/components/AntSwitch'

import { PAYMENT_METHODS } from 'Constants/payment'

export const TIME_UNITS = {
  Days: 60 * 60 * 24,
  Hours: 60 * 60,
  Minutes: 60,
  Seconds: 1,
}

const BankingDetails = ({
  control, errors, merchantData, setValue, revalidate,
}) => {
  const handleChangeMethods = (currentVal, method, isAdd) => {
    const newVal = isAdd ? currentVal?.concat([method]) : currentVal?.filter(v => v !== method)
    setValue('paymentMethods', newVal)
    revalidate('paymentMethods')
  }

  return (
    <div className="banking-details-containers">
      <hr />
      <div className="row">
        <div className="col-6">
          <Input
            control={control}
            error={!!errors.invoiceNumber}
            errorMessage={errors.invoiceNumber?.message}
            label="Invoice Number"
            name="invoiceNumber"
            fullWidth
          />
        </div>
        <div className="col-6 input-wrapper">
          <FormControl
            className="store-select w-100"
            variant="outlined"
          >
            <label className="label">Merchant Account</label>
            <SelectWithStyles
              className="w-100"
              control={control}
              error={!!errors.receivingMerchant}
              errorMessage={errors.receivingMerchant?.message}
              name="receivingMerchant"
              displayEmpty
            >
              {merchantData.map(bank => (
                <MenuItem
                  key={bank.id}
                  value={bank.id}
                >
                  {bank.name}
                </MenuItem>
              ))}
            </SelectWithStyles>
          </FormControl>
        </div>
      </div>
      <Controller
        control={control}
        name="paymentMethods"
        render={({ field }) => (
          <>
            <label className="label">Payment methods accepted</label>
            <div className="d-flex">
              <AntSwitch
                checked={field.value?.includes(PAYMENT_METHODS.CREDIT_CARD)}
                name="card"
                title="Cards"
                onChange={(e, v) =>
                  handleChangeMethods(field.value, PAYMENT_METHODS.CREDIT_CARD, v)}
              />
              <label className="ml-5">Cards</label>
            </div>
            <div className="d-flex">
              <AntSwitch
                checked={field.value?.includes(PAYMENT_METHODS.BANK)}
                name="bank"
                title="Bank Transfer"
                onChange={(e, v) =>
                  handleChangeMethods(field.value, PAYMENT_METHODS.BANK, v)}
              />
              <label className="ml-5">Bank Transfer</label>
            </div>
            {errors?.paymentMethods && (
              <span className="error-message">{errors?.paymentMethods?.message}</span>
            )}
          </>
        )}
      />
      <hr />
      <div className="row">
        <div className="col-6">
          <Input
            control={control}
            error={!!errors.expiresIn}
            errorMessage={errors.expiresIn?.message}
            label="Link expiration"
            name="expiresIn"
            fullWidth
          />
        </div>
        <div className="col-6 input-wrapper">
          <FormControl
            className="store-select w-100"
            variant="outlined"
          >
            <label className="label ">Time Unit</label>
            <SelectWithStyles
              className="w-100"
              control={control}
              error={!!errors.timeUnit}
              errorMessage={errors.timeUnit?.message}
              name="timeUnit"
              displayEmpty
            >
              {Object.keys(TIME_UNITS).map(unit => (
                <MenuItem
                  key={unit}
                  value={unit}
                >
                  {unit}
                </MenuItem>
              ))}
            </SelectWithStyles>
          </FormControl>
        </div>
      </div>
      <hr style={{ 'margin-top': '0' }} />
    </div>
  )
}

BankingDetails.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({
    expiresIn: PropTypes.shape({
      message: PropTypes.string,
    }),
    receivingMerchant: PropTypes.shape({
      message: PropTypes.string,
    }),
    timeUnit: PropTypes.shape({
      message: PropTypes.string,
    }),
    invoiceNumber: PropTypes.shape({
      message: PropTypes.string,
    }),
    paymentMethods: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  merchantData: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  revalidate: PropTypes.func.isRequired,
}

export default BankingDetails
