import React, { useState } from 'react'
import Input from 'Components/common/Input'
import Button from 'Components/common/Button'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useApolloMutation } from 'GraphQL/apollo'
import { UPDATE_FORGOTTEN_PASSWORD } from 'GraphQL/mutations/user'
import { toast } from 'react-toastify'
import { LOGIN_PATH } from 'Constants/routerPaths'
import { validationSchema } from './validationSchema'

export const ResetPassword = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const email = query.get('email')
  const recoveryToken = query.get('recoveryToken')

  const [updatePassword] = useApolloMutation(UPDATE_FORGOTTEN_PASSWORD, {
    onCompleted: () => {
      history.push(LOGIN_PATH)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const handleShowPassword = () => {
    setIsPasswordVisible(state => !state)
  }

  const handleShowConfirmPassword = () => {
    setIsConfirmPasswordVisible(state => !state)
  }

  const onSubmit = (data) => {
    const { newPassword } = data
    updatePassword({
      variables: {
        email,
        recoveryToken,
        password: newPassword,
      },
    })
  }

  const onError = (submitErrors, error) => console.error('Reset Password submitErrors', { submitErrors }, error)

  return (
    <div className="session-screen">
      <div className="session-container">
        <div className="session-logo-wrapper">
          <img
            alt="logo"
            className="logo"
            src={`${process.env.PUBLIC_URL}/assets/images/logo/main-logo-dark.png`}
          />
        </div>

        <span className="session-title">
          Enter a new password.
        </span>
        <form className="session-form">
          <div className="input-wrapper">
            <Input
              control={control}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              error={errors.newPassword}
              errorMessage={errors.newPassword?.message}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              label="New password"
              name="newPassword"
              placeholder="Enter your new Password"
              type={isPasswordVisible ? 'text' : 'password'}
              fullWidth
            />
          </div>
          <div className="input-wrapper">
            <Input
              control={control}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmPassword}
                  >
                    {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              error={errors.confirmNewPassword}
              errorMessage={errors.confirmNewPassword?.message}
              inputProps={{
                autocomplete: 'repeat-new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              label="Repeat new password"
              name="confirmNewPassword"
              placeholder="Repeat your new password"
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              fullWidth
            />
          </div>

          <Button
            className="button text-white fluid"
            color="primary"
            disabled={!isValid}
            size="large"
            variant="contained"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Recover password
          </Button>
        </form>
      </div>
    </div>
  )
}
