import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'

import { useApolloMutation, useApolloLazyQuery } from 'GraphQL/apollo'
import { GET_STORES } from 'GraphQL/queries/store'
import { DELETE_STORE } from 'GraphQL/mutations/store'
import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { SearchInput } from 'Components/common/SearchInput'
import { useDebounce } from 'Hooks/useDebounce'
import { useCheckPermission } from 'Hooks/useCheckPermission'
import { PERMISSION_NAMES } from 'config/permissions'
import { useAuthContext } from 'Providers/AuthProvider'
import { CreateUpdateStoreModal } from 'Pages/settings/stores/CreateUpdateStoreModal'
import TablePagination from '../components/TablePagination'
import { StoresTable } from './storesTable'
import { storesHeaders } from './storesTableData'

const initPagination = {
  pagination: {
    offset: 0,
    size: 5,
  },
}

const DEBOUNCED_SEARCH_DELAY = 800

const Stores = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isOpenAddStoreModal, setIsOpenAddStoreModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const checkPermission = useCheckPermission()
  const debouncedSearch = useDebounce(search, DEBOUNCED_SEARCH_DELAY)
  const isUserHasEditSorePermission = checkPermission(PERMISSION_NAMES.EDIT_STORE)
  const isUserHasAddSorePermission = checkPermission(PERMISSION_NAMES.ADD_STORE)

  // todo Pass name when the text to be displayed in the confirmation window
  // eslint-disable-next-line no-unused-vars
  const [storeName, setStoreName] = useState('')
  const [storeId, setStoreId] = useState('')
  const { getOrganizationId } = useAuthContext()

  const organizationId = getOrganizationId()

  const [loadStoresQuery,
    { data: dataStoresQuery, refetch: refetchStoresQuery }] = useApolloLazyQuery(GET_STORES, {
    variables: {
      organization: organizationId,
      ...initPagination,
    },
  })
  const [deleteStoreMutation] = useApolloMutation(DELETE_STORE, {
    onCompleted: (store) => {
      const { DeleteStore: { name } } = store

      setStoreId('')
      setStoreName('')
      refetchStoresQuery()
      toast.success(`${name} Store deleted`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    loadStoresQuery()
  }, [])

  useEffect(() => {
    if (debouncedSearch.length >= 2 || !debouncedSearch.length) {
      loadStoresQuery({
        variables: {
          search: debouncedSearch,
          organization: organizationId,
          ...initPagination,
        },
      })
    }
  }, [debouncedSearch])

  const storesPagination = dataStoresQuery?.Stores.pagination
  const storesData = dataStoresQuery?.Stores.items

  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [positionedConfirmOpen, setPositionedConfirmOpen] = useState(false)
  const [positionedConfirmPlacement, setPositionedConfirmPlacement] = useState()

  const pagination = {
    offset: 0,
    limit: initPagination.pagination.size,
    total: storesPagination?.totalItems,
  }
  const countPage = Math.ceil(pagination.total / pagination.limit)

  const changePagination = (_, currentPage) => {
    refetchStoresQuery({
      pagination: {
        offset: (currentPage - 1) * pagination.limit,
        size: pagination.limit,
      },
    })
    setPage(currentPage)
  }

  const deleteStore = (id) => {
    deleteStoreMutation({
      variables: {
        id,
      },
      refetchQueries: [{
        query: GET_STORES,
        variables: {
          organization: organizationId,
          ...initPagination,
        },
      }],
    })
  }

  const handleOpenAddStoreModal = () => {
    setIsEditMode(false)
    setIsOpenAddStoreModal(true)
  }

  const handleCloseAddStoreModal = () => {
    setIsOpenAddStoreModal(false)
  }

  const handlePositionedConfirm = (id, name, newPlacement) => (event) => {
    setStoreId(id)
    setStoreName(name)
    setPositionedConfirmAnchorEl(event.currentTarget)
    setPositionedConfirmOpen(prev => positionedConfirmPlacement !== newPlacement || !prev)
    setPositionedConfirmPlacement(newPlacement)
  }

  const handleEdit = (id) => {
    setStoreId(id)
    setIsEditMode(true)
    setIsOpenAddStoreModal(true)
  }
  const handlePositionedConfirmDelete = (id, name, newPlacement) => (event) => {
    handlePositionedConfirm(id, name, newPlacement)(event)
  }

  const closePositionedConfirm = () => setPositionedConfirmOpen(false)

  const handleAccept = () => {
    deleteStore(storeId)
  }

  const actions = {
    onEdit: handleEdit,
    onDelete: handlePositionedConfirmDelete,
  }

  return (
    <div className="stores-page">
      {positionedConfirmOpen && (
        <PositionedConfirmModal
          acceptTitle="Delete"
          anchorEl={positionedConfirmAnchorEl}
          open={positionedConfirmOpen}
          placement={positionedConfirmPlacement}
          title="Are you sure you want to delete this store?"
          onAccept={handleAccept}
          onCancel={() => {}}
          onClose={closePositionedConfirm}
        />
      )}

      {isOpenAddStoreModal && (
        <CreateUpdateStoreModal
          edit={isEditMode}
          hasPermission={isUserHasEditSorePermission}
          open={isOpenAddStoreModal}
          refetchStores={refetchStoresQuery}
          storeId={storeId}
          width={750}
          onClose={handleCloseAddStoreModal}
        />
      )}

      <div className="settings-page-title-container">
        <section className="settings-page-title">
          <span className="title"><small>General settings</small></span>
          <h3>Stores</h3>
        </section>
      </div>
      <div className="search-and-filter-container">
        <section className="search-and-filter">
          <SearchInput
            onChange={e => setSearch(e.target.value)}
          />
        </section>
        <Button
          className="btn-custom"
          disabled={!isUserHasAddSorePermission}
          onClick={handleOpenAddStoreModal}
        >
          <i className="zmdi zmdi-plus" />
          {' '}
          <span>Add New</span>
        </Button>
      </div>
      <div className="table-responsive">
        <StoresTable
          actions={actions}
          headers={storesHeaders}
          refetchStores={refetchStoresQuery}
          stores={storesData}
        />
      </div>
      <div className="table-pagination-container">
        <TablePagination
          count={countPage}
          page={page}
          onChange={changePagination}
        />
      </div>
    </div>
  )
}

export default Stores
