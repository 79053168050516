import { gql } from '@apollo/client'

export const GET_ORGANIZATION_ANALYTICS = gql`
    query OrganizationAnalytics($organization: String!, $date: DateInput!){
        OrganizationAnalytics(organization: $organization, date: $date){
            id
            name
            date{
              from
              to
            }
            abandonedSessions
            avgTimeToAnswer
            timeOnChat
            numberOfSessions
            avgSessionLength
            typeOfChat
            reviewScore
            sessionsPerDay
        }
    }
`
