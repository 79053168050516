import { gql } from '@apollo/client'

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $name: String!,
    $nmgId: String!,
    $site: [String!]!,
    $phoneNumber: [String!]!,
    $merchantAccounts: [MerchantAccountInput!],
    $reviewDestinations: [ReviewDestinationInput!],
    $address: String!
    $launcherVisible: Boolean
    $paymentEnabled: Boolean
  ) {
    CreateOrganization(
      name: $name,
      nmgId: $nmgId,
      site: $site,
      phoneNumber: $phoneNumber,
      merchantAccounts: $merchantAccounts
      reviewDestinations: $reviewDestinations
      address: $address,
      launcherVisible: $launcherVisible
      paymentEnabled: $paymentEnabled
    ) {
      id
      name
      nmgId
      site
      address
      launcherVisible
      paymentEnabled
      phoneNumber {
          id
          organization
          store
      }
      merchantAccounts {
        id
        name
        username
        password
        integratorId
        active
      }
      reviewDestinations {
        id
        title
        url
        active
      }
    }
  }
`

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: ID!) {
    DeleteOrganization(id:$id) {
      id
      name
    }
  }`

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $id: ID!
    $name: String
    $nmgId: String
    $site: [String!]
    $phoneNumber: [String!]
    $merchantAccounts: [MerchantAccountInput!]
    $reviewDestinations: [ReviewDestinationInput!],
    $address: String
    $logo: String,
    $launcherVisible: Boolean
    $paymentEnabled: Boolean
  ) {
    UpdateOrganization(
      id: $id
      name: $name
      nmgId: $nmgId
      site: $site
      phoneNumber: $phoneNumber
      merchantAccounts: $merchantAccounts
      reviewDestinations: $reviewDestinations
      address: $address
      logo: $logo
      launcherVisible: $launcherVisible
      paymentEnabled: $paymentEnabled
    ) {
      id
      name
      nmgId
      site
      address
      logo
      launcherVisible
      paymentEnabled
      phoneNumber {
          id
          organization
          store
      }
      merchantAccounts {
        id
        name
        username
        password
        integratorId
        active
      }
      reviewDestinations {
        id
        title
        url
        active
      }
      admin {
        id
        name
        email
      }
    }
  }
`

export const DELETE_ORGANIZATION_PHONE_NUMBER = gql`
    mutation DeleteOrganizationPhoneNumber($id: ID!) {
        DeleteOrganizationNumber(id:$id) {
            id
        }
    }`
