import React from 'react'
import PropTypes from 'prop-types'
import { getNumberWithComma, convertHoursToDaysAndHHMMSS } from 'Helpers/helpers'
import { StatisticsItem } from './StatisticsItem'
import { useStyles } from './styles'

const STATISTICS = [
  {
    name: 'Abandoned sessions',
    key: 'abandonedSessions',
    render: value => getNumberWithComma(+Number(value).toFixed()),
  },
  {
    name: 'Avg. time to answer',
    key: 'avgTimeToAnswer',
    render: value => convertHoursToDaysAndHHMMSS(value),
  },
  {
    name: 'Time on chat',
    key: 'timeOnChat',
    render: value => convertHoursToDaysAndHHMMSS(value),
  },
  {
    name: 'Number of sessions',
    key: 'numberOfSessions',
    render: value => getNumberWithComma(+Number(value).toFixed()),
  },
  {
    name: 'Avg. session length',
    key: 'avgSessionLength',
    render: value => convertHoursToDaysAndHHMMSS(value),
  },
]

export const GeneralStatistics = ({ analyticsData }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {STATISTICS.map(statisticsItem => (
        <StatisticsItem
          key={statisticsItem.key}
          item={{ ...statisticsItem, value: analyticsData[statisticsItem.key] }}
          onRenderValue={statisticsItem.render}
        />
      ))}
    </div>
  )
}

GeneralStatistics.propTypes = {
  analyticsData: PropTypes.shape({
    abandonedSessions: PropTypes.string.isRequired,
    avgSessionLength: PropTypes.string.isRequired,
    avgTimeToAnswer: PropTypes.string.isRequired,
    numberOfSessions: PropTypes.string.isRequired,
    timeOnChat: PropTypes.string.isRequired,
  }),
}

GeneralStatistics.defaultProps = {
  analyticsData: {
    abandonedSessions: '0',
    avgSessionLength: '0',
    avgTimeToAnswer: '0',
    numberOfSessions: '0',
    timeOnChat: '0',
  },
}
