import { React } from 'react'
import PropTypes from 'prop-types'
import AntSwitch from 'Pages/settings/components/AntSwitch'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getLogoFromURL } from 'Helpers/helpers'

const AccountItem = ({
  field, setValue, revalidate, destinationData,
}) => {
  const handleChangeAccounts = (currentVal, val, isAdd) => {
    const newVal = isAdd ? currentVal?.concat([val]) : currentVal?.filter(v => v !== val)
    setValue('reviewAccounts', newVal)
    revalidate('reviewAccounts')
  }

  return (
    <div className="accountItem">
      <div className="accountCheck">
        <AntSwitch
          checked={!!field.value?.includes(destinationData.id)}
          className="mr-2"
          name={destinationData.id}
          onChange={(e, v) =>
            handleChangeAccounts(field.value, destinationData.id, v)}
        />
      </div>
      <div className="accountInfo">
        <FontAwesomeIcon
          className="socialIcon"
          icon={getLogoFromURL(destinationData.url)}
        />
        <span className="infoText">{destinationData.title}</span>
      </div>
    </div>
  )
}

AccountItem.propTypes = {
  destinationData: PropTypes.shape.isRequired,
  field: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  revalidate: PropTypes.func.isRequired,
}

export default AccountItem
