import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    border: '1px solid lightgrey',
  },
  agent: {
    color: 'lightgrey',
  },
}))
