import React from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

import ChatList from './chat'
import { UserInfo } from './UserInfo/UserInfo'
import { MessengerPages } from './chat/components/Inbox/config'
import { MessengerContext, useMessengerProvider } from './useMessenger'
import './styles.scss'

const Messenger = () => {
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser } = chatAppReducer
  const chatHeaderReducer = useSelector(state => state.chatHeader)
  const { messengerStep } = chatHeaderReducer

  return (
    <div className="messenger-wrapper">
      {(!isMobile || messengerStep !== MessengerPages.USERINFO) && <ChatList />}
      {isMobile && messengerStep === MessengerPages.USERINFO && selectedUser && (
        <UserInfo />
      )}
      {!isMobile && selectedUser && (
        <UserInfo />
      )}
    </div>
  )
}

const MessengerWrapper = () => {
  const provider = useMessengerProvider()

  return (
    <MessengerContext.Provider value={provider}>
      <Messenger />
    </MessengerContext.Provider>
  )
}

export default MessengerWrapper
