import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from 'Pages/Components/TableWithStyles'
import './styles.scss'

export const TableHeaderCell = ({
  children,
  isSorting,
  handleCellClick,
  sortField,
  ...restProps
}) => (
  <TableCell {...restProps}>
    <div className="table-header">
      <div className="table-header-container">
        {children}
        {isSorting && (
          <div className="table-header-sorting">
            <i
              className="zmdi zmdi-caret-up zmdi-icon-arrow-up"
              onClick={() => handleCellClick(sortField, 'ASC')}
            />
            <i
              className="zmdi zmdi-caret-down zmdi-icon-arrow-down"
              onClick={() => handleCellClick(sortField, 'DESC')}
            />
          </div>
        )}
      </div>
    </div>
  </TableCell>
)

TableHeaderCell.propTypes = {
  children: PropTypes.node,
  isSorting: PropTypes.bool,
  handleCellClick: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired,
}

TableHeaderCell.defaultProps = {
  isSorting: true,
  children: null,
}
