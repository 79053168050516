import { gql } from '@apollo/client'

export const CLIENT_INFORMATION = gql`
query ClientInformation($client: String!) {
  ClientInformation(client: $client) {
    client
    email
    phone
    address
    time
    site
    userAgent
    userIp
    nmgId
  }
}
`
