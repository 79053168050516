import React from 'react'
import PropTypes from 'prop-types'

export const TabPanel = ({
  children,
  value,
  tab,
  ...props
}) => (
  <div
    aria-labelledby={`tab-${tab}`}
    hidden={value !== tab}
    id={`tabpanel-${tab}`}
    role="tabpanel"
    {...props}
  >
    {value === tab && (
      <div>
        {children}
      </div>
    )}
  </div>
)

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.string,
  tab: PropTypes.string,
}

TabPanel.defaultProps = {
  children: null,
  value: null,
  tab: null,
}
