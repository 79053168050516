/* eslint-disable max-lines */ // TODO refactor and remove
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled'
import classNames from 'classnames'
import { Avatar } from 'Components/Avatar/Avatar'

import { toast } from 'react-toastify'
import { useAuthContext } from 'Providers/AuthProvider'
import { MessageType, MessageContentType } from 'Constants/chatConstants'
import { getTime, getBlobURL, getTimeDifference } from 'Helpers/helpers'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { MARK_MESSAGE_AS_READ } from 'GraphQL/mutations/chat'
import { parsePaymentInfo } from 'Helpers/messages'

import PaymentRequest from './PaymentRequest'
import ReviewResponse from './ReviewResponse'

const userInfoLabels = {
  name: 'full name',
  email: 'email address',
  phone: 'phone number',
}

const Message = ({ isEven, message, userName }) => {
  const [imageUrl, setImageURL] = useState('')
  const paymentInfo = [MessageContentType.PAYMENT_REQUEST, MessageContentType.PAYMENT_RESPONSE]
    .includes(message.contentType) ? parsePaymentInfo(message.content) : null
  const isDocumentMessageType = message.type === MessageType.DOC
  const isTextMessageType = !message.type || message.type === MessageType.TEXT
  const isPaymentRequestMessage = message.contentType === MessageContentType.PAYMENT_REQUEST
    && !!paymentInfo
  const isPaymentResponseMessage = message.contentType === MessageContentType.PAYMENT_RESPONSE
    && !!paymentInfo
  const isMediaMessageType = message.type === MessageType.FILE || !!message.attachments?.length
  const isInfoRequestMessage = message.contentType === MessageContentType.INFORMATION_REQUEST
  const isInfoResponseMessage = message.contentType === MessageContentType.INFORMATION_RESPONSE
  const infoProvided = isInfoResponseMessage ? message.content?.split(',')?.map(p => p.split(':'))
    : null
  const infoPieces = isInfoResponseMessage ? infoProvided.map(p => p[0]).join(',') : null
  const isReviewRequestMesssage = message.contentType === MessageContentType.REVIEW_REQUEST
  const isReviewResponseMessage = message.contentType === MessageContentType.REVIEW_RESPONSE
  const reviewRequestPieces = isReviewRequestMesssage ? JSON.parse(message.content) : null
  const reviewResponsePieces = isReviewResponseMessage ? JSON.parse(message.content) : null

  const chatBubble = classNames(
    { 'chat-bubble': !isDocumentMessageType },
    { 'even bg-aqua': isEven && !isDocumentMessageType && !isMediaMessageType },
    { odd: !isEven && !isDocumentMessageType && !isMediaMessageType },
    { 'p-0': Boolean(message.media) },
    { 'd-block': isPaymentRequestMessage || isInfoRequestMessage || isReviewRequestMesssage || isReviewResponseMessage },
  )

  const isReadMessage = classNames('zmdi pl-2 fs-14',
    { 'zmdi-check-all text-pink': message?.isRead },
    { 'zmdi-check': !message?.isRead })

  const typeText = (<p className="mb-0">{message.content}</p>)
  const file = message.attachments?.[0] || message.file || {}

  const getDataURL = (fileName, url) => {
    if (fileName && url) {
      if (fileName.endsWith('.heic')) {
        getBlobURL(url).then((res) => {
          setImageURL(res)
        })
      } else {
        setImageURL(url)
      }
    }
  }

  const getMessageLabel = (type) => {
    let icon = ''
    let label = ''

    switch (type) {
      case 'chat':
        icon = '/assets/images/flag-icons/speech-bubble-with-text-lines.png'
        label = 'Web Chat'
        break
      case 'sms':
        icon = '/assets/images/flag-icons/speech-bubble.png'
        label = 'SMS'
        break
      case 'email':
        icon = '/assets/images/flag-icons/envelope.png'
        label = 'Email'
        break
      default:
        break
    }

    return { icon, label }
  }

  useEffect(() => {
    if (file?.contentType?.startsWith('image')) {
      getDataURL(file?.name, file?.url)
    }
  }, [imageUrl])

  const typePaymentRequest = isPaymentRequestMessage && (
    <div className="type-payment">
      <PaymentRequest amount={paymentInfo?.amount} />
    </div>
  )

  const typePaymentResponse = isPaymentResponseMessage && (
    <div className="type-infoRequest d-flex align-items-center">
      <div className="payment-label">$</div>
      <span className="mx-2">
        Customer paid for $
        {paymentInfo.amount}
        &nbsp;via&nbsp;
        {paymentInfo.method?.replace('_', ' ')}
        ...
      </span>
    </div>
  )

  const typeInfoRequest = isInfoRequestMessage && (
    <div className="type-infoRequest">
      <i
        className={`zmdi zmdi-${message.content !== 'email' && message.content !== 'phone'
          ? 'info' : message.content} icon`}
      />
      <span className="mx-2">
        You requested customer&nbsp;
        {message.content.split(',').map(i => userInfoLabels[i]).join(' and ')}
        ...
      </span>
    </div>
  )

  const typeInfoResponse = isInfoResponseMessage && (
    <div className="type-infoRequest">
      <i
        className={`zmdi zmdi-${infoPieces !== 'email' && infoPieces !== 'phone'
          ? 'info' : infoPieces} icon`}
      />
      <span className="mx-2">
        Customer provided&nbsp;
        {infoProvided.map(p => `${userInfoLabels[p[0]]} (${p[1]})`).join(' and ')}
        ...
      </span>
    </div>
  )

  const typeReviewRequest = isReviewRequestMesssage && (
    <div className="type-infoRequest">
      <span className="new-line">{reviewRequestPieces.message}</span>
      <br />
      <span className="fake-link">click here</span>
      <span> to review</span>
    </div>
  )

  const typeReviewResponse = isReviewResponseMessage && (
    <ReviewResponse
      comment={reviewResponsePieces.comment}
      name={userName}
      rating={reviewResponsePieces.rating}
    />
  )

  const typeDoc = (
    <div className="type-doc">
      <a
        href={file?.url}
        rel="noreferrer"
        target="_blank"
        download
      >
        {file?.name}
      </a>
    </div>
  )

  const typeMedia = (
    <div>
      <div className="type-media">
        {file?.contentType?.startsWith('image') && (
          <img
            alt={file?.name}
            src={file && file.url ? imageUrl : ''}
          />
        )}
        {file?.contentType?.startsWith('video') && (
          <div className="type-media-video">
            <video
              src={file.url}
              controls
            />
          </div>
        )}
      </div>
      {isEven && (
        <a
          className="download-link"
          href={file?.downloadUrl}
          download
        >
          <span className="zmdi zmdi-download" />
        </a>
      )}
    </div>
  )

  const messageLabel = getMessageLabel(message.sentVia)

  const timeFormat = getTimeDifference(message.sentAt, 'hours') >= 24 ? 'ddd, MMM Do HH:mm A' : 'HH:mm A'

  return (
    <div className="chat-bubble-wrap">
      <div className={chatBubble}>
        {
          (isTextMessageType && !isPaymentRequestMessage && !isPaymentResponseMessage
          && !isInfoRequestMessage && !isInfoResponseMessage
          && !isReviewRequestMesssage && !isReviewResponseMessage) && typeText
        }
        {isDocumentMessageType && typeDoc}
        {isMediaMessageType && typeMedia}
        {typePaymentRequest}
        {typePaymentResponse}
        {typeInfoRequest}
        {typeInfoResponse}
        {typeReviewRequest}
        {typeReviewResponse}
      </div>
      <div className={classNames(
        'chat-bubble-timer-container mt-1',
        { 'justify-content-end': !isEven },
      )}
      >
        {isEven && messageLabel.icon && messageLabel.label && (
          <div className="chat-bubble-type">
            <img
              alt="chat-type-icon"
              className="mr-1"
              src={messageLabel.icon}
            />
            {messageLabel.label}
          </div>
        )}
        <div className={classNames(
          'd-block font-xs text-muted time',
          { 'text-left': isEven },
          { 'text-right': !isEven },
        )}
        >
          {getTime(message.sentAt, timeFormat)}
          {' '}
          {!isEven && <i className={isReadMessage} />}
        </div>
      </div>
    </div>
  )
}

const MessageBlock = ({
  isEven,
  message,
}) => {
  const { currentUser } = useAuthContext()
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser } = chatAppReducer
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)

  const isEndCallMessage = message.type === MessageType.TEXT && message.channel === 'video'
  const isCallDeclined = message.content === 'Call declined'
  const agentAvatarSrc = message?.agent?.avatar ?? currentUser.avatar
  const agentName = message?.agent?.name ?? currentUser.name
  const [markMessageAsRead] = useApolloMutation(MARK_MESSAGE_AS_READ, {
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    const sumbitMarkMessageAsRead = async () => {
      if (message.sender !== 'agent') {
        await markMessageAsRead({
          variables: {
            conversation: selectedUser.conversationId,
            messageId: message.messageId,
          },
        })
      }
    }
    sumbitMarkMessageAsRead()
  }, [])

  if (isEndCallMessage) {
    return (
      <div className={classNames(
        'call-ended-message',
        { 'call-declined': isCallDeclined },
      )}
      >
        <PhoneDisabledIcon fontSize="small" />
        <span>{message.content}</span>
      </div>
    )
  }

  if (isEven) {
    return (
      <div className="d-flex flex-nowrap mb-3">
        <Avatar
          className="img-fluid rounded-circle ml-15 align-self-start"
          userName={selectedUser?.name ?? personalCall?.customerName}
        />
        <Message
          isEven={isEven}
          message={message}
          userName={selectedUser.name}
        />
      </div>
    )
  }

  return (
    <div className="d-flex flex-nowrap flex-row-reverse mb-3">
      <Avatar
        avatarSrc={agentAvatarSrc}
        userName={agentName}
      />

      <Message
        isEven={isEven}
        message={message}
      />
    </div>
  )
}

Message.propTypes = {
  isEven: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    content: PropTypes.string,
    contentType: PropTypes.string,
    media: PropTypes.node,
    sentAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sentVia: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    isRead: PropTypes.bool,
    attachments: PropTypes.array,
    file: PropTypes.shape({
      originalName: PropTypes.string,
      file_name: PropTypes.string,
      mime_type: PropTypes.string,
      url: PropTypes.string,
      file_size: PropTypes.number,
      contentType: PropTypes.string,
    }),
  }).isRequired,
  userName: PropTypes.string.isRequired,
}

MessageBlock.propTypes = {
  isEven: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    content: PropTypes.string,
    type: PropTypes.string,
    isRead: PropTypes.bool,
    channel: PropTypes.string,
    messageId: PropTypes.string,
    sender: PropTypes.string,
    agent: PropTypes.shape({
      name: PropTypes.string,
      avatar: PropTypes.string,
    }),
  }).isRequired,
}

export default MessageBlock
