import { useAuthContext } from 'Providers/AuthProvider'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { NOTIFICATION_TYPES, NOTIFICATIONS_TEXTS } from 'Constants/notifications'
import { useFirebaseContext } from 'Providers/FirebaseProvider'

const MAX_STORE_NAME_LENGTH = 25
export const useNotifications = () => {
  const { db } = useFirebaseContext()
  const [isNotificationShown, setIsNotificationShown] = useState(false)
  const rootRef = db.ref()
  const { currentUser } = useAuthContext()
  const organizationRef = rootRef.child(`organizations/${currentUser?.accessList?.[0]?.organization}`)

  const urlRef = rootRef.child(
    `organizations/${currentUser?.accessList?.[0]?.organization}/notifications/${currentUser?.id}`,
  )

  const notificationPath = `notifications/${currentUser?.id}`

  const handleCloseNotification = () => {
    setIsNotificationShown(false)
    organizationRef.child(notificationPath).remove()
  }

  useEffect(() => {
    if (currentUser) {
      urlRef.on('value', (snapshot) => {
        const notification = snapshot.val()
        const key = snapshot.key
        if (key === currentUser.id) {
          const type = notification?.type
          const isNotification = type === NOTIFICATION_TYPES.NEW_CONVERSATION
            || type === NOTIFICATION_TYPES.NEW_CONVERSATION_ASSIGNMENT

          if (isNotification && !isNotificationShown) {
            const storeName = notification.storeName.length > MAX_STORE_NAME_LENGTH
              ? `${notification.storeName.substring(0, MAX_STORE_NAME_LENGTH) }...` : notification.storeName
            setIsNotificationShown(true)

            const text = (
              <div>
                {NOTIFICATIONS_TEXTS[type]}
                {' '}
                <br />
                {`Store: ${storeName}`}
              </div>
            )

            toast.info(text, {
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: handleCloseNotification,
              toastId: 'notification-toast-id',
            })
          }
        }
      })
    }
  }, [currentUser, isNotificationShown])
}
