/* eslint-disable no-magic-numbers */
import React from 'react'
import PropTypes from 'prop-types'
import GetApp from '@material-ui/icons/GetApp'
import ControlHeader from '../ControlHeader'
import { ICONS } from '../../../config'
import './styles.scss'

// NEED FONT AWESOME PRO FOR ICON

const LauncherIconComponent = ({
  settings,
  onChange,
}) => {
  const handleOnChange = value => onChange('launcherIcon', value)
  return (
    <div className="launcher-icon-container mt-4">
      <ControlHeader
        icon={<GetApp />}
        label="Icon"
      />
      <div className="icons">
        {ICONS.map(
          item => (
            <div
              className={`launcher-icon ${item === settings.launcherIcon && 'selected'}`}
              onClick={() => handleOnChange(item)}
            >
              <span
                key={item}
                className={`zmdi zmdi-${item}`}
              />
            </div>
          ),
        )}
      </div>
    </div>
  )
}

export default LauncherIconComponent

LauncherIconComponent.propTypes = {
  settings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
