import { gql } from '@apollo/client'

export const GET_WIDGET = gql`
query Widget($organizationID: String!){
  Widget(organization: $organizationID){
    id,
    whitelistAddresses,
    styles
  }
}
`
