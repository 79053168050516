import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'

const TableFooter = ({ control }) => {
  const items = useWatch({ control, name: 'items' })
  const totalAmount = useMemo(
    () => items?.reduce(
      (r, item) => (r + parseFloat(item.price) * parseInt(item.quantity, 10)),
      0,
    ) || 0,
    [items],
  )

  return (
    <tr>
      <td className="expend-column" />
      <th
        className="text-right"
        colSpan="2"
      >
        Total payment request:
      </th>
      <th
        className="text-right"
      >
        $
        {totalAmount}
      </th>
    </tr>
  )
}

TableFooter.propTypes = {
  control: PropTypes.shape({}).isRequired,
}

export default TableFooter
