/* eslint-disable max-lines */ // TODO refactor and remove
import { STORE_CONV_ASSIGN_TYPES } from 'Constants/common'
import { ASSIGN_AGENT_TO_CONVERSATION } from 'GraphQL/mutations/conversations'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isMobile, isTablet } from 'react-device-detect'
import classNames from 'classnames'
import { Popover, Tooltip } from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views'
import { useClickOutside } from 'Hooks/useClickOutside'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessageToUser } from 'Store/Actions'
import EmojiPicker from 'Components/EmojiPicker'
import { MessageType } from 'Constants/chatConstants'
import { useFirebaseContext } from 'Providers/FirebaseProvider'
import { useChatFileUpload } from 'Pages/messenger/useChatFileUpload'
import { useSendMessage } from 'Pages/messenger/chat/components/useSendMessage'
import { SenderTabs } from 'Pages/messenger/chat/components/SenderTabs/SenderTabs'
import { useConversation } from 'Hooks/useConversation'
import { useConversationPath } from 'Hooks/useConversationPath'
import { useAuthContext } from 'Providers/AuthProvider'
import { TabContainer } from 'Pages/messenger/chat/components/TabContainer'
import RequestPaymentModal from './RequestPaymentModal'
import RequestReviewModal from './RequestReviewModal/RequesetReviewModal'
import InfoRequestPopover from './InfoRequestPopover'
import { useMessengerContext } from '../../useMessenger'

const ENTER_KEYCODE = 13

const Sender = ({
  chatScroll,
  isCall,
  userName,
  assignmentMode,
}) => {
  const { db } = useFirebaseContext()
  const { currentUser, getOrganization } = useAuthContext()
  const { modalState, setModalState } = useMessengerContext()

  const organizationData = getOrganization()

  const [message, setMessage] = useState('')
  const [activeTabIndex, setSetActiveTabIndex] = useState(0)
  const [showEmoji, setShowEmoji] = useState(false)
  const [, setChosenEmoji] = useState(null)
  const [requestPaymentModal, setRequestPaymentModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const rootPath = db.ref()

  const callMessagesPath = rootPath.child(
    `organizations/${currentUser?.accessList[0].organization}/personal-calls/${currentUser.personalCallId}/messages`,
  )
  const sendToPersonalCall = data => callMessagesPath.push().set(data)
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)

  const { conversationPath } = useConversationPath()
  const conversation = useConversation()
  const { selectedUser } = useSelector(state => state?.chatAppReducer)

  const { sendMessage } = useSendMessage()

  const {
    conversationId,
    email,
    storeId,
    customerChannel,
    phone,
  } = selectedUser ?? {}

  const currentAgent = conversation?.currentAgent
  const assignedToAnotherAgent = Boolean(currentAgent && currentUser.id !== currentAgent)
  const isSentDisabled = !personalCall && (assignedToAnotherAgent
    || (!currentAgent && assignmentMode === STORE_CONV_ASSIGN_TYPES.LESS_BUSY) || !assignmentMode)

  const isPaymentEnabled = !isSentDisabled && !!organizationData?.paymentEnabled

  const dispatch = useDispatch()

  const sendToFirebase = useCallback((data) => {
    db.ref(`${conversationPath}/${storeId}/conversations/${conversationId}/messages`).push().set(data)
  }, [conversationPath, conversationId])

  const { getInputProps, getRootProps } = useChatFileUpload({ sendToFirebase, chatScroll })

  const handleChange = (event, newValue) => {
    setSetActiveTabIndex(newValue)
  }

  const handleChangeIndex = (index) => {
    setSetActiveTabIndex(index)
  }

  const onEmojiClick = (event, { emoji }) => {
    setChosenEmoji(emoji)
    setMessage(prevMessage => prevMessage + emoji)
  }

  const currentRef = useClickOutside({
    onClick: () => setShowEmoji(false),
  })

  const [assignAgent] = useApolloMutation(ASSIGN_AGENT_TO_CONVERSATION, {
    variables: {
      user: currentUser.id,
      conversation: conversationId,
    },
  })

  const onSubmitMessage = async (event, customData) => {
    event?.preventDefault()
    const sendingMessage = message.trim()
    if (sendingMessage || customData) {
      const data = {
        content: sendingMessage,
        type: MessageType.TEXT,
        sender: 'retailer',
        sentAt: new Date().getTime(),
        ...customData,
      }

      if (personalCall) {
        sendToPersonalCall(data)
      } else if (assignmentMode === STORE_CONV_ASSIGN_TYPES.FIRST_TO_RESPOND) {
        await assignAgent()
        dispatch(sendMessageToUser(data))
        await sendMessage(data, customerChannel)
      } else {
        dispatch(sendMessageToUser(data))
        await sendMessage(data, customerChannel)
      }
    }

    setMessage('')
    setShowEmoji(false)
    chatScroll.current?.scrollToBottom()
  }

  const handleKeyUpEvent = (evt) => {
    evt.preventDefault()
    if (evt.keyCode === ENTER_KEYCODE && evt.shiftKey === false && message) {
      onSubmitMessage(evt)
    }
  }

  const handleChangeEvent = (evt) => {
    if (evt.keyCode !== ENTER_KEYCODE) {
      setMessage(evt.target.value)
    }
    return false
  }

  const handleCloseRequestModal = () => setRequestPaymentModal(false)

  const handleOpenRequestModal = (e) => {
    if (isPaymentEnabled) {
      if (conversation?.customer?.email && conversation?.customer?.phone) {
        setRequestPaymentModal(true)
      } else {
        setAnchorEl(e.currentTarget)
      }
    }
  }

  return (
    <>
      {showEmoji && (
        <div ref={currentRef}>
          <EmojiPicker
            className="position-relative"
            onEmojiClick={onEmojiClick}
          />
        </div>
      )}
      {!isCall && (
        <SenderTabs
          activeConversationChannel={customerChannel}
          activeTabIndex={activeTabIndex}
          handleChange={handleChange}
          userEmail={email}
          userPhone={phone}
        />
      )}
      <RequestPaymentModal
        open={requestPaymentModal}
        onClose={handleCloseRequestModal}
        onSubmitMessage={onSubmitMessage}
      />
      <RequestReviewModal
        customerName={selectedUser.name}
        open={modalState.requestReview}
        onClose={() => setModalState(ms => ({ ...ms, requestReview: false }))}
        onSubmitMessage={onSubmitMessage}
      />
      <SwipeableViews
        index={activeTabIndex}
        style={{ width: isTablet ? '100%' : 'auto' }}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer>
          <div className={classNames(
            'sender-wrap d-flex bg-white pt-2',
            { 'pl-10': isMobile },
          )}
          >
            <div className="pl-2 pt-2 sender-controls">
              <Tooltip
                disableHoverListener={isPaymentEnabled}
                placement="bottom"
                title={!isPaymentEnabled
                  ? 'Payment is disabled for your organization. Please contact your organization administrator.'
                  : 'Payment is enabled'}
                arrow
              >
                <span
                  className={classNames(
                    'zmdi zmdi-money-box',
                    { disabledSenderIcon: !isPaymentEnabled },
                  )}
                  onClick={handleOpenRequestModal}
                />
              </Tooltip>
              <span
                className={classNames(
                  'zmdi zmdi-star-outline pl-2',
                  { disabledSenderIcon: isSentDisabled },
                )}
                onClick={() => setModalState(ms => ({ ...ms, requestReview: true }))}
              />
              <span
                className={classNames(
                  'zmdi zmdi-mood pl-2',
                  { disabledSenderIcon: isSentDisabled },
                )}
                onClick={() => setShowEmoji(prevState => !prevState)}
              />
              <span
                className={classNames(
                  'zmdi zmdi-attachment-alt pl-2',
                  { disabledSenderIcon: isSentDisabled },
                )}
                {...getRootProps()}
              >
                <input
                  {...getInputProps()}
                  type="file"
                />
              </span>
            </div>
            <form
              className={classNames(
                'msg-form',
                { 'with-payment': true },
              )}
              onSubmit={onSubmitMessage}
            >
              <textarea
                className="msg-input"
                disabled={isSentDisabled}
                placeholder={`Send your message to ${userName}...`}
                value={message}
                onChange={event => handleChangeEvent(event)}
                onKeyUp={handleKeyUpEvent}
              />
              {isMobile && (
                <a
                  className={classNames(
                    'submit-button',
                    { 'btn-in-use': message },
                  )}
                  href=""
                  onClick={onSubmitMessage}
                >
                  Send
                </a>
              )}
            </form>
          </div>
        </TabContainer>
      </SwipeableViews>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="info-request-popover"
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <InfoRequestPopover
          onClose={() => setAnchorEl(null)}
          onProceed={() => setRequestPaymentModal(true)}
          onSubmitMessage={onSubmitMessage}
        />
      </Popover>
    </>
  )
}

Sender.propTypes = {
  chatScroll: PropTypes.shape({
    current: PropTypes.shape({
      scrollToBottom: PropTypes.func,
    }),
  }).isRequired,
  isCall: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  assignmentMode: PropTypes.string.isRequired,
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Sender
