/* eslint-disable max-lines */
// TODO refactor and remove
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import PhoneIcon from '@material-ui/icons/Phone'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import {
  DeleteOutline,
  CallToActionOutlined,
  LocationCityOutlined,
  MapOutlined,
  RoomOutlined,
  StorefrontOutlined,
  AccessTime,
} from '@material-ui/icons'
import { useFieldArray, Controller } from 'react-hook-form'
import moment from 'moment-timezone'

import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import Input from 'Components/common/Input'
import { SelectWithStyles } from 'Components/common/Select'
import { Autocomplete } from 'Components/common/Autocomplete'

import { DELETE_STORE_PHONE_NUMBER } from 'GraphQL/mutations/store'
import { useApolloMutation } from 'GraphQL/apollo'
import { useOrganizationPhoneNumbers } from 'Pages/settings/stores/CreateUpdateStoreModal/useOrganizationPhoneNumbers'

import { STORE_CONV_ASSIGN_OPTIONS } from 'Constants/common'
import { states as US_STATES } from 'Constants/states'
import { TIMEZONES } from 'Constants/timezones'
import { WorkingHourRow } from './components/WorkingHourRow'

export const StoreDetails = ({
  control,
  errors,
  permission,
  handleAssignModeChange,
  conversationAssignmentMode,
  storeData,
  setStorePhoneNumber,
  refetchStore,
  getValues,
  resetValues,
  setValue,
}) => {
  const { fields } = useFieldArray(
    {
      control,
      name: 'workingHours',
    },
  )
  const organizationPhoneNumbers = useOrganizationPhoneNumbers()
  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [positionedConfirmOpen, setPositionedConfirmOpen] = useState(false)
  const [positionedConfirmPlacement, setPositionedConfirmPlacement] = useState('bottom-end')
  const availablePhoneNumbers = useMemo(() => organizationPhoneNumbers.filter(
    phone => !phone.store || phone.store === storeData?.id,
  ) ?? [], [organizationPhoneNumbers, storeData])

  const phoneOptions = useMemo(() => availablePhoneNumbers.map(phone => phone.phoneNumber), [availablePhoneNumbers])
  const [deleteStorePhoneNumber] = useApolloMutation(DELETE_STORE_PHONE_NUMBER, {
    onCompleted: () => {
      refetchStore()
    },
  })

  const handlePositionedConfirmDelete = newPlacement => (event) => {
    setPositionedConfirmAnchorEl(event.currentTarget)
    setPositionedConfirmOpen(prev => positionedConfirmPlacement !== newPlacement || !prev)
    setPositionedConfirmPlacement(newPlacement)
  }

  const handleAccept = () => {
    const phone = organizationPhoneNumbers.find(
      organizationPhone => organizationPhone.phoneNumber === storeData.phoneNumber,
    )
    deleteStorePhoneNumber({
      variables: {
        id: phone?.id,
      },
    })
  }
  const closePositionedConfirm = () => setPositionedConfirmOpen(false)

  const handleApplyMondayToAll = () => {
    const mondayValue = getValues().workingHours[0]
    const workingHours = fields.map(filed => ({
      ...mondayValue,
      day: filed.day,
    }))
    resetValues({
      ...getValues(),
      workingHours,
    })
  }

  return (
    <form className="store-details-wrapper">
      <PositionedConfirmModal
        acceptIcon={false}
        acceptTitle="Remove"
        anchorEl={positionedConfirmAnchorEl}
        open={positionedConfirmOpen}
        placement={positionedConfirmPlacement}
        title="Are you sure you want to remove this phone number? Default number will be used"
        onAccept={handleAccept}
        onClose={closePositionedConfirm}
      />
      <Input
        control={control}
        error={!!errors.name}
        errorMessage={errors.name?.message}
        label="Store name"
        labelIcon={<StorefrontOutlined />}
        name="name"
        placeholder="Store name"
        readOnly={!permission}
        fullWidth
      />
      <Input
        control={control}
        error={!!errors.address}
        errorMessage={errors.address?.message}
        label="Street 1"
        labelIcon={<RoomOutlined />}
        name="address"
        placeholder="New York"
        readOnly={!permission}
        fullWidth
      />
      <Input
        control={control}
        error={!!errors.secondaryAddress}
        errorMessage={errors.secondaryAddress?.message}
        label="Street 2"
        labelIcon={<RoomOutlined />}
        name="secondaryAddress"
        placeholder="Street"
        readOnly={!permission}
        fullWidth
      />
      <Input
        control={control}
        error={!!errors.city}
        errorMessage={errors.city?.message}
        label="City"
        labelIcon={<LocationCityOutlined />}
        name="city"
        placeholder="City"
        readOnly={!permission}
        fullWidth
      />
      <div className="d-flex selects-wrapper">
        <div className="w-70 ">
          <SelectWithStyles
            control={control}
            error={!!errors.state}
            errorMessage={errors.state?.message}
            label="State"
            labelIcon={<MapOutlined />}
            name="state"
            readOnly={!permission}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">
              <em>Select State</em>
            </MenuItem>
            {US_STATES.map(state => (
              <MenuItem
                key={state.abbreviation}
                value={state.name}
              >
                {state.name}
              </MenuItem>
            ))}
          </SelectWithStyles>
        </div>
        <div className="w-30 pl-15">
          <Input
            control={control}
            error={!!errors.postalCode}
            errorMessage={errors.postalCode?.message}
            label="Zip code"
            labelIcon={<CallToActionOutlined />}
            name="postalCode"
            placeholder="90001"
            readOnly={!permission}
            fullWidth
          />
        </div>
      </div>

      <SelectWithStyles
        control={control}
        error={!!errors.timezone}
        errorMessage={errors.timezone?.message}
        label="Time Zone"
        labelIcon={<AccessTime />}
        name="timezone"
        readOnly={!permission}
        displayEmpty
        fullWidth
      >
        <MenuItem value="">
          <em>Select Timezone</em>
        </MenuItem>
        {TIMEZONES.map(timezone => (
          <MenuItem
            key={timezone}
            value={timezone}
          >
            {moment.tz(timezone).format(`(z Z) zz ([${timezone}])`)}
          </MenuItem>
        ))}
      </SelectWithStyles>
      <div className="working-hours-wrapper">
        <span className="working-hours-label">
          Messenger Working Hours
        </span>
        {fields.map((filed, index) => (
          <WorkingHourRow
            key={filed.id}
            control={control}
            day={filed.day}
            errors={errors?.workingHours?.[index]}
            name={`workingHours[${index}]`}
            permission={permission}
            setValue={setValue}
            onApplyAll={handleApplyMondayToAll}
          />
        ))}
        <div className="store-phone-wrapper">
          <div className="title-wrapper">
            <PhoneIcon />
            <span>Store Phone number</span>
          </div>
          <div className="input-wrapper">
            <Autocomplete
              control={control}
              handleChange={setStorePhoneNumber}
              inputProps={{
                error: !!errors.phoneNumber,
                errorMessage: errors.phoneNumber?.message,
                readOnly: !permission,
                wrapperClassName: 'no-margin',
              }}
              name="phoneNumber"
              options={phoneOptions}
              fullWidth
            />
            <IconButton
              aria-label="delete"
              disabled={!permission}
              disableRipple
              onClick={event => handlePositionedConfirmDelete('bottom-end')(event)}
            >
              <DeleteOutline />
            </IconButton>
          </div>
        </div>
        <div className="conversation-assign-mode-wrapper">
          <div className="title-wrapper">
            <NotificationsNoneIcon className="conversation-assign-mode-icon" />
            <span className="conversation-assign-mode-title">New Conversation Alert Mode</span>
          </div>
          <div>
            <FormControl name="conversationAssignMode">
              <RadioGroup
                className="conversation-assign-mode-controls"
                onChange={handleAssignModeChange}
              >
                {STORE_CONV_ASSIGN_OPTIONS.map(type => (
                  <FormControlLabel
                    key={type.value}
                    className="conversation-assign-mode-radio"
                    control={(
                      <Radio
                        checked={conversationAssignmentMode === type.value}
                        size="small"
                      />
                    )}
                    label={type.label}
                    value={type.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div>
          <Controller
            control={control}
            name="allowReassignChatByAgent"
            render={({ field }) => (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={field.value}
                    name={field.name}
                    onChange={e => setValue(field.name, e.target.checked)}
                  />
                )}
                label="Allow Agents to Re-assign Conversations"
              />
            )}
          />
        </div>
      </div>
    </form>
  )
}

StoreDetails.propTypes = {
  control: PropTypes.shape({}).isRequired,
  handleAssignModeChange: PropTypes.func.isRequired,
  conversationAssignmentMode: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      message: PropTypes.string,
    }),
    address: PropTypes.shape({
      message: PropTypes.string,
    }),
    secondaryAddress: PropTypes.shape({
      message: PropTypes.string,
    }),
    city: PropTypes.shape({
      message: PropTypes.string,
    }),
    state: PropTypes.shape({
      message: PropTypes.string,
    }),
    postalCode: PropTypes.shape({
      message: PropTypes.string,
    }),
    timezone: PropTypes.shape({
      message: PropTypes.string,
    }),
    workingHours: PropTypes.array,
    phoneNumber: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  permission: PropTypes.bool,
  storeData: PropTypes.shape({
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  setStorePhoneNumber: PropTypes.func.isRequired,
  refetchStore: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  resetValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}

StoreDetails.defaultProps = {
  permission: false,
  storeData: {},
}
