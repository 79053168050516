/* eslint-disable max-lines */
import React, { useEffect, useState, useMemo } from 'react'
import Input from 'Components/common/Input'
import {
  Add,
  DeleteOutline,
  MailOutline,
  MoreHoriz,
  PersonOutline,
  VpnKeyOutlined,
} from '@material-ui/icons'
import AntSwitch from 'Pages/settings/components/AntSwitch'
import {
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { LinkButton } from 'Components/common/LinkButton'
import { PositionedConfirmModal } from 'Components/common/PositionedConfirmModal'
import { useFieldArray, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { findDuplicates } from 'Helpers/helpers'
import { DELETE_ORGANIZATION_PHONE_NUMBER } from 'GraphQL/mutations/organization'
import { useApolloMutation } from 'GraphQL/useApolloMutation'
import { useStyles } from './styles'
import { CreateMerchantAccountModal } from '../CreateMerchantAccountModal/CreateMerchantAccountModal'
import { CreateReviewDestinationModal } from '../CreateReviewDestinationModal/CreateReviewDestinationModal'

export const OragnizationForm = ({
  control,
  errors,
  values,
  setError,
  organizationId,
}) => {
  const classes = useStyles()
  const {
    fields: sitesFields, append: appendUrl, remove: removeUrl,
  } = useFieldArray({
    control,
    name: 'sites',
  })

  const {
    fields: phoneFields, append: appendPhone, remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })

  const {
    fields: merchantAccounts, append: appendAccount, remove: removeAccount, update: updateAccount,
  } = useFieldArray({
    control,
    name: 'merchantAccounts',
  })

  const {
    fields: reviewDestinations, append: appendDestination, remove: removeDestination, update: updateDestination,
  } = useFieldArray({
    control,
    name: 'reviewDestinations',
  })

  const [positionedConfirmAnchorEl, setPositionedConfirmAnchorEl] = useState(null)
  const [phoneToDelete, setPhoneToDelete] = useState(null)
  const [positionedConfirmOpen, setPositionedConfirmOpen] = useState(false)
  const [positionedConfirmPlacement, setPositionedConfirmPlacement] = useState('bottom-end')
  const addPhone = () => appendPhone({ phone: { phoneNumber: '' } })
  const phones = useMemo(() => values.phones?.map(phone => phone.phone), [values.phones])
  const [removePhoneNumber] = useApolloMutation(DELETE_ORGANIZATION_PHONE_NUMBER)

  const [merchantAccountMenuAnchorEl, setMerchantAccountMenuAnchorEl] = useState(null)
  const [selectedMerchantAccount, setSelectedMerchantAccount] = useState(null)
  const [isMerchantAccountModalVisible, setIsMerchantAccountModalVisible] = useState(false)

  const [reviewDestinationMenuAnchorEl, setReviewDestinationMenuAnchorEl] = useState(null)
  const [selectedReviewDestination, setSelectedReviewDestination] = useState(null)
  const [isReviewDestinationModalVisible, setIsReviewDestinationModalVisible] = useState(false)

  const showMerchantAccountMenu = (event, accountIndex) => {
    setMerchantAccountMenuAnchorEl(event.currentTarget)
    setSelectedMerchantAccount(accountIndex)
  }

  const closeMerchantAccountMenu = () => {
    setMerchantAccountMenuAnchorEl(null)
    setSelectedMerchantAccount(null)
  }

  const handleAddMerchantAccount = () => {
    setSelectedMerchantAccount(null)
    setIsMerchantAccountModalVisible(true)
  }

  const handleEditMerchantAccount = () => {
    setMerchantAccountMenuAnchorEl(null)
    setIsMerchantAccountModalVisible(true)
  }

  const handleDeleteMerchantAccount = (index) => {
    closeMerchantAccountMenu(null)
    removeAccount(index)
  }

  const showReviewDestinationMenu = (event, accountIndex) => {
    setReviewDestinationMenuAnchorEl(event.currentTarget)
    setSelectedReviewDestination(accountIndex)
  }

  const closeReviewDestinationMenu = () => {
    setReviewDestinationMenuAnchorEl(null)
    setSelectedReviewDestination(null)
  }

  const handleAddReviewDestination = () => {
    setSelectedReviewDestination(null)
    setIsReviewDestinationModalVisible(true)
  }

  const handleEditReviewDestination = () => {
    setReviewDestinationMenuAnchorEl(null)
    setIsReviewDestinationModalVisible(true)
  }

  const handleDeleteReviewDestination = (index) => {
    closeReviewDestinationMenu(null)
    removeDestination(index)
  }

  const handleRemovePhone = () => {
    const { index, phone } = phoneToDelete
    removePhone(index)
    removePhoneNumber({
      variables: {
        id: phone.id,
        organization: organizationId,
      },
    })
  }

  const handlePositionedConfirmDelete = newPlacement => (event, phone, index) => {
    setPositionedConfirmAnchorEl(event.currentTarget)
    setPositionedConfirmOpen(prev => positionedConfirmPlacement !== newPlacement || !prev)
    setPositionedConfirmPlacement(newPlacement)
    setPhoneToDelete({ index, phone })
  }

  useEffect(() => {
    const phoneNumbers = phones?.map(phone => phone.phoneNumber)
    const duplicate = findDuplicates(phoneNumbers ?? [])[0]
    const duplicateIndex = phoneNumbers?.lastIndexOf(duplicate)

    if (duplicate && !errors.phones?.[duplicateIndex]) {
      setError(`phones.${duplicateIndex}.phone.phoneNumber`, {
        type: 'phone',
        message: 'Store phone number is already added.',
      })
    }
  }, [phones])

  return (
    <form>
      <PositionedConfirmModal
        acceptIcon={false}
        acceptTitle="Remove"
        anchorEl={positionedConfirmAnchorEl}
        open={positionedConfirmOpen}
        placement={positionedConfirmPlacement}
        title="Are you sure you want to remove this phone number? If it is already assigned to Store, default number will be used"
        onAccept={handleRemovePhone}
        onClose={() => setPositionedConfirmOpen(false)}
      />
      <Input
        control={control}
        error={!!errors.name}
        errorMessage={errors.name?.message}
        label="Organization name"
        labelIcon={<PersonOutline />}
        name="name"
        fullWidth
      />
      <Input
        control={control}
        error={!!errors.nmgId}
        errorMessage={errors.nmgId?.message}
        label="NMG ID"
        labelIcon={<MailOutline />}
        name="nmgId"
        fullWidth
      />
      <ul className={classes.organizationSiteList}>
        {sitesFields.map((site, index) => (
          <li
            key={site.id}
            className={classes.organizationSiteItem}
          >
            <Input
              control={control}
              error={!!errors?.sites?.[index]}
              errorMessage={errors?.sites?.[index]?.message}
              label="Organization URL"
              labelIcon={<VpnKeyOutlined />}
              name={`sites.${index}`}
              fullWidth
            />
            <IconButton
              aria-label="delete"
              className={classes.removeButton}
              disabled={sitesFields.length <= 1}
              disableRipple
              onClick={() => removeUrl(index)}
            >
              <DeleteOutline />
            </IconButton>
          </li>
        ))}
      </ul>

      <div className="d-flex justify-content-between pt-20 pb-25">
        <LinkButton
          color="primary"
          startIcon={<Add />}
          onClick={() => appendUrl('')}
        >
          Add site
        </LinkButton>
      </div>

      <ul className={classes.organizationSiteList}>
        {phoneFields.map((phoneField, index) => (
          <li
            key={phoneField.id}
            className={classes.organizationSiteItem}
          >
            <Input
              control={control}
              error={!!errors?.phones?.[index]?.phone.phoneNumber}
              errorMessage={errors?.phones?.[index]?.phone.phoneNumber.message}
              label="Store phone number"
              labelIcon={<VpnKeyOutlined />}
              name={`phones.${index}.phone.phoneNumber`}
              fullWidth
            />
            <IconButton
              aria-label="delete"
              className={classes.removeButton}
              disableRipple
              onClick={event => handlePositionedConfirmDelete('bottom-end')(event, phoneField.phone, index)}
            >
              <DeleteOutline />
            </IconButton>
          </li>
        ))}
      </ul>

      <div className="d-flex justify-content-between pt-20 pb-25">
        <LinkButton
          color="primary"
          startIcon={<Add />}
          onClick={addPhone}
        >
          Add phone number
        </LinkButton>
      </div>

      <ul className={classes.organizationSiteList}>
        <div className={classes.merchantAccountListWrapperHeader}>
          <div className="d-flex">
            <Controller
              control={control}
              name="paymentEnabled"
              render={({ field }) => (
                <AntSwitch
                  checked={field.value ?? false}
                  name={field.name}
                  title="Enable Payments"
                  onChange={field.onChange}
                />
              )}
            />
            <label className={classes.merchantAccountSwitch}>Enable Payments</label>
          </div>
          <div className="d-flex">
            <Icon>account_balance</Icon>
            <label className={classes.merchantAccountList}>Merchant Accounts</label>
          </div>
        </div>
        {merchantAccounts.map((merchantAccount, idx) => (
          <li
            key={merchantAccount.id}
            className={classes.organizationSiteItem}
          >
            <Grid
              alignItems="center"
              className={classes.merchantAccountListWrapper}
              direction="row"
              justify="center"
              spacing={2}
              container
            >
              <Grid
                xs={2}
                item
              >
                <Controller
                  control={control}
                  name={`merchantAccounts.${idx}.active`}
                  render={({ field }) => (
                    <AntSwitch
                      checked={field.value ?? false}
                      name={field.name}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={8}
                item
              >
                <label>{merchantAccount.name}</label>
                <br />
                <label>{merchantAccount.username}</label>
              </Grid>
              <Grid
                xs={2}
                item
              >
                <IconButton onClick={e => showMerchantAccountMenu(e, idx)}>
                  <MoreHoriz />
                </IconButton>
                {idx === selectedMerchantAccount && merchantAccountMenuAnchorEl && (
                  <Menu
                    anchorEl={merchantAccountMenuAnchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    aria-labelledby="merchant-account-context-menu"
                    id="merchant-account-context-menu"
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open
                    onClose={closeMerchantAccountMenu}
                  >
                    <MenuItem onClick={handleEditMerchantAccount}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteMerchantAccount(idx)}>
                      Delete
                    </MenuItem>
                  </Menu>
                )}
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>

      <div className="d-flex justify-content-between pt-20 pb-25">
        <LinkButton
          color="primary"
          startIcon={<Add />}
          onClick={handleAddMerchantAccount}
        >
          Add Merchant Account
        </LinkButton>
      </div>

      {isMerchantAccountModalVisible && (
        <CreateMerchantAccountModal
          appendAccount={appendAccount}
          defaultValues={merchantAccounts[selectedMerchantAccount] || {}}
          open={isMerchantAccountModalVisible}
          selectedIndex={selectedMerchantAccount}
          updateAccount={updateAccount}
          width={400}
          onClose={() => setIsMerchantAccountModalVisible(false)}
        />
      )}

      <ul className={classes.organizationSiteList}>
        <div className={classes.merchantAccountListWrapperHeader}>
          <div className="d-flex">
            <Icon>reviews</Icon>
            <label className={classes.merchantAccountList}>Review Destinations</label>
          </div>
        </div>
        {reviewDestinations.map((reviewDestination, idx) => (
          <li
            key={reviewDestination.id}
            className={classes.organizationSiteItem}
          >
            <Grid
              alignItems="center"
              className={classes.merchantAccountListWrapper}
              direction="row"
              justify="center"
              spacing={2}
              container
            >
              <Grid
                xs={2}
                item
              >
                <Controller
                  control={control}
                  name={`reviewDestinations.${idx}.active`}
                  render={({ field }) => (
                    <AntSwitch
                      checked={field.value ?? false}
                      name={field.name}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={8}
                item
              >
                <label>{reviewDestination.title}</label>
                <br />
                <label>{reviewDestination.url}</label>
              </Grid>
              <Grid
                xs={2}
                item
              >
                <IconButton onClick={e => showReviewDestinationMenu(e, idx)}>
                  <MoreHoriz />
                </IconButton>
                {idx === selectedReviewDestination && reviewDestinationMenuAnchorEl && (
                  <Menu
                    anchorEl={reviewDestinationMenuAnchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    aria-labelledby="review-destination-context-menu"
                    id="review-destination-context-menu"
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open
                    onClose={closeReviewDestinationMenu}
                  >
                    <MenuItem onClick={handleEditReviewDestination}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteReviewDestination(idx)}>
                      Delete
                    </MenuItem>
                  </Menu>
                )}
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>

      <div className="d-flex justify-content-between pt-20 pb-25">
        <LinkButton
          color="primary"
          startIcon={<Add />}
          onClick={handleAddReviewDestination}
        >
          Add Review Destination
        </LinkButton>
      </div>

      {isReviewDestinationModalVisible && (
        <CreateReviewDestinationModal
          appendDestination={appendDestination}
          defaultValues={reviewDestinations[selectedReviewDestination] || {}}
          open={isReviewDestinationModalVisible}
          selectedIndex={selectedReviewDestination}
          updateDestination={updateDestination}
          width={400}
          onClose={() => setIsReviewDestinationModalVisible(false)}
        />
      )}

      <Input
        control={control}
        error={!!errors.address}
        errorMessage={errors.address?.message}
        label="Organization Address"
        labelIcon={<VpnKeyOutlined />}
        name="address"
        fullWidth
      />

      {/* label Organization ADMINISTRATOR */}
      <div className={classes.separator}>Organization Administrator</div>
      {/* todo finish the retailer */}
      <Input
        control={control}
        error={!!errors.retailerAdministratorName}
        errorMessage={errors.retailerAdministratorName?.message}
        label="Organization administrator name"
        labelIcon={<VpnKeyOutlined />}
        name="retailerAdministratorName"
        fullWidth
      />
      <Input
        control={control}
        error={!!errors.retailerAdministratorEmail}
        errorMessage={errors.retailerAdministratorEmail?.message}
        label="Organization administrator email"
        labelIcon={<VpnKeyOutlined />}
        name="retailerAdministratorEmail"
        fullWidth
      />
      <Input
        control={control}
        disabled={!!organizationId}
        label="Organization administrator password"
        labelIcon={<VpnKeyOutlined />}
        name="retailerAdministratorPassword"
        placeholder="********"
        fullWidth
      />
    </form>
  )
}

OragnizationForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      message: PropTypes.string,
    }),
    nmgId: PropTypes.shape({
      message: PropTypes.string,
    }),
    organizationSiteList: PropTypes.shape({
      message: PropTypes.string,
    }),
    address: PropTypes.shape({
      message: PropTypes.string,
    }),
    retailerAdministratorName: PropTypes.shape({
      message: PropTypes.string,
    }),
    retailerAdministratorEmail: PropTypes.shape({
      message: PropTypes.string,
    }),
    sites: PropTypes.array,
    phones: PropTypes.array,
  }).isRequired,
  values: PropTypes.shape({
    phones: PropTypes.arrayOf(PropTypes.shape({})),
    paymentEnabled: PropTypes.bool,
  }),
  setError: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
}

OragnizationForm.defaultProps = {
  values: {},
}
