import { gql } from '@apollo/client'

export const DELETE_NOTE = gql`
mutation DeleteNote($id: ID!) {
  DeleteNote(id: $id) {
    id
    body
  }
}`

export const CREATE_NOTE = gql`
mutation CreateNote($conversation: String!, $userId: String!, $body: String!) {
  CreateNote(conversation: $conversation, user: $userId, body: $body) {
    id
    body
  }
}`

export const UPDATE_NOTE = gql`
mutation updateNote($id: ID!, $body: String){
  UpdateNote(id: $id, body: $body){
    id,
    body
  }
}`
