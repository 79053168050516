
import {
  createContext, useContext, useState, useEffect,
} from 'react'
import { useSelector } from 'react-redux'

import { GET_STORE } from 'GraphQL/queries/store'
import { useApolloLazyQuery } from 'GraphQL/useApolloLazyQuery'

export const MessengerContext = createContext({
  currentStore: {},
})

export const useMessengerContext = () => useContext(MessengerContext)

export const useMessengerProvider = () => {
  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser } = chatAppReducer
  const [modalState, setModalState] = useState({
    requestReview: false,
  })

  const [getStore, { data: storeData }] = useApolloLazyQuery(GET_STORE)

  useEffect(() => {
    if (selectedUser?.storeId) {
      getStore({
        variables: {
          id: selectedUser.storeId,
        },
      })
    }
  }, [selectedUser])

  return {
    currentStore: storeData?.Store ?? {},
    modalState,
    setModalState,
  }
}
