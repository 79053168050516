/* eslint-disable max-lines */
import React from 'react'
import ContentLoader from 'react-content-loader'

const PreloadSidebar = () => (
  <div className="rct-sidebar preloader-bg">
    <div className="sidebar-top px-4 py-3">
      <ContentLoader
        backgroundColor="rgba(0,0,0,0.05)"
        foregroundColor="rgba(0,0,0,0.04)"
        height={150}
        speed={1}
        width={260}
      >
        <rect
          height="50"
          rx="0"
          ry="0"
          width="260"
          x="0"
          y="0"
        />
        <circle
          cx="37"
          cy="110"
          r="35"
        />
        <rect
          height="30"
          rx="0"
          ry="0"
          width="170"
          x="90"
          y="95"
        />
      </ContentLoader>
    </div>
    <div className="sidebar-nav px-4">
      <ContentLoader
        backgroundColor="rgba(0,0,0,0.05)"
        foregroundColor="rgba(0,0,0,0.04)"
        height={930}
        speed={1}
        width={260}
      >
        <rect
          height="12"
          rx="0"
          ry="0"
          width="90"
          x="0"
          y="0"
        />

        <circle
          cx="20"
          cy="50"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="44"
        />
        <circle
          cx="20"
          cy="90"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="84"
        />
        <circle
          cx="20"
          cy="130"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="124"
        />
        <circle
          cx="20"
          cy="170"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="164"
        />
        <circle
          cx="20"
          cy="210"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="204"
        />
        <circle
          cx="20"
          cy="250"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="244"
        />

        <rect
          height="12"
          rx="0"
          ry="0"
          width="90"
          x="0"
          y="290"
        />

        <circle
          cx="20"
          cy="335"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="329"
        />
        <circle
          cx="20"
          cy="375"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="369"
        />
        <circle
          cx="20"
          cy="415"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="409"
        />

        <rect
          height="12"
          rx="0"
          ry="0"
          width="90"
          x="0"
          y="455"
        />

        <circle
          cx="20"
          cy="500"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="494"
        />
        <circle
          cx="20"
          cy="540"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="534"
        />
        <circle
          cx="20"
          cy="580"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="574"
        />

        <rect
          height="12"
          rx="0"
          ry="0"
          width="90"
          x="0"
          y="620"
        />

        <circle
          cx="20"
          cy="665"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="659"
        />
        <circle
          cx="20"
          cy="705"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="699"
        />
        <circle
          cx="20"
          cy="745"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="739"
        />
        <circle
          cx="20"
          cy="785"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="779"
        />

        <rect
          height="12"
          rx="0"
          ry="0"
          width="90"
          x="0"
          y="825"
        />

        <circle
          cx="20"
          cy="870"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="864"
        />
        <circle
          cx="20"
          cy="910"
          r="15"
        />
        <rect
          height="15"
          rx="0"
          ry="0"
          width="180"
          x="50"
          y="904"
        />

      </ContentLoader>
    </div>
  </div>
)
export default PreloadSidebar
