const multiplier = 100
export const formatedNumber = number => (Math.round(number * multiplier) / multiplier).toFixed(2)

export const getAPIURLBasedENV = () => {
  if (document.URL.includes('dev01.showroomvideo.chat')) {
    return 'https://api.dev01.showroomvideo.chat'
  } if (document.URL.includes('staging01.showroomvideo.chat')) {
    return 'https://api.staging01.showroomvideo.chat'
  } if (document.URL.includes('staging-stable.showroomvideo.chat')) {
    return 'https://api.staging-stable.showroomvideo.chat'
  } if (document.URL.includes('nmgservices.org')) {
    return 'https://api.nmgservices.org'
  }
  return 'https://api.dev01.showroomvideo.chat'
}

export const ptPaymentStyles = {
  cc: {
    font_color: '#0D172A',
    border_color: '#D8DADD',
    border_style: 'solid',
    font_size: '12pt',
    input_border_radius: '4px',
    input_border_width: '1px',
    input_margin: '8px 0 0 0',
    input_padding: '0 0 0 8px',
    label_color: '#0D172A',
    label_size: '12pt',
    label_margin: '0',
    label_padding: '0',
    label_border_style: 'none',
    background_color: 'white',
    width: '100%',
    height: '40px',
    padding_bottom: '0px',
  },
  exp: {
    font_color: '#0D172A',
    border_color: '#D8DADD',
    border_style: 'solid',
    font_size: '12pt',
    input_border_radius: '4px',
    input_border_width: '1px',
    input_margin: '8px 0 0 0',
    input_padding: '0 0 0 8px',
    label_color: '#0D172A',
    label_size: '12pt',
    label_margin: '12px 0 0 0',
    label_padding: '0',
    label_border_style: 'none',
    background_color: 'white',
    height: '40px',
    padding_bottom: '0px',
    type: 'textbox',
    width: '100px',
  },
  code: {
    font_color: '#0D172A',
    border_color: '#D8DADD',
    border_style: 'solid',
    font_size: '12pt',
    input_border_radius: '4px',
    input_border_width: '1px',
    input_margin: '8px 0 0 0',
    input_padding: '0 0 0 8px',
    label_color: '#0D172A',
    label_size: '12pt',
    label_margin: '12px 0 0 0',
    label_padding: '0 0 0 0',
    label_border_style: 'none',
    background_color: 'white',
    height: '40px',
    padding_bottom: '0px',
    width: '213px',
  },
  body: {
    background_color: 'white',
  },
}
