import { gql } from '@apollo/client'

export const UPDATE_AUTO_RESPOND = gql`
mutation UpdateAutoRespond(
  $id: ID!,
  $active: Boolean!,
  $body: String,
  $delay: DelayOptionInput,
) {
  UpdateAutoRespond(id: $id, active: $active, body: $body, delay: $delay) {
    id
    active
    body
    delay {
      value
      option
    }
  }
}
`
