/* eslint-disable camelcase */
import { useChatArea } from 'Pages/messenger/chat/components/useChatArea'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useAuthContext } from 'Providers/AuthProvider'
import { useFirebaseContext } from 'Providers/FirebaseProvider'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Scrollbars } from 'react-custom-scrollbars'
import { SCROLL_TIMEOUT, STORE_CONV_ASSIGN_TYPES } from 'Constants/common'

import { isMobile } from 'react-device-detect'
import { ChatControls } from 'Pages/messenger/chat/components/ChatControls'
import MessageBlock from './MessageBlock'
import Sender from './Sender'
import SurveyBlock from './SurveyBlock'
import { useMessengerContext } from '../../useMessenger'

function ChatArea({ onMenuIconPress, isCall, chatRef }) {
  const [messages, setMessages] = useState([])
  const { db } = useFirebaseContext()

  const chatAppReducer = useSelector(state => state.chatAppReducer)
  const { selectedUser, admin_photo_url } = chatAppReducer
  const { currentUser } = useAuthContext()
  const { currentStore: store } = useMessengerContext()

  const {
    conversation,
    needUpdateSurvey,
    setNeedUpdateSurvey,
  } = useChatArea()
  const assignmentMode = store.conversationAssignmentMode ?? STORE_CONV_ASSIGN_TYPES.LESS_BUSY
  const messageComparator = (currentMessage, nextMessage) =>
    (new Date(currentMessage.sentAt)) - (new Date(nextMessage.sentAt))
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)
  const rootPath = db.ref()
  const customerName = personalCall?.customerName || selectedUser?.name

  useEffect(() => {
    if (isCall && personalCall) {
      const messagesPath = rootPath.child(
        `organizations/${currentUser.accessList[0].organization}/personal-calls/${currentUser.personalCallId}/messages`,
      )
      messagesPath.on('value', (snap) => {
        const value = snap.val()
        if (value) {
          const snapMessages = Object.keys(value).map(key => ({
            messageId: key,
            ...value[key],
          })).sort(messageComparator)
          setMessages(snapMessages)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (conversation?.customer && !personalCall) {
      const isSelectedUser = conversation.customer?.client === selectedUser?.id
      if (isSelectedUser) {
        if (conversation.messages) {
          const snapMessages = Object.keys(conversation.messages).map(key => ({
            messageId: key,
            ...conversation.messages[key],
          })).sort(messageComparator)
          setTimeout(() => {
            chatRef.current?.scrollToBottom()
          }, SCROLL_TIMEOUT)
          setMessages(snapMessages)
        } else {
          setMessages([])
        }
      }
    }
  }, [conversation])

  if (selectedUser === null && !personalCall) {
    return (
      <div className="chat-box-main">
        <div className="text-center">
          <i className="zmdi zmdi-comments font-3x mb-2" />
          <Button
            className="d-none sidebar-toggler"
            onClick={onMenuIconPress}
          >
            Select user
          </Button>
        </div>
      </div>
    )
  }

  // eslint-disable-next-line no-magic-numbers
  const height = isCall ? '65vh' : `calc(100vh - ${145}px)!important`
  return (
    <div className="chat-main-body">
      {!isCall && (
        <div className="chat-head">
          <div className={isMobile ? 'd-none' : 'd-flex justify-content-between align-items-center'}>
            <div className="media align-items-center">
              <IconButton
                aria-label="open drawer"
                className="mr-3 chat-sidebar-toggler d-none"
                color="inherit"
                onClick={onMenuIconPress}
              >
                <MenuIcon />
              </IconButton>
              <div className="mr-10" />
              <div className="pt-5">
                <h5 className="mb-0">
                  {selectedUser?.first_name}
                    &nbsp;
                  {selectedUser?.last_name}
                </h5>
              </div>
            </div>
            <div />
          </div>
          <ChatControls conversation={conversation} />
        </div>
      )}
      <Scrollbars
        ref={chatRef}
        className="rct-scroll"
        style={{ height }}
        autoHide
      >
        <div className={classNames(
          'chat-body',
          { isCall },
        )}
        >
          {selectedUser && !personalCall && (
            <SurveyBlock
              relation="preChat"
              selectedUser={selectedUser}
            />
          )}
          {messages.map(message => (
            <MessageBlock
              key={message.messageId}
              adminPhotoUrl={admin_photo_url}
              isEven={message.sender === 'customer'}
              message={message}
              selectedUserPhotoUrl={selectedUser?.photo_url}
            />
          ))}
          {selectedUser && !personalCall && (
            <SurveyBlock
              refetchSurvey={needUpdateSurvey}
              relation="postChat"
              selectedUser={selectedUser}
              stopRefetching={() => setNeedUpdateSurvey(false)}
            />
          )}
        </div>
      </Scrollbars>
      <div className="chat-footer">
        <Sender
          assignmentMode={assignmentMode}
          chatScroll={chatRef}
          isCall={isCall}
          userName={customerName}
        />
      </div>
    </div>
  )
}

ChatArea.propTypes = {
  onMenuIconPress: PropTypes.func.isRequired,
  isCall: PropTypes.bool,
  chatRef: PropTypes.shape({
    current: PropTypes.shape({
      scrollToBottom: PropTypes.func,
    }),
  }).isRequired,
}

ChatArea.defaultProps = {
  isCall: false,
}

export default withRouter(ChatArea)
