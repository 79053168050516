import classNames from 'classnames'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { IconButton } from '@material-ui/core'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import { Scrollbars } from 'react-custom-scrollbars'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useEffect } from 'react'
import { changeHeaderData } from 'Store/Actions/ChatHeader'
import { MessagesFilters } from './MessagesFilters'
import { useStyles } from './styles'
import { Stores } from './Stores/Stores'

export const Inbox = ({ isOpen, setIsOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const chatHeaderReducer = useSelector(state => state.chatHeader)
  const { messengerStep } = chatHeaderReducer

  const toggleInbox = () => {
    if (isMobile) {
      dispatch(changeHeaderData({
        name: 'Messenger',
        messengerStep: Number(isOpen),
      }))
    }
    setIsOpen(state => !state)
  }
  useEffect(() => {
    setIsOpen(!messengerStep)
  }, [messengerStep])

  return (
    <Scrollbars
      className={classNames(classes.scrollBar, 'd-md-block', {
        'd-none': !isOpen,
      })}
      autoHide
    >
      <div
        className={classNames(
          classes.wrapper,
          isOpen && classes.open,
        )}
      >
        <div
          className={classNames(
            !isOpen && classes.titleWrapper,
            isOpen && classes.titleWrapperOpen,
          )}
          onClick={toggleInbox}
        >
          <IconButton
            className={classNames(
              classes.button,
              isOpen && classes.isOpen,
            )}
            disableRipple
          >
            {isOpen && (<ArrowBackIosIcon className={classes.iconOpen} />)}
            {!isOpen && (<ArrowForwardIosIcon className={classes.icon} />)}
          </IconButton>

          <div className={classNames(
            !isOpen && classes.title,
            isOpen && classes.titleOpen,
          )}
          >
            {isOpen && (<FolderOpenIcon className={classes.folderIcon} />)}
            <span>
              Inbox
            </span>
          </div>
        </div>
        {isOpen && (
          <>
            <Stores />
            <MessagesFilters />
          </>
        )}
      </div>
    </Scrollbars>
  )
}
Inbox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}
