import { gql } from '@apollo/client'

export const ASSIGN_AGENT_TO_CONVERSATION = gql`
    mutation AssignRetailerToConversation($user: String!, $conversation: String!) {
        AssignRetailerToConversation(user: $user, conversation: $conversation) {
            result
        }
    }
`
export const CLOSE_CONVERSATION = gql`
    mutation CloseConversation($id: ID!) {
        CloseConversation(id: $id) {
            result
        }
    }
`

export const RESOLVE_CONVERSATION = gql`
    mutation ResolveConversation($id: ID!) {
        ResolveConversation(id: $id) {
            result
        }
    }
`

export const MARK_CONVERSATION_AS_READ = gql`
    mutation MarkAsRead($conversation: String!) {
        MarkAsRead(conversation: $conversation) {
            result
        }
    }
`
