import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { useApolloMutation, useApolloQuery } from 'GraphQL/apollo'
import Button from '@material-ui/core/Button'
import { DELETE_QUESTION, UPDATE_QUESTION } from 'GraphQL/mutations/questions'
import { GET_QUESTION } from 'GraphQL/queries/questions'

import { QUESTIONS_LIMIT } from 'Constants/preChatQuestions'

import AntSwitch from '../AntSwitch'
import { CardCheckboxes } from '../CardCheckboxes'
import { Choices } from '../Choices'
import { CardDefaultProps, CardPropTypes } from './propTypes'
import { CardTitleAndDescription } from './CardTitleAndDescription'
import './styles.scss'

export const Card = ({
  id,
  drag,
  isActive,
  activeQuestionsLength,
  refetchQuestions,
}) => {
  const [questionTitle, setQuestionTitle] = useState('')
  const [questionDescription, setQuestionDescription] = useState('')
  const [min, setMin] = useState(null)
  const [max, setMax] = useState(null)
  const [type, setType] = useState(null)
  const [resultOptions, setResultOptions] = useState([])
  const isRatingType = type === 'review'

  const { data: dataQuestion } = useApolloQuery(GET_QUESTION, { variables: { id } })
  const [updateQuestion] = useApolloMutation(UPDATE_QUESTION, {
    refetchQueries: [{
      query: GET_QUESTION,
      variables: { id },
    }],
    onCompleted() {
      refetchQuestions()
    },
  })

  const [deleteQuestion] = useApolloMutation(DELETE_QUESTION, {
    onCompleted() {
      refetchQuestions()
    },
  })
  let questionLabel
  if (type === 'input') {
    questionLabel = 'Text reply'
  } else if (isRatingType) {
    questionLabel = 'Rating'
  } else {
    questionLabel = 'Choice'
  }

  useEffect(() => {
    const result = dataQuestion?.Feedback
    if (result) {
      setQuestionTitle(result.title)
      setQuestionDescription(result.description)
      setMin(result.min)
      setMax(result.max)
      setType(result.type)
      setResultOptions(result.resultOptions)
    }
  }, [dataQuestion?.Feedback])

  const saveChanges = () => {
    const choicesContentCheck = resultOptions.every(option => !!option.value)
    if (!choicesContentCheck) return
    updateQuestion({
      variables: {
        id,
        title: questionTitle,
        description: questionDescription,
        options: resultOptions?.map(opt => ({
          id: opt.id,
          value: opt.value,
        })),
        min,
        max,
        type,
      },
    })
  }

  const toggleActiveCard = () => {
    updateQuestion({
      variables: {
        id,
        active: !isActive,
      },
    })
  }

  const onDeleteQuestion = () => {
    deleteQuestion({
      variables: {
        id,
      },
    })
  }

  const antSwitchTooltip = !isActive && activeQuestionsLength >= QUESTIONS_LIMIT
    ? 'You can not add more than 3 active questions'
    : 'Active/Deactivate questions'

  return (
    <div className="ant-card">
      <div className={classNames('ant-card-header', { drag })}>
        {drag && <span className="ti-menu drag-mark" />}
        <div className={classNames('switch-wrapper', { drag })}>
          <AntSwitch
            checked={isActive}
            disabled={!isActive && activeQuestionsLength >= QUESTIONS_LIMIT}
            name="checked"
            title={antSwitchTooltip}
            onChange={toggleActiveCard}
          />
        </div>
        <span className="label">{questionLabel}</span>
        <CardTitleAndDescription
          id={id}
          questionDescription={questionDescription}
          questionTitle={questionTitle}
          setQuestionDescription={setQuestionDescription}
          setQuestionTitle={setQuestionTitle}
          showDescription={!isRatingType}
          onDeleteQuestion={onDeleteQuestion}
        />
      </div>
      <div className="ant-card-body">
        {!isRatingType && (
          <CardCheckboxes
            max={max}
            min={min}
            optionsLength={resultOptions?.length}
            setMax={setMax}
            setMin={setMin}
            setType={setType}
            type={type}
          />
        )}
        {type !== 'input' && !isRatingType && (
          <Choices
            options={resultOptions}
            setOptions={setResultOptions}
          />
        )}
      </div>
      <Button onClick={saveChanges}>Save</Button>
    </div>
  )
}
Card.propTypes = CardPropTypes
Card.defaultProps = CardDefaultProps
