import * as yup from 'yup'

const MIN_LENGTH_NAME = 3
const MAX_LENGTH_TEXT = 255
export const validationSchema = yup.object().shape({
  name: yup.string().trim().min(MIN_LENGTH_NAME).max(MAX_LENGTH_TEXT)
    .required('Store Name cannot be empty.'),
  postalCode: yup.string().trim().required('Zip code cannot be empty. '),
  address: yup.string().trim().max(MAX_LENGTH_TEXT).required('Store Address cannot be empty.'),
  timezone: yup.string().required('Timezone cannot be empty.'),
  secondaryAddress: yup.string().trim().max(MAX_LENGTH_TEXT),
  city: yup.string().trim().required('City cannot be empty.'),
  state: yup.string().required('Store Address cannot be empty.'),
  users: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email('E-mail is incorrect')
        .required('Email is a required field'),
    }),
  ),
  workingHours: yup.array().of(
    yup.object().shape({
      open: yup.string().required('Required'),
      from: yup.string().when('open', {
        is: value => value === 'Open',
        then: yup.string().required('"From" time cannot be empty.'),
      }),
      to: yup.string().when('open', {
        is: value => value === 'Open',
        then: yup.string().required('"To" time cannot be empty.'),
      }),
      breaks: yup.array().when('open', {
        is: value => value === 'Open',
        then: yup.array().of(
          yup.object().shape({
            from: yup.string().required('"From" time cannot be empty.'),
            to: yup.string().required('"To" time cannot be empty.'),
          }),
        ),
      }),
    }),
  ),
})
