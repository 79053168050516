import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

const ButtonWithIcon = withStyles(() => ({
  root: {
    padding: 0,
    fontSize: 16,
    margin: 4,
  },
}))(IconButton)

export default ButtonWithIcon
