import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useAuthContext } from 'Providers/AuthProvider'
import { useFirebaseContext } from 'Providers/FirebaseProvider'

import { AGENT_STATUSES } from 'Constants/common'

const AgentStatusContext = createContext({
  availability: null,
})
export const useAgentStatusContext = () => useContext(AgentStatusContext)

export const AgentStatusProvider = ({ children }) => {
  const storedAvailabilityStatus = window.localStorage.getItem('availabilityStatus')
  const [availability, setAvailability] = useState(storedAvailabilityStatus ?? AGENT_STATUSES.AVAILABLE)

  const { currentUser } = useAuthContext()
  const { db } = useFirebaseContext()
  const rootRef = db.ref()

  const rootPath = rootRef.child(`users/${currentUser?.id}`)

  const setAvailabilityStatus = (status) => {
    rootPath.set({ availability: status })
    window.localStorage.setItem('availabilityStatus', status)
    setAvailability(status)
  }

  useEffect(() => {
    const availabilityStatus = window.localStorage.getItem('availabilityStatus')

    if (!availabilityStatus && currentUser?.id) {
      rootPath.set({ availability: AGENT_STATUSES.AVAILABLE }).then(() => {
        setAvailabilityStatus(AGENT_STATUSES.AVAILABLE)
      })
    }
  }, [])

  useEffect(() => {
    rootPath.onDisconnect().remove()
  }, [])

  useEffect(() => {
    if (storedAvailabilityStatus && currentUser?.id) {
      rootPath.set({ availability: storedAvailabilityStatus })
    }
  }, [storedAvailabilityStatus, currentUser])

  useEffect(() => {
    rootPath.on('value', (snapshot) => {
      const value = snapshot.val()
      if (value?.availability) {
        setAvailabilityStatus(value.availability)
      }

      if (!value && currentUser?.id) {
        const availabilityStatus = window.localStorage.getItem('availabilityStatus')

        rootPath.set({ availability: availabilityStatus })
        setAvailabilityStatus(availabilityStatus)
      }
    })
  }, [])

  const changeAvailability = (status) => {
    const agentStatus = status ?? AGENT_STATUSES.AVAILABLE
    setAvailabilityStatus(agentStatus)
  }

  const contextValue = {
    changeAvailability,
    availability,
  }

  return (
    <AgentStatusContext.Provider value={contextValue}>
      {children}
    </AgentStatusContext.Provider>
  )
}

AgentStatusProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
