import React from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'
import ControlHeader from '../ControlHeader'
import { LAUNCHER_POSITION_MOBILE } from '../../../config'

import './styles.scss'

const LauncherPositionMobile = ({
  settings,
  onChange,
}) => {
  const handleOnChange = value => onChange('launcherPositionMobile', value)
  return (
    <div className="launcher-position-mobile-container mt-4">
      <ControlHeader
        icon={<GetAppIcon />}
        label="Launcher position mobile"
      />
      <div className="launcher-positions">
        {LAUNCHER_POSITION_MOBILE.map(
          item => (
            <div
              className={`launcher-container ${item} ${item === settings.launcherPositionMobile && 'selected'}`}
              onClick={() => handleOnChange(item)}
            />
          ),
        )}
      </div>
    </div>
  )
}

export default LauncherPositionMobile

LauncherPositionMobile.propTypes = {
  settings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
