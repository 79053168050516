import React, { useEffect, useRef, useMemo } from 'react'
import { useMediaDevices } from 'Pages/video-call/waiting-room/useMediaDevices'

import { resolutions } from 'Pages/video-call/waiting-room/SettingsModal/config'
import { SettingsSelect } from 'Pages/video-call/waiting-room/SettingsModal/SettingsSelect/SettingsSelect'
import { usePeerContext } from 'Providers/PeerProvider/PeerProvider'
import { useCallSettingsContext } from 'Providers/CallSettingsProvider'

export const VideoSettings = () => {
  const videoRef = useRef(null)
  const { cameras, setMediaDevices } = useMediaDevices()
  const { mediaStream } = usePeerContext()
  const {
    setVideoDeviceId,
    setReceivedVideoResolution,
    setCameraResolution,
    cameraResolution,
    receivedVideoResolution,
    videoDeviceId,
    isCameraEnabled,
  } = useCallSettingsContext()

  useEffect(() => {
    if (videoRef.current && mediaStream) {
      videoRef.current.srcObject = mediaStream
      mediaStream.getVideoTracks()[0].enabled = true
    }

    return () => {
      if (mediaStream) {
        mediaStream.getVideoTracks()[0].enabled = isCameraEnabled
      }
    }
  }, [videoRef, mediaStream])

  useEffect(() => {
    if (cameras.length && !cameras[0].label) {
      setMediaDevices()
    }
  }, [cameras])

  useEffect(() => {
    if (!videoDeviceId && cameras[0]?.deviceId) {
      setVideoDeviceId(cameras[0]?.deviceId)
    }
  }, [videoDeviceId, cameras])

  useEffect(() => {
    if (!cameraResolution) {
      setCameraResolution(resolutions[0].value)
    }
  }, [cameraResolution])

  useEffect(() => {
    if (!receivedVideoResolution) {
      setReceivedVideoResolution(resolutions[0].value)
    }
  }, [receivedVideoResolution])

  const chosenResolution = useMemo(() => resolutions.find(
    resolution => resolution.value.height === cameraResolution?.height,
  ), [cameraResolution])

  const chosenReceivedResolution = useMemo(() => resolutions.find(
    resolution => resolution.value.height === receivedVideoResolution?.height,
  ), [receivedVideoResolution])

  const getResolutionValue = label => resolutions.find(resolution => resolution.label === label)?.value

  return (
    <div className="video-block">
      <div className="settings">
        <SettingsSelect
          inputProps={{ value: videoDeviceId }}
          label="Camera"
          options={cameras}
          valueField="deviceId"
          onChange={event => setVideoDeviceId(event.target.value)}
        />
        <div>
          <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
          />
        </div>
      </div>
      <div className="settings">
        <SettingsSelect
          inputProps={{ value: chosenResolution?.label ?? resolutions[0].label }}
          label="Send resolution (maximum)"
          options={resolutions}
          valueField="label"
          onChange={event => setCameraResolution(getResolutionValue(event.target.value))}
        />
      </div>
      <div className="settings">
        <SettingsSelect
          inputProps={{ value: chosenReceivedResolution?.label ?? resolutions[0].label }}
          label="Receive resolution (maximum)"
          options={resolutions}
          valueField="label"
          onChange={event => setReceivedVideoResolution(getResolutionValue(event.target.value))}
        />
      </div>
    </div>
  )
}
