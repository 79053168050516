
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR'
export const DARK_MODE = 'DARK_MODE'
export const BOXED_LAYOUT = 'BOXED_LAYOUT'
export const RTL_LAYOUT = 'RTL_LAYOUT'
export const MINI_SIDEBAR = 'MINI_SIDEBAR'
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE'
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR'
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE'
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const START_USER_TOUR = 'START_USER_TOUR'
export const STOP_USER_TOUR = 'STOP_USER_TOUR'
export const TOGGLE_DARK_SIDENAV = 'TOGGLE_DARK_SIDENAV'

// Chat App Actions
export const CHAT_WITH_SELECTED_USER = 'CHAT_WITH_SELECTED_USER'
export const SEND_MESSAGE_TO_USER = 'SEND_MESSAGE_TO_USER'
export const SEND_FILE_MESSAGE_TO_USER = 'SEND_FILE_MESSAGE_TO_USER'
export const UPDATE_USERS_SEARCH = 'UPDATE_USERS_SEARCH'
export const GET_RECENT_CHAT_USERS = 'GET_RECENT_CHAT_USERS'
export const ADD_NEW_CUSTOMERS = 'ADD_NEW_CUSTOMERS'
export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS'
export const REMOVE_CUSTOMERS = 'REMOVE_CUSTOMERS'
export const GET_MESSAGE_FROM_CUSTOMER = 'GET_MESSAGE_FROM_CUSTOMER'
export const GENERATE_CALL_ID = 'GENERATE_CALL_ID'
export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER'
export const CHANGE_ASSIGNED_AGENT = 'CHANGE_ASSIGNED_AGENT'
export const SET_CONVERSATION_RESOLVED = 'SET_CONVERSATION_RESOLVED'
export const MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ'
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER'
export const RESET_USER_SELECTION = 'RESET_USER_SELECTION'
export const SET_PERSONAL_CALL = 'SET_PERSONAL_CALL'
// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU'

// Agency Sidebar
export const AGENCY_TOGGLE_MENU = 'AGENCY_TOGGLE_MENU'
export const CHANGE_AGENCY_LAYOUT_BG = 'CHANGE_AGENCY_LAYOUT_BG'

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE'
export const LOGIN_FACEBOOK_USER = 'LOGIN_FACEBOOK_USER'
export const LOGIN_GOOGLE_USER = 'LOGIN_GOOGLE_USER'
export const LOGIN_TWITTER_USER = 'LOGIN_TWITTER_USER'
export const LOGIN_GITHUB_USER = 'LOGIN_GITHUB_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE'

// CHAT Header Action
export const CHANGE_HEADER = 'CHANGE_HEADER'
