import React from 'react'
import PropTypes from 'prop-types'
import { TableHeader } from 'Components/TableHeader'
import { isMobile } from 'react-device-detect'
import { UserTableRow } from './UserTableRow'

import {
  Table,
  TableBody,
} from '../components/TableWithStyles'
import './styles.scss'
import { agentsHeaders } from './agentsTableData'

export const AgentsTable = ({ agents, actions, refetchUsers }) => {
  const handleCellClick = (field, direction) => {
    refetchUsers({
      sorting: {
        field,
        direction,
      },
    })
  }
  return (
    <Table>
      {!isMobile && (
        <TableHeader
          handleCellClick={handleCellClick}
          headerTitles={agentsHeaders}
        />
      )}
      <TableBody>
        <>
          {agents && agents.map(user => (
            <UserTableRow
              actions={actions}
              kwy={user.id}
              refetchUsers={refetchUsers}
              user={user}
            />
          ))}
        </>
      </TableBody>
    </Table>
  )
}

AgentsTable.propTypes = {
  agents: PropTypes.array,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  }),
  refetchUsers: PropTypes.func.isRequired,
}

AgentsTable.defaultProps = {
  agents: [],
  actions: {
    onEdit: () => { },
    onDelete: () => { },
  },
}
