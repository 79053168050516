import { React, useState } from 'react'
import PropTypes from 'prop-types'
import Input from 'Components/common/Input'
import AntSwitch from 'Pages/settings/components/AntSwitch'
import { Controller } from 'react-hook-form'

const EmailReceipt = ({
  control, errors, revalidate,
}) => {
  const [selectEmail, setSelectEmail] = useState(false)
  const onSelectEmailChange = (field, val) => {
    setSelectEmail(val)
    field.onChange(val)
    revalidate('receiptEmail')
  }
  return (
    <div className="banking-details-containers">
      <div className="itemTitle">Receipt</div>
      <div className="d-flex">
        <Controller
          control={control}
          name="receiptCheck"
          render={({ field }) => (
            <AntSwitch
              checked={field.value ?? false}
              name={field.name}
              title="Receipt Email"
              onChange={(e, v) => onSelectEmailChange(field, v)}
            />
          )}
        />
        <label className="ml-5">Send receipt email after a successful payment</label>
      </div>
      <div className="mt-2 w-50">
        <Input
          control={control}
          disabled={!selectEmail}
          error={!!errors.receiptEmail}
          errorMessage={errors.receiptEmail?.message}
          label="Receipt email address"
          name="receiptEmail"
          fullWidth
        />
      </div>
    </div>
  )
}

EmailReceipt.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({
    receiptEmail: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  revalidate: PropTypes.func.isRequired,
}

export default EmailReceipt
