/* eslint-disable */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects'
import {
  LOGIN_USER,
  LOGIN_GOOGLE_USER,
  LOGOUT_USER,
  SIGNUP_USER,
} from 'Store/Actions/types'

import {
  signinUserSuccess,
  signinUserFailure,
  signUpUserInFirebaseSuccess,
  signUpUserInFirebaseFailure,
  logoutUserFromFirebaseSuccess,
  logoutUserFromFirebaseFailure,
} from 'Store/Actions'
import { toast } from 'react-toastify'

import {
  auth,
  googleAuthProvider,
} from '../Firebase'

const signInUserWithEmailPasswordRequest = async (email, password) => await auth.signInWithEmailAndPassword(email, password)
  .then(authUser => authUser)
  .catch(error => error)

const signInUserWithGoogleRequest = async () => await auth.signInWithPopup(googleAuthProvider)
  .then(authUser => authUser)
  .catch(error => error)

const signOutRequest = async () => await auth.signOut().then(authUser => authUser).catch(error => error)

/**
 * Create User
 */
const createUserWithEmailPasswordRequest = async (email, password) => await auth.createUserWithEmailAndPassword(email, password)
  .then(authUser => authUser)
  .catch(error => error)

/**
 * Signin User With Email & Password
 */
function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password)
    if (signInUser.message) {
      yield put(signinUserFailure(signInUser.message))
    } else {
      localStorage.setItem('user_id', JSON.stringify(signInUser.user.uid))
      const getData = JSON.parse(localStorage.getItem('user_id'))
      yield put(signinUserSuccess(getData))
      history.push('/')
      toast.success('User Logged In')
    }
  } catch (error) {
    yield put(signinUserFailure(error))
  }
}

function* signinUserWithGoogleAccount({ payload }) {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest)
    if (signUpUser.message) {
      yield put(signinUserFailure(signUpUser.message))
    } else {
      localStorage.setItem('user_id', signUpUser.uid)
      yield put(signinUserSuccess(signUpUser))
      payload.push('/')
    }
  } catch (error) {
    yield put(signinUserFailure(error))
  }
}

function* signOut() {
  try {
    yield call(signOutRequest)
    localStorage.removeItem('user_id')
    yield put(logoutUserFromFirebaseSuccess())
  } catch (error) {
    yield put(logoutUserFromFirebaseFailure())
  }
}

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password)
    if (signUpUser.message) {
      yield put(signUpUserInFirebaseFailure(signUpUser.message))
    } else {
      localStorage.setItem('user_id', signUpUser.uid)
      yield put(signUpUserInFirebaseSuccess(signUpUser))
      history.push('/')
    }
  } catch (error) {
    yield put(signUpUserInFirebaseFailure(error))
  }
}

export function* signinUserInFirebase() {
  yield takeEvery(LOGIN_USER, signInUserWithEmailPassword)
}

export function* signInWithGoogle() {
  yield takeEvery(LOGIN_GOOGLE_USER, signinUserWithGoogleAccount)
}

export function* signOutUser() {
  yield takeEvery(LOGOUT_USER, signOut)
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword)
}

export default function* rootSaga() {
  yield all([
    fork(signinUserInFirebase),
    fork(signInWithGoogle),
    fork(signOutUser),
    fork(createUserAccount),
  ])
}
