import React from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import './styles.scss'
import { Redirect, Route, Switch } from 'react-router-dom'
import AgentProfile from 'Pages/settings/agent-profile'
import { settingsMenu } from 'Pages/MenuNavLinks'
import Stores from 'Pages/settings/stores'
import {
  AGENT_PROFILE_PATH,
  AGENTS_PATH,
  STORES_PATH,
  WIDGET_CODE_PATH,
  AUTORESPONDER_PATH,
  PRE_QUALIFIED_QUESTIONS_PATH,
  POST_CHAT_QUESTION_PATH,
  STYLE_YOUR_MESSENGER_PATH,
} from 'Constants/routerPaths'
import MenuItem from './components/MenuItem'
import Agents from './agents'
import WidgetInstallCode from './widget-install-code'
import AutoResponder from './auto-responder'
import PreQualifiedQuestions from './pre-qualified-questions'
import PostChatQuestion from './post-chat-question'
import StyleYourMessenger from './style-your-messenger'

const Settings = ({ match }) => {
  const activeLink = window.location.pathname.replace(/^.*[\\/]/, '')

  return (
    // <div className="settings-page d-flex">
    <div className={activeLink !== 'settings' ? 'settings-page d-flex inactive-menu' : 'settings-page d-flex'}>
      <section className="settings-left-sidebar w-20">
        <div className="settings-general d-flex flex-column pt-20">
          <span className="pb-10 pl-10">General</span>
          {settingsMenu.general.map(item => (
            <MenuItem
              key={item.title}
              description={item.description}
              iconName={item.iconName}
              link={`${match.url}/${item.link}`}
              titleItem={item.title}
            />
          ))}
        </div>
        <div className="settings-chat d-flex flex-column pt-30">
          <span className="pb-10 pl-10">Chat settings</span>
          {settingsMenu.chat.map(item => (
            <MenuItem
              key={item.title}
              description={item.description}
              iconName={item.iconName}
              link={`${match.url}/${item.link}`}
              titleItem={item.title}
            />
          ))}
        </div>
      </section>
      <section className={isMobile ? 'settings-main-content w-100' : 'settings-main-content pl-5 w-80'}>
        <Switch>
          <Redirect
            from={`${match.url}/`}
            to={isMobile ? `${match.url}` : `${match.url}${AGENT_PROFILE_PATH}`}
            exact
          />
          <Route
            component={AgentProfile}
            path={`${match.url}${AGENT_PROFILE_PATH}`}
          />
          <Route
            component={Agents}
            path={`${match.url}${AGENTS_PATH}`}
          />
          <Route
            component={Stores}
            path={`${match.url}${STORES_PATH}`}
          />
          <Route
            component={WidgetInstallCode}
            path={`${match.url}${WIDGET_CODE_PATH}`}
          />
          <Route
            component={AutoResponder}
            path={`${match.url}${AUTORESPONDER_PATH}`}
          />
          <Route
            component={PreQualifiedQuestions}
            path={`${match.url}${PRE_QUALIFIED_QUESTIONS_PATH}`}
          />
          <Route
            component={PostChatQuestion}
            path={`${match.url}${POST_CHAT_QUESTION_PATH}`}
          />
          <Route
            component={StyleYourMessenger}
            path={`${match.url}${STYLE_YOUR_MESSENGER_PATH}`}
          />
        </Switch>
      </section>
    </div>
  )
}

Settings.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
}

export default Settings
