/* eslint-disable max-lines */
import { getUuid, listenBeforeUnload, removeBeforeUnloadListener } from 'Helpers/helpers'
import { useAuthContext } from 'Providers/AuthProvider'
import { useFirebaseContext } from 'Providers/FirebaseProvider'
// TODO remove and fix
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Peer from 'peerjs'
import PropTypes from 'prop-types'

import { useUserMedia } from 'Pages/useUserMedia'
import { peerConfig } from 'Providers/PeerProvider/config'
import { useCallSettingsContext } from 'Providers/CallSettingsProvider'
import { setPersonalCall } from 'Store/Actions'
// TODO spread logic and refactor this provider
const PeerContext = createContext({
  startCall: null,
  endCall: null,
  call: null,
  remoteVideoRef: null,
  localVideoRef: null,
  peer: null,
  remoteStream: null,
  customerId: null,
  setVideoDeviceId: null,
  setMicrophoneId: null,
  setSpeakerId: null,
  mediaStream: null,
  setResolution: null,
  setReceivedResolution: null,
  receivedResolution: null,
  microphoneId: null,
  setAudioOutputDeviceId: null,
  isCallEnded: null,
  isConnected: false,
  isConnectingError: false,
  callPath: null,
})
export const usePeerContext = () => useContext(PeerContext)

export const PeerProvider = ({ children }) => {
  const dispatch = useDispatch()

  const { db } = useFirebaseContext()
  const { currentUser } = useAuthContext()
  const { retailerId = '', customerId = '' } = useSelector(state => state?.chatAppReducer?.selectedUser?.call ?? {})
  const personalCall = useSelector(state => state?.chatAppReducer.personalCall)
  const rootPath = db.ref()

  const callPath = rootPath.child(`organizations/${currentUser?.accessList[0].organization}/personal-calls/${currentUser.personalCallId}`)
  const customerPeerId = personalCall?.customerId || customerId

  const {
    isCameraEnabled,
    setIsCameraEnabled,
  } = useCallSettingsContext()

  const [call, setCall] = useState(null)
  const [isConnected, setIsConnected] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [connection, setConnection] = useState(null)
  const [isCallEnded, setIsCallEnded] = useState(false)
  const [incomingCall, setIncomingCall] = useState(null)
  const [myPeer, setPeer] = useState(null)
  const [remoteStream, setRemoteStream] = useState(null)
  const [localStream, setLocalStream] = useState(null)
  const [screenShareStream, setScreenShareStream] = useState(null)
  const [isScreenSharing, setIsScreenSharing] = useState(false)
  const [opponentCameraStatus, setOpponentCameraStatus] = useState(false)
  const [peerId, setPeerId] = useState(null)
  const remoteVideoRef = useRef(null)
  const localVideoRef = useRef(null)
  const localScreenShareRef = useRef(null)
  const agentPeerId = peerId || retailerId

  const cleanUp = () => {
    setPeer(null)
    setCall(null)
    myPeer?.destroy()
  }

  const {
    mediaStream,
    setVideoDeviceId,
    setMicrophoneId,
    setSpeakerId,
    setResolution,
    setReceivedResolution,
    receivedResolution,
    setAudioOutputDeviceId,
    audioOutputDeviceId,
  } = useUserMedia()

  useEffect(() => {
    if (customerId) {
      window.localStorage.setItem('customerPeerId', customerId)
    }
  }, [customerId])

  useEffect(() => {
    if (personalCall && personalCall.customerId && !personalCall.agentId) {
      const personalCallPeerId = getUuid()
      setPeerId(personalCallPeerId)
      callPath.set({ ...personalCall, agentId: personalCallPeerId })
    }
    if (personalCall?.agentId && !peerId) {
      setPeerId(personalCall.agentId)
    }

    return () => {
      setPeerId(null)
      dispatch(setPersonalCall(null))
    }
  }, [])

  useEffect(() => {
    const peer = new Peer(agentPeerId, peerConfig)

    peer.on('open', () => {
      setConnection(peer?.connect(customerPeerId))
      setPeer(peer)
    })

    peer.on('call', (customerCall) => {
      setIncomingCall(customerCall)
      customerCall.answer(localStream)
    })

    peer.on('error', (error) => {
      console.error('peer error', error)
    })

    peer.on('disconnected', () => {
      if (peer.disconnected && !peer.destroyed) {
        peer.reconnect()
      }
    })

    return () => {
      cleanUp()
      myPeer?.destroy()
    }
  }, [agentPeerId])

  useEffect(() => {
    if (myPeer && !personalCall) {
      setConnection(myPeer.connect(customerPeerId))
    }
  }, [myPeer])

  useEffect(() => {
    connection?.on('close', () => {
      setIsCallEnded(true)
      setIsConnected(false)
    })
    connection?.on('data', (data) => {
      if (data === 'connected') {
        setIsConnected(true)
      } else {
        setOpponentCameraStatus(data.isCameraEnabled)
      }
    })
  }, [connection])

  useEffect(() => {
    if ((localVideoRef.current && remoteVideoRef.current) || forceUpdate) {
      localVideoRef.current.srcObject = mediaStream
      remoteVideoRef.current.srcObject = remoteStream
    }
  }, [localStream, remoteStream, localVideoRef, remoteVideoRef, mediaStream, forceUpdate])

  useEffect(() => {
    incomingCall?.on('stream', (stream) => {
      connection?.send({ type: 'camera_status', isCameraEnabled: !isCameraEnabled })
      setRemoteStream(stream)
      remoteVideoRef.current.srcObject = stream
    })
    incomingCall?.on('close', () => {
      setCall(null)
      localStream?.getTracks().forEach(track => track.stop())
    })
  }, [incomingCall])

  useEffect(() => {
    call?.on('stream', (stream) => {
      setRemoteStream(stream)
      remoteVideoRef.current.srcObject = stream
    })
    call?.on('close', () => {
      setIsConnected(false)
      localStream?.getTracks().forEach(track => track.stop())
    })
  }, [call])

  const onShareScreenEnd = () => {
    setIsScreenSharing(false)
    setCall(myPeer?.call(customerPeerId, mediaStream))
    screenShareStream?.getVideoTracks()[0].removeEventListener('ended', onShareScreenEnd)

    const tracks = screenShareStream?.getVideoTracks() || []
    tracks.forEach(track => track.stop())
    setScreenShareStream(null)
  }

  useEffect(() => {
    if (isScreenSharing) {
      screenShareStream.getVideoTracks()[0].addEventListener('ended', onShareScreenEnd)
    }
  }, [isScreenSharing])

  const toggleCamera = () => {
    connection?.send({ type: 'camera_status', isCameraEnabled: !isCameraEnabled })
    setIsCameraEnabled(!isCameraEnabled)
  }

  const startCall = () => {
    listenBeforeUnload()
    setCall(myPeer?.call(customerPeerId, mediaStream))
    connection?.send({ type: 'camera_status', isCameraEnabled })
    setLocalStream(mediaStream)
  }

  const endCall = () => {
    removeBeforeUnloadListener()
    myPeer?.destroy()
    if (isScreenSharing) {
      screenShareStream.getTracks().forEach(track => track.stop())
      setIsScreenSharing(false)
    }
    if (personalCall?.agentId) {
      callPath.remove()
    }
  }

  const handleScreenShareSuccess = (stream) => {
    const localScreenShare = stream

    localScreenShareRef.current.srcObject = stream
    localScreenShare.addTrack(mediaStream.getAudioTracks()[0])
    setScreenShareStream(localScreenShare)
    setIsScreenSharing(true)
    setCall(myPeer?.call(customerPeerId, localScreenShare, { metadata: { isScreenShare: true } }))
  }

  const handleScreenShareError = (error) => {
    console.error(`getDisplayMedia error: ${error.name}`, error)
  }

  const screenShareToggle = () => {
    if (!isScreenSharing) {
      navigator.mediaDevices.getDisplayMedia({ video: true }).then(handleScreenShareSuccess, handleScreenShareError)
    } else onShareScreenEnd()
  }

  const contextValue = {
    startCall,
    endCall,
    call,
    remoteVideoRef,
    localVideoRef,
    peer: myPeer,
    remoteStream,
    customerId,
    setVideoDeviceId,
    mediaStream,
    setMicrophoneId,
    setSpeakerId,
    setResolution,
    setReceivedResolution,
    receivedResolution,
    localScreenShareRef,
    setCall,
    setAudioOutputDeviceId,
    audioOutputDeviceId,
    screenShareToggle,
    setForceUpdate,
    toggleCamera,
    screenShareStream,
    isScreenSharing,
    opponentCameraStatus,
    isCallEnded,
    isConnected,
    callPath,
  }

  return (
    <PeerContext.Provider value={contextValue}>
      {children}
    </PeerContext.Provider>
  )
}

PeerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
