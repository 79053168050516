import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloProvider } from '@apollo/client/react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import 'Assets/scss/pwPlatformCss'

// firebase
import './Firebase'

import App from 'Container/App'
import { configureStore } from 'Store'
import { apolloClient } from './GraphQL'

function MainApp() {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={configureStore()}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <Switch>
              <Route
                component={App}
                path="/"
              />
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </Provider>
      <ToastContainer position="bottom-right" />
    </ApolloProvider>
  )
}

export default MainApp
