import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const ControlHeader = ({ icon, label }) => (
  <div className="control-header-container">
    {icon}
    <span>{label}</span>
  </div>
)

export default ControlHeader

ControlHeader.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
}
