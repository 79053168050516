import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderCell } from 'Components/common/Table/TableHeaderCell'
import {
  TableHead,
  TableRow,
} from '@material-ui/core'

export const TableHeader = ({ handleCellClick, headerTitles }) => (
  <TableHead>
    <TableRow hover>
      {headerTitles.map(title => (
        <TableHeaderCell
          key={title.title}
          align={title.field === 'actions' ? 'right' : 'inherit'}
          handleCellClick={handleCellClick}
          isSorting={title.isSorting}
          sortField={title.field}
        >
          {title.title}
        </TableHeaderCell>
      ))}
    </TableRow>
  </TableHead>
)

TableHeader.propTypes = {
  headerTitles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      field: PropTypes.string,
    }),
  ).isRequired,
  handleCellClick: PropTypes.func.isRequired,
}
