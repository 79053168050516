import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { StoreSelect } from 'Components/StoreSelect/StoreSelect'
import './styles.scss'

export const QuestionsPageHeader = ({
  storeId,
  changeStore,
  stores,
  openQuestionModal,
  title,
}) => (
  <div className="d-flex justify-content-between">
    <section className="settings-page-title d-flex flex-column">
      <span><small>Chat settings</small></span>
      <h3 className="pt-5">
        {title}
      </h3>
    </section>
    <section className={isMobile ? 'store-select-container w-100' : 'store-select-container'}>
      <StoreSelect
        changeStore={changeStore}
        storeId={storeId}
        stores={stores}
      />
      <section className="buttons-groups">
        <Button
          className="btn-custom mr-5"
          onClick={openQuestionModal}
        >
          <i className="zmdi zmdi-plus" />
          {' '}
          Add New
        </Button>
      </section>
    </section>
  </div>
)

QuestionsPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  changeStore: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  openQuestionModal: PropTypes.func.isRequired,
}
